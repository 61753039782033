import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import { COMPLETENESS, ID_SEPARATOR, IS_VALID, VALUE } from '../utils';
import { checkCondition } from './condition';
/**
 * Execute a 2-pass calculation of completeness on the provided
 * event data object.
 *
 * A 2-pass calculation is necessary, as conditions can be based
 * on another fields completeness. A one pass approach would require
 * us to calculate which fields completeness we need to evaluate
 * before we can evaluate some other fields condition(s). We do
 * not want to do this now, so we fall back to a 2-pass approach.
 *
 * This function will modify the provided eventData object
 *
 * @param eventSchema
 * @param eventData
 * @returns {object} the eventData object argument
 */

export function eventCompleteness(eventSchema) {
  var eventData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  // first pass, calculate completeness, ignoring conditional fields
  fieldsCompleteness(eventSchema, eventData, false); // second pass, calculate completeness, with field conditions being evaluated

  fieldsCompleteness(eventSchema, eventData, true);
  return eventData;

  function fieldsCompleteness(fieldsContainer, containerData) {
    var evaluateFieldConditions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var depth = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

    if (fieldsContainer.fields) {
      var completenessFromFields = fieldsContainer.fields.map(function (field) {
        if (!containerData.hasOwnProperty(VALUE)) {
          containerData[VALUE] = {};
        }

        var fieldId = field.id.split(ID_SEPARATOR)[depth];

        if (!containerData[VALUE].hasOwnProperty(fieldId)) {
          containerData[VALUE][fieldId] = {};
        }

        var fieldData = containerData[VALUE][fieldId];
        fieldCompleteness(field, fieldData, depth + 1, evaluateFieldConditions); // a field is hidden if the number of mandatory and optional fields === 0

        var fieldIsHidden = fieldData[COMPLETENESS].mandatory.total === 0 && fieldData[COMPLETENESS].optional.total === 0;

        if (fieldIsHidden) {
          // hidden fields must not be included in the eventData
          delete containerData[VALUE][fieldId];
        } else {
          // non-hidden fields must be included
          containerData[VALUE][fieldId] = fieldData;
        }

        return fieldData;
      });
      var completeness = completenessFromFields.reduce(function (containerCompleteness, result) {
        ['mandatory', 'optional'].forEach(function (t) {
          containerCompleteness[t].set += result[COMPLETENESS][t].set > 0 ? 1 : 0;
          containerCompleteness[t].total += result[COMPLETENESS][t].total > 0 ? 1 : 0;
        });
        return containerCompleteness;
      }, {
        mandatory: {
          set: 0,
          total: 0
        },
        optional: {
          set: 0,
          total: 0
        }
      });
      var completenessWithScores = addCompletenessScores(completeness);
      containerData[COMPLETENESS] = completenessWithScores;
      return _defineProperty({}, COMPLETENESS, completenessWithScores);
    }
  }
  /**
   * A list of fields completeness is the number of set+valid
   * fields divided by the total number of fields. (Separately
   * calculated for mandatory and optional fields).
   *
   * completeness.*.set is the number of set+valid fields
   * completeness.*.total is the number of fields
   * completeness.*.score is a value between 0 and 1
   *
   * @param fieldSchema
   * @param fieldData
   * @param evaluateFieldConditions
   * @returns {*}
   */


  function fieldCompleteness(fieldSchema, fieldData) {
    var depth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var evaluateFieldConditions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

    // any field having subfields will only be evaluated based on it's sub-fields
    if (fieldSchema.hasOwnProperty('fields')) {
      return fieldsCompleteness(fieldSchema, fieldData, evaluateFieldConditions, depth);
    } // evaluate the field condition if required,
    // a field condition which evaluates to false,
    // hides the field from any completeness
    // calculations.


    if (evaluateFieldConditions && fieldSchema.hasOwnProperty('condition')) {
      var conditionIsValid = checkCondition(eventSchema, fieldSchema.id, eventData);

      if (conditionIsValid.result === false) {
        fieldData[IS_VALID] = true;
        fieldData[COMPLETENESS] = {
          mandatory: {
            set: 0,
            total: 0,
            score: 1
          },
          optional: {
            set: 0,
            total: 0,
            score: 1
          }
        };
        return fieldData;
      }
    }

    var isMandatory = fieldSchema.def.mandatory || false;
    var completeness = addCompletenessScores({
      mandatory: {
        set: 0,
        total: isMandatory * 1
      },
      optional: {
        set: 0,
        total: !isMandatory * 1
      }
    }); // field not set

    if (!fieldData.hasOwnProperty(VALUE)) {
      fieldData[IS_VALID] = true;
    }

    if (!fieldData.hasOwnProperty(IS_VALID)) {
      throw new Error("required '".concat(IS_VALID, "' property for field '").concat(fieldSchema.id, "'"));
    } // get validation state


    var validationResult = {
      set: fieldData[VALUE] !== undefined,
      valid: fieldData[IS_VALID]
    }; // set field completeness

    completeness = {
      mandatory: {
        set: (isMandatory && validationResult.set && validationResult.valid) * 1,
        total: isMandatory * 1
      },
      optional: {
        set: (!isMandatory && validationResult.set && validationResult.valid) * 1,
        total: !isMandatory * 1
      }
    }; // attach validation and completeness information to field

    fieldData[COMPLETENESS] = addCompletenessScores(completeness);
    return fieldData;
  }
}

function addCompletenessScores(completeness) {
  ['mandatory', 'optional'].forEach(function (t) {
    if (completeness[t].total > 0) {
      completeness[t].score = completeness[t].set / completeness[t].total;
    } else {
      completeness[t].score = 1;
    }
  });
  return completeness;
}