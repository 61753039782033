import {defineMessages} from 'react-intl';
import moment from 'moment';

const messages = defineMessages({
    footerPageInfo: {
        id: 'print_template_footer_page_text',
        defaultMessage: '- {page} of {pages} -'
    },
    footerPrintDate: {
        id: 'print_template_footer_date_text',
        defaultMessage: 'Printed on {formattedDate}'
    },
    footerHomepage: {
        id: 'print_template_footer_source_text',
        defaultMessage: 'A service of www.progether.com'
    }
});

export const marginChanges = {
    bottom: 10
};

export const normal = (doc, renderOptions) => {

    const {
        size: {width, height},
        margins: {bottom, left, right},
        language,
        intl,
        footer: {
            page,
            pages
        }
    } = renderOptions;

    const footerPaddings = {
        top: 10,
        bottom: 0,
        left: 0,
        right: 0
    };

    const footerBlockPadding = 10;

    const footerWidth = width - left - right;
    const footerLeft = left;

    doc.save()
        .font('light')
        .fontSize(10);

    const footerHeight = bottom + marginChanges.bottom;
    const footerTop = height - footerHeight;

    const footerBoxTop = footerTop;
    const footerBoxHeight = footerHeight;
    const footerBoxLeft = 0;
    const footerBoxWidth = width;

    doc.save()
        .fillColor('#f7f7f7')
        .rect(footerBoxLeft, footerBoxTop, footerBoxWidth, footerBoxHeight)
        .fill()
        .restore();

    const renderPrintdate = () => {

        const top = footerTop + footerPaddings.top;
        const left = footerLeft + footerPaddings.left;
        const width = (footerWidth - footerBlockPadding) / 2;
        const text = intl.formatMessage(messages.footerPrintDate, {formattedDate: moment().locale(language).format('LL')});

        doc.fillColor('#555')
            .text(text, left, top, {width});

        doc.text(intl.formatMessage(messages.footerHomepage), {
            link: 'https://www.progether.com',
            underline: false,
            align: 'left'
        });

    };

    const renderPageInfo = () => {

        const top = footerTop + footerPaddings.top;
        const width = (footerWidth - footerBlockPadding) / 2;
        const left = footerLeft + width + footerBlockPadding;

        doc.fillColor('#555')
            .text(intl.formatMessage(messages.footerPageInfo, {page, pages}), left, top, {
                width,
                align: 'right'
            });

    };

    renderPrintdate();
    renderPageInfo();

    doc.restore();

    // return modified renderOptions
    return Object.assign({}, renderOptions, {
        margins: Object.assign({}, renderOptions.margins, {
            bottom: footerHeight + bottom
        })
    });

};

export const renderFooterLastPage = (doc, renderOptions) => {

    const {
        size: {width, height},
        margins: {bottom, left},
        language,
        intl
    } = renderOptions;

    const footerPaddings = {
        top: 10,
        bottom: 0,
        left: 0,
        right: 0
    };

    const logoHeight = 50;
    const logoWidth = 1000 * (logoHeight / 546);
    const blockPadding = 20;
    const footerLeft = left;
    const textWidth = 100;

    doc.save()
        .font('light')
        .fontSize(10);

    const footerAboutProgetherText = intl.formatMessage(messages.whatIsProgetherDoing);
    const footerAboutProgetherTextHeight = doc.heightOfString(footerAboutProgetherText, {textWidth});

    const footerHeight = Math.min(50, footerAboutProgetherTextHeight) + footerPaddings.top + footerPaddings.bottom;
    const footerTop = height - bottom - footerHeight;

    const footerBoxTop = footerTop;
    const footerBoxHeight = footerHeight + bottom;
    const footerBoxLeft = 0;
    const footerBoxWidth = width;

    doc.save()
        .fillColor('#f7f7f7')
        .rect(footerBoxLeft, footerBoxTop, footerBoxWidth, footerBoxHeight)
        .fill()
        .restore();

    const logoLeft = footerLeft + footerPaddings.left;
    const logoTop = footerTop + footerPaddings.top;

    doc.image(`images/progether-logo-${language}-alpha-1000.png`, logoLeft, logoTop, {
        height: logoHeight
    });

    const textLeft = footerLeft + footerPaddings.left + blockPadding + logoWidth;
    const textTop = footerTop + footerPaddings.top;
    const textHeight = footerHeight;

    doc.fillColor('#555')
        .text(footerAboutProgetherText, textLeft, textTop, {textWidth, textHeight});

    doc.restore();

    // return modified renderOptions
    return Object.assign({}, renderOptions, {
        margins: Object.assign({}, renderOptions.margins, {
            bottom: footerHeight + bottom
        })
    });

};

export const renderFooterFirstPage = normal;
