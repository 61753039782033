import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {Popover, attachToBody} from '../../popups';

export class EventDetailsPopover extends Component {

    static propTypes = {
        editLabel: PropTypes.string,
        cancelTitle: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
        show: PropTypes.bool,
        proceed: PropTypes.func,     // called when ok button is clicked.
        cancel: PropTypes.func,      // called when cancel button is clicked.
        dismiss: PropTypes.func,     // called when backdrop is clicked or escaped.
        placement: PropTypes.string, // top, bottom, left, right
        positionLeft: PropTypes.number,
        positionTop: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
        element: PropTypes.object,
        editStyle: PropTypes.string
    };

    componentDidMount() {
        this.updatePlacement();
    }

    componentDidUpdate() {
        this.updatePlacement();
    }

    updatePlacement() {

        const popover = this._staticConfirm.childNodes[0];
        if (popover && this.props.height === 'auto') {


            switch (this.props.placement) {
                case 'top' : {
                    const bb = popover.getBoundingClientRect();

                    // this is apparently the way to escalate: https://github.com/nuxt/nuxt.js/issues/2512#issuecomment-358215583
                    const scrollTop = Math.max(
                        window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop
                    );

                    popover.style.top = (150 - bb.height + bb.top + scrollTop) + 'px';
                    break;
                }
            }

        }

    }

    render() {

        const {
            editLabel = 'Yes',
            cancelTitle = 'No',
            title = 'Event',
            text = '...',
            editStyle = 'primary',
            proceed,
            cancel,
            placement = 'top',
            positionLeft,
            positionTop,
            width = 250,
            height = 150,
        } = this.props;

        const titleNode = (
            <div>
                {title}
                <a className="close" onClick={() => cancel()} title={cancelTitle}>
                    <i className="fa fa-times"/>
                </a>
            </div>
        );

        return (
            <div className="static-confirm" ref={node => this._staticConfirm = node}>
                <Popover
                    id="popup-event-data"
                    title={titleNode}
                    style={{width:width+'px',height:height+'px'}}
                    placement={placement}
                    positionLeft={positionLeft}
                    positionTop={positionTop}
                >
                    <ReactMarkdown
                        escapeHtml={true}
                        containerTagName="div"
                        className=""
                        source={text}
                    />
                    { proceed ? (
                        <div className="button-toolbar" style={{paddingBottom:'6px', float:'right'}}>
                            <button className={`btn btn-small btn-${editStyle}`} onClick={proceed}>{editLabel}</button>
                        </div>
                    ) : null }
                </Popover>
            </div>
        );

    }

}

export const showDetailsPopup = (options = {}) => attachToBody(EventDetailsPopover)({ ...options });
