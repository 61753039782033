import { SchemaFactory } from "progether-data-schema";
import { createContext, useContext, useEffect, useReducer } from "react";

export const EventSchemaContext = createContext(null);

function reducer(state, action) {
    switch (action.type) {
        case 'load':
            return { ...state, loading: true, error: '' };
        case 'load.success':
            return { ...state, loading: false, schema: action.schema };
        case 'load.failure': 
            return { ...state, loading: false, schema: null, error: action.error };
        default:
            return state;
    }
}

export function EventSchemaProvider({ tags, version, children }) {
    const [state, dispatch] = useReducer(reducer, { loading: true, schema: null, error: ''});

    useEffect(() => {
        dispatch({ type: 'load' });
        SchemaFactory.getInstance(tags, version)
            .then((schema) => dispatch({ type: 'load.success', schema }))
            .catch((error) => dispatch({ type: 'load.failure', error: error.message }));
    }, [tags, version]);

    return (
        <EventSchemaContext.Provider value={state}>
            {children}
        </EventSchemaContext.Provider>
    )
}

export function useEventSchema() {
    const context = useContext(EventSchemaContext);
    if (!context) {
        throw new Error('no EventSchemaContext found');
    }

    return context;
}