import { createContext, useCallback, useEffect, useMemo, useReducer } from "react";
import LoadingPlaceholder from "../components/loading-placeholder";


export const EventEditorContext = createContext();

const defaultState = { event: null, error: '', loading: true, deleting: false, saving: false, dirty: false }

export function EventEditorProvider({ eventTypeId, eventId, storage, onContinue, onDone, children }) {

    const [state, dispatch] = useReducer(reducer, defaultState);

    useEffect(() => {
        dispatch({ type: 'load' });

        const handleSuccess = event => {
            dispatch({ type: 'load.success', event });
        };

        const handleError = error => {
            console.error(error);
            dispatch({ type: 'load.failure', error: error.message })
        };

        if (eventId) {
            storage.getEvent(eventId)
                .then(handleSuccess)
                .catch(handleError)
        } else if (eventTypeId) {
            storage.createEvent(eventTypeId)
                .then(handleSuccess)
                .catch(handleError);
        } else {
            handleError(new Error('no eventId or eventTypeId given'));
        }

    }, [eventTypeId, eventId]);

    const onSave = useCallback(async (event, next) => {
        dispatch({ type: 'save' });

        await storage.saveEvent(event)
            .then(() => dispatch({ type: 'save.success' }))
            .catch(error => dispatch({ type: 'save.failure', error: error.message }));

        if (next) {
            return next();
        }

        if (onDone) {
            onDone();
        }
    }, [storage, eventTypeId, onDone]);

    const onSaveAndNext = useMemo(() => {
        if (!onContinue) {
            return undefined;
        }

        return (event) => onSave(event, onContinue);
    }, [onSave, onContinue]);

    const onUpdate = useCallback((event) => {
        console.log('on update', event);
        dispatch({ type: 'update', event })
    }, [dispatch]);

    const onDelete = useMemo(() => {
        dispatch({ type: 'delete' });
        if (!eventId) {
            return undefined;
        }

        return () => {
            storage.deleteEvent(eventId)
                .then(onDone)
                .catch(error => dispatch({ type: 'delete.failure', error: error.message }));
        };
    }, [eventId, onDone]);

    const contextValue = useMemo(() => ({
        ...state,
        onDelete,
        onUpdate,
        onSave,
        onSaveAndNext
    }), [state, onDelete, onUpdate, onSave, onSaveAndNext]);

    return (
        <LoadingPlaceholder loading={state.loading}>
            <EventEditorContext.Provider value={contextValue}>
                {children}
            </EventEditorContext.Provider>
        </LoadingPlaceholder>
    );

}


function reducer(state, action) {
    switch(action.type) {
        case 'load':
            return { ...state, loading: true, error: '', dirty: false, event: null };

        case 'load.success': 
            return { ...state, loading: false, event: action.event };

        case 'load.failure':
            return { ...state, loading: false, error: action.error };

        case 'update':
            return { ...state, event: action.event, dirty: true };

        case 'save':
            return { ...state, saving: true };

        case 'save.success':
            return { ...state, saving: false, dirty: false };

        case 'save.failure':
            return { ...state, saving: false, error: action.error };

        case 'delete':
            return { ...state, deleting: true };

        case 'delete.success':
            return defaultState;

        case 'delete.failure': 
            return { ...state, deleting: false, error: action.error };

        default:
            return state;
    }
}
