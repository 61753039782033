import { defineMessages } from "react-intl";
import { Conditional } from "../utils/conditional";
import { eventFormTitle } from "../../lib/intl-helper";
import { LoadingAndAlternative } from "../loading-placeholder/loading-and-alternative";
import DataForm from '../data-form';
import { EditTitle, messages as titleMessages } from "./edit-title";
import { Help } from "./edit-help";
import { EditActions } from "./edit-actions";
import { GuidanceProgress } from "./guidance-progress";
import './event-editor.less';

const messages = defineMessages({
    showHelpLabel: {
        id: "event_form_help_floating_button_label",
        defaultMessage: "Help & Info",
    },
    hideHelpLabel: {
        id: "event-dialog-help-mobileclose-link",
        defaultMessage: "Back to data entry",
    },
    progressLabel: {
        id: "part_my_data_edit_completeness_mandatory_label",
        defaultMessage: "Completeness",
    },
});


export function EventEditor({
    event,
    showHelp,
    stylePaged,
    showCompleteness,
    renderMobile,
    deleteInProgress,
    saveInProgress,
    onCancel,
    onChange,
    onSubmit,
    onSubmitAndNext,
    onRemove,
    onShowHelp,
    onChangeStyle,
    onShowCompleteness,
}) {


    return (
        <div className="edit-event-container">
            <div className="edit-event-box">
                <EditTitle
                    titleText={{
                        id: eventFormTitle(event && event.typeId()),
                    }}
                    helpText={
                        showHelp
                            ? messages.hideHelpLabel
                            : messages.showHelpLabel
                    }
                    backText={
                        showHelp
                            ? messages.backToDataEntryLabel
                            : titleMessages.backToMyDataLabel
                    }
                    onHelp={() => onShowHelp(!showHelp)}
                    onBack={showHelp ? () => onShowHelp(false) : onCancel}
                />

                <Conditional cond={showHelp}>
                    <Help
                        onClose={() => onShowHelp(!showHelp)}
                        event={event}
                        closeMessage={messages.hideHelpLabel}
                    />
                </Conditional>

                <Conditional cond={!showHelp}>
                    <LoadingAndAlternative
                        loading={saveInProgress || deleteInProgress}
                        condition={true}
                    >
                        <div className="edit-event-box-form-container">
                            <DataForm
                                event={event}
                                pagedFields={stylePaged}
                                onChange={(event) => onChange(event)}
                                showCompleteness={showCompleteness}
                            />

                            <GuidanceProgress
                                primary={{
                                    progress:
                                        event.completeness().mandatory.score,
                                    title: messages.progressLabel,
                                }}
                                secondary={{
                                    progress:
                                        event.completeness().optional.score,
                                    title: messages.progressLabel,
                                }}
                                disableSecondary={
                                    true /*!(event.completeness().mandatory.score === 1 && event.completeness().mandatory.set > 0)*/
                                }
                                renderMobile={renderMobile}
                            />
                        </div>
                    </LoadingAndAlternative>
                </Conditional>

                <Conditional cond={!showHelp}>
                    <EditActions
                        enableRemove={!!event.id()}
                        enableCancel={!!event}
                        enableSubmit={
                            !!(
                                event &&
                                event.isValid() &&
                                event.completeness().mandatory.score === 1
                            )
                        }
                        removeInProgress={deleteInProgress}
                        onRemove={() => onRemove(event.id())}
                        onCancel={onCancel}
                        submitInProgress={saveInProgress}
                        onSubmit={() => {
                            if (
                                event.isValid() &&
                                event.completeness().mandatory.score === 1
                            ) {
                                onSubmit(event);
                            } else {
                                onShowCompleteness(true);
                            }
                        }}
                        onSubmitAndNext={
                            onSubmitAndNext
                                ? () => {
                                    if (
                                        event.isValid() &&
                                        event.completeness().mandatory
                                            .score === 1
                                    ) {
                                        onSubmitAndNext(event);
                                    } else {
                                        onShowCompleteness(true);
                                    }
                                }
                                : undefined
                        }
                    />
                </Conditional>
            </div>
        </div>

    )
}