import React, {Component} from 'react';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import {eventFieldPlaceholder} from '../../../../lib/intl-helper';
import {FormsMixin} from '../forms-mixin';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const messages = defineMessages({
    undefinedOption: {
        id: 'part_data_ui_form_types_oneof_select_option_none_label',
        defaultMessage: '(none)'
    }
});

const Option = ({active, label, value}) => {

    const classes = classnames('btn', 'btn-default', {
        active: active
    });

    return (
        <option className={classes} value={value}>{label}</option>
    );

};

Option.propTypes = {
    active: PropTypes.bool,
    label: PropTypes.node,
    value: PropTypes.any,
};

const UNDEFINED_OPTION_VALUE = '$$$_UNDEFINED_VALUE';

export class OneOfSelect extends Component {

    static propTypes = {
        values: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string,
            labelFormatted: PropTypes.node
        })).isRequired,
        selectedValue: PropTypes.any,
        onSelection: PropTypes.func.isRequired,
        allowUndefined: PropTypes.bool
    };

    static defaultProps = {
        allowUndefined: true
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const {onSelection} = this.props;
        const value = e.target.value;
        onSelection(value === UNDEFINED_OPTION_VALUE ? undefined : value);
    }

    renderUndefinedOption() {

        const {allowUndefined, selectedValue} = this.props;

        if (!allowUndefined) {
            return null;
        }

        return (
            <FormattedMessage {...messages.undefinedOption}>
                {
                    (txt) => <Option key={UNDEFINED_OPTION_VALUE}
                        active={selectedValue === undefined}
                        label={txt}
                        value={UNDEFINED_OPTION_VALUE}/>
                }
            </FormattedMessage>
        );

    }

    render() {

        const {values, selectedValue} = this.props;

        return (
            <div>
                <select className="form-control" value={selectedValue} onChange={this.handleChange}>
                    {this.renderUndefinedOption()}
                    {
                        values.map(v => {
                            const isActive = v.value === selectedValue;
                            return (
                                <Option key={v.value}
                                    active={isActive}
                                    label={v.labelFormatted || v.label || v.value}
                                    value={v.value}/>
                            );
                        })
                    }
                </select>
            </div>
        );

    }

}

class ConnectedOneOfSelect extends FormsMixin(Component) {

    handleSelectChange(e) {
        let val = e.target.value;
        this.update(this.props.def, this.props.id, val, val !== null, (val !== null) ? 1 : 0);
    }

    getPlaceholderText() {

        const {intl} = this.props;
        const messageId = eventFieldPlaceholder(this.props.eventId, this.props.id);
        return intl.messages.hasOwnProperty(messageId) ? intl.formatMessage({id: messageId}) : '';

    }

    render() {

        const def = this.props.def;
        const items = def.values.slice(0); // copy array

        if (items[0].value !== null) {
            items.splice(0, 0, {
                key: 'default',
                value: null,
                label: '' // TODO should use this.getPlaceholderText(), but requires additional handling
            });
        }

        return (
            <div>
                <select onChange={(e) => this.handleSelectChange(e)} className="form-control" value={this.getValue()}>
                    {
                        items.map(i => <SelectOption key={i.key || i.value} {...i}/>)
                    }
                </select>
            </div>
        );
    }

}

function SelectOption(props) {

    const label = props.labelFormatted ? props.labelFormatted : props.label;

    return (
        <option
            value={props.value}>
            {label}
        </option>
    );

}

SelectOption.propTypes = {
    labelFormatted: PropTypes.node,
    label: PropTypes.node,
    key: PropTypes.string,
    value: PropTypes.any,
};

export default injectIntl(ConnectedOneOfSelect);
