import React, {Component} from 'react';
import {FormsMixin} from '../forms-mixin';
import {eventFieldPlaceholder, eventFieldPostfixLabel, eventFieldPrefixLabel} from '../../../../lib/intl-helper';
import {injectIntl} from 'react-intl';

function isNumberFormat(valueType) {
    return ['float', 'int', 'integer'].indexOf(valueType) !== -1;
}

class Input extends FormsMixin(Component) {

    // numbers need special treatment
    getValueFromInput(input) {

        if (this.props.def.valueType && input && ('' + input).length > 0) {
            const matchEndChar = ('' + input).match(/([,.]0*)+$/);
            if(matchEndChar){
                this.numberSpecialCharAtEnd = matchEndChar[1];
            } else {
                this.numberSpecialCharAtEnd = null;
            }

            // handle number fields
            if (isNumberFormat(this.props.def.valueType)) {
                // accept German and English separators
                if (input.match(/^-?(\d+,)*\d+\.\d*$/)) {
                    // en number format
                    input = input.replace(/,/g, '');
                } else if (input.match(/^-?(\d+\.)*\d+,\d*$/)) {
                    // de number format
                    input = input.replace(/\./g, '').replace(/,/g, '.');
                }

                // parse as number
                if (!isNaN(input)) {
                    let value = null;
                    switch (this.props.def.valueType) {
                        case 'float':
                            value = parseFloat(input);
                            break;
                        case 'int':
                        case 'integer':
                            value = parseInt(input);
                            break;
                    }
                    return value;
                } else {
                    // Return broken input instead of null.
                    // Returning null would reset the input field if
                    // one would enter accidentally a non digit
                    // character into the input. This would
                    // understandably piss people off.
                    return input;
                }
            }
        }

        return input;

    }

    getValueFromProps() {
        let nextValue = this.getValue(),
            outputValue = nextValue;

        // handle numbers differently
        if (isNumberFormat(this.props.def.valueType)) {

            // has intl property with number format function
            let hasIntl = function() {
                return Intl && Intl.NumberFormat;
            };

            // has a formattable number as value
            let hasValue = function() {
                return nextValue !== null
                    && nextValue !== ''
                    && !isNaN(nextValue);
            };

            if (hasIntl() && hasValue()) {
                outputValue = new Intl.NumberFormat('de-DE', { useGrouping: false, maximumFractionDigits: 5 }).format(nextValue);
            }

        }

        const matchEndChar = ('' + outputValue).match(/([,.]0*)+$/);
        if((!matchEndChar && this.numberSpecialCharAtEnd && outputValue.length - 1 >= 0)){
            outputValue += this.numberSpecialCharAtEnd;
        }

        return outputValue;
    }

    getPostFixText() {

        if(this.props.postfixText) return this.props.postfixText;

        const msgId = eventFieldPostfixLabel(this.props.eventId, this.props.id);
        return this.props.intl.messages.hasOwnProperty(msgId) ? this.props.intl.messages[msgId] : null;

    }

    getPrefixText() {

        if(this.props.prefixText) return this.props.prefixText;

        const msgId = eventFieldPrefixLabel(this.props.eventId, this.props.id);
        return this.props.intl.messages.hasOwnProperty(msgId) ? this.props.intl.messages[msgId] : null;

    }

    getPlaceholderText() {
        const {intl} = this.props;
        const messageId = eventFieldPlaceholder(this.props.eventId, this.props.id);
        return intl.messages.hasOwnProperty(messageId) ? intl.formatMessage({id: messageId}) : null;
    }

    handleTextOnChange = (e) => {
        let value = e.target.value;

        this.props.onChange(this.props.id, this.getValueFromInput(value));

    };

    render() {
        let additionalProps = {};

        if (this.props.def.defaultSize) {
            additionalProps.size = this.props.def.defaultSize;
            additionalProps.style = {
                width: (this.props.def.defaultSize * 15) + 'px'
            };
        }

        let input = (
            <input
                type="text"
                className="form-control"
                value={this.getValueFromProps() || ''}
                onChange={this.handleTextOnChange}
                placeholder={this.getPlaceholderText()}
                {...additionalProps} />
        );

        const postfixText = this.getPostFixText();
        const prefixText = this.getPrefixText();

        if (prefixText || postfixText) {
            return (
                <div className="input-group" style={{width: (this.props.def.defaultSize ? '1%' : '100%')}}>
                    {prefixText ? <div className="input-group-addon">{prefixText}</div> : null}
                    {input}
                    {postfixText ? <div className="input-group-addon">{postfixText}</div> : null}
                </div>
            );
        }

        return input;
    }

}

export default injectIntl(Input);
