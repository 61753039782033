import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import moment from 'moment';
import { defineMessages } from 'progether-messages';
import { getDateFromEvent } from './event-utils';
var toDateMessages = defineMessages({
  pointComplete: {
    "id": "event_time_complete_date",
    "defaultMessage": "on {date, date}"
  },
  pointNoDay: {
    "id": "event_time_complete_one_month_no_days",
    "defaultMessage": "in {date}"
  },
  rangeComplete: {
    "id": "event_time_complete_range_complete",
    "defaultMessage": "from {from} to {to}"
  },
  rangeIncomplete: {
    "id": "event_time_incomplete",
    "defaultMessage": "since {date}"
  },
  pointToday: {
    "id": "event_time_today",
    "defaultMessage": "Today"
  },
  rangeInSameYear: {
    "id": "event_time_complete_range_complete_same_year_year_only",
    "defaultMessage": "within {year}"
  }
});
var DATE_TO_STRING_FORMAT_MAPPINGS = {
  rangeOngoingDaySet: 'll',
  rangeOngoingNoDaySet: 'MMM YYYY',
  rangeOngoingNoMonthSet: 'YYYY',
  rangeEndedSameYearSameMonthWithTwoDaysFrom: 'll',
  rangeEndedSameYearSameMonthWithTwoDaysTo: 'll',
  rangeEndedSameYearSameMonthWithOneDay: 'MMM YYYY',
  rangeEndedSameYearDifferentMonthFrom: 'MMM',
  rangeEndedSameYearDifferentMonthTo: 'MMM YYYY',
  rangeEndedSameYearWithMonth: 'MMM YYYY',
  rangeEndedSameYear: 'YYYY',
  rangeEndedDiffYearWithTwoDaysFrom: 'll',
  rangeEndedDiffYearWithTwoDaysTo: 'll',
  rangeEndedDiffYearWithOneDayFrom: 'MMM YYYY',
  rangeEndedDiffYearWithOneDayTo: 'MMM YYYY',
  rangeEndedDiffYearWithMonth: 'MMM YYYY',
  rangeEndedDiffYear: 'YYYY',
  pointWithDay: 'll',
  pointWithoutDay: 'MMMM YYYY',
  pointWithoutMonth: 'YYYY'
};
var BACKUP_DATE_STRING = '-';
/**
 * Extract date information from eventData and convert to localized string
 *
 * @param event
 * @param {object} intl - Translation context (progether-server-intl)
 */

export function dateToString(event, intl) {
  if (!intl) {
    throw new Error('Requires an instance of (react-)intl as second parameter');
  }

  var eventDate = getDateFromEvent(event);

  if (!eventDate) {
    console.warn('event has no date information', event.id());
    return BACKUP_DATE_STRING;
  }

  try {
    var forMoment = mapMomentLocale(intl.locale);

    if (eventDate.interval) {
      var from = explodeIsoDate(eventDate.range.from);
      var to = explodeIsoDate(eventDate.range.to); // date range

      if (!to) {
        // still running
        if (from.day > 0) {
          // day set
          return intl.formatMessage(toDateMessages.rangeIncomplete, {
            date: eventDate.from.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeOngoingDaySet)
          });
        } else {
          // no day given
          return intl.formatMessage(toDateMessages.rangeIncomplete, {
            date: eventDate.from.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeOngoingNoDaySet)
          });
        }
      } else {
        // has ended
        if (to.year === from.year) {
          // in same year
          if (to.month > 0 && from.month > 0) {
            // both months set
            if (to.month === from.month) {
              // in same month
              if (from.day > 0 && to.day > 0) {
                // with days given
                return intl.formatMessage(toDateMessages.rangeComplete, {
                  from: eventDate.from.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedSameYearSameMonthWithTwoDaysFrom).replace(from.year, ''),
                  to: eventDate.to.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedSameYearSameMonthWithTwoDaysTo)
                });
              } else {
                // with at least one day not set
                return intl.formatMessage(toDateMessages.pointNoDay, {
                  date: eventDate.from.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedSameYearSameMonthWithOneDay)
                });
              }
            } else {
              // with diverging months
              return intl.formatMessage(toDateMessages.rangeComplete, {
                from: eventDate.from.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedSameYearDifferentMonthFrom),
                to: eventDate.to.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedSameYearDifferentMonthTo)
              });
            }
          } else if (from.month > 0 && to.month <= 0) {
            // from month set
            if (from.day > 0) {
              // from day set
              return intl.formatMessage(toDateMessages.rangeComplete, {
                from: eventDate.from.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedSameYearDifferentMonthFrom),
                to: eventDate.to.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedSameYear)
              });
            } else {
              // from day not set
              return intl.formatMessage(toDateMessages.rangeComplete, {
                from: eventDate.from.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedSameYearDifferentMonthFrom),
                to: eventDate.to.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedSameYear)
              });
            }
          } else if (to.month > 0 && from.month <= 0) {
            // to month set
            if (to.day > 0) {
              // to day set
              return intl.formatMessage(toDateMessages.rangeComplete, {
                from: eventDate.from.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedSameYear),
                to: eventDate.to.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedSameYearDifferentMonthTo)
              });
            } else {
              // to day not set
              return intl.formatMessage(toDateMessages.rangeComplete, {
                from: eventDate.from.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedSameYear),
                to: eventDate.to.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedSameYearDifferentMonthTo)
              });
            }
          } else {
            // no months set
            return intl.formatMessage(toDateMessages.rangeInSameYear, {
              year: from.year
            });
          }
        } else {
          // not in same year
          if (to.month > 0 && from.month > 0) {
            // both have month info
            if (from.day > 0 && to.day > 0) {
              // both have days info
              return intl.formatMessage(toDateMessages.rangeComplete, {
                from: eventDate.from.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedDiffYearWithTwoDaysFrom),
                to: eventDate.to.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedDiffYearWithTwoDaysTo)
              });
            } else {
              // with at least one day not given
              return intl.formatMessage(toDateMessages.rangeComplete, {
                from: eventDate.from.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedDiffYearWithOneDayFrom),
                to: eventDate.to.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedDiffYearWithOneDayTo)
              });
            }
          } else if (from.month > 0 && to.month <= 0) {
            // only from month set
            if (from.day > 0) {
              // from day set
              return intl.formatMessage(toDateMessages.rangeComplete, {
                from: eventDate.from.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedDiffYearWithOneDayFrom),
                to: eventDate.to.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedDiffYear)
              });
            } else {
              // from day not set
              return intl.formatMessage(toDateMessages.rangeComplete, {
                from: eventDate.from.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedDiffYearWithMonth),
                to: eventDate.to.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedDiffYear)
              });
            }
          } else if (to.month > 0 && from.month <= 0) {
            // only to month set
            if (to.day > 0) {
              // to day set
              return intl.formatMessage(toDateMessages.rangeComplete, {
                from: eventDate.from.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedDiffYear),
                to: eventDate.to.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedDiffYearWithOneDayTo)
              });
            } else {
              // to day not set
              return intl.formatMessage(toDateMessages.rangeComplete, {
                from: eventDate.from.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedDiffYear),
                to: eventDate.to.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedDiffYearWithMonth)
              });
            }
          } else {
            // no months set
            return intl.formatMessage(toDateMessages.rangeComplete, {
              from: eventDate.from.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedDiffYear),
              to: eventDate.to.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.rangeEndedDiffYear)
            });
          }
        }
      }
    } else {
      // point in time
      var _explodeIsoDate = explodeIsoDate(eventDate.point),
          year = _explodeIsoDate.year,
          month = _explodeIsoDate.month,
          day = _explodeIsoDate.day;

      if (day > 0) {
        // day is set
        return eventDate.from.locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.pointWithDay);
      } else if (month > 0) {
        // month is set but day not
        // months must be zero indexed here
        return moment([year, month - 1, 1]).locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.pointWithoutDay);
      } else {
        // only year set
        return moment([year, 0, 1]).locale(forMoment).format(DATE_TO_STRING_FORMAT_MAPPINGS.pointWithoutMonth);
      }
    }
  } catch (e) {
    console.error('Could not convert date to string', event, e.message, e);
    return BACKUP_DATE_STRING;
  }
}
/**
 * @typedef {Object} ExplodedIsoDate
 * @property {number} year
 * @property {number} month
 * @property {number} day
 */

/**
 *
 * @param {string} isoDate
 * @returns {ExplodedIsoDate|undefined}
 */

function explodeIsoDate(isoDate) {
  if (!isoDate) {
    return undefined;
  }

  var _isoDate$split = isoDate.split('-'),
      _isoDate$split2 = _slicedToArray(_isoDate$split, 3),
      _isoDate$split2$ = _isoDate$split2[0],
      year = _isoDate$split2$ === void 0 ? '' : _isoDate$split2$,
      _isoDate$split2$2 = _isoDate$split2[1],
      month = _isoDate$split2$2 === void 0 ? '0' : _isoDate$split2$2,
      _isoDate$split2$3 = _isoDate$split2[2],
      day = _isoDate$split2$3 === void 0 ? '0' : _isoDate$split2$3;

  return {
    year: parseInt(year, 10),
    month: parseInt(month, 10),
    day: parseInt(day, 10)
  };
}

function mapMomentLocale(locale) {
  switch (locale) {
    case 'no':
      return 'nb';

    default:
      return locale;
  }
}