import moment from "moment";
import { useCallback, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { ContentBox } from "../components/content-box/content-box";
import { EventTabs } from "../components/event-vis";
import { MenuBar } from "../components/menu-bar/menu-bar";
import { EventContext, useEvents } from "../contexts/event.context";
import { triggerDownload } from "../lib/download";
import { renderPdf } from "../lib/pdf-rendering/print-events";

export function DataPage() {
  const {events, loading} = useEvents();
  const navigate = useNavigate();

  const handleCreate = useCallback((eventTypeId) => {
    navigate('add');
  }, [navigate]);

  const handleEdit = useCallback((event) => {
    navigate('edit/' + event.id());
  }, [navigate]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">

          <MenuBar>
            <PrintMenu/>
            <SettingsMenu/>
          </MenuBar>

          <ContentBox id="my-data-overview">
            <EventTabs 
              events={events} 
              loading={loading} 
              showComments 
              onCreateEvent={handleCreate} 
              onEventSelection={handleEdit}
            />

              <div className="text-center" style={{ padding: '1em 0'}}>
                <Link to="./add" className="btn btn-primary btn-lg">
                  <i className="fa fa-plus-square"/>{' '}
                  <FormattedMessage id="menu_action_add_event_label" defaultMessage="Add data"/>
                </Link>
              </div>
          </ContentBox>
        </div>
      </div>
    </div>
  );
}
  


function PrintMenu() {
  const intl = useIntl();
  const {events, loading} = useEvents();

  const startDownload = (size) => {
    renderPdf(events, { language: intl.locale, size, intl })
      .then((data) => triggerDownload(data.buffer, 'application/pdf', `progether-events_${moment().format('YYYY-MM-DD_HHmmss')}.pdf`))
      .catch(console.error);
  };

  const handleA4Clicked = useCallback((e) => {
    e.preventDefault();
    startDownload('A4');
  }, [events]);

  const handleLetterClicked = useCallback((e) => {
    e.preventDefault();
    startDownload('LETTER');
  }, [events]);

  return (
    <div className="btn-group">
      <button
        type="button"
        className="btn btn-link dropdown-toggle"
        data-toggle="dropdown" 
        aria-haspopup="true"
        aria-expanded="false"
        disabled={loading}
      >
        <i className="fa fa-print"/>{' '} 
        <FormattedMessage id="part_my_data_legend_options_print" defaultMessage="Print"/>
      </button>
      <ul className="dropdown-menu">
        <li>
          <a href="#" role="button" onClick={handleA4Clicked}>
            <i className="fa fa-print"/>{' '} DIN A4
          </a>
        </li>
        <li>
          <a href="#" role="button" onClick={handleLetterClicked}>
            <i className="fa fa-print"/>{' '} US Letter
          </a>
        </li>
      </ul>
    </div>
  )
}

function SettingsMenu() {
  const intl = useIntl();

  const { storage } = useContext(EventContext);

  const handleExport = useCallback(async (e) => {
      e.preventDefault();
      if (storage.exportEvents) {
        return storage.exportEvents();
      }

      const events = storage.listEvents().map(e => e._event);
      triggerDownload(JSON.stringify(events, null, 2), 'application/json', `progether-events_${moment().format('YYYY-MM-DD_HHmmss')}.json`);
  }, [storage]);

  const handleImport = useCallback(async (e) => {
      e.preventDefault();

      const confirmed = window.confirm(intl.formatMessage({
        id: 'data_page_datamgmt_import_confirm_overwrite_message',
        defaultMessage: 'This operation will replace all existing events, are you sure?'
      }));

      if (!confirmed) {
        return;
      }

      storage.importEvents();
  }, [storage, intl]);

  const exportLinkProps = storage.exportEventsLink
    ? storage.exportEventsLink()
    : { onClick: handleExport };

  return (
    <div className="btn-group">
      <button 
        className="btn btn-link dropdown-toggle" 
        data-toggle="dropdown" 
        role="button" 
        aria-haspopup="true" 
        aria-expanded="false"
      >
        <i className="fa fa-database"/>{' '}
        <FormattedMessage id="data_page_datamgmt_menu_label" defaultMessage="Manage your Data"/>
      </button>
      <ul className="dropdown-menu">
          <li>
              <a href="#" role="button" {...exportLinkProps}>
                <i className="fa fa-download"/>{' '}
                <FormattedMessage id="data_page_datamgmt_menu_export_action_label" defaultMessage="Export data"/>
              </a >
          </li>
          {storage.importEvents && (
              <li>
                <a role="button" onClick={handleImport}>
                  <i className="fa fa-upload"/>{' '}
                  <FormattedMessage id="data_page_datamgmt_menu_import_action_label" defaultMessage="Import data"/>
                </a >
              </li>
          )}
      </ul>
    </div>
  )
}