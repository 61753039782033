import moment from "moment";
import { eventFromJSON } from "progether-data-schema";
import { EventStorage } from "./event-storage";


export class RemoteEventStorage extends EventStorage {
    _storageKey;
    _events = new Map();

    constructor(storageKey) {
        super();
        this._storageKey = storageKey;
    }

    async load() {
        const response = await fetch('/api/web/v1/my/events', {
            method: 'get',
            headers: {
                accept: 'application/json'
            }
        });

        if (!response.ok) {
            if (response.status === 404) {
                this._events = new Map();
                return;
            }
            throw new Error(`unexpected status code from backend: ${response.status}`);
        }

        const events = await response.json();

        this._events = new Map();
        for (let jsonEvent of events) {
            try {
                const event = await eventFromJSON(jsonEvent);
                this._events.set(event.id(), event);
            } catch (error) {
                console.warn('failed to read event', jsonEvent);
            }
        }

        this._syncStorage();
    }

    async getEvent(eventId) {
        if (!this._events.has(eventId)) {
            throw new Error(`unknown eventId: ${eventId}`);
        }

        return this._events.get(eventId);
    }

    async saveEvent(event) {
        let method, data, url = '/api/web/v1/my/events';
        if (event.id()) {
            method = 'put';
            url = url + '/' + event.id();
            data = event.data();
        } else {
            method = 'post';
            data = { data: event.data().data }
        }

        const response = await fetch(url, {
            method,
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`unexpected status code while saving event: ${response.status}`);
        }

        const jsonEvent = await response.json();
        const newEvent = await eventFromJSON(jsonEvent);

        this._events.set(newEvent.id(), newEvent);
        this._syncStorage();

        return newEvent;
    }

    async deleteEvent(eventId) {
        const response = await fetch('/api/web/v1/my/events/' + eventId, {
            method: 'delete',
        });

        if (!response.ok) {
            throw new Error(`unexpected status code while deleting event: ${response.status}`);
        }

        this._events.delete(eventId);
        this._syncStorage();
    }

    listEvents() {
        // subopti
        return Array.from(this._events.values());
    }

    exportEventsLink() {
        return {
            href: '/api/web/v1/my/export',
            download: `progether-mydata-export_${moment().format('YYYY-MM-DD_HHmmss')}.zip`,
            target: '_blank'
        }
    }

    _syncStorage() {
        const json = Array.from(this._events.values()).map(e => e._event);
        localStorage.setItem(this._storageKey, JSON.stringify(json));
    }

}