import PSA from './psa';
import GleasonPrimarySecondary from './gleason-primary-secondary';
import GleasonTertiary from './gleason-tertiary';
import GleasonPercentages from './gleason-percentage';
import inputWithRightButtonDropdown from './input-with-right-button-dropdown';
import {DrugId} from './drug-id/drug-id';

const custom = {
    'body/types/weight': inputWithRightButtonDropdown(),
    'body/types/height': inputWithRightButtonDropdown(),
    'testing/marker/testosterone/types/testosterone': inputWithRightButtonDropdown(),
    'testing/marker/psa/types/psa': PSA,
    'types/gleason-primary-secondary': GleasonPrimarySecondary,
    'types/gleason-tertiary': GleasonTertiary,
    'types/gleason-percentages': GleasonPercentages,
    'therapy/systemic/types/drug-id': DrugId
};

export default custom;
