import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { schemaTreeWalker } from './schema-tree-walker';
var DEFAULT_TAG_NAME = 'default';

var tagToArray = function tagToArray(tag) {
  if (Array.isArray(tag)) {
    return _toConsumableArray(tag);
  }

  if (tag !== undefined) {
    return [tag];
  }

  return [];
};

var addDefaultIfEmpty = function addDefaultIfEmpty(tags) {
  if (tags.length === 0) {
    tags.push(DEFAULT_TAG_NAME);
  }
};
/**
 * Flatten an array of arrays into a single array.
 * Removing any duplicates on the way. And removing
 * any undefined values.
 *
 * @param array
 * @returns {Array.<*>}
 */


var flattenAndRemoveDuplicates = function flattenAndRemoveDuplicates(array) {
  var output = new Set();

  var flatten = function flatten(value) {
    if (Array.isArray(value)) {
      value.forEach(function (v) {
        return flatten(v);
      });
    } else {
      output.add(value);
    }
  };

  flatten(array);
  return Array.from(output).filter(function (o) {
    return o !== undefined;
  });
};

var intersection = function intersection(arrayOfArrays) {
  var intersection = new Map();
  var arrayCount = arrayOfArrays.length;
  arrayOfArrays.forEach(function (array) {
    array.forEach(function (element) {
      if (!intersection.has(element)) {
        intersection.set(element, 1);
      } else {
        intersection.set(element, intersection.get(element) + 1);
      }
    });
  });
  return Array.from(intersection).filter(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        value = _ref2[1];

    return value === arrayCount;
  }).map(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
        key = _ref4[0],
        value = _ref4[1];

    return key;
  });
};

var removeDuplicates = function removeDuplicates(array) {
  return Array.from(new Set(array));
};

var resolveValue = function resolveValue(value, parentTags) {
  if (typeof value === 'string' || typeof value === 'number') {
    value = {
      value: value
    };
  }

  value.tag = removeDuplicates(tagToArray(value.tag).concat(parentTags));
  addDefaultIfEmpty(value.tag);
  return value;
};

var PARTIAL_TAG_PROPERTY_NAME = 'partial_tag';

var resolveField = function resolveField(field, parentTags, options) {
  var fieldTags = removeDuplicates(tagToArray(field.tag).concat(parentTags));

  if (field.def.values) {
    field.def.values = field.def.values.map(function (v) {
      return resolveValue(v, fieldTags);
    });
  } // Common value tags basically are field tags:
  // A tag that is shared by all values, is the same as
  // if this tag had been applied on the field level


  var commonValueTags = intersection((field.def.values || []).map(function (v) {
    return v.tag;
  }));

  if (field.fields) {
    field.fields = field.fields.map(function (f) {
      return resolveField(f, fieldTags, options);
    });
  }

  var commonFieldTags = intersection((field.fields || []).map(function (f) {
    return f.tag;
  }));
  var commonTags = [];

  if (field.def.values) {
    if (field.fields) {
      commonTags = intersection([commonValueTags, commonFieldTags]);
    } else {
      commonTags = commonValueTags;
    }
  } else if (field.fields) {
    commonTags = commonFieldTags;
  }

  var enhancedFieldTags = removeDuplicates(fieldTags.concat(commonTags));
  addDefaultIfEmpty(enhancedFieldTags);

  if (options.includePartialTags) {
    var valueTags = flattenAndRemoveDuplicates((field.def.values || []).map(function (v) {
      return v.tag;
    })); // need to remove the field tags from the partial tags

    var fieldPTags = valueTags.filter(function (t) {
      return !enhancedFieldTags.includes(t);
    }).filter(function (t) {
      return !commonValueTags.includes(t);
    });
    field[PARTIAL_TAG_PROPERTY_NAME] = fieldPTags;
  }

  field.tag = enhancedFieldTags;
  return field;
};

var resolveEvent = function resolveEvent(event, options) {
  var eventTags = removeDuplicates(tagToArray(event.tag));

  if (event.fields) {
    event.fields = event.fields.map(function (f) {
      return resolveField(f, eventTags, options);
    }); // Common field tags basically are event tags:
    // A tag that is shared by all fields, is the same as
    // if this tag had been applied on the event level

    var commonFieldTags = intersection(event.fields.map(function (f) {
      return f.tag;
    }));
    var commonAndLocalEventTags = removeDuplicates(eventTags.concat(commonFieldTags));
    addDefaultIfEmpty(commonAndLocalEventTags);

    if (options.includePartialTags) {
      var fieldTags = flattenAndRemoveDuplicates(event.fields.map(function (f) {
        return f.tag;
      }));
      var fieldPartialTags = flattenAndRemoveDuplicates(event.fields.map(function (f) {
        return f[PARTIAL_TAG_PROPERTY_NAME];
      })); // need to remove the event tags from the partial tags

      var eventPartialTags = fieldTags.concat(fieldPartialTags).filter(function (t) {
        return !commonAndLocalEventTags.includes(t);
      }).filter(function (t) {
        return !commonFieldTags.includes(t);
      });
      event[PARTIAL_TAG_PROPERTY_NAME] = eventPartialTags;
    }

    event.tag = commonAndLocalEventTags;
  }

  return event;
};
/**
 * Take a schema and attach the right tag information to each
 * event block.
 *
 * If no tag information was available initially, this will
 * add a ['default'] tag. For value items, this might transform
 * a simple value item into an object to be apple to attach
 * the tag information.
 *
 * The caller might opt to disable the inclusion of partial tag
 * information, e.g. tags that are not present on all
 * sub-items, but on some.
 *
 * Tags on the event level will be pushed to the field and value
 * level. Tags on the field level will be pushed to the value
 * level.
 *
 * @param {object} schema
 * @param {string} [idSeparator]
 * @param {boolean} [includePartialTags] defaults to "true", will include information on partial tags to the output
 * @returns {object} a copy of the incoming schema with all
 * blocks, events, their fields and their respective values
 * having a tag property set to the array of associated tags.
 */


var schemaTagResolver = function schemaTagResolver(schema) {
  var idSeparator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
  var includePartialTags = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var schemaCopy = JSON.parse(JSON.stringify(schema)); // walk through the schema tree to find and register all events and types

  schemaTreeWalker(schemaCopy, function (elementId, elementType, element) {
    switch (elementType) {
      case 'event':
        resolveEvent(element, {
          includePartialTags: includePartialTags
        });
        break;

      case 'types':
      case 'data':
      case 'translations':
        // ignore
        break;

      default:
        throw new Error('unknown schema element type', elementId, elementType);
    }
  }, {
    idSeparator: idSeparator
  });
  return schemaCopy;
};

export { schemaTagResolver };