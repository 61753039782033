import { messageIdGenerators } from 'progether-data-schema';

// pass through the generators from the schema
export const eventFormHelpText = messageIdGenerators.eventFormHelpText;
export const eventFormHelpTitle = messageIdGenerators.eventFormHelpTitle;
export const eventFormTitle = messageIdGenerators.eventFormTitle;
export const eventFieldHelpText = messageIdGenerators.eventFieldHelpText;
export const eventFieldLabel = messageIdGenerators.eventFieldLabel;
export const eventFieldPlaceholder = messageIdGenerators.eventFieldPlaceholder;
export const eventFieldValueHelp = messageIdGenerators.eventFieldValueHelp;
export const eventFieldValueLabel = messageIdGenerators.eventFieldValueLabel;
export const eventFieldPostfixLabel = messageIdGenerators.eventFieldPostfixLabel;

// TODO: Move to progether-data-schema
export function eventFieldPrefixLabel(eventId, fieldId) {
    return _getMessageId('form', eventId, 'field', fieldId, 'prefix');
}

export const eventFormNavigationCategoryLabel = (category) => {
    return _getMessageId('event', 'form', category, 'navigation', 'label');
};

export const eventFormNavigationEventLabel = (eventId) => {
    return _getMessageId(eventId, 'form', 'navigation', 'label');
};

const _getMessageId = (...keys) => {
    return keys
        .map(k => ('' + k).replace(/[^a-z0-9]+/ig, '_'))
        .join('_');
};

if (process.env.NODE_ENV !== 'production') {

    // struct to keep track of all used messageIds
    // TODO make this conditional for development only
    const messageIds = new Set();

    // provide access to the struct via a global
    // TODO make this conditional for development only
    if (typeof window !== 'undefined') {

        window.globals = {
            getMessageIds: () => JSON.stringify(Array.from(messageIds).sort(), null, 4)
        };

        console.info('Registered information on generated message ids as global, ' +
            'call `window.globals.getMessageIds()`.');
        console.warn('Use the right frame I am looking at you, storybook user!');

    }

}
