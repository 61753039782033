import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import Datepicker2 from '../datepicker-2';
import '../style.less';

// -------------------------------------------------------------------
// intl-messages
// -------------------------------------------------------------------

const messages = defineMessages({
    part_daterangepicker2_from_placeholder: { 
        id: 'part_daterangepicker2_from_placeholder', 
        defaultMessage: 'Started on MM/DD/YYYY',
        description: 'Placeholder text for the from date input field, should provide the usable date format in the current locale'
    },
    part_daterangepicker2_to_placeholder: { 
        id: 'part_daterangepicker2_to_placeholder', 
        defaultMessage: 'Ended on MM/DD/YYYY',
        description: 'Placeholder text for the to date input field, should provide the usable date format in the current locale'
    }
});

class DateRangePicker2 extends Component {

    constructor(props) {

        super(props);

        this.propsToState = (props) => {

            const fromDate = Object.assign({
                year: 0,
                month: 0,
                day: 0
            }, props.date && props.date.from);

            const toDate = Object.assign({
                year: 0,
                month: 0,
                day: 0
            }, props.date && props.date.to);

            const calculatedState = {
                fromDate: fromDate,
                toDate: toDate,
                ongoing: this.state.showToField ? false : !(props.date && props.date.to),
            };

            return calculatedState;

        };

        this.state = {
            showToField: false,
        };

    }

    triggerChange(date) {

        this.props.onChange(sanitize(date));

        function sanitize(date) {

            const {from, to} = date;
            const sanitized = {};

            if (hasValue(from)) {
                sanitized.from = from;
            }

            if (hasValue(to)) {
                sanitized.to = to;
            }

            if (Object.keys(sanitized).length === 0) {
                return null;
            }

            return sanitized;

        }

        function hasValue(date) {
            return date && (Object.keys(date).some(key => date[key] && date[key] > 0));
        }

    }

    handleChange(type, value) {

        const propState = this.propsToState(this.props);

        const newPropState = Object.assign({}, propState, {
            [type]: value
        });

        if (!newPropState.fromDate) {

            // if the user deliberately removed the from date
            // we are somewhat in uncharted territory. To get
            // back into stable state, we will move to Go
            // and not take $200.

            newPropState.toDate = undefined;

            this.setState({
                showToField: false,
            });

        }

        if (fromIsBeforeTo(newPropState)) {

            this.triggerChange({
                from: newPropState.fromDate,
                to: newPropState.toDate,
            });

        } else {

            console.warn('Inconsistent DateRangePicker2 input from is after to');

            //this.triggerChange({
            //    from: newPropState.toDate,
            //    to: newPropState.fromDate,
            //});

        }

        function fromIsBeforeTo(state) {

            const {fromDate, toDate} = state;

            if (!fromDate || !toDate) {
                return true;
            }

            if (fromDate.year > toDate.year && toDate.year > 0) return false;
            if (fromDate.year === toDate.year) {
                if (fromDate.month > toDate.month && toDate.month > 0) return false;
                if (fromDate.month === toDate.month) {
                    if (fromDate.day > toDate.day && toDate.day > 0) return false;
                }
            }

            return true;

        }

    }

    handleOngoingChange(nextOngoingState) {

        const state = this.propsToState(this.props);

        if (state.ongoing === nextOngoingState) {
            return; // no change no action
        }

        this.setState({
            showToField: !nextOngoingState,
        });            

        if (nextOngoingState === true) {
            this.handleChange('toDate', undefined);
        }

    }

    render() {

        const {intl, min, max, showShortcuts} = this.props;
        const {fromDate, toDate, ongoing} = this.propsToState(this.props);

        const fromPlaceholder = intl.formatMessage(messages.part_daterangepicker2_from_placeholder),
            toPlaceholder = intl.formatMessage(messages.part_daterangepicker2_to_placeholder);

        const precision = (date) => {
            if (!date) return undefined;
            if (date.day > 0) {
                return 'date';
            }
            if (date.month > 0) {
                return 'month';
            }
            return 'year';
        };

        const toMoment = (date) => {
            let d = Object.assign({}, date, {
                month: (date.month) ? date.month - 1 : date.month
            });
            return moment(d);
        };

        const toMin = fromDate
            ? toMoment(fromDate).startOf(precision(fromDate))
            : min;

        // could be !ongoing, but no date info set yet
        const fromMax = !ongoing && toDate.year > 0
            ? toMoment(toDate).isSameOrBefore(max)
                ? toMoment(toDate)
                : max
            : max;

        const ongoingClasses = classNames('btn', {
                'btn-default': !ongoing,
                'btn-success': ongoing
            }),
            doneClasses = classNames('btn', {
                'btn-default': ongoing,
                'btn-success': !ongoing
            });

        const toPicker = !ongoing
            ? <Datepicker2
                    placeholder={toPlaceholder} 
                    showShortcuts={showShortcuts}
                    min={toMin} 
                    max={max} 
                    date={toDate} 
                onChange={this.handleChange.bind(this, 'toDate')}/>
            : null;

        const disableOngoingButtons = !fromDate || fromDate.year === 0;

        return (
            <div className="pg-date-picker2 pg-range-picker2">
                <Datepicker2
                    placeholder={fromPlaceholder} 
                    showShortcuts={showShortcuts}
                    min={min} 
                    max={fromMax}
                    date={fromDate} 
                    onChange={this.handleChange.bind(this, 'fromDate')} 
                />
                <div className="btn-group clearfix" role="group">

                        <FormattedMessage 
                            id="part_daterangepicker2_range_ongoing_label" 
                            defaultMessage="Ongoing" 
                            description="The label indicating the range is open (ongoing till now, does not have an end date)"
                    >
                        {
                            (txt) => (
                                <button type="button"
                                    className={ongoingClasses}
                                    disabled={disableOngoingButtons}
                                    onClick={this.handleOngoingChange.bind(this, true)}>
                                    {txt}
                    </button>
                            )
                        }
                    </FormattedMessage>

                        <FormattedMessage 
                            id="part_daterangepicker2_range_done_label" 
                            defaultMessage="Completed" 
                            description="The label indicating the range is closed (has an end date)"
                    >
                        {
                            (txt) => (
                                <button type="button" className={doneClasses}
                                    disabled={disableOngoingButtons}
                                    onClick={this.handleOngoingChange.bind(this, false)}>
                                    {txt}
                    </button>
                            )
                        }

                    </FormattedMessage>
                </div>
                { toPicker }
            </div>
        );
    }

}

DateRangePicker2.propTypes = {
    min: PropTypes.object, // moment
    max: PropTypes.object, // moment
    date: PropTypes.shape({
        from: PropTypes.shape({
            year: PropTypes.number,
            month: PropTypes.number,
            day: PropTypes.number
        }),
        to: PropTypes.shape({
            year: PropTypes.number,
            month: PropTypes.number,
            day: PropTypes.number
        })
    }),
    onChange: PropTypes.func.isRequired, // callback on valid values
    fromPlaceholder: PropTypes.string,
    toPlaceholder: PropTypes.string,
    showShortcuts: PropTypes.bool
};

DateRangePicker2.defaultProps = {
    min: moment().subtract(50, 'years'),
    max: moment()
};

export default injectIntl(DateRangePicker2);
