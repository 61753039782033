import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import classnames from 'classnames';
import moment from 'moment';
import {
    dateToString,
    eventDataToString,
    EventTrie,
    getEventsGroupedByTypeId as groupEventsByTypeId,
    typeIdToString,
    getDateFromEvent as getDate,
} from 'progether-event-utils';
import {showDetailsPopup} from './shared/event-details-popover';
import * as d3 from '../../lib/d3-package';
import {IfNoEvents} from './shared/if-no-events';
import './event-timeline.less';

const messages = defineMessages({
    toggleAxisTitleLog: {
        id: 'part_event_timeline_axis_psa_switch_to_log_title',
        defaultMessage: 'Change to: Focus on smaller values'
    },
    toggleAxisTitleLinear: {
        id: 'part_event_timeline_axis_psa_switch_to_linear_title',
        defaultMessage: 'Change to: Handle all values alike'
    },
    toggleAxisLabel: {
        id: 'part_event_timeline_axis_psa_switch_label',
        defaultMessage: 'Toggle view'
    },
    popupEditButtonLabel: {
        id: 'part_event_timeline_popup_edit_button_label',
        defaultMessage: 'Edit'
    },
    popupCloseButtonTitle: {
        id: 'part_event_timeline_popup_close_button_title',
        defaultMessage: 'Close'
    }
});

const margins = {
    top: 18,
    bottom: 0,
    left: 0,
    right: 0
};

const colors = {
    valueAxisLabels: '#555',
    psaValueAxisTickText: '#555',
    psaValueAxisTickLine: '#ccc', // or '#777'?
    psaAreaFill: '#66c2a5',
    psaDotStroke: '#66c2a5',
    psaDotFill: '#fff',
    psaCenterLine: '#555',
    timeAxisLabels: '#555',
    timeAxisTickText: '#555',
    timeAxisTickLine: '#ccc',
    valueLineStroke: '#66c2a5',
    valuePointStroke: '#808080',
    valuePointFill: '#66c2a5',
    event_testing: '#8da0cb',
    event_therapy: '#fc8d62',
    event_psa: '#66c2a5',
    eventDefaultFill: '#e9e9e9',
    eventDefaultStroke: '#eee',
    event_default: '#eee',
    ganttBorders: '#ccc',
    ganttLabel: '#555',
    timeBandEvenFill: '#fff', // '#f3fbff',
    timeBandEvenFillOpacity: .1,
    timeBandOddFill: '#fff',
    timeBandOddFillOpacity: 0,
    eventTherapyFill: '#fc8d62',
    eventTherapyStroke: 'none',
    eventTestingFill: '#8da0cb',
    eventTestingStroke: 'none',
};

const defaultLaneHeight = 50;
const defaultMarkerHeight = 150;
const defaultFontSize = 12;

const optionallyCreate = (selection, creator) => {
    if (selection.empty()) {
        if (typeof creator !== 'function') {
            throw new Error('creator is not a function');
        }
        return creator();
    } else {
        return selection;
    }
};

/**
 * Use the same parameter as used for the psa chart in progether-print-service
 *
 * @param svg
 * @param {EventTrie} eventTrie
 * @param eventTypeIdGroups
 * @param eventTypeIdGroups
 * @param onEventSelection
 * @param onCreateEvent
 * @param logPsaValueAxis
 * @param margins
 * @param eventTypeIdGroups
 * @param onEventSelection
 * @param onCreateEvent
 * @param logPsaValueAxis
 * @param margins
 * @param eventTypeIdGroups
 * @param onEventSelection
 * @param onCreateEvent
 * @param logPsaValueAxis
 * @param margins
 * @param eventTypeIdGroups
 * @param onEventSelection
 * @param onCreateEvent
 * @param logPsaValueAxis
 * @param margins
 * @param intl
 * @param onEventSelection
 * @param onCreateEvent
 * @param logPsaValueAxis
 * @param margins
 */
function renderTimeline(svg, eventTrie, eventTypeIdGroups, intl, onEventSelection, onCreateEvent, logPsaValueAxis, margins) {

    const today = moment().startOf('day');
    const eventToDateMap = eventTrie.getEvents().reduce((map, event) => {

        const time = getDate(event);

        if (!time) {
            const timeToday = {
                fromDate: today.toDate(),
                toDate: today.toDate(),
                interval: false,
                from: today,
                to: today
            };

            map.set(event.id(), timeToday);
            return map;
        }

        time.ongoing = !time.to;
        if (time.ongoing) {
            time.to = today;
        }

        time.fromDate = time.from.toDate();
        time.toDate = time.to.toDate();

        map.set(event.id(), time);

        return map;

    }, new Map());

    svg = d3.select(svg);
    const [, , viewBoxWidth, viewBoxHeight] = svg.attr('viewBox').split(' ');

    const innerDimensions = {
        width: viewBoxWidth - margins.left - margins.right,
        height: viewBoxHeight - margins.top - margins.bottom
    };

    const hasPsaEvents = eventTrie.getCount('testing/marker/psa') > 0;
    const markerHeight = hasPsaEvents ? defaultMarkerHeight : 0;
    const marginTopOffset = 0; // hasPsaEvents ? -20 : 0;

    // init containers
    const timeBandsGroup = optionallyCreate(
        svg.selectAll('g.time-bands'),
        () => svg.append('g').attr('class', 'time-bands').attr('transform', `translate(${margins.left}, ${margins.top})`)
    );

    // time axis must be rendered before the marker group
    // to ensure markers are pointed on top of the axis
    const timeAxisGroup = optionallyCreate(
        svg.selectAll('g.time-axis'),
        () => svg.append('g').attr('class', 'time-axis')
    ).attr('transform', `translate(${margins.left}, ${margins.top + markerHeight})`);

    const timeAxisHeight = 18;

    // time axis background
    timeAxisGroup.selectAll('rect.time-axis-background')
        .data([1]).enter()
        .append('rect')
        .attr('class', 'time-axis-background')
        .attr('fill', '#eee')
        .attr('x', -margins.left).attr('y', 0)
        .attr('width', innerDimensions.width + margins.left + margins.right).attr('height', timeAxisHeight);

    const markerGroup = optionallyCreate(
        svg.selectAll('g.marker'),
        () => svg.append('g')
    ).attr('class', 'marker')
        .attr('transform', `translate(${margins.left}, ${margins.top + marginTopOffset})`)
        .style('font', 'light');

    const psaGroup = optionallyCreate(
        markerGroup.selectAll('g.psa'),
        () => markerGroup.append('g').attr('class', 'psa')
    );

    const ganttGroup = optionallyCreate(
        svg.selectAll('g.gantt'),
        () => svg.append('g').attr('class', 'gantt')
    ).attr('transform', `translate(${margins.left}, ${margins.top + markerHeight + timeAxisHeight})`);
    // render time axis
    const timeAxisDimensions = {width: innerDimensions.width, height: timeAxisHeight};
    const {timeScale, ticks} = renderTimeAxis(timeAxisGroup, eventToDateMap, timeAxisDimensions, intl);

    // update time bands
    const timeBandsHeightFix = 18;
    const timeBandsDimensions = {
        width: innerDimensions.width,
        height: innerDimensions.height + timeBandsHeightFix,
        marginTop: -timeBandsHeightFix
    };
    renderTimeBands(timeBandsGroup, timeScale, ticks, timeBandsDimensions);

    // render psa values
    const psaDimensions = {width: innerDimensions.width, height: markerHeight};
    renderPsaValues(psaGroup, eventTrie, eventToDateMap, timeScale, psaDimensions, intl, onEventSelection, onCreateEvent, logPsaValueAxis);

    // render gantt chart
    const ganttDimensions = {
        width: innerDimensions.width,
        height: innerDimensions.height - markerHeight - timeAxisHeight
    };
    renderGanttChart(ganttGroup, eventTypeIdGroups, eventToDateMap, timeScale, ganttDimensions, intl, onEventSelection, onCreateEvent);

}

function renderTimeBands(selection, timeScale, ticks, dimensions) {

    const updateSelection = selection.selectAll('rect.time-band').data(ticks, d => d);

    updateSelection.exit().remove();

    // @formatter:off
    updateSelection.enter()
        .append('rect')
        .attr('class', 'time-band')
        .merge(updateSelection)
        .attr('x', d => timeScale(d) + 1)
        .attr('y', dimensions.marginTop)
        .attr('width', (d, ix) => timeScale(ix < ticks.length - 1 ? ticks[ix + 1] : new Date()) - timeScale(ticks[ix]))
        .attr('height', dimensions.height)
        .attr('fill', (d, ix) => ix % 2 ? colors.timeBandEvenFill : colors.timeBandOddFill)
        .attr('fill-opacity', (d, ix) => ix % 2 ? colors.timeBandEvenFillOpacity : colors.timeBandOddFillOpacity);
    // @formatter:on

}

function renderTimeAxis(timeAxisGroup, eventToDateMap, innerDimensions, intl) {

    const handleTime = (eventDateInformation) => {

        const today = moment().startOf('day');
        const first = d3.min(eventDateInformation, d => d.fromDate);
        const timeDifferenceInMonths = today.diff(first, 'months', true);

        let firstMoment, lastMoment = today, format = 'LL', numTicks;

        if (timeDifferenceInMonths < 1) {
            // show one month
            firstMoment = moment(first).startOf('week').subtract(1, 'week');
            format = 'l';
            numTicks = 3;
        } else if (timeDifferenceInMonths < 18) {
            firstMoment = moment(first).startOf('month').subtract(1, 'month');
            format = 'MMM YY';
            numTicks = 4;
        } else {
            firstMoment = moment(first).startOf('year').subtract(3, 'months');
            format = 'YYYY';
            const timeDifferenceInYears = Math.ceil(today.diff(first, 'years', true));
            numTicks = Math.min(timeDifferenceInYears, 6);
        }

        return {
            first: firstMoment,
            last: lastMoment,
            formatter: (date) => moment(date).locale(momentLocaleMapping(intl.locale)).format(format),
            numTicks,
        };

    };

    const {first: timeDomainStart, last: timeDomainEnd, formatter: myTimeFormatter, numTicks} = handleTime(Array.from(eventToDateMap.values()));
    const timeDomain = [timeDomainStart.toDate(), timeDomainEnd.toDate()];
    const timeScale = d3.scaleTime()
        .rangeRound([0, innerDimensions.width])
        .domain(timeDomain);

    const timeAxis = d3.axisBottom(timeScale)
        .tickSize(0)
        .ticks(numTicks)
        .tickFormat(myTimeFormatter);

    const styledTimeAxis = (g) => {
        g.call(timeAxis);
        g.selectAll('.domain')
            .attr('stroke', colors.timeAxisTickLine);
        g.selectAll('.tick line')
            .attr('stroke', colors.timeAxisTickLine);
        g.selectAll('.tick text')
            .attr('text-anchor', 'start')
            .attr('y', 4)
            .attr('dx', 4)
            .attr('font-size', defaultFontSize)
            .attr('font', 'light')
            .attr('fill', colors.timeAxisTickText);
        /*
        g.selectAll('line.time-axis-close')
            .data([1])
            .enter().append('line')
            .attr('class', 'time-axis-close')
            .attr('x1', 0)
            .attr('x2', innerDimensions.width)
            .attr('y1', fontSize + 4)
            .attr('y2', fontSize + 4)
            .attr('stroke', colors.timeAxisTickText);
            */
    };

    styledTimeAxis(timeAxisGroup);

    return {timeScale, ticks: timeScale.ticks(numTicks)};

}

const timeOverlap = {
    x1: (time) => time.from.unix(),
    x2: (time) => time.to.unix()
};

function calculateEventOverlap(element0, element1, mapping = timeOverlap) {

    const {x1, x2} = mapping;

    // no overlap
    if ((x1(element0) > x2(element0)) || x2(element1) < x1(element0)) {
        return 0;
    }

    // which one is the first?
    const [first, second] = (x1(element0) <= x1(element1)) ? [element0, element1] : [element1, element0];

    const overlapStart = second.from;
    // 2nd ends before first ? inclusion : overlap
    const overlapEnd = (x2(second) <= x2(first)) ? x2(second) : x2(first);

    // overlap is the difference between end and start
    return overlapEnd - overlapStart;

}

function minimizeEventOverlaps(events, maxGroups = 2, timeScale, viewBoxWidth) {

    if (maxGroups < 1) {
        throw new Error('maxGroups must be at least 1');
    }

    const groups = [];

    const screenSpaceMapping = {
        x1: (time) => timeScale(time.from) - .05 * viewBoxWidth,
        x2: (time) => timeScale(time.to) + .05 * viewBoxWidth
    };

    events.forEach(unplacedEvent => {

        if (groups.length === 0) {
            groups.push([unplacedEvent]);
            return; // continue with next event
        }

        // get min overlap for each group
        const groupOverlaps = groups.map(eventsInGroup => {
            return {
                overlap: eventsInGroup.reduce((totalOverlap, placedEvent) => {
                    return totalOverlap + calculateEventOverlap(unplacedEvent.time, placedEvent.time, screenSpaceMapping);
                }, 0),
                group: eventsInGroup
            };
        }).sort(byAscendingMinOverlap);

        if (groupOverlaps[0].overlap !== 0 && groups.length < maxGroups) {
            // create a new group
            groups.push([unplacedEvent]);
            return; // continue with next event
        }

        // need to place in group with least overlaps
        groupOverlaps[0].group.push(unplacedEvent);

    });

    if (groups.length < maxGroups) {
        groups.splice(0, 0, new Array(maxGroups - groups.length).map(() => []));
    }

    return groups;

    function byAscendingMinOverlap(a, b) {
        return a.overlap - b.overlap;
    }

}

function eventTypeIdToColor(typeId) {

    if (typeId.startsWith('therapy')) {

        return {
            fill: colors.eventTherapyFill,
            stroke: colors.eventTherapyStroke,
            identifier: colors.event_therapy
        };

    }

    if (typeId.startsWith('testing/marker/psa')) {

        return {
            fill: colors.event_psa,
            stroke: colors.event_psa,
            identifier: colors.event_psa
        };

    }

    if (typeId.startsWith('testing')) {

        return {
            fill: colors.eventTestingFill,
            stroke: colors.eventTestingStroke,
            identifier: colors.event_testing
        };

    }

    return {
        fill: colors.eventDefaultFill,
        stroke: colors.eventDefaultStroke,
        identifier: colors.event_default
    };

}

function renderGanttChart(ganttGroup, eventTypeIdGroups, eventToDateMap, timeScale, ganttDimensions, intl, onEventSelection, onCreateEvent) {

    const canCreate = typeof onCreateEvent === 'function';

    // all events but the markers (psa, testosterone, ...)
    const filteredEventGroups = Object.entries(eventTypeIdGroups).filter(([typeId]) => !typeId.startsWith('testing/marker'));

    // convert them to something usable
    const mapEvents = (event) => {
        return {
            time: eventToDateMap.get(event.id()),
            text: eventDataToString(event, intl, false, true),
            color: eventTypeIdToColor(event.typeId()),
            semanticClasses: eventToSemanticClasses(event),
            event: event
        };
    };

    // sort them by typeId
    const sortEvents = (a, b) => {
        return a.typeId.localeCompare(b.typeId);
    };

    // take the filtered groups, convert them to something better usable, then sort by typeId
    const mappedEventGroups = filteredEventGroups.map(([typeId, events]) => {
        return {
            typeId, events: events.map(mapEvents)
        };
    }).sort(sortEvents);

    const typeIdScale = d3.scaleBand()
        .domain(mappedEventGroups.map(m => m.typeId))
        .range([0, ganttDimensions.height])
        .paddingInner(0)
        .paddingOuter(0)
        .align([0.5]);

    const updateSelection = ganttGroup.selectAll('g.typeId')
        .data(mappedEventGroups, d => d.typeId);

    updateSelection.exit().remove();

    // @formatter:off
    updateSelection.enter()
        .append('g')
        .attr('class', 'typeId')
        .call(createBorder)
        .call(createBackground)
        .call(groupIdentifierCreate, canCreate ? (d) => onCreateEvent(d.typeId.split('/')[0]) : undefined)
        .each(renderGanttGroup)
        .call(createGroupLabel, colors.ganttLabel)
        .merge(updateSelection)
        .attr('transform', (d) => `translate(0, ${typeIdScale(d.typeId)})`)
        .call(updateGroupLabel)
        .call(updateBorder, ganttDimensions.width, typeIdScale.bandwidth, colors.ganttBorders, 1)
        .call(updateBackground, ganttDimensions.width, typeIdScale.bandwidth())
        .call(groupIdentifierUpdate, typeIdScale.bandwidth(), d => d.events[0].color.identifier)
        .each(renderGanttGroup);
    // @formatter:on

    function createBorder(selection) {
        selection.append('line')
            .attr('class', 'border-top');
        selection.append('line')
            .attr('class', 'border-bottom');
    }

    function updateBorder(selection, width, height, color, size) {
        selection.selectAll('line.border-top')
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', width)
            .attr('y2', 0)
            .style('stroke-width', size)
            .style('stroke', color);
        selection.selectAll('line.border-bottom')
            .attr('x1', 0)
            .attr('y1', height)
            .attr('x2', width)
            .attr('y2', height)
            .style('stroke-width', size)
            .style('stroke', color);
    }

    function createBackground(selection) {
        selection
            .append('rect')
            .attr('class', 'group-background');
    }

    function updateBackground(selection, width, height) {
        selection.selectAll('rect.group-background')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', width)
            .attr('height', height)
            .attr('fill', 'none');
    }

    function createGroupLabel(selection, fontColor) {
        return selection.append('text')
            .classed('group-label', true)
            .attr('font-size', '10px')
            .attr('fill', fontColor)
            .attr('dx', 12)
            .attr('y', '4')
            .attr('dy', '1em')
            .attr('text-anchor', 'left');

    }

    function updateGroupLabel(selection) {
        const createFunction = canCreate
            ? d => onCreateEvent(d.typeId)
            : undefined;

        selection.selectAll('text')
            .classed('clickable', canCreate)
            .on('click', createFunction)
            .text((d) => {
                const label = typeIdToString(d.typeId, intl);
                return canCreate
                    ? `${label} +`
                    : label;
            });
    }

    function renderEventCreate(selection) {

        if (selection.empty()) {
            return;
        }

        const d = selection.datum();
        if (d.time.interval) {

            selection
                .append('rect')
                .attr('class', 'event-handler')
                .on('click', onEventSelection);

        } else {

            selection
                .append('g')
                .attr('class', 'triangle')
                .append('path')
                .attr('class', 'event-handler')
                .on('click', onEventSelection);

        }

    }

    function renderEventUpdate(selection, size) {

        if (selection.empty()) {
            return;
        }

        const d = selection.datum();

        if (d.time.interval) {

            selection.selectAll('rect.event-handler')
                .datum(d)
                .attr('x', timeScale(d.time.fromDate))
                .attr('y', 0)
                .attr('rx', size / 4)
                .attr('ry', size / 4)
                .attr('width', timeScale(d.time.toDate) - timeScale(d.time.fromDate))
                .attr('height', size)
                .attr('fill', d.color.fill)
                .attr('fill-opacity', .3)
                .attr('stroke', d.color.stroke)
                .attr('stroke-width', 2)
                .classed(d.semanticClasses, true)
                .on('click', onEventSelection);
            //.attr('filter', 'url(#blur)');

        } else {

            selection.selectAll('g.triangle')
                .attr('transform', `translate(${timeScale(d.time.fromDate) - (size / 2)} 0)`);

            selection.selectAll('g.triangle path.event-handler')
                .datum(d)
                .attr('d', `M ${size / 2} ${0} L ${size} ${size} H 0 z`)
                .attr('fill', d.color.fill)
                .attr('fill-opacity', .3)
                .attr('stroke', d.color.stroke)
                .attr('stroke-width', 2)
                .classed(d.semanticClasses, true)
                .on('click', onEventSelection);

        }

    }

    function renderEventGroup(events, height) {

        if (events.length === 0 || events[0] === undefined) {
            return null;
        }

        const updateSelection = d3.select(this)
            .selectAll('g.event')
            .data(events, d => d.event.id());

        updateSelection.exit().remove();

        updateSelection.enter()
            .append('g').attr('class', 'event')
            .each(function () {
                renderEventCreate(d3.select(this));
            })
            .merge(updateSelection)
            .each(function () {
                renderEventUpdate(d3.select(this), height);
            });

    }

    function renderGanttGroup(d) {

        const listOfEventsInThisGroup = minimizeEventOverlaps(d.events, 2, timeScale, ganttDimensions.width);
        const eventsDomain = listOfEventsInThisGroup.map((d, index) => index);

        const eventGroupScale = d3.scaleBand()
            .domain(eventsDomain)
            .range([0, typeIdScale.bandwidth()])
            .paddingInner(.1)
            .paddingOuter(.2)
            .align(0.5);

        const updateSelection = d3.select(this).selectAll('g.event-group')
            .data(listOfEventsInThisGroup, d => d.map(e => e.event.id()).join('-'));

        updateSelection.exit().remove();

        updateSelection.enter()
            .append('g')
            .attr('class', 'event-group')
            .attr('transform', (d, index) => `translate(0, ${eventGroupScale(index)})`)
            .each(function (d) {
                renderEventGroup.call(this, d, eventGroupScale.bandwidth());
            });

        updateSelection.attr('transform', (d, index) => `translate(0, ${eventGroupScale(index)})`)
            .each(function (d) {
                renderEventGroup.call(this, d, eventGroupScale.bandwidth());
            });

    }

}

function groupIdentifierCreate(selection, onCreateEvent = null) {

    const rect = selection.append('rect')
        .attr('class', 'group-identifier');

    if (typeof onCreateEvent === 'function') {
        rect.on('click', onCreateEvent);
    }

}

function groupIdentifierUpdate(selection, height, color, width = 6) {

    selection.selectAll('rect.group-identifier')
        .attr('x', 0).attr('y', 0)
        .attr('width', width).attr('height', height)
        .attr('fill', color);

}

function renderPsaValues(psaGroup, eventTrie, eventToDateMap, timeScale, dimensions, intl, onEventSelection, onCreateEvent, logPsaValueAxis) {

    const psaEvents = eventTrie.getEvents('testing/marker/psa');

    const psaLineGroup = optionallyCreate(
        psaGroup.select('g.line'),
        () => psaGroup.append('g').attr('class', 'line')
    );

    const psaAreaGroup = optionallyCreate(
        psaGroup.select('g.area'),
        () => psaGroup.append('g').attr('class', 'area')
    );

    const psaAreaDotsGroup = optionallyCreate(
        psaGroup.select('g.dots'),
        () => psaGroup.append('g').attr('class', 'dots')
    );

    const psaValueAxisGroup = optionallyCreate(
        psaGroup.select('g.marker-axis'),
        () => psaGroup.append('g').attr('class', 'marker-axis')
    );

    /*
    const psaDoublingTimeGroup = optionallyCreate(
        psaGroup.select('g.doubling-time'),
        () => psaGroup.append('g').attr('class', 'doubling-time')
    );

    const psaAddMoreGroup = optionallyCreate(
        psaGroup.select('g.add-more'),
        () => psaGroup.append('g').attr('class', 'add-more')
    );
    */

    /*
    const psaIdentifier = optionallyCreate(
        psaGroup.select('g.psa-identifier'),
        () => psaGroup.append('g')
            .attr('class', 'psa-identifier')
            // adjust the height, as the value axis overshoots the bounding box
            .attr('transform', `translate(0,-${margins.top - 7})`)
            .call(groupIdentifierCreate, () => onCreateEvent('testing/marker/psa'))
            .call(groupIdentifierUpdate, dimensions.height + margins.top - 7, colors.event_psa)
    );
    */

    if (psaEvents.length === 0) {
        // cleanup
        psaGroup.remove();
        return;
    }

    /*
    const addDoublingTime = (mapped, index, array) => {

        const {value: currentValue, time: currentTime} = mapped;
        let psaDoublingTimeMonth = undefined;

        if (index > 0) {
            const { value: previousValue, time: previousTime} = array[index-1];
            const timeDelta = moment(currentTime).diff(previousTime, 'months', true);
            if (previousValue === currentValue) {
                psaDoublingTimeMonth = undefined;
            } else {
                psaDoublingTimeMonth = timeDelta * (Math.log10(2) / (Math.log10(currentValue) - Math.log10(previousValue)));
            }
        }

        return Object.assign(mapped, {
            psaDoublingTimeMonth
        });

    };
    */

    const mappedPSAEvents = psaEvents.map((event) => {
        const value = event.get('value/value');
        const modifier = event.get('value/modifier');
        return {
            value: value,
            renderValue: modifier === 'LT' ? 0 : value,
            time: eventToDateMap.get(event.id()).from.toDate(),
            text: eventDataToString(event, intl, false),
            fuzzy: modifier !== 'EQ',
            event: event
        };

    }).filter(m => m.value >= 0).sort((a, b) => a.time - b.time);//.map(addDoublingTime);

    const psaValueScale = d3.scaleLinear().rangeRound([dimensions.height, 0]);
    const psaValueAccessor = d => d.renderValue;
    const psaValuePosition = d => psaValueScale(psaValueAccessor(d));
    // const psaDoublingTimePosition = d => psaValueScale(d.psaDoublingTimeMonth);

    const defaultDomainValues = [0, .2, 1, 10, 100, 1000, 10000, 100000];
    // ensure maxValue has a default
    const maxValue = d3.max([100, ...mappedPSAEvents.map(psaValueAccessor)]);
    // TODO combine defaultDomainValues and sortedValueList to focus on "real values"
    //const sortedValueList = events.map(valueAccessor).sort();
    const valueDomain = logPsaValueAxis
        ? defaultDomainValues.filter(t => t < maxValue).concat(maxValue)
        : [0, maxValue];
    // the distance between two values is the total height
    // divided by the total number of segments on the scale
    // (And the number of segments, it array.length - 1)
    const valueRangeHeight = dimensions.height / (valueDomain.length - 1);
    // calculate the break points in the valueRange by inverting the
    // index (length - index) and multiplying the result with the
    // height between two values
    const valueRange = logPsaValueAxis
        ? valueDomain.map((v, i) => (valueDomain.length - (i + 1)) * valueRangeHeight)
        : [dimensions.height, 0];

    psaValueScale.domain(valueDomain).range(valueRange);

    const psaValueAxis = d3.axisRight(psaValueScale)
        .tickSize(dimensions.width)
        .tickValues(logPsaValueAxis ? valueDomain : null)
        .tickFormat((v) => intl.formatNumber(v, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        }));

    const psaAxis = (g) => {
        g.call(psaValueAxis);
        // remove vertical line (.domain)
        g.select('.domain').remove();
        // remove first tick line (overlays time axis)
        g.selectAll('.tick:first-of-type line').remove();
        // change the styling of the tick marks aka lines
        g.selectAll('.tick:not(:first-of-type) line')
            .style('stroke-opacity', .5)
            .attr('stroke', colors.psaValueAxisTickLine)
            .attr('stroke-dasharray', '2, 2');
        g.selectAll('.tick text')
            .attr('text-anchor', 'left')
            .attr('x', 4)
            .attr('dy', -3)
            .style('font', 'light')
            .attr('fill', colors.psaValueAxisTickText);
    };

    psaAxis(psaValueAxisGroup);

    /*
    // line chart doubling time
    // define the line
    const psaDoublingTimeLine = d3.line()
        .x(timePosition)
        .y(psaDoublingTimePosition);

    psaDoublingTimeGroup.append('path')
        .datum(mappedPSAEvents.filter(m => m.psaDoublingTimeMonth))
        .attr('class', 'line psa')
        .attr('d', psaDoublingTimeLine)
        .style('stroke', colors.valueLineStroke)
        .style('fill', 'none');
    */

    const timePosition = d => timeScale(d.time);

    const config = {
        areaChart: false,
        lineChart: true
    };

    if (config.areaChart) { // area chart

        const psaValueArea = d3.area()
            .curve(d3.curveMonotoneX)
            .x(timePosition)
            .y0(psaValueScale(0))
            .y1(psaValuePosition);

        // area
        const updateAreaSelection = psaAreaGroup.selectAll('path')
            .data([mappedPSAEvents]);

        // remove
        updateAreaSelection.exit().remove();

        // add & update
        updateAreaSelection.enter()
            .append('path')
            .attr('stroke', 'none')
            .attr('fill', colors.psaAreaFill)
            .merge(updateAreaSelection)
            .attr('d', psaValueArea);

    }

    if (config.lineChart) { // line chart

        const psaValueLine = d3.line()
            .curve(d3.curveMonotoneX)
            //.curve(d3.curveStepAfter)
            .x(timePosition)
            .y(psaValuePosition);

        // line
        const updateLineSelection = psaLineGroup.selectAll('path')
            .data([mappedPSAEvents]);

        // remove
        updateLineSelection.exit().remove();

        // add & update
        // @formatter:off
        updateLineSelection.enter()
            .append('path')
            .attr('class', 'line psa')
            .style('stroke', colors.valueLineStroke)
            .style('fill', 'none')
            .merge(updateLineSelection)
            .attr('d', psaValueLine);
        // @formatter:on

    }

    // dots
    const dots = psaAreaDotsGroup.selectAll('g.dot')
        .data(mappedPSAEvents, (d) => d.event.id());

    dots.exit().remove();

    const hasManyEvents = mappedPSAEvents.length > 30;

    const radius = hasManyEvents ? 2 : 4,
        hoverRadiusScaling = hasManyEvents ? 5 : 3,
        baseFillOpacity = .2,
        hoverFillOpacity = .6;

    // @formatter:off
    dots.enter()
        .append('g')
        .attr('class', 'dot')

        .each(function () {

            const dotGroup = d3.select(this);

            dotGroup
                .on('click', onEventSelection)
                .on('mouseenter', function () {

                    dotGroup.select('circle')
                        .attr('r', radius * hoverRadiusScaling)
                        .attr('fill-opacity', hoverFillOpacity);

                    /*
                    // with text we get a z-index problem, with
                    // the next dots being painted on top of the text
                    // to fix this, the "zoom" must be it's own
                    // element after all the dots

                    dotGroup.append('text')
                        .attr('class', 'zoom-value')
                        .attr('text-anchor', 'middle')
                        .attr('font-size', 4)
                        .attr('fill', '#555')
                        .attr('fill-opacity', 0)
                        .attr('dy', -5)
                        .text(d.text);

                    window.setTimeout(() => {
                        dotGroup.selectAll('text').attr('fill-opacity', 1);
                    }, 100);
                    */

                })
                .on('mouseleave', function() {

                    dotGroup.select('circle')
                        .attr('r', radius)
                        .attr('fill-opacity', baseFillOpacity);

                    dotGroup.selectAll('text').remove();

                });

            dotGroup
                .append('circle')
                .attr('class', 'point psa event-handler')
                .attr('cx', 0)
                .attr('cy', 0)
                .attr('r', radius)
                .attr('fill', colors.psaDotFill)
                .attr('fill-opacity', baseFillOpacity)
                .attr('stroke', colors.psaDotStroke)
                .attr('stroke-width', 2)
                // dash if not an exact value
                .attr('stroke-dasharray', (d) => (d.fuzzy) ? '2 2' : null)
                .attr('title', d => d.time + ': ' + d.text);

        })
        .merge(dots)
        .attr('transform', d => {
            return `translate(${timePosition(d)} ${psaValuePosition(d)})`;
        })
        .each(function () {

            const circle = d3.select(this).select('circle');

            circle
            // update the fuzzy state
                .attr('stroke-dasharray', (d) => (d.fuzzy) ? '2 2' : null)
                // update the radius (which might change)
                .attr('r', radius);

        });

    dots.on('click',onEventSelection);

    /*
    .append('circle')
        .attr('class', 'point psa event-handler')
        .attr('r', radius)
        .attr('fill', colors.psaDotFill)
        .attr('stroke', colors.psaDotStroke)
        .attr('stroke-width', 2)
        // dash if not an exact value
        .attr('stroke-dasharray', (d) => (d.fuzzy) ? '2' : null)
        .attr('title', d => d.time + ': ' + d.text)
        .on('click', d => onEventSelection(d, d3.event))
        .on('mouseenter', function () {
            d3.select(this).attr('r', radius * 2.5);
        })
        .on('mouseleave', function() {
            d3.select(this).attr('r', radius);
        })
        .attr('fill-opacity', .5)
    .merge(dots)
        .attr('cx', timePosition)
        .attr('cy', psaValuePosition);
        */

    const centerLines = psaAreaDotsGroup.selectAll('line.center-line')
        .data(mappedPSAEvents, (d) => d.event.id());

    centerLines.exit().remove();

    centerLines.enter()
        .append('line')
        .attr('class', 'center-line')
        .attr('stroke', colors.psaCenterLine)
        .attr('stroke-width', 1)
        .attr('opacity', .5)
        .merge(centerLines)
        .attr('x1', d => timePosition(d) - radius * .75)
        .attr('y1', d => psaValuePosition(d) - .5)
        .attr('x2', d => timePosition(d) + radius * .75)
        .attr('y2', d => psaValuePosition(d) - .5);
    // @formatter:on

    // @TODO: Add an addd more button (if space allows)
    /*
    if (true) {
        // psaAddMoreGroup
    }
    */

}

class _EventTimeline extends Component {

    _svg = null;

    static propTypes = {
        events: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        onEventSelection: PropTypes.func,
        onCreateEvent: PropTypes.func
    };

    static defaultProps = {
        events: [],
        loading: true,
    };

    state = {
        useLogPsaValueAxis: true
    };

    constructor(props) {
        super(props);
        this.handlePsaValueAxisChange = this.handlePsaValueAxisChange.bind(this);
        this.updateSvgDimensions = this.updateSvgDimensions.bind(this);
    }

    componentDidMount() {
        this.renderD3();
        this.updateSvgDimensions();
        window.setTimeout(() => window.addEventListener('resize', this.updateSvgDimensions), 10);
    }

    componentWillUnmount() {
        window.setTimeout(() => window.removeEventListener('resize', this.updateSvgDimensions), 10);
    }

    componentDidUpdate() {
        this.renderD3();
        this.updateSvgDimensions();
    }

    updateSvgDimensions() {

        // needed for ie11 (which cannot scale svg elements properly ...)

        if (this._svg) {
            const availableWidth = this._svg.parentNode.clientWidth;
            const [, , svgWidth, svgHeight] = this._svg.getAttribute('viewBox').split(' ');
            this._svg.setAttribute('width', availableWidth);
            this._svg.setAttribute('height', (svgHeight / svgWidth) * availableWidth);
        }

    }

    renderD3() {

        if (this._svg) {

            const {onEventSelection, onCreateEvent, intl, events} = this.props;

            const eventTrie = new EventTrie(events);
            const numMarkerEvents = eventTrie.getCount('testing/marker');

            const dynamicMargins = Object.assign({}, margins, {
                top: numMarkerEvents > 0 ? margins.top : 5
            });

            const onEventSelectionProxy = (domEvent, dataPoint) => {

                const {x, y, target} = domEvent;
                const { event } = dataPoint;
                let placement = 'bottom';

                const typeId = event.typeId();
                const width = typeId.startsWith('testing/marker') ? 200 : 350;

                if (y > 190) {
                    if (x < width) {
                        placement = 'right';
                    } else if (x > window.innerWidth - width && x > width) {
                        placement = 'left';
                    } else {
                        placement = 'top';
                    }
                }

                const time = dateToString(event, intl);

                const title = time + ' / ' + typeIdToString(typeId, intl);

                const popupEditLabel = intl.formatMessage(messages.popupEditButtonLabel);
                const popupCloseTitle = intl.formatMessage(messages.popupCloseButtonTitle);


                showDetailsPopup({
                    title: title,
                    text: dataPoint.text,
                    editLabel: popupEditLabel,
                    cancelTitle: popupCloseTitle,
                    placement: placement,
                    element: target,
                    width: width,
                    height: 'auto',
                    noProceed: typeof onEventSelection !== 'function',
                }).then(
                    // resolved
                    () => {
                        onEventSelection(event);
                    },
                    // rejected
                    () => {
                    }
                );

            };

            renderTimeline(
                this._svg,
                eventTrie,
                this._eventTypeIdGroups,
                this.props.intl,
                onEventSelectionProxy,
                onCreateEvent,
                this.state.useLogPsaValueAxis,
                dynamicMargins
            );

        }

    }

    handlePsaValueAxisChange(e) {

        e.preventDefault();
        e.stopPropagation();

        this.setState({
            useLogPsaValueAxis: !this.state.useLogPsaValueAxis
        });

    }

    render() {

        const {events, loading, intl} = this.props;

        // it makes no sense to include events without
        // attached dates to the _time_line, so we filter
        // them out here
        const eventsWithDates = events.filter(withDates);

        if (events.length === 0 || loading) {
            return (
                <IfNoEvents {...this.props} />
            );
        }

        const eventTrie = new EventTrie(eventsWithDates);
        const numMarkerEvents = eventTrie.getCount('testing/marker');

        this._eventTypeIdGroups = groupEventsByTypeId(eventsWithDates);
        const typeIds = Object.keys(this._eventTypeIdGroups);
        // noinspection JSCheckFunctionSignatures
        const therapyLanes = typeIds.filter(t => t.startsWith('therapy')).length;
        // noinspection JSCheckFunctionSignatures
        const testingLanes = typeIds.filter(t => t.startsWith('testing') && !t.startsWith('testing/marker')).length;

        const timeAxisHeight = 17;
        const viewBoxWidth = 500;

        const viewBoxHeight = (therapyLanes + testingLanes) * defaultLaneHeight
            + timeAxisHeight
            + (numMarkerEvents > 0 ? defaultMarkerHeight : 0)
            + margins.top + margins.bottom;

        const viewBox = `0 0 ${viewBoxWidth} ${viewBoxHeight}`;

        const axisToggleButtonTitleMessage = (this.state.useLogPsaValueAxis) ? messages.toggleAxisTitleLinear : messages.toggleAxisTitleLog;
        const axisToggleButtonTitle = intl.formatMessage(axisToggleButtonTitleMessage);
        const axisToggleButtonClasses = classnames('btn btn-default', {
            hidden: numMarkerEvents === 0
        });

        return (
            <div className="event-timeline">
                <div className="event-timeline-options">
                    <FormattedMessage {...messages.toggleAxisLabel}>
                        {txt => (
                            <a
                                className={axisToggleButtonClasses}
                                title={axisToggleButtonTitle}
                                onClick={this.handlePsaValueAxisChange}
                            >
                                {txt}
                            </a>
                        )}
                    </FormattedMessage>
                </div>
                <svg
                    className="event-timeline-canvas"
                    ref={(node) => this._svg = node}
                    width="100%"
                    viewBox={viewBox}
                    preserveAspectRatio="xMinYMin meet"
                >
                    <defs>
                        <filter id="blur">
                            <feGaussianBlur stdDeviation="9"/>
                        </filter>
                    </defs>

                </svg>
            </div>
        );

    }

}

export default injectIntl(_EventTimeline);

function momentLocaleMapping(locale) {

    switch (locale) {
        case 'no':
            return 'nb';
        default:
            return locale;
    }

}

function eventToSemanticClasses(event) {

    const typeId = event.typeId();

    switch (typeId) {
        case 'testing/pathology/biopsy':
            if (event.get('cancer-found') === 'YES') {
                return 'event-marker-biopsy-positive';
            }
            break;
        case 'testing/imaging':
            if (event.get('metastasis-found') === 'YES') {
                return 'event-marker-imaging-metastasis-yes';
            } else if (event.get('metastasis-found') === 'SUSPICION') {
                return 'event-marker-imaging-metastasis-suspicion';
            }
            break;
    }

    return undefined;

}

function withDates(event) {

    try {
        // Event.date() will throw of no (default) date field exists
        return !!event.date();
    } catch (e) {
        return false;
    }

}
