import { v4 as uuidv4} from "uuid";
import { eventFromJSON } from "progether-data-schema";
import { EventStorage } from "./event-storage";

export class LocalEventStorage extends EventStorage {
    _storageKey;
    _events = new Map();

    constructor(storageKey) {
        super();
        this._storageKey = storageKey;
    }

    async load() {
        const str = localStorage.getItem(this._storageKey);
        if (!str) {
            return;
        }

        const json = JSON.parse(str);
        const events = [];
        for (let jsonEvent of json) {
            events.push(await eventFromJSON(jsonEvent));
        }
        this._events = events.reduce((all, event) => {
            all.set(event.id(), event);
            return all;
        }, new Map());
    }

    async getEvent(eventId) {
        if (!this._events.has(eventId)) {
            throw new Error(`unknown eventId: ${eventId}`);
        }

        return this._events.get(eventId);
    }
    
    async saveEvent(event) {
        if (!event.id()) {
            event._event._id = uuidv4();
        }
        
        // 
        if (navigator.storage && navigator.storage.persist) {
            const isPersisted = await navigator.storage.persist();
            console.log('storage persistence granted:', isPersisted);
        }

        this._events.set(event.id(), event);
        this._syncStorage();

        
        return event;
    }

    async deleteEvent(eventId) {
        this._events.delete(eventId);
    }

    async importEvents() {
        const el = document.createElement('input');
        el.setAttribute('id', 'import-json');
        el.setAttribute('type', 'file');
        el.setAttribute('accept', '.json,application/json');
        el.style.display = 'none';
        el.click();
        el.onchange = async (ev) => {
            ev.preventDefault();
            const file = el.files[0];
            if (!file) {
                return;
            }
            const reader = new FileReader();
            reader.onload = async (e) => {
                try {
                    const json = JSON.parse(e.target.result);
                    localStorage.setItem(this._storageKey, JSON.stringify(json));
                    await this.load();
                    window.location.reload();
                } finally { 
                    document.body.removeChild(el);
                }
            }
            reader.readAsText(file);
        }
    }

    listEvents() {
        // subopti
        return Array.from(this._events.values());
    }
    
    _syncStorage() {
        const json = Array.from(this._events.values()).map(e => e._event);
        localStorage.setItem(this._storageKey, JSON.stringify(json));
    }
}