import _typeof from "@babel/runtime/helpers/esm/typeof";
import { COMPLETENESS, EVENT_DATA_OTHER_VALUE_PREFIX, ID_SEPARATOR, IS_VALID, VALUE } from '../utils';
var testForOtherValue = new RegExp("^".concat(EVENT_DATA_OTHER_VALUE_PREFIX));
/**
 * @typedef {object} CheckConditionResult
 * @property {boolean} result - true if the condition evaluated to true, false otherwise
 * @property {string} [message] - optional string message on failure during evaluation of the condition
 * @property {string} [messageId] - optional message id, to be used for translating error messages (not used yet)
 * @property {object} [context] - additional detail data from a failed evaluation
 */

/**
 * Check the condition for a specific field given the provided
 * event data. Returns success if no condition has been defined
 * on the checked field id.
 *
 * Throws an Error exception if an unresolvable state is provided.
 *
 * @param {object} eventSchema
 * @param {string} fieldId
 * @param {object} eventData
 * @returns {CheckConditionResult}
 */

export function checkCondition(eventSchema, fieldId, eventData) {
  var fieldSchema = getFieldSchemaById(eventSchema, fieldId);
  var condition = fieldSchema.condition;

  try {
    var checkResult = checkSingleCondition(condition, eventData, fieldId); // we always return an object with a result property

    return {
      result: checkResult
    };
  } catch (e) {
    if (e instanceof Error) {
      throw e;
    } // checkSingleCondition and its sub methods will throw a plain object
    // exception on any conditions failure, we catch it here and wrap it
    // into an object response including the detailed information.


    var result = Object.assign({
      result: false
    }, e, {
      context: Object.assign({
        checkedField: fieldId
      }, e.context)
    }); // running in node context

    if (typeof window === 'undefined') {
      // running in development mode
      if (/development/i.test(process.env.NODE_ENV)) {
        console.error(JSON.stringify(result, null, 4));
      }
    }

    return result;
  }
}

function checkSingleCondition(condition, eventData, fieldId) {
  // return always true for a missing/undefined condition
  if (!condition) {
    return true;
  } // special handling for multiple conditions type,
  // as this one is strangely defined


  var hasMultipleConditions = condition.hasOwnProperty('method') && condition.hasOwnProperty('conditions');
  var type = condition.type || hasMultipleConditions && 'multiple'; // we have one implementation per type for smaller units of code

  switch (type) {
    case 'field':
      return checkConditionTypeField(condition, eventData, fieldId);

    case 'completeness':
      return checkConditionTypeCompleteness(condition, eventData, fieldId);

    case 'multiple':
      return checkConditionTypeMultiple(condition, eventData, fieldId);

    default:
      throw new Error("unknown condition type '".concat(type, "'"));
  }
}
/**
 * @param fieldSchema
 * @param eventData
 * @returns {*}
 */


function checkConditionTypeField(condition, eventData, fieldId) {
  var status = condition.status;

  switch (status) {
    case 'set':
      return checkFieldConditionStatusSet(condition, eventData, fieldId);

    case 'unset':
      return checkFieldConditionStatusUnset(condition, eventData, fieldId);

    default:
      throw new Error("Unsupported condition.status value: ".concat(status));
  }
}
/**
 * @param fieldSchema
 * @param eventData
 * @returns {{result}|{result, msg}}
 */


function checkConditionTypeCompleteness(condition, eventData, fieldId) {
  var referencedField = getReferencedField(condition.id, eventData, fieldId);

  if (referencedField === undefined) {
    throw {
      message: "Referenced field not defined",
      context: {
        refererencedField: condition.id
      }
    };
  }

  var byConditionReferencedCompleteness = referencedField[COMPLETENESS];
  var completenessType = condition.completeness || 'mandatory';
  var status = condition.status || 'equals';

  var checkCompleteness = function checkCompleteness(compareFunction) {
    var score = byConditionReferencedCompleteness[completenessType].score;
    var value = condition.value;

    if (compareFunction(score, value)) {
      return true;
    } else {
      throw {
        message: 'Completeness check failed',
        context: {
          type: completenessType,
          mode: condition.status,
          expected: condition.value,
          actual: score
        }
      };
    }
  };

  var getCompareFunction = function getCompareFunction() {
    switch (status) {
      case 'greaterThan':
        return function (score, value) {
          return score > value;
        };

      case 'lessThan':
        return function (score, value) {
          return score < value;
        };

      case 'equals':
      default:
        return function (score, value) {
          return score === value;
        };
    }
  };

  return checkCompleteness(getCompareFunction());
}
/**
 * @param eventSchema
 * @param fieldId
 * @param eventData
 */


function checkConditionTypeMultiple(condition, eventData, fieldId) {
  var method = (condition.method || 'AND').toUpperCase();
  var conditions = condition.conditions;

  if (!Array.isArray(conditions)) {
    throw new Error('conditions must be an array');
  } // As {@see checkSingleCondition} will throw (pass through) any
  // exceptions during condition checking, we need to capture them
  // here, else we would prematurely exit, although some alternative
  // OR branch might be true.


  var subConditionIsTrue = function subConditionIsTrue(condition) {
    try {
      return checkSingleCondition(condition, eventData, fieldId);
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      return false;
    }
  };

  switch (method) {
    case 'AND':
      return conditions.every(function (c) {
        return checkSingleCondition(c, eventData, fieldId);
      });

    case 'OR':
      return conditions.some(subConditionIsTrue);

    default:
      throw new Error("Unknown multiple condition method '".concat(method, "'"));
  }
}
/**
 * Return the referenced field. Returns undefined
 * if the field is not found.
 *
 * If the referringFieldId is set, will also look for the required
 * fieldId in each parent of the referringField.
 *
 * @param fieldId
 * @param eventData
 * @param referringFieldId
 */


function getReferencedField(fieldId, eventData) {
  var referringFieldId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var referencedFieldIdOptions = [];
  var referringFieldIdSegments = referringFieldId.split(ID_SEPARATOR);
  var segmentCount = referringFieldIdSegments.length; // construct the list of field ids to check

  for (var i = 1; i < segmentCount; i++) {
    referencedFieldIdOptions.push([referringFieldIdSegments.slice(0, segmentCount - i), fieldId]);
  }

  referencedFieldIdOptions.push(fieldId.split(ID_SEPARATOR));
  /**
   * Traverse the data tree from root to leaf.
   *
   * @param {object} object - The current node
   * @param {string[]} fieldIdPath - An array with the fieldId split up
   * @returns {*} - The objectValue or undefined if the value has not been set prior
   */

  var recursiveGetField = function recursiveGetField(object, fieldIdPath) {
    if (fieldIdPath.length === 0) {
      return object;
    }

    if (!object || !object.hasOwnProperty(VALUE)) {
      // invalid structure, cannot infer any information
      return undefined;
    }

    var objectValue = object[VALUE];
    var fieldId = fieldIdPath.shift();

    if (!objectValue.hasOwnProperty(fieldId)) {
      return undefined;
    }

    return recursiveGetField(objectValue[fieldId], fieldIdPath);
  }; // check all identified field ids


  for (var _i = 0, _referencedFieldIdOpt = referencedFieldIdOptions; _i < _referencedFieldIdOpt.length; _i++) {
    var id = _referencedFieldIdOpt[_i];
    var retrievedValue = recursiveGetField(eventData, id);

    if (retrievedValue !== undefined) {
      return retrievedValue;
    }
  }

  return undefined;
}

function checkFieldConditionStatusSet(condition, eventData, fieldId) {
  var referencedField = getReferencedField(condition.id, eventData, fieldId);

  if (referencedField === undefined) {
    throw {
      message: "Referenced field not defined",
      context: {
        refererencedField: condition.id
      }
    };
  }

  var byConditionReferencedFieldsValue = referencedField[VALUE];
  var isReferencedFieldValid = referencedField[IS_VALID];

  if (byConditionReferencedFieldsValue === undefined) {
    throw {
      message: "'Referenced field's value not defined'",
      context: {
        referencedField: condition.id
      }
    };
  }

  if (!isReferencedFieldValid) {
    // we cannot learn anything from an invalid field
    throw {
      message: "Referenced field is invalid",
      context: {
        referencedField: condition.id,
        referencedValue: byConditionReferencedFieldsValue,
        referencedValidity: isReferencedFieldValid
      }
    };
  } // value(s) is optional


  if (!condition.hasOwnProperty('value') && !condition.hasOwnProperty('values')) {
    // only check the field is not undefined, which is true at this point
    return true;
  }

  var referencedValues = !Array.isArray(byConditionReferencedFieldsValue) ? [byConditionReferencedFieldsValue] : byConditionReferencedFieldsValue;
  var conditionValues = condition.values || [condition.value];
  var valuesType = condition.valuesType || 'oneOf';

  switch (valuesType) {
    case 'allOf':
      {
        if (!conditionValues.every(valueMatches(referencedValues))) {
          throw {
            message: 'Not all required values found',
            context: {
              referencedField: condition.id,
              requiredValuesAll: conditionValues,
              foundValues: referencedValues
            }
          };
        }

        break;
      }

    case 'noneOf':
      {
        if (conditionValues.some(valueMatches(referencedValues))) {
          throw {
            message: 'Some not allowed values have been found',
            context: {
              referencedField: condition.id,
              notAllowedValues: conditionValues,
              foundValues: referencedValues
            }
          };
        }

        break;
      }

    case 'oneOf':
    default:
      {
        if (!conditionValues.some(valueMatches(referencedValues))) {
          throw {
            message: 'None of the required value(s) have been found',
            context: {
              referencedField: condition.id,
              requiredValuesSome: conditionValues,
              foundValues: referencedValues
            }
          };
        }
      }
  }

  return true;
  /**
   * Dedicated function to check matches, as we need to use
   * different logic depending on the actual conditionalValue.
   *
   * This is mostly relevant for the '_OTHER_' field value
   *
   * @param referencedValues
   * @returns {Function}
   */

  function valueMatches(referencedValues) {
    return function (conditionalValue) {
      if (conditionalValue === EVENT_DATA_OTHER_VALUE_PREFIX) {
        return referencedValues.some(function (v) {
          return testForOtherValue.test(v);
        });
      }

      return referencedValues.includes(conditionalValue);
    };
  }
}

function checkFieldConditionStatusUnset(condition, eventData, fieldId) {
  var referencedField = getReferencedField(condition.id, eventData, fieldId);

  if (referencedField === undefined) {
    return true;
  }

  var byConditionReferencedFieldsValue = referencedField[VALUE];

  if (byConditionReferencedFieldsValue === undefined) {
    return true;
  }

  var isReferencedFieldValid = referencedField[IS_VALID];

  if (!isReferencedFieldValid) {
    return true;
  }

  return false;
}
/**
 * Extract the actual field schema from an event schema.
 *
 * @param eventSchema
 * @param fieldId
 * @return the schema for the requested field, if it exists, throws an Error otherwise
 */


function getFieldSchemaById(eventSchema, fieldId) {
  var fieldIdType = _typeof(fieldId);

  if (fieldIdType !== 'string') {
    throw new Error("fieldId must be of type string, was ".concat(fieldIdType));
  }

  var fieldPath = fieldId.split(ID_SEPARATOR);

  var recursiveGetField = function recursiveGetField(schema, path) {
    var depth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

    if (depth === fieldPath.length) {
      return schema;
    } // assuming resolved sub-field ids


    var id = fieldPath.slice(0, depth + 1).join(ID_SEPARATOR);

    if (schema.hasOwnProperty('fields')) {
      if (!schema.fields.some(function (f) {
        return f.id === id;
      })) {
        throw new Error("Field '".concat(fieldId, "' unknown in schema '").concat(eventSchema.id, "'"));
      }

      return recursiveGetField(schema.fields.find(function (f) {
        return f.id === id;
      }), fieldPath, depth + 1);
    }
  };

  return recursiveGetField(eventSchema, fieldPath);
}