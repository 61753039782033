import React from 'react';
import PropTypes from 'prop-types';

/**
 * Will render links with target="_blank" to open
 * them in a new tab.
 *
 * @param href
 * @param children
 * @returns {*}
 * @constructor
 */
export const LinkWithTargetRenderer = ({href, title, children}) => {

    // Use rel="noopener noreferrer" for security reasons: https://mathiasbynens.github.io/rel-noopener
    return (
        <a href={href} target="_blank" rel="noopener noreferrer" title={title}>{children}</a>
    );

};

LinkWithTargetRenderer.propTypes = {
    href: PropTypes.string.isRequired,
    title: PropTypes.string
};

/**
 * Will render image markdown with a m4v file as a video
 *
 * @param props
 * @returns {*}
 * @constructor
 *
 * {
 *  "src": "/media/image/58664bdc6899b5351f000002.png?size=md",
 *  "alt": "",
 *  "literal": null,
 *  "nodeKey": "3:1-3:541"
 *  }
 *
 */
export const ImageToVideoRenderer = ({src = ''}) => {

    if (/\.m4v/i.test(src)) {
        return (
            <video controls="controls">
                <source type="video/mp4" src={src}></source>
                <p>Your browser does not support the video element.</p>
            </video>
        );
    }

    if (/\.(jpg|jpeg|png|gif)/i.test(src)) {
        return (
            <img src={src}/>
        );
    }

    // only renders known file format
    return null;

};

ImageToVideoRenderer.propTypes = {
    src: PropTypes.string.isRequired
};

export const NoParagraphsRenderer = (props) => {
    return (<span>{props.children}</span>);
};
