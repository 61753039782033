import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {HelpMixin} from './help-mixin';
import {injectIntl} from 'react-intl';

class Radio extends HelpMixin(Component) {

    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        label: PropTypes.node.isRequired,
        checked: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired
    };

    render() {
        return (

            <div className={'radio' + (this.props.checked ? ' checked' : '')}
                onChange={this.props.onChange.bind(this, this.props.value)}>

                <label>
                    <input type="radio"
                        name={this.props.name}
                        value={this.props.value}
                        checked={this.props.checked}/>
                    {this.props.label}
                </label>
                {this.renderHelp()}
                {this.renderHelpText()}
            </div>
        );
    }
}

export default injectIntl(Radio);
