import PropTypes from 'prop-types';

const valueType = PropTypes.oneOfType([
    PropTypes.shape({
        // used in the ui
        label: PropTypes.string,
        // internal value
        value: PropTypes.any.isRequired
    }),
    PropTypes.number,
    PropTypes.string
]);

const defType = PropTypes.shape({
    // type, used to select the rendering class
    type: PropTypes.string.isRequired,
    // specifing rendering details
    defaultRepresentation: PropTypes.string,
    // only mandatory fields are used to calculate completeness
    mandatory: PropTypes.bool,
    // not visible in the ui
    hidden: PropTypes.bool,
    // default value, if none set
    defaultValue: PropTypes.any,
    // available values
    values: PropTypes.arrayOf(valueType)
});

const fieldType = PropTypes.shape({
    // unique identifier
    id: PropTypes.string.isRequired,
    // definition
    def: defType.isRequired,
    // locale label
    label: PropTypes.string,
    // help text
    help: PropTypes.string
});

export default {
    value: valueType,
    def: defType,
    field: fieldType,
    fieldValue: PropTypes.any
};

