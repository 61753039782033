import { TYPE, IS_VALID, ID_SEPARATOR, COMPLETENESS, VALUE, EVENT_DATA_OTHER_VALUE_PREFIX } from './data/utils'; // Used by UI to build eventData objects
// @TODO: Evaluate of this can be removed in the future

export var EVENT_KEY_WORDS = {
  TYPE: TYPE,
  VALUE: VALUE,
  COMPLETENESS: COMPLETENESS,
  IS_VALID: IS_VALID,
  ID_SEPARATOR: ID_SEPARATOR,
  EVENT_DATA_OTHER_VALUE_PREFIX: EVENT_DATA_OTHER_VALUE_PREFIX
};
import * as _SchemaFactory from './schema-factory';
export { _SchemaFactory as SchemaFactory };
import * as _messageIdGenerators from './schema-message-id-generators';
export { _messageIdGenerators as messageIdGenerators };
import * as _eventClasses from './eventClasses';
export { _eventClasses as eventClasses };
export * from './event';
import * as _SchemaFactorySync from './schema-factory-sync';
export { _SchemaFactorySync as SchemaFactorySync };
export * from './event-sync';
export { minimizedJson } from './data/export';