import React, {Component} from 'react';
import {FormsMixin} from '../forms-mixin';
import {MultipleOfOtherMixin} from './mixin';
import PartsCheck from '../../parts/check';

import {FormattedMessage} from 'react-intl';
import {eventFieldValueLabel} from '../../../../lib/intl-helper';

class MultipleOfOtherCheck extends MultipleOfOtherMixin(FormsMixin(Component)) {

    renderOther() {
        if (!this.hasOther()) {
            return null;
        }

        let value = this.getOther() || null;

        let cb = function (e) {
            this.setOther(e.target.value);
        }.bind(this);

        return (
            <input
                type="text"
                className="form-control"
                onChange={cb}
                value={value}
            />
        );

    }

    render() {
        let cbOther = () => {
            this.toggleOther();
        };

        return (
            <div>
                {
                    this.props.def.values.map(v => {
                        let label = v.labelFormatted ? v.labelFormatted : v.label;

                        let prop = Object.assign({}, v, {
                            name: this.props.id,
                            checked: this.selectionHasItem(v.value),
                            onChange: this.toggleSelectionItem.bind(this, v.value),
                            label
                        });

                        return <PartsCheck key={this.props.id + '-' + v.value} {...prop}/>;
                    })
                }
                <PartsCheck
                    onChange={cbOther}
                    name={this.props.id}
                    label={<FormattedMessage id={eventFieldValueLabel(this.props.eventId, this.props.id, 'other')}/>}
                    value="_OTHER_"
                    checked={this.hasOther()}/>
                {this.renderOther()}
            </div>
        );
    }
}

export default MultipleOfOtherCheck;
