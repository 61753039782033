import { COMPLETENESS, EVENT_DATA_OTHER_VALUE_PREFIX, ID_SEPARATOR, IS_VALID, SCHEMA_ID, TYPE, VALUE } from '../utils';
import validate from 'validate.js';
import moment from 'moment'; // Before using it we must add the parse and format functions
// Here is a sample implementation using moment.js
// See: https://validatejs.org/#validators-datetime

validate.extend(validate.validators.datetime, {
  // The value is guaranteed not to be null or undefined
  // but otherwise it could be anything.
  parse: function parse(value, options) {
    // parse with an explicit format, allowing us
    // to use valid ISO8601 formats like:
    // YYYY-MM-DD
    // YYYY-MM
    // YYYY
    var useStrictParsingMode = true;
    var allowedFormats = ['YYYY-MM-DD', 'YYYY-MM', 'YYYY'];
    var parsedValue = moment.utc(value, allowedFormats, useStrictParsingMode);
    var isValidDate = parsedValue.isValid();

    if (!isValidDate) {
      // not valid
      return NaN;
    } // return as unix timestamp in milliseconds as per the api spec


    var timestampInMilliseconds = parsedValue.valueOf();
    return timestampInMilliseconds;
  },
  // input is a millisecond unix timestamp
  format: function format(value, options) {
    var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
    return moment.utc(value).format(format);
  }
});
/**
 * A list of fields is valid if all fields are valid.
 * A list of fields is invalid if any one field is not valid.
 *
 * @param fieldsContainer
 * @param containerData
 */

export var validateFields = function validateFields(fieldsContainer, containerData) {
  var depth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {
    logTarget: console
  };

  if (fieldsContainer.fields) {
    // need to allow (incomplete list): id, created, updated, ... ?
    warnOnUnknownField(fieldsContainer, containerData, options);
    var validationResults = fieldsContainer.fields.map(function (field) {
      if (!containerData[VALUE]) {
        containerData[VALUE] = {};
      }

      var fieldId = field.id.split(ID_SEPARATOR)[depth];

      if (!containerData[VALUE][fieldId]) {
        containerData[VALUE][fieldId] = {};
      }

      var fieldData = containerData[VALUE][fieldId];
      return validateField(field, fieldData, depth + 1, options);
    });
    var eventIsValid = validationResults.every(function (r) {
      return r[IS_VALID];
    });
    containerData[IS_VALID] = eventIsValid;
    return containerData;
  }
};
/**
 * A field is valid if
 *
 * - the field is not set (undefined),
 *   or the set value is within all defined
 *   constraints.
 *
 * @param fieldSchema
 * @param fieldData
 */

export var validateField = function validateField(fieldSchema) {
  var fieldData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var depth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {
    logTarget: console
  };

  if (fieldSchema.hasOwnProperty('fields')) {
    return validateFields(fieldSchema, fieldData, depth, options);
  }

  if (!fieldData.hasOwnProperty(VALUE)) {
    // shortcut, if no value is defined, it's by definition valid
    fieldData[IS_VALID] = true;
    return fieldData;
  }

  var type = fieldSchema.def.type;
  var validationResult = {
    valid: false,
    set: false
  };
  var currentValue = fieldData[VALUE];
  var fieldConstraints = fieldSchema.def.constraints;
  var fieldValueType = fieldSchema.def.valueType || 'string';
  var allowedValues = (fieldSchema.def.values || []).map(function (v) {
    return v.value;
  }); // delegate validation to type specific implementations

  switch (type) {
    case 'text':
      validationResult = validateText(currentValue, fieldValueType, fieldConstraints, options);
      break;

    case 'oneOf':
      validationResult = validateValues(currentValue, allowedValues, fieldConstraints, false, false, options);
      break;

    case 'oneOfOther':
      validationResult = validateValues(currentValue, allowedValues, fieldConstraints, false, true, options);
      break;

    case 'multipleOf':
      validationResult = validateValues(currentValue, allowedValues, fieldConstraints, true, false, options);
      break;

    case 'multipleOfOther':
      validationResult = validateValues(currentValue, allowedValues, fieldConstraints, true, true, options);
      break;

    default:
      throw new Error('field type unsupported by validation');
  } // attach validation and completeness information to field


  fieldData[IS_VALID] = validationResult.valid;
  return fieldData;
};

function validateText(fieldValue, fieldValueType, fieldConstraints) {
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {
    logTarget: console
  };
  var _options$logTarget = options.logTarget,
      logTarget = _options$logTarget === void 0 ? console : _options$logTarget;
  var hasValue = fieldValue !== undefined;
  var isValid = typeof fieldValue === 'string';
  var isConstraintValid = true;

  if (fieldConstraints) {
    var constraintValidationResult = validate({
      value: fieldValue
    }, {
      value: fieldConstraints
    });

    if (constraintValidationResult) {
      logTarget.error('validation failed: ' + JSON.stringify(constraintValidationResult, null, 4));
    }

    isConstraintValid = hasValue && constraintValidationResult === undefined;
  }

  if (fieldValueType) {
    switch (fieldValueType) {
      case 'integer':
        isValid = typeof fieldValue === 'number' && Number.isInteger(fieldValue);
        break;

      case 'float':
        isValid = typeof fieldValue === 'number';
        break;
    }
  }

  return {
    valid: hasValue && isValid && isConstraintValid,
    set: hasValue
  };
}

function validateValues(fieldValue, allowedValues, fieldConstraints, allowMultiple, allowOther) {
  var options = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {
    logTarget: console
  };
  var _options$logTarget2 = options.logTarget,
      logTarget = _options$logTarget2 === void 0 ? console : _options$logTarget2;

  if (!Array.isArray(allowedValues)) {
    throw new Error('allowedValues must be an array');
  }

  if (fieldConstraints) {
    // TODO
    throw new Error('validateOneOf with validations is not supported yet!');
  } else {
    var hasValue = false,
        isInAllowedValues = false;

    var checkIsAllowedValue = function checkIsAllowedValue(value) {
      var isOtherValue = typeof value === 'string' && value.startsWith(EVENT_DATA_OTHER_VALUE_PREFIX);
      var isOneOfTheAllowedValues = allowedValues.includes(value);
      var isAllowedOtherValue = allowOther && isOtherValue;

      if (!isOneOfTheAllowedValues && !isAllowedOtherValue) {
        logTarget.error('value', value, 'is not one of the allowed values', allowedValues);
      }

      return isOneOfTheAllowedValues || isAllowedOtherValue;
    };

    if (allowMultiple) {
      hasValue = Array.isArray(fieldValue);
      isInAllowedValues = !hasValue || fieldValue.every(checkIsAllowedValue);
    } else {
      hasValue = fieldValue !== undefined;
      isInAllowedValues = checkIsAllowedValue(fieldValue);
    }

    return {
      valid: hasValue && isInAllowedValues,
      set: hasValue
    };
  }
}
/**
 * Throw an exception if containerData contains any
 * unexpected properties. Relies on {@see warnOnUnknownField}
 *
 * @param fieldsContainer
 * @param containerData
 */


function assertNoUnknownFields(fieldsContainer, containerData) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    logEnabled: false
  };
  var warning = warnOnUnknownField(fieldsContainer, containerData, options);

  if (warning && warning.tag) {
    throw new Error(warning.tag.message);
  }
}
/**
 * Return a warning message (and log it to stderr) if containerData
 * contains any unexpected properties.
 *
 * @param fieldsContainer
 * @param containerData
 */


function warnOnUnknownField(fieldsContainer, containerData) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    logTarget: console
  };
  var _options$logTarget3 = options.logTarget,
      logTarget = _options$logTarget3 === void 0 ? console : _options$logTarget3;

  if (containerData === undefined) {
    // this is ok
    return;
  }

  var warnIfUnknownValuesFound = function warnIfUnknownValuesFound(values, knownValues, location) {
    var unknownValues = values.filter(function (v) {
      return !knownValues.includes(v);
    });

    if (unknownValues.length > 0) {
      var message = {
        tag: 'schema-data-validate-field-found-unknown-property',
        message: "Expected properties ".concat(JSON.stringify(knownValues), ", but found ").concat(JSON.stringify(unknownValues), ", on ").concat(location)
      };

      if (options.logEnabled) {
        logTarget.error(message);
      }

      return message;
    }
  }; // props (fixed)


  var dataProps = Object.keys(containerData);
  var allowedProps = [VALUE, COMPLETENESS, TYPE, IS_VALID, SCHEMA_ID];
  return warnIfUnknownValuesFound(dataProps, allowedProps, fieldsContainer.id); // fields (dynamic, from schema)

  var dataFields = Object.keys(containerData[VALUE] || {});
  var allowedFields = fieldsContainer.fields.map(function (f) {
    return f.id;
  });
  return warnIfUnknownValuesFound(dataFields, allowedFields, "".concat(fieldsContainer.id).concat(ID_SEPARATOR).concat(VALUE));
}