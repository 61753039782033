import React, {Component, useMemo} from 'react';
import PropTypes from 'prop-types';
import {defineMessages} from "react-intl";
import {EditTitle} from './edit-title';
import {EventTypeCategoryList} from './menu-parts';
import './event-menu.less';
import { useEventSchema } from '../../contexts/schema.context';
import LoadingPlaceholder from '../loading-placeholder';

const messages = defineMessages({
    menuTitle: {
        id: 'event_form_header_navigation_header',
        defaultMessage: 'Events',
    },
});

export function EventMenu({ eventTypeId = '', onSelect, onBack }) {
    const { schema, loading, error } = useEventSchema();
    
    const eventTypes = useMemo(() => {
        if (schema) {
            return schema.listEvents().map(e => e.id).sort();
        }
        return [];
    }, [schema]);

    if (error) {
        return <pre>{error}</pre>
    }

    return (
        <LoadingPlaceholder loading={loading}>
    
            <div className="event-menu">

                <EditTitle titleText={messages.menuTitle} onBack={onBack} />

                <EventTypeCategoryList
                    eventTypes={eventTypes}
                    onClick={onSelect}/>

            </div>
        </LoadingPlaceholder>
    );
}

/*
export default connect(
    state => ({
        schema: schemaSelectors.getSchema(state),
    }),
    dispatch => ({
        onSelect: (eventTypeId) => dispatch(eventActions.createEvent(eventTypeId)),
        onBack: () => dispatch(eventActions.cancelEdit()),
    })
)(EventMenu);
*/