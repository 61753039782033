// Provides basic information on the current viewport.
// @author Sebastian <sebastian@progether.com>

const mobileWidth = 420,
    tabletWidth = 786;

let viewport = getViewport();

function getViewport() {

    // requires browser context
    if (typeof window === 'undefined' || typeof document === 'undefined') return;

    const w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        x = w.innerWidth || e.clientWidth || g.clientWidth,
        y = w.innerHeight || e.clientHeight || g.clientHeight;

    return Object.freeze({
        width: x,
        height: y,
        mobile: x <= mobileWidth,
        tablet: x > mobileWidth && x <= tabletWidth,
        portrait: y > x,
        landscape: x > y
    });

}

const viewportListener = [];
let isListeningYet = false;

export function registerViewportListener(listener) {

    viewportListener.push(listener);
    listenForViewportChanges();

}

export function unregisterViewportListener(listener) {

    const ix = viewportListener.indexOf(listener);

    if (ix > -1) {
        viewportListener.splice(ix, 1);
        listenForViewportChanges();
    } else {
        // we can ignore this case
    }

}

function listenForViewportChanges() {

    if (viewportListener.length > 0) {

        // should listen
        if (!isListeningYet) {
            // is not listening yet
            window.addEventListener('resize', listener, {passive: true});
            triggerViewportUpdate(getViewport());
        }

    } else {

        // should not listen
        if (isListeningYet) {
            // is listening
            window.removeEventListener('resize', listener);
        }

    }

}

function listener() {
    triggerViewportUpdate(getViewport());
}

function triggerViewportUpdate(nextViewport) {
    viewportListener.forEach(l => l(nextViewport));
}

export default viewport;
