import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormsMixin } from '../forms-mixin';

import StructTypes from '../../prop-types';
import PSAInput  from '../../../psa-input';

class PSA extends FormsMixin(Component) {
    static propTypes = {
        value: StructTypes.fieldValue,
        eventData: PropTypes.object,
        eventId: PropTypes.string,

    };

    render () {
        let valueField = this.getSubfield(this.props.id);

        if(!valueField) return null;

        const typeValue = this.getValueOfField(valueField.type.fields[0].id);
        const psaValue = this.getValueOfField(valueField.type.fields[1].id);

        return (
            <PSAInput
                psa={{
                    entry: psaValue,
                    type: typeValue
                }}

                onChange={({ entry, type }) => {
                    this.props.onChange({
                        [valueField.type.fields[0].id]: type,
                        [valueField.type.fields[1].id]: entry
                    });
                }}

                disabled={false}
            />
        );
    }
}

export default PSA;
