import _typeof from "@babel/runtime/helpers/esm/typeof";
export var ID_SEPARATOR = '/';
export var VALUE = '_value';
export var TYPE = '_type';
export var COMPLETENESS = '_completeness';
export var IS_VALID = '_valid';
export var SCHEMA_ID = '_schema';
export var EVENT_DATA_OTHER_VALUE_PREFIX = '_OTHER_';
export function assertDataObjectIsValid(dataObject) {
  if (_typeof(dataObject) !== 'object' || Array.isArray(dataObject)) {
    throw new Error('not a valid data object');
  }
}
export function assertMatchingEvent(eventSchema, dataObject) {
  var addIfMissing = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  if (!dataObject[TYPE]) {
    if (!addIfMissing) {
      throw new Error('dataObject has no eventSchemaId');
    }

    dataObject[TYPE] = eventSchema.id;
  }

  if (dataObject[TYPE] !== eventSchema.id) {
    throw new Error('dataObject has wrong eventSchemaId');
  }
}
export function assertSchemaHasField(eventSchema, fieldId) {
  if (!eventSchema) {
    throw new Error('missing eventSchema');
  }

  var hasMatchingId = function hasMatchingId(field) {
    if (field.id === fieldId) {
      // found, fine
      return true;
    }

    if (field.fields) {
      return field.fields.some(hasMatchingId);
    }

    return false;
  };

  if (eventSchema.fields) {
    if (!eventSchema.fields.some(hasMatchingId)) {
      throw new Error("Field '".concat(fieldId, "' not defined/present in schema '").concat(eventSchema.id, "'"));
    }
  }

  return true;
}