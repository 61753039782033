import React from 'react';
import PropTypes from 'prop-types';

export const HelpMixin = SuperClass => class _HelpHelpMixin extends SuperClass {
    static propTypes = {
        help: PropTypes.string
    };

    state = {
        help: false
    };

    getHelpText () {
        if(!this.props.help) return null;

        return (this.props.intl.messages.hasOwnProperty(this.props.help) ? this.props.intl.messages[this.props.help] : null);
    }

    toggleHelp = () => {
        this.setState(oldState => ({
            help: !oldState.help
        }));
    };

    renderHelp () {
        if (!this.getHelpText()) return null;

        return (
            <span className={'help-icon' + (this.state.help ? ' expanded' : '')} onClick={this.toggleHelp} />
        );
    }

    renderHelpText () {
        if (!this.state.help) return null;

        return (
            <div className="help-text">{this.getHelpText()}</div>
        );
    }
};
