import React, { Component } from 'react';
import { FormsMixin } from '../forms-mixin';

class MultipleOf extends FormsMixin(Component) {
    getSelection () {
        let selection = this.getValue();

        if (!Array.isArray(selection)) {
            if (selection !== null && selection !== undefined) {
                selection = [selection];
            } else {
                selection = [];
            }
        }
        return selection;
    }

    renderCheckbox (value) {
        let label = value.labelFormatted ? value.labelFormatted : value.label,
            val = value.value || value,
            pos = -1;

        let cb = (e) => {
            let selection = this.getSelection();

            if ((pos = selection.indexOf(val)) === -1) {
                selection.push(val);
            } else {
                selection.splice(pos, 1);
            }

            /**
             * See: https://facebook.github.io/react/docs/forms.html
             * "Be aware that, in an attempt to normalize change handling
             *  for checkbox and radio inputs, React uses a click event in
             *  place of a change event. For the most part this behaves as
             *  expected, except when calling preventDefault in a change
             *  handler. preventDefault stops the browser from visually
             *  updating the input, even if checked gets toggled. This can
             *  be worked around either by removing the call to
             *  preventDefault, or putting the toggle of checked in a
             *  setTimeout."
            **/
            window.setTimeout(function () {
                this.update(this.props.def, this.props.id, selection.length ? selection : undefined, true, 1);
            }.bind(this), 1);

            e.preventDefault();

        };

        let checked = (this.getValue()) ? (this.getValue().indexOf(val) !== -1) : false;

        return (
            <div className={'checkbox' + (checked ? ' checked' : '')} key={val} onClick={cb}>
                <label>
                    <input type="checkbox" className="styled" value="val" checked={checked}/>
                    {label}
                </label>
            </div>
        );
    }

    render () {
        return (
            <div>
                { this.props.def.values.map(this.renderCheckbox.bind(this)) }
            </div>
        );
    }
}

export default MultipleOf;
