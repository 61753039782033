import React from 'react';
import PropTypes from 'prop-types';
import { EventTimeline, SortedEventTable } from './index';
import './event-tabs.less';
import Tabs from '../tabs';
import { defineMessages, FormattedMessage } from 'react-intl';
import { EVENT_TABLE_SHOW_UPDATED_OPTIONS_KEYS } from './event-table';
import moment from 'moment';
import classNames from 'classnames';

const messages = defineMessages({
    eventChartLabel: {
        id: 'part_tabbed_events_chart_tab_label',
        defaultMessage: 'Visual Overview',
    },
    eventListPSALabel: {
        id: 'part_tabbed_events_list_psa_tab_label',
        defaultMessage: 'PSA Values',
    },
    eventListTestingLabel: {
        id: 'part_tabbed_events_list_testing_tab_label',
        defaultMessage: 'Test results',
    },
    eventListTherapyLabel: {
        id: 'part_tabbed_events_list_local_therapies_tab_label',
        defaultMessage: 'Therapies',
    },
    eventListDrugsLabel: {
        id: 'part_tabbed_events_list_systemic_therapies_tab_label',
        defaultMessage: 'Medication',
    },
    eventListAllLabel: {
        id: 'part_tabbed_events_list_all_tab_label',
        defaultMessage: 'All',
    },
});

const TabbedEventFilters = [
    {
        id: 'psa',
        label: messages.eventListPSALabel,
        filter: e => /psa/.test(e.typeId()),
        showTypeHeader: false,
    },
    {
        id: 'testing',
        label: messages.eventListTestingLabel,
        filter: e => !/psa/.test(e.typeId()) && /testing/.test(e.typeId()),
        showTypeHeader: true,
    },
    {
        id: 'localTherapy',
        label: messages.eventListTherapyLabel,
        filter: e => /therapy\/local/.test(e.typeId()),
        showTypeHeader: true,
    },
    {
        id: 'systemicTherapy',
        label: messages.eventListDrugsLabel,
        filter: e => /therapy\/systemic/.test(e.typeId()),
        showTypeHeader: true,
    },
    {
        id: 'all',
        label: messages.eventListAllLabel,
        filter: () => true,
        showTypeHeader: true,
    },
];

export default function TabbedEvents(props) {

    const {
        events,
        showUpdated,
        highlightUpdatedAfter,
    } = props;

    const refTime = moment(highlightUpdatedAfter);

    return (
        <div>
            <Tabs panels={[
                {
                    panelId: 'chart',
                    tabLabelMessage: messages.eventChartLabel,
                    panelNode: <EventTimeline {...props} />,
                },
                ...TabbedEventFilters.map(({ id, label, filter, showTypeHeader }) => {
                    const filteredEvents = events.filter(filter);
                    const updatedSince = showUpdated !== EVENT_TABLE_SHOW_UPDATED_OPTIONS_KEYS.none
                        ? filteredEvents.filter(e => refTime.isBefore(e.updated())).length
                        : undefined;
                    return {
                        panelId: id,
                        tabNode: (
                            <span>
                                <FormattedMessage {...label} />
                                &nbsp;
                                <EventCounterBadge count={filteredEvents.length} updatedCount={updatedSince}/>
                            </span>
                        ),
                        panelNode: <SortedEventTable {...props}
                                                     events={filteredEvents}
                                                     showTypeHeader={showTypeHeader}
                        />,
                    };
                }),
            ]}/>

        </div>
    );

}

function EventCounterBadge(props) {
    const {
        count,
        updatedCount,
    } = props;

    if (count && updatedCount >= 0) {
        return (
            <span className={classNames('label', {
                'label-warning': updatedCount > 0,
                'label-default': updatedCount === 0,
            })}>{updatedCount}/{count}</span>
        );
    } else {
        return (<span className="label label-default">{count}</span>);
    }
}

EventCounterBadge.propTypes = {
    count: PropTypes.number.isRequired,
    updatedCount: PropTypes.number,
};

const EVENT_TABLE_SHOW_UPDATED_OPTIONS = Object.values(EVENT_TABLE_SHOW_UPDATED_OPTIONS_KEYS);

TabbedEvents.propTypes = {
    events: PropTypes.arrayOf(
        PropTypes.object,
    ),
    onEventSelection: PropTypes.func,
    onEventDeletion: PropTypes.func,
    onCreateEvent: PropTypes.func,

    sortBy: PropTypes.oneOf([ 'time', 'type' ]),
    sortDirection: PropTypes.oneOf([ 'asc', 'desc' ]),
    onSort: PropTypes.func,

    loading: PropTypes.bool,
    existingEventsCount: PropTypes.number,
    showUpdated: PropTypes.oneOf(EVENT_TABLE_SHOW_UPDATED_OPTIONS),
    showComments: PropTypes.bool,
    highlightUpdatedAfter: PropTypes.oneOfType([ PropTypes.string, PropTypes.instanceOf(Date) ]),
};

TabbedEvents.defaultProps = {
    showUpdated: EVENT_TABLE_SHOW_UPDATED_OPTIONS_KEYS.none,
};
