import { injectIntl, defineMessages } from 'react-intl';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { PointDateInput, PointDateDropdown } from '../_parts.js';
import '../style.less';

// -------------------------------------------------------------------
// intl-messages
// -------------------------------------------------------------------

const messages = defineMessages({
    part_datepicker2_input_placeholder: {
        id: 'part_datepicker2_input_placeholder',
        defaultMessage: 'MM/DD/YYYY',
        description: 'The localized date format pattern used as placeholder for the input field'
    }
});

class Datepicker2 extends Component {

    constructor(props) {

        super(props);
        this.state = {
            showDropdown: false
        };

        // bind this
        this.handleDropdownCancel = this.handleDropdownCancel.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);

    }

    handleDropdownCancel() {
        this.doHideDropdown();
    }

    handleDropdownChange(value) {
        this.doHideDropdown();
        this.props.onChange(value);
    }

    handleInputChange(value) {
        this.props.onChange(value);
    }

    handleInputFocus() {
        this.doHideDropdown();
    }

    doShowDropdown(e) { // eslint-disable-line no-unused-vars
        //e && e.preventDefault();
        this.setState({
            showDropdown: true
        });
    }

    doHideDropdown(e) {
        e && e.preventDefault();
        this.setState({
            showDropdown: false
        });
    }

    doToggleDropdown(e) { // eslint-disable-line no-unused-vars
        //e && e.preventDefault();
        const {disabled} = this.props;
        this.setState({
            showDropdown: !disabled && !this.state.showDropdown
        });
    }

    render() {

        const {
            date,
            intl,
            min,
            max,
            placeholder = intl.formatMessage(messages.part_datepicker2_input_placeholder),
            showShortcuts,
            disabled,
            tabIndex
        } = this.props;
        const { showDropdown } = this.state;

        const doShowDropdown = showDropdown && !disabled;

        const classes = classNames('pg-date-picker2 pg-point-picker2', {
            open: doShowDropdown,
            disabled: disabled
        });

        return (
            <div className={ classes }>
                <PointDateInput
                    placeholder={placeholder}
                    min={min}
                    max={max}
                    date={date}
                    onFocus={ this.handleInputFocus.bind(this) }
                    onChange={this.handleInputChange.bind(this)}
                    onDropdownShow={() => {}}
                    onDropdownToggle={this.doToggleDropdown.bind(this)}
                    disabled={disabled}
                    tabIndex={tabIndex}
                />
                <PointDateDropdown
                    show={doShowDropdown}
                    min={min}
                    max={max}
                    date={date}
                    onCancel={this.handleDropdownCancel}
                    onChange={this.handleDropdownChange}
                    showShortcuts={showShortcuts}
                    tabIndex={tabIndex}
                />
            </div>
        );

    }

}

Datepicker2.propTypes = {
    min: PropTypes.object, // moment
    max: PropTypes.object, // moment
    date: PropTypes.shape({
        year: PropTypes.number,
        month: PropTypes.number,
        day: PropTypes.number
    }),
    onChange: PropTypes.func.isRequired, // callback on valid values
    placeholder: PropTypes.string,
    showShortcuts: PropTypes.bool,
    disabled: PropTypes.bool,
    tabIndex: PropTypes.number
};

Datepicker2.defaultProps = {
    min: moment().subtract(50, 'years'),
    max: moment(),
    disabled: false
};

export default injectIntl(Datepicker2);
