var getMessageId = function getMessageId() {
  for (var _len = arguments.length, keys = new Array(_len), _key = 0; _key < _len; _key++) {
    keys[_key] = arguments[_key];
  }

  return keys.map(function (k) {
    return ("" + k).replace(/[^a-z0-9]+/ig, '_');
  }).join("_");
};

export var eventFormHelpText = function eventFormHelpText(eventId) {
  return getMessageId('form', eventId, 'help', 'text');
};
export var eventFormHelpTitle = function eventFormHelpTitle(eventId) {
  return getMessageId('form', eventId, 'help', 'name');
};
export var eventFormTitle = function eventFormTitle(eventId) {
  return getMessageId('form', eventId, 'title');
};
export var eventFieldHelpText = function eventFieldHelpText(eventId, fieldId) {
  return getMessageId('form', eventId, 'field', fieldId, 'help');
};
export var eventFieldLabel = function eventFieldLabel(eventId, fieldId) {
  return getMessageId('form', eventId, 'field', fieldId, 'label');
};
export var eventFieldPlaceholder = function eventFieldPlaceholder(eventId, fieldId) {
  return getMessageId('form', eventId, 'field', fieldId, 'placeholder');
};
export var eventFieldPostfixLabel = function eventFieldPostfixLabel(eventId, fieldId) {
  return getMessageId('form', eventId, 'field', fieldId, 'postfix');
};
export var eventFieldValueHelp = function eventFieldValueHelp(eventId, fieldId, value) {
  return getMessageId('form', eventId, 'field', fieldId, 'value', value, 'help');
};
export var eventFieldValueLabel = function eventFieldValueLabel(eventId, fieldId, value) {
  return getMessageId('form', eventId, 'field', fieldId, 'value', value, 'label');
};
export var messageKeyPrototypesByComponentLevel = {
  event: [function (eventId) {
    return {
      id: eventFormHelpText(eventId),
      description: "The (optional) help text for the '".concat(eventId, "' form dialog")
    };
  }, function (eventId) {
    return {
      id: eventFormHelpTitle(eventId),
      description: "The (optional) help headline for the '".concat(eventId, "' form dialog")
    };
  }, function (eventId) {
    return {
      id: eventFormTitle(eventId),
      description: "The title for the '".concat(eventId, "' form dialog")
    };
  }],
  field: [function (eventId, fieldId) {
    return {
      id: eventFieldHelpText(eventId, fieldId),
      description: "The (optional) help text for the '".concat(fieldId, "' field in the '").concat(eventId, "' form dialog")
    };
  }, function (eventId, fieldId) {
    return {
      id: eventFieldLabel(eventId, fieldId),
      description: "The label for the '".concat(fieldId, "' field in the '").concat(eventId, "' form dialog")
    };
  }, function (eventId, fieldId) {
    return {
      id: eventFieldPlaceholder(eventId, fieldId),
      description: "The (optional) placeholder text for the '".concat(fieldId, "' field in the '").concat(eventId, "' form dialog")
    };
  }, function (eventId, fieldId) {
    return {
      id: eventFieldPostfixLabel(eventId, fieldId),
      description: "The (optional) postfix label (shown after the element) for the '".concat(fieldId, "' field in the '").concat(eventId, "' form dialog")
    };
  }],
  value: [function (eventId, fieldId, value) {
    return {
      id: eventFieldValueHelp(eventId, fieldId, value),
      description: "The (optional) help text for the value '".concat(value, "' of the '").concat(fieldId, "' field in the '").concat(eventId, "' form dialog")
    };
  }, function (eventId, fieldId, value) {
    return {
      id: eventFieldValueLabel(eventId, fieldId, value),
      description: "The label for the value '".concat(value, "' of the '").concat(fieldId, "' field in the '").concat(eventId, "' form dialog")
    };
  }]
};