import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import './app-header.less';

export function AppHeader() {
    const {locale} = useIntl();

    return (
        <nav className='navbar navbar-default navbar-fixed-top'>
            <div className="container">
                <div className="navbar-header">
                    <button 
                        type="button" 
                        className="navbar-toggle collapsed" 
                        data-toggle="collapse"
                        data-target="#app-navbar-collapse" 
                        aria-expanded="false"
                    >
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    <a className="navbar-brand" href="#">Progether</a>
                </div>

                <div className="collapse navbar-collapse" id="app-navbar-collapse">
                    <ul className="nav navbar-nav navbar-left">
                        <li>
                            <Link to={`/${locale}/add`}>
                                <i className="fa fa-plus-square"/>{' '}
                                <FormattedMessage id="tpl_base_nav_main_adddata_label" defaultMessage="Add Data"/>
                            </Link> 
                        </li>
                    </ul>
                    <ul className="nav navbar-nav navbar-right">

                        <li className="dropdown">
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-language"/>{' '}
                                <FormattedMessage id="app_header_language_switcher_label" defaultMessage="Language"/>
                            </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link to='/de'><i className="fa fa-language"/>{' '}Deutsch</Link>
                                    </li>
                                    <li>
                                        <Link to="/en"><i className="fa fa-language"/>{' '}English</Link>
                                    </li>
                                    <li>
                                        <Link to="/no"><i className="fa fa-language"/>{' '}Norsk</Link>
                                    </li>
                                </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}