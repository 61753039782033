import React, {Component} from 'react';
import {DateMixin} from './date-mixin';
import {FormsMixin} from '../forms-mixin';
import DateTypes from './types';
import DateRangePicker2 from '../../../datepicker/date-range-picker-2';

class DateRange extends DateMixin(FormsMixin(Component)) {

    static propTypes = {
        value: DateTypes.interval
    };

    getSubFields() {
        const dateField = this.getSubfield(this.props.id);

        const fromField = this.getSubfield('from', dateField);
        const toField = this.getSubfield('to', dateField);

        if(!fromField || !toField) return null;

        return {
            from: fromField,
            to: toField
        };
    }

    getValidDateRange() {
        const subFields = this.getSubFields();

        const fromValue = this.getValueOfField(subFields.from.id);
        const toValue = this.getValueOfField(subFields.to.id);

        try {
            return {
                from: this.fromCustomIsoString(fromValue),
                to: this.fromCustomIsoString(toValue)
            };
        } catch (ex) {
            return null;
        }

    }

    render() {
        const subFields = this.getSubFields();
        if(!subFields) return null;

        const dateVal = this.getValidDateRange();

        return (
            <DateRangePicker2 showShortcuts={true} date={dateVal} onChange={onChange.bind(this)}/>
        );

        function onChange(changedDate) {

            const date = this.getDate(changedDate);

            this.props.onChange({
                [subFields.from.id]: date && date.from,
                [subFields.to.id]: date && date.to,
            });

        }

    }
}

export default DateRange;
