
function blobToBase64(blob) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = () => {
            reject(reader.error);
        };
        reader.readAsDataURL(blob);
    })
}

export async function triggerDownload(data, type, filename) {
    const blob = new Blob([data], { type });

    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        const el = document.createElement('a');
        el.style.display = 'none';
        
        if (window.webView) { // when seen in a native webview, hillarity ensues
            const base64 = await blobToBase64(blob);
            el.setAttribute('href', base64);
        } else {
            el.setAttribute('href', window.URL.createObjectURL(blob));
            el.setAttribute('download', filename);
            el.setAttribute('target', '_blank');
        }
        document.body.appendChild(el);
        el.click();
        document.body.removeChild(el);
    }
}