import React from 'react';
import PropTypes from 'prop-types';
import { attachToBody, Popover } from '../../popups';

export class EventDeletePopover extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        okLabel: PropTypes.string,
        cancelLabel: PropTypes.string,
        title: PropTypes.string,
        confirmation: PropTypes.string,
        show: PropTypes.bool,
        proceed: PropTypes.func,
        cancel: PropTypes.func,
        dismiss: PropTypes.func,
        placement: PropTypes.string,
        positionLeft: PropTypes.number,
        positionTop: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        element: PropTypes.object,
        confirmationColor: PropTypes.string,
        okStyle: PropTypes.string,
        cancelStyle: PropTypes.string,
    };

    static defaultProps = {
        okLabel: 'Yes',
        cancelLabel: 'No',
        okStyle: 'primary',
        cancelStyle: 'default',
        placement: 'top',
        width: 250,
        height: 150,
    };

    render() {

        const {
            id,
            okLabel,
            cancelLabel,
            okStyle,
            cancelStyle,
            confirmation,
            title,
            proceed,
            dismiss,
            cancel,
            placement,
            positionLeft,
            positionTop,
            width,
            height,
        } = this.props;

        return (
            <div className="static-confirm">
                <Popover
                    id={id}
                    title={title}
                    style={{ width: width + 'px', height: height + 'px' }}
                    onHide={dismiss}
                    placement={placement}
                    positionLeft={positionLeft}
                    positionTop={positionTop}
                >
                    <p>{confirmation}</p>
                    <div className="button-toolbar" style={{ paddingBottom: '6px', float: 'right' }}>
                        <button className={`btn btn-${cancelStyle}`} onClick={cancel}>
                            { cancelLabel }
                        </button>
                        <button className={`btn btn-${okStyle}`} onClick={proceed}>
                            { okLabel }
                        </button>
                    </div>
                </Popover>
            </div>
        );
    }
}

export const confirmDeletePopup = (options = {}) => {
    return attachToBody(EventDeletePopover)({ ...options });
};
