import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, injectIntl} from 'react-intl';
import classNames from 'classnames';
import './guidance-progress.less';

const messages = defineMessages({
    mandatoryTitle: {
        id: 'part_guidance_progress_mandatory_title',
        defaultMessage: 'Mandatory information is {progress, number, percent} complete',
    },
    optionalTitle: {
        id: 'part_guidance_progress_optional_title',
        defaultMessage: 'Additional (optional) information is {progress, number, percent} complete',
    },
    mandatoryLabel: {
        id: 'part_guidance_progress_mandatory_label',
        defaultMessage: 'Mandatory',
    },
    optionalLabel: {
        id: 'part_guidance_progress_optional_label',
        defaultMessage: 'Optional',
    },
});

export const progressPropType = PropTypes.shape({
    progress: PropTypes.number.isRequired,
    title: PropTypes.shape({
        id: PropTypes.number.isRequired,
        defaultMessage: PropTypes.string,
        description: PropTypes.string,
    }),
});

const defaultPrimaryProgressPropType = {
    progress: 0,
    title: messages.mandatoryTitle,
};

const defaultSecondaryProgressPropType = {
    progress: 0,
    title: messages.optionalTitle,
};

const OptionalSecondary = ({active, children}) => {

    if (active) {
        return null;
    }

    return children;

};

const DesktopGuidanceProgress = injectIntl((props) => {

    const primaryRadius = 42, secondaryRadius = 28;

    const primaryCircumference = getCircumference(primaryRadius),
        secondaryCircumference = getCircumference(secondaryRadius);

    const primaryProgress = props && props.primary && props.primary.progress || 0;
    const secondaryProgress = props && props.secondary && props.secondary.progress || 0;

    const primaryOffset = getOffset(primaryCircumference, primaryProgress),
        secondaryOffset = getOffset(secondaryCircumference, secondaryProgress);
    const primaryText = getText(primaryProgress),
        secondaryText = getText(secondaryProgress);
    const primaryTitle = getTitle(props.primary.title || messages.mandatoryTitle, primaryProgress),
        secondaryTitle = getTitle(props.secondary.title || messages.optionalTitle, secondaryProgress);

    return (

        <svg viewBox="0 0 100 100">
            <g className="guidance-progress-tracks">
                <circle className="guidance-progress-track-primary"
                        cx="50" cy="50" r={primaryRadius}
                        strokeWidth="12"
                />
                <OptionalSecondary active={props.disableSecondary}>
                    <circle className="guidance-progress-track-secondary"
                            cx="50" cy="50" r={secondaryRadius}
                            strokeWidth="5"
                    />
                </OptionalSecondary>
            </g>
            <g className="guidance-progress-progress" transform="rotate(-90, 50, 50)">
                <circle
                    className="guidance-progress-progress-primary"
                    cx="50" cy="50" r={primaryRadius}
                    strokeWidth="16"
                    strokeDasharray={primaryCircumference}
                    strokeDashoffset={primaryOffset}
                >
                    <title>{primaryTitle}</title>
                </circle>
                <OptionalSecondary active={props.disableSecondary}>
                    <circle
                        className="guidance-progress-progress-secondary"
                        cx="50" cy="50" r={secondaryRadius}
                        strokeWidth="8"
                        strokeDasharray={secondaryCircumference}
                        strokeDashoffset={secondaryOffset}
                    >
                        <title>{secondaryTitle}</title>
                    </circle>
                </OptionalSecondary>
            </g>
            <text
                className={classNames("guidance-progress-text-primary", {'guidance-progress-text-complete': primaryProgress === 1})}
                x="50" y="50" dy={props.disableSecondary ? '.35em' : 0} textAnchor="middle">
                {primaryText}
            </text>
            <OptionalSecondary active={props.disableSecondary}>
                <text
                    className={classNames("guidance-progress-text-secondary", {'guidance-progress-text-complete': secondaryProgress === 1})}
                    x="50" y="50" dy="1em" textAnchor="middle">
                    {secondaryText}
                </text>
            </OptionalSecondary>
        </svg>
    );

    function getCircumference(radius) {
        return 2 * Math.PI * radius;
    }

    function getOffset(circumference, progress) {
        return circumference * (1 - progress);
    }

    function getText(progress) {

        if (progress === 1.0) {
            return '\uf00c'; // fa-check, https://fontawesome.com/v4.7.0/icon/check
        }

        return props.intl.formatNumber(progress, {
            style: 'percent',
            maximumFractionDigits: 0,
        });

    }

    function getTitle(message, progress) {
        return (message) ? props.intl.formatMessage(message, {progress}) : getText(progress);
    }

});

DesktopGuidanceProgress.propTypes = {
    primary: progressPropType,
    secondary: progressPropType,
    disableSecondary: PropTypes.bool,
    renderMobile: PropTypes.bool,
};

DesktopGuidanceProgress.defaultProps = {
    primary: defaultPrimaryProgressPropType,
    secondary: defaultSecondaryProgressPropType,
    disableSecondary: false,
    renderMobile: false,
};

const MobileGuidanceProgress = injectIntl((props) => {

    const primaryProgress = props && props.primary && props.primary.progress || 0;
    const secondaryProgress = props && props.secondary && props.secondary.progress || 0;

    const primaryTitle = getTitle(props.primary.title || messages.mandatoryTitle, primaryProgress),
        secondaryTitle = getTitle(props.secondary.title || messages.optionalTitle, secondaryProgress);

    const primaryLabel = getLabel(props.primary.label || messages.mandatoryLabel),
        secondaryLabel = getLabel(props.secondary.label || messages.optionalLabel);

    return (
        <div className="guidance-progress-mobile">
            <div className="guidance-track guidance-track-primary">
                <div className="guidance-progress guidance-progress-primary"
                     style={{width: getWidth(primaryProgress)}}
                     title={primaryTitle}>
                    <div className="guidance-progress-text">
                        {getText(primaryProgress)}
                    </div>
                    <div className="guidance-progress-label">{primaryLabel}</div>
                </div>
            </div>
            <OptionalSecondary active={props.disableSecondary}>
                <div className="guidance-track guidance-track-secondary">
                    <div className="guidance-progress guidance-progress-secondary"
                         style={{width: getWidth(secondaryProgress)}}
                         title={secondaryTitle}>
                        <div className="guidance-progress-text">
                            {getText(secondaryProgress)}
                        </div>
                        <div className="guidance-progress-label">{secondaryLabel}</div>
                    </div>
                </div>
            </OptionalSecondary>
        </div>
    );

    function getWidth(progress) {
        return Math.round(progress * 100) + '%';
    }

    function getText(progress) {

        return props.intl.formatNumber(progress, {
            style: 'percent',
            maximumFractionDigits: 0,
        });

    }

    function getTitle(message, progress) {
        return (message) ? props.intl.formatMessage(message, {progress}) : getText(progress);
    }

    function getLabel(message) {
        return (message) ? props.intl.formatMessage(message) : '';
    }

});

MobileGuidanceProgress.propTypes = {
    primary: progressPropType,
    secondary: progressPropType,
    disableSecondary: PropTypes.bool,
    renderMobile: PropTypes.bool,
};

MobileGuidanceProgress.defaultProps = {
    primary: defaultPrimaryProgressPropType,
    secondary: defaultSecondaryProgressPropType,
    disableSecondary: false,
    renderMobile: false,
};

export const GuidanceProgress = injectIntl((props) => {

    return (
        <div className="guidance-progress">
            {props.renderMobile ? <MobileGuidanceProgress {...props}/> : <DesktopGuidanceProgress {...props}/>}
        </div>
    );

});

GuidanceProgress.propTypes = {
    primary: progressPropType,
    secondary: progressPropType,
    disableSecondary: PropTypes.bool,
    renderMobile: PropTypes.bool,
};

GuidanceProgress.defaultProps = {
    primary: defaultPrimaryProgressPropType,
    secondary: defaultSecondaryProgressPropType,
    disableSecondary: false,
    renderMobile: false,
};
