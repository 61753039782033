import {FormattedMessage, injectIntl, defineMessages} from 'react-intl';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// load required locales just to be sure
// see: https://github.com/moment/moment/issues/2566
import 'moment/locale/nb';
import 'moment/locale/de';
import classNames from 'classnames';
import {getScrollParent, ifNull, parseAndCheckDateConstraints} from './_helper';
import MarkdownMessage from '../markdown-message';
import RenderInBody from '../utils/render-in-body';
import {registerViewportListener, unregisterViewportListener} from '../../lib/viewport-listener';

// -------------------------------------------------------------------
// intl-messages
// -------------------------------------------------------------------

const messages = defineMessages({
    part_datepicker2_dont_know: {
        id: 'part_datepicker2_dont_know',
        defaultMessage: 'I do not know',
        description: 'The (initial) option for the user to select no specific date value, because I does not know it.'
    },
    part_datepicker2_year_label: {
        id: 'part_datepicker2_year_label',
        defaultMessage: 'Year',
        description: 'Label for the year selection'
    },
    part_datepicker2_year_placeholder: {
        id: 'part_datepicker2_year_placeholder',
        defaultMessage: 'Select the year',
        description: 'The initial option shown to the user, should be a motivation to select a year value. Is not a valid selection'
    },
    part_datepicker2_month_label: {
        id: 'part_datepicker2_month_label',
        defaultMessage: 'Month',
        description: 'Label for the month selection'
    },
    part_datepicker2_month_placeholder: {
        id: 'part_datepicker2_month_placeholder',
        defaultMessage: 'Select the month',
        description: 'The initial option shown to the user, should be a motivation to select a month value. Is not a valid selection'
    },
    part_datepicker2_day_label: {
        id: 'part_datepicker2_day_label',
        defaultMessage: 'Day',
        description: 'Label for the day selection'
    },
    part_datepicker2_day_placeholder: {
        id: 'part_datepicker2_day_placeholder',
        defaultMessage: 'Select the day',
        description: 'The initial option shown to the user, should be a motivation to select a day value. Is not a valid selection'
    },
    shortcut_today: {
        id: 'part_datepicker2_shortcuts_today',
        defaultMessage: 'Today',
        description: 'Label for the shortcut to select todays date'
    },
    shortcut_yesterday: {
        id: 'part_datepicker2_shortcuts_yesterday',
        defaultMessage: 'Yesterday',
        description: 'Label for the shortcut to select yesterdays date'
    },
    shortcut_lastweek: {
        id: 'part_datepicker2_shortcuts_lastweek',
        defaultMessage: 'Last week',
        description: 'Label for the shortcut to select the day a week ago'
    },
    shortcut_lastmonth: {
        id: 'part_datepicker2_shortcuts_lastmonth',
        defaultMessage: 'Last month',
        description: 'Label for the shortcut to select the last month as of now'
    },
    shortcut_lastquarter: {
        id: 'part_datepicker2_shortcuts_lastquarter',
        defaultMessage: '3 months ago',
        description: 'Label for the shortcut to select the month three month into the past from now'
    },
    shortcut_lasthalfyear: {
        id: 'part_datepicker2_shortcuts_lasthalfyear',
        defaultMessage: '6 months ago',
        description: 'Label for the shortcut to select the month half a year (6 month) into the past from now'
    },
    shortcut_lastyear: {
        id: 'part_datepicker2_shortcuts_lastyear',
        defaultMessage: 'Last year',
        description: 'Label for the shortcut to select the previous year as of now'
    },
    from_placeholder: {
        id: 'part_daterangepicker2_from_placeholder',
        defaultMessage: 'Started on MM/DD/YYYY',
        description: 'Placeholder text for the from date input field, should provide the usable date format in the current locale'
    },
    to_placeholder: {
        id: 'part_daterangepicker2_to_placeholder',
        defaultMessage: 'Ended on MM/DD/YYYY',
        description: 'Placeholder text for the to date input field, should provide the usable date format in the current locale'
    },
    today_text: {
        id: 'part_daterangepicker2_input_today_value',
        defaultMessage: 'today',
        description: 'Text shown in the input field of the single input field range picker to indicate the range includes today'
    },
    btn_submit: {
        id: 'part_datepicker2_controls_submit_label',
        defaultMessage: 'Ok',
        description: 'Label for the submit button which closes the dropdown and saves the changes'
    },
    btn_cancel: {
        id: 'part_datepicker2_controls_cancel_label',
        defaultMessage: 'Cancel',
        description: 'Label for the cancel button which closes the dropdown discarding any changes'
    },
    part_daterangepicker3_to_ongoing_text: {
        id: 'part_daterangepicker3_to_ongoing_text',
        defaultMessage: 'This event is currently marked as still ongoing. If it has ended, click _Enter end date_',
        description: 'Markdown enabled text to explain that this range is open/ongoing and how to change this. The name of the button should be in sync with part_daterangepicker3_to_ongoing_done_button_label'
    }
});

// -------------------------------------------------------------------
// constant values
// -------------------------------------------------------------------

const VALUE_INTERNAL_NOT_SET = -1,
    VALUE_INTERNAL_DONT_KNOW = -2,
    VALUE_INTERNAL_DISABLED = -3,   // eslint-disable-line no-unused-vars
    VALUE_EXTERNAL_NOT_SET = 0;     // eslint-disable-line no-unused-vars

// -------------------------------------------------------------------
// some general functions
// -------------------------------------------------------------------

const CONVERT_INTERNAL_VALUES_TO_UNCERTAIN = (value, delta = 0) => {
    switch (value) {
        case VALUE_INTERNAL_NOT_SET:
        case VALUE_INTERNAL_DONT_KNOW:
            return 0;
        default:
            return value + delta;
    }
};

const CONVERT_INTERNAL_DATE_TO_UNCERTAIN = (date) => {

    if (!date) {
        return {
            year: 0,
            month: 0,
            day: 0
        };
    }

    let uncertainDate = {
        year: CONVERT_INTERNAL_VALUES_TO_UNCERTAIN(date.year),
        month: CONVERT_INTERNAL_VALUES_TO_UNCERTAIN(date.month, 1),
        day: CONVERT_INTERNAL_VALUES_TO_UNCERTAIN(date.day)
    };
    return uncertainDate;
};

const CONVERT_UNCERTAIN_VALUES_TO_INTERNAL = (value, delta) => {
    if (value === null || value === undefined) {
        return VALUE_INTERNAL_NOT_SET;
    } else if (value === 0) {
        return VALUE_INTERNAL_DONT_KNOW;
    } else {
        // months are zero index in moment.js
        // delta covers this
        return value + delta;
    }
};

const CONVERT_UNCERTAIN_DATE_TO_INTERNAL = (date, source) => {

    if (!date) {
        return {
            year: VALUE_INTERNAL_NOT_SET,
            month: VALUE_INTERNAL_NOT_SET,
            day: VALUE_INTERNAL_NOT_SET,
            changedBy: source,
            changedField: []
        };
    }

    let internalDate = {
        year: CONVERT_UNCERTAIN_VALUES_TO_INTERNAL(date.year, 0),
        month: CONVERT_UNCERTAIN_VALUES_TO_INTERNAL(date.month, -1),
        day: CONVERT_UNCERTAIN_VALUES_TO_INTERNAL(date.day, 0),
        changedBy: source,
        changedField: []
    };

    if (internalDate.year === VALUE_INTERNAL_DONT_KNOW) {
        internalDate.month = VALUE_INTERNAL_NOT_SET;
        internalDate.day = VALUE_INTERNAL_NOT_SET;
    } else if (internalDate.month === VALUE_INTERNAL_DONT_KNOW) {
        internalDate.day = VALUE_INTERNAL_NOT_SET;
    }

    return internalDate;
};

const FORMAT_INTERNAL_DATE = (date, locale, alternative) => {

    if (!date || !date.year || date.year <= 0) {
        return alternative || '';
    }

    let formatted = alternative || '';

    if (date.month >= 0) {
        if (date.day > 0) {
            // full date
            formatted = moment([date.year, date.month, date.day]).locale(mapLocaleForMoment(locale)).format('L');
        } else {
            // only year + month
            formatted = moment([date.year, date.month]).locale(mapLocaleForMoment(locale)).format('MMMM YYYY');
        }
    } else {
        // only year
        formatted = moment([date.year]).locale(mapLocaleForMoment(locale)).format('YYYY');
    }
    return formatted;
};

const FORMAT_UNCERTAIN_DATE = (date, locale, alternative) => {
    if (!date || !date.year || date.year <= 0) {
        return alternative || '';
    }
    return FORMAT_INTERNAL_DATE(CONVERT_UNCERTAIN_DATE_TO_INTERNAL(date), locale, alternative);
};

const CONVERT_MOMENT_TO_UNCERTAIN = (moment, precision) => {

    let uncertain = {
        year: 0,
        month: 0,
        day: 0
    };

    if (moment) {
        uncertain.year = moment.year();
        // Make sure to return the right precision only
        switch (precision) {
            case 'day':
                uncertain.day = moment.date();
            case 'month': // eslint-disable-line no-fallthrough
                uncertain.month = moment.month() + 1;
        }
    }

    return uncertain;
};

// -------------------------------------------------------------------
// the react components
// -------------------------------------------------------------------

// a wrapper for a date part (e.g. year, month, day)
class DatePart extends Component {

    static propTypes = {
        name: PropTypes.string.isRequired,
        className: PropTypes.string
    }

    render () {

        let {name, children, className} = this.props;

        let classes = classNames(className, {
            part: true
        });

        return (
            <div className={classes}>
                <label>{ name }</label>
                <div className="selector">
                    { children }
                </div>
            </div>
        );
    }
}


// a simple select based value selector
let DatePartSelectorDropdown = injectIntl(class DatePartSelectorDropdown extends Component {

    static propTypes = {
        focusOnMount: PropTypes.bool,
        placeholder: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.shape({
                value: PropTypes.any,
                label: PropTypes.string
            })
        ])),
        onChange: PropTypes.func.isRequired,
        selected: PropTypes.any,
        canBeEmpty: PropTypes.bool,
        tabIndex: PropTypes.number
    };

    constructor(props) {
        super(props);
        this.select = React.createRef();
    }

    componentDidMount () {
        if (this.props.focusOnMount) {
            this.select.current.focus();
        }
    }

    render () {
        let {placeholder, values, onChange, selected, canBeEmpty, tabIndex} = this.props;

        let onlyEnabled = (v) => {
            return !v.disabled;
        };

        return (

            <select
                ref={this.select}
                className="form-control"
                onChange={ (e) => onChange(e.target.value, 'dropdown') }
                placeholder={ placeholder || '' }
                value={selected}
                tabIndex={tabIndex}
            >
                <option
                    key="na"
                    value={ VALUE_INTERNAL_DONT_KNOW }
                >
                    {canBeEmpty ? this.props.intl.formatMessage(messages.part_datepicker2_dont_know) : placeholder}
                </option>
                { ifNull(values, [])
                    .filter(onlyEnabled)
                    .map(v => <option key={ifNull(v.value, v)} value={ifNull(v.value, v)}>{ifNull(v.label,
                        v)}</option>) }
            </select>
        );
    }
});

// a simple table based selector
class DatePartSelectorTable extends Component {
    constructor(props) {
        super(props);
        this.table = React.createRef();
    }
    componentDidMount () {
        this.table.current.focus();
    }

    render () {
        let {values, onChange, selected, canBeEmpty, columns, columnHeaders, rowHeaders, tabIndex} = this.props;

        let count = values.length;

        let rows = [];
        for (let row = 0; row < Math.ceil(count / columns); row++) {

            let cols = [];

            if (rowHeaders) {
                cols.push(<tr key="head">{ rowHeaders[row] }</tr>);
            }

            for (let col = 0; col < Math.min(columns, count - (row * columns)); col++) {

                let current = values[row * columns + col];
                let label = ifNull(current.label, current),
                    value = ifNull(current.value, current);

                let classes = classNames({
                    active: value === selected && !current.delta,
                    disabled: current.disabled || current.delta,
                    notInCurrentMonth: current.delta
                });

                let handleClick = () => {
                    onChange(value, 'table');
                };

                if (current.delta) {
                    handleClick = () => {
                    };
                } else if (current.disabled) {
                    handleClick = () => {
                    };
                }

                cols.push(
                    <td key={col} onClick={handleClick} className={classes} tabIndex={tabIndex}>
                        { label }
                    </td>
                );
            }

            rows.push(
                <tr key={ row }>
                    { cols }
                </tr>
            );
        }

        let thead = null;
        if (columnHeaders) {
            // render column headers
            thead = (
                <thead>
                    <tr>
                        { rowHeaders ? (<th key="head"></th>) : null }
                        { columnHeaders.map(h => <th key={ h }>{ h }</th>) }
                    </tr>
                </thead>
            );
        }

        let button = null;
        if (canBeEmpty) {

            let classes = classNames('btn btn-block', {
                'btn-default': selected !== VALUE_INTERNAL_DONT_KNOW,
                'btn-success': selected === VALUE_INTERNAL_DONT_KNOW
            });

            button = (
                <button className={classes} onClick={ () => onChange(VALUE_INTERNAL_DONT_KNOW, 'button') }
                    tabIndex={tabIndex}>
                    <FormattedMessage
                        id="part_daterangepicker2_noday_label"
                        defaultMessage="I do not know"
                        description="Label for the 'I do not know the day' button"
                    />
                </button>
            );
        }

        return (
            <div>
                <table ref={this.table}>
                    { thead }
                    <tbody>
                        { rows }
                    </tbody>
                </table>
                <div>
                    { button }
                </div>
            </div>
        );
    }
}


DatePartSelectorTable.propTypes = {
    values: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.node,
                value: PropTypes.any,
                disabled: PropTypes.bool
            })
        )
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.any.isRequired,
    canBeEmpty: PropTypes.bool,
    columns: PropTypes.number.isRequired,
    columnHeaders: PropTypes.arrayOf(PropTypes.node),
    rowHeaders: PropTypes.arrayOf(PropTypes.node),
    tabIndex: PropTypes.number
};

DatePartSelectorTable.defaultPropTypes = {
    canBeEmpty: true
};

let IntlDataPartSelectorTable = injectIntl(DatePartSelectorTable);

// date selector
// a component encapsulating the input fields for
// year, month and day as well as the shortcuts
class DateSelector extends Component {

    constructor (props) {
        super(props);
        let localeMoment = moment();
        localeMoment.locale(mapLocaleForMoment(this.props.intl.locale));
        this.state = {
            localeMoment: localeMoment
        };

        this.year = React.createRef();
        this.month = React.createRef();
        this.day = React.createRef();
    }

    handleChange (field, value, source) {

        value = parseInt(value);

        let update = {
            [field]: value
        };

        if (value <= VALUE_INTERNAL_NOT_SET) {
            // Set higher precision values to not set if
            // current precision is set to dont know.
            switch (field) {
                case 'year':
                    update.month = VALUE_INTERNAL_NOT_SET;
                case 'month': // eslint-disable-line no-fallthrough
                    update.day = VALUE_INTERNAL_NOT_SET;
            }
        }
        this.props.onChange(update, source);
    }

    renderYear () {
        let {min, max, tabIndex} = this.props;
        let {year, month} = this.props.date;

        let years = [];
        for (let i = max.year(); i >= min.year(); i--) {
            // iterate the years in reverse such that
            // the most current year comes on top
            years.push(i);
        }

        let classes = classNames({
            inline: month >= 0
        });

        return (
            <DatePart name={ this.props.intl.formatMessage(messages.part_datepicker2_year_label) } className={classes}>
                <DatePartSelectorDropdown
                    ref={this.year}
                    placeholder={ this.props.intl.formatMessage(messages.part_datepicker2_year_placeholder) }
                    values={years}
                    selected={year}
                    canBeEmpty={false}
                    onChange={this.handleChange.bind(this, 'year')}
                    focusOnMount={false}
                    tabIndex={tabIndex}
                />
            </DatePart>
        );
    }

    renderMonth () {
        let {min, max, tabIndex} = this.props;
        let {year, month} = this.props.date;

        if (year === VALUE_INTERNAL_NOT_SET || year === VALUE_INTERNAL_DONT_KNOW) {
            return null;
        }

        let minMonth = 0, maxMonth = 11;

        if (year === max.year()) {
            // you cannot select a month from the future
            // this only applies if the selected year === the current year
            maxMonth = max.month();
        }

        if (year === min.year()) {
            minMonth = min.month();
        }

        let months = [];
        for (let i = minMonth; i <= maxMonth; i++) {
            months.push({
                value: i,
                label: this.state.localeMoment.localeData()._months[i]
            });
        }

        let classes = classNames({
            inline: month >= 0
        });

        return (
            <DatePart name={ this.props.intl.formatMessage(messages.part_datepicker2_month_label) } className={classes}>
                <DatePartSelectorDropdown
                    ref={this.month}
                    placeholder={ this.props.intl.formatMessage(messages.part_datepicker2_month_placeholder) }
                    values={months}
                    selected={month}
                    canBeEmpty={true}
                    onChange={this.handleChange.bind(this, 'month')}
                    focusOnMount={false}
                    tabIndex={tabIndex}
                />
            </DatePart>
        );
    }

    renderDayCalendar() {

        const  {min, max, tabIndex} = this.props;
        const {year, month, day} = this.props.date;

        let minDay = 1, maxDay = moment([year, month, 1]).endOf('month').date();

        if (max.year() === year && max.month() === month) {
            maxDay = max.date();
        }

        if (min.year() === year && min.month() === month) {
            minDay = min.date();
        }

        // establish on what day the week starts
        // for the current locale
        const currentFirstDayOfWeek = catchWithDefault(
            () => this.state.localeMoment.localeData()._week.dow, // 0 = Sunday, 6 = Saturday
            0
        );
        const localeWeekdays = catchWithDefault(
            () => this.state.localeMoment.localeData()._weekdaysMin, // sunday is always the first value
            ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        );
        const weekdayLabelsByFirstDayOfWeek = [].concat(localeWeekdays.slice(currentFirstDayOfWeek), localeWeekdays.slice(0, currentFirstDayOfWeek));

        // day is zero index
        // 0 = Sunday, 6 = Saturday
        // to use for example monday as first day of the week, we need to shift the values by one
        // for wednesday it would be 3
        // (dayOfWeek + 7 - 1) % 7 = offset
        const monthOffsetForFirstDayOfWeek = (moment([year, month]).startOf('month').day() + 7 - 1) % 7;
        const daysInMonth = moment([year, month]).endOf('month').date();
        const daysInPreviousMonth = moment([year, month]).subtract(1, 'month').endOf('month').date();
        const daysInCalendarWidget = Math.ceil((daysInMonth + monthOffsetForFirstDayOfWeek) / 7) * 7;
        const days = [];

        for (let i = -1 * monthOffsetForFirstDayOfWeek; i < daysInCalendarWidget - monthOffsetForFirstDayOfWeek; i++) {
            let value = {};
            if (i < 0) {
                value = {
                    label: daysInPreviousMonth + i + 1,
                    disabled: moment([year, month])
                        .subtract(1, 'month')
                        .date(daysInPreviousMonth + i + 1)
                        .isBefore(min),
                    delta: -1
                };
            } else if (i >= daysInMonth) {
                value = {
                    label: i - daysInMonth + 1,
                    value: i - daysInMonth + 1,
                    disabled: moment([year, month]).add(1, 'month').date(i - daysInMonth + 1).isAfter(max),
                    delta: +1
                };
            } else {
                value = {
                    label: i + 1,
                    value: i + 1,
                    disabled: (i + 1) < minDay || (i + 1) > maxDay
                };
            }
            days.push(value);
        }

        return (
            <DatePart name={ this.props.intl.formatMessage(messages.part_datepicker2_day_label) }>
                <IntlDataPartSelectorTable
                    ref={this.day}
                    values={days}
                    selected={day}
                    columns={7}
                    columnHeaders={weekdayLabelsByFirstDayOfWeek}
                    canBeEmpty={true}
                    onChange={this.handleChange.bind(this, 'day')}
                    focusOnMount={false}
                    tabIndex={tabIndex}
                />
            </DatePart>
        );

    }

    renderDaySelect() {

        const {min, max, tabIndex} = this.props;
        const {year, month, day} = this.props.date;

        const firstDayInMonth = moment([year, month, 1]);
        const firstUsableDayInMonth = firstDayInMonth.isBefore(min) ? min.date() : 1;
        const lastDayInMonth = moment([year, month]).endOf('month');
        const lastUsableDayInMonth = lastDayInMonth.isAfter(max) ? max.date() : lastDayInMonth.date();

        const days = [];
        for (let dayOfMonth = firstUsableDayInMonth; dayOfMonth < lastUsableDayInMonth; dayOfMonth++) {
            days.push(dayOfMonth);
        }

        return (
            <DatePart name={this.props.intl.formatMessage(messages.part_datepicker2_day_label)}>
                <DatePartSelectorDropdown
                    ref={this.day}
                    placeholder={ this.props.intl.formatMessage(messages.part_datepicker2_day_placeholder) }
                    values={days}
                    selected={day}
                    canBeEmpty={true}
                    onChange={this.handleChange.bind(this, 'day')}
                    focusOnMount={false}
                    tabIndex={tabIndex}
                />
            </DatePart>
        );

    }

    renderDay () {

        const {year, month} = this.props.date;

        if (year < 0 || month < 0) {
            return null;
        }

        const {mobile} = this.context;

        return mobile ? this.renderDaySelect() : this.renderDayCalendar();

    }

    renderShortcuts () {

        let {showShortcuts, onChange, min, max, intl, tabIndex} = this.props;
        let {year, month, day} = this.props.date;

        if (year > 0 || month >= 0 || day > 0) {
            // do not render shortcuts if date has been provided
            return null;
        }

        if (!showShortcuts) {
            return null;
        }

        let today = moment(),
            yesterday = moment().subtract(1, 'days'),
            lastWeek = moment().subtract(1, 'week'),
            lastMonth = moment().subtract(1, 'month'),
            lastQuarter = moment().subtract(3, 'months'),
            lastHalfYear = moment().subtract(6, 'months'),
            lastYear = moment().subtract(1, 'year');

        let dateFromMoment = (moment, precision) => {
            let date = {
                year: moment.year(),
                month: VALUE_INTERNAL_DONT_KNOW,
                day: VALUE_INTERNAL_NOT_SET
            };
            switch (precision) {
                case 'day':
                    date.day = moment.date();
                    date.month = moment.month();
                    break;
                case 'month':
                    date.day = VALUE_INTERNAL_DONT_KNOW;
                    date.month = moment.month();
                    break;
            }
            return date;
        };

        let isSelected = (value) => {
            return value.year === year && value.month === month && value.day === day;
        };

        let shotcuts = [
            {
                id: 'today',
                label: intl.formatMessage(messages.shortcut_today),
                value: dateFromMoment(today, 'day'),
                moment: today
            },
            {
                id: 'yesterday',
                label: intl.formatMessage(messages.shortcut_yesterday),
                value: dateFromMoment(yesterday, 'day'),
                moment: yesterday
            },
            {
                id: 'lastWeek',
                label: intl.formatMessage(messages.shortcut_lastweek),
                value: dateFromMoment(lastWeek, 'day'),
                moment: lastWeek
            },
            {
                id: 'lastMonth',
                label: intl.formatMessage(messages.shortcut_lastmonth),
                value: dateFromMoment(lastMonth, 'month'),
                moment: lastMonth
            },
            {
                id: 'lastQuarter',
                label: intl.formatMessage(messages.shortcut_lastquarter),
                value: dateFromMoment(lastQuarter, 'month'),
                moment: lastQuarter
            },
            {
                id: 'lastHalfYear',
                label: intl.formatMessage(messages.shortcut_lasthalfyear),
                value: dateFromMoment(lastHalfYear, 'month'),
                moment: lastHalfYear
            },
            {
                id: 'lastYear',
                label: intl.formatMessage(messages.shortcut_lastyear),
                value: dateFromMoment(lastYear, 'year'),
                moment: lastYear
            },
        ];

        let withinMinMaxRange = (shortcut) => {
            return shortcut.moment.isSameOrAfter(min) && shortcut.moment.isSameOrBefore(max);
        };

        let triggerChange = (value, id, evt) => {
            evt.stopPropagation();

            onChange(value, 'shortcut');
        };

        return (
            <ul className="shortcuts list list-inline">
                {
                    shotcuts.filter(withinMinMaxRange).map(s => {
                        let classes = classNames({
                            label: true,
                            'label-default': !isSelected(s.value),
                            'label-success': isSelected(s.value)
                        });
                        return (
                            <li key={s.id} onClick={ triggerChange.bind(this, s.value, s.id) } tabIndex={tabIndex}>
                                <span className={ classes }>
                                    { s.label }
                                </span>
                            </li>
                        );
                    })
                }
            </ul>
        );
    }

    render () {
        return (
            <div className="selector">
                { this.renderShortcuts() }
                { this.renderYear() }
                { this.renderMonth() }
                { this.renderDay() }
            </div>
        );
    }
}

DateSelector.propTypes = {
    min: PropTypes.object, // moment
    max: PropTypes.object, // moment
    date: PropTypes.shape({
        year: PropTypes.number,
        month: PropTypes.number,
        day: PropTypes.number
    }),
    showShortcuts: PropTypes.bool, // show entry shortcuts
    onChange: PropTypes.func.isRequired, // callback
    tabIndex: PropTypes.number
};

DateSelector.contextTypes = {
    mobile: PropTypes.bool
};

let IntlDateSelector = injectIntl(DateSelector);

// wrap the date selector for a date point
class PointDateSelector extends Component {

    constructor (props) {
        super(props);
    }

    render () {
        return (
            <IntlDateSelector {...this.props} />
        );
    }
}

PointDateSelector.propTypes = {
    min: PropTypes.object, // moment
    max: PropTypes.object, // moment
    date: PropTypes.shape({
        year: PropTypes.number,
        month: PropTypes.number,
        day: PropTypes.number
    }),
    showShortcuts: PropTypes.bool, // show entry shortcuts
    onChange: PropTypes.func.isRequired, // callback
    tabIndex: PropTypes.number
};

let IntlPointDateSelector = injectIntl(PointDateSelector);

const DropDownContext = React.createContext({ mobile: false });

// the dropdown
class DateDropdown extends Component {

    constructor (props) {

        super(props);

        // defining the function that way will actually
        // allow us to deregister the event listener on
        // before component unmount. Using a class
        // method will not work
        this.handleOutsideClicks = (e) => {

            if (!this.dropdown.current) {
                // dropdown not rendered yet
                return;
            }

            let el = e.target;

            do {
                if (el === this.dropdown.current) {
                    // clicked within dropdown, do nothing
                    return;
                } else if (this.dropdown.current && el === this.dropdown.current.parentNode) {
                    // clicked within datepicker, do nothing
                    return;
                }
                el = el.parentNode;
            } while (el);

            // click outside of dropdown, hide dropdown
            this.props.onCancel('outside-click');

        };

        this.handleKeys = (e) => {
            if (e.which === 27) {
                e.preventDefault();
                this.props.onCancel('escape-key');
            }
        };

        this.state = {
            originalScrollPosition: null
        };

        this.dropdown = React.createRef();

    }

    componentWillReceiveProps (nextProps) {

        const body = document.getElementsByTagName('body')[0];

        if (nextProps.show === this.props.show) {

            // nothing changed

        } else if (nextProps.show) {

            // bind key listener on show
            body.addEventListener('keydown', this.handleKeys);
            // bind click listener
            body.addEventListener('click', this.handleOutsideClicks);

        } else {

            // unbind key listener on hide
            body.removeEventListener('keydown', this.handleKeys);
            // unbind click listener
            body.removeEventListener('click', this.handleOutsideClicks);

        }

    }

    componentDidUpdate () {

        const { mobile } = this.props;

        if (!mobile) {
            this.handleAutoScrolling();
        }

    }

    getChildContext() {

        return {
            mobile: this.props.mobile,
        };

    }

    handleAutoScrolling () {

        const {show} = this.props;
        const {originalScrollPosition} = this.state;

        // requires browser context, skip otherwise
        if (typeof window === 'undefined') return;

        let scrollParent = getScrollParent(this.dropdown.current);

        if (scrollParent === document.body){
            scrollParent = window;
        }

        // if shown
        if (show) {

            // try to scroll dropdown into view
            if (this.dropdown.current && typeof this.dropdown.current.getBoundingClientRect === 'function') {

                // get the height of the (fixed) page header (navigation) element, which
                // is different based on the actual available window width
                const stickyHeaderElement = document.querySelector('.header-sticky');
                const hasStickyHeader = !!stickyHeaderElement;
                const headerOffset = (hasStickyHeader) ? stickyHeaderElement.offsetHeight : 0;

                const {top, bottom} = this.dropdown.current.getBoundingClientRect();
                const windowHeight = scrollParent.innerHeight || scrollParent.clientHeight;

                const hasNotScrolledYet = originalScrollPosition === null;

                if (hasNotScrolledYet) {

                    // keep original position to go back on close
                    // what do we do if the windows is resized in the meantime?
                    const originalScrollPosition = scrollParent.scrollY || scrollParent.scrollTop;
                    this.setState({
                        originalScrollPosition
                    });

                    // only scroll once!
                    if (bottom > windowHeight) {

                        // maximum we can scroll to not loose the top
                        const scrollTop = top - headerOffset - 55;
                        // minimum we need to scroll to see the bottom
                        const scrollBottom = bottom - windowHeight + 15;
                        // amount to scroll
                        const yDelta = Math.min(scrollBottom, scrollTop);

                        if(typeof scrollParent.scrollBy === 'function') {
                            scrollParent.scrollBy(0, yDelta);
                        } else {
                            scrollParent.scrollTop = yDelta;
                        }

                    }

                }

            } else {
                // any alternative methods?
                console.debug('scrolling not available on this device');
            }

        } else {

            if (originalScrollPosition) {

                if(typeof scrollParent.scrollTo === 'function'){
                    scrollParent.scrollTo(0, originalScrollPosition);
                } else {
                    scrollParent.scrollTop = originalScrollPosition;
                }

                // reset after use
                this.setState({
                    originalScrollPosition: null
                });

            }

        }

    }

    render () {
        let {children, show} = this.props;

        let content = null;
        if (show) {
            content = children;
        }


        let classes = classNames({
            dropdown: true,
            hidden: !show
        });

        return (
            <div className={classes} ref={this.dropdown}>
                { content }
            </div>
        );
    }
}


DateDropdown.propTypes = {
    show: PropTypes.bool.isRequired, // shown or not shown
    onCancel: PropTypes.func.isRequired, // called whenever the dropdown is closed by the user
    mobile: PropTypes.bool.isRequired // true if rendered on a mobile device
};

DateDropdown.childContextTypes = {
    mobile: PropTypes.bool
};

// dropdown for a point date
class PointDateDropdown extends Component {

    constructor (props) {

        super(props);

        let localeMoment = moment();
        localeMoment.locale(mapLocaleForMoment(this.props.intl.locale));

        this.state = {
            changedBy: undefined,
            localeMoment,
            mobile: false,
        };

    }

    combinedState(props, state) {

        const combined = {};

        const date = CONVERT_UNCERTAIN_DATE_TO_INTERNAL(props.date);

        combined.year = state.year !== undefined ? state.year : date.year;
        combined.month = state.month !== undefined ? state.month : date.month;
        combined.day = state.day !== undefined ? state.day : date.day;

        combined.canSave = combined.year > VALUE_INTERNAL_NOT_SET;

        combined.changedBy = state.changedBy;
        combined.changedField = state.changedField;

        combined.value = CONVERT_INTERNAL_DATE_TO_UNCERTAIN(combined);
        return combined;

    }

    componentDidMount () {

        this._mounted = true;
        registerViewportListener(this.listenToViewportChanges.bind(this));

    }

    componentWillUnmount () {

        unregisterViewportListener(this.listenToViewportChanges.bind(this));
        this._mounted = false;

    }

    listenToViewportChanges (viewport) {

        // ensure we are allowed to call setState by monitoring the mount state
        if (!this._mounted) {
            return;
        }

        this.setState({
            mobile: viewport.mobile
        });

    }

    doChange (update, source) {

        const newState = Object.assign({}, this.state, update, {
            changedBy: source,
            changedField: Object.keys(update)
        });

        const combinedState = this.combinedState(this.props, newState);
        const {year, month, day} = this.state;

        if (newState.year !== year || newState.month !== month || newState.day !== day) {

            // do only update if some value actually changed
            this.setState(newState, () => {

                if (combinedState.canSave) {
                    this.doCanSave(combinedState);
                }

            });

        }

    }

    doCanSave (combinedState) {

        if (combinedState.changedBy === 'shortcut') {

            // no auto-save on shortcut triggered changes
            return false;

        } else if (combinedState.changedField
            && combinedState.changedField.length === 1
            && combinedState.changedField.includes('day')) {

            // auto-save on (only) day changed
            this.triggerChange(true);
            return;

        }

    }

    clearState() {

        this.setState({
            year: undefined,
            month: undefined,
            day: undefined,
            changedBy: undefined,
            changedField: undefined,
        });

    }

    triggerCancel (track = true) {

        this.props.onCancel();
        this.clearState();

    }

    triggerChange (track = true) {

        const {value, changedBy} = this.combinedState(this.props, this.state);
        this.props.onChange(value, changedBy);
        this.clearState();

    }

    renderControls () {

        const {canSave} = this.combinedState(this.props, this.state);
        let okBtn = null;

        if (canSave) {
            okBtn = (
                <button type="button" className="btn btn-primary" onClick={this.triggerChange.bind(this)}>
                    <FormattedMessage { ...messages.btn_submit } />
                </button>
            );
        }

        return (
            <div className="controls">
                <div className="btn-group">
                    <button type="button" className="btn btn-default" onClick={this.triggerCancel.bind(this)}>
                        <FormattedMessage { ...messages.btn_cancel } />
                    </button>
                    { okBtn }
                </div>
            </div>
        );

    }

    render () {
        return this.renderDesktop();
    }

    renderDesktop () {

        const {show} = this.props;
        const {year, month, day} = this.combinedState(this.props, this.state);

        const date = {year, month, day};

        return (
            <DateDropdown show={show} mobile={false} onCancel={this.triggerCancel.bind(this, false)}>
                <IntlPointDateSelector {...this.props} date={date} onChange={this.doChange.bind(this)}/>
                { this.renderControls() }
            </DateDropdown>
        );

    }

    renderMobile () {

        const {show, intl} = this.props;
        const {year, month, day} = this.combinedState(this.props, this.state);

        const date = {year, month, day};

        if (!show) {
            return null;
        }

        return (
            <RenderInBody language={intl.locale} messages={intl.messages}>
                <div className="pg-date-picker2 pg-point-picker2 mobile">
                    <DateDropdown show={show} mobile={true} onCancel={this.triggerCancel.bind(this)}>
                        <button type="button"
                            className="close"
                            aria-label="Close"
                            onClick={ this.triggerCancel.bind(this) }>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <IntlPointDateSelector {...this.props} date={date} onChange={this.doChange.bind(this)}/>
                        { this.renderControls() }
                    </DateDropdown>
                </div>
            </RenderInBody>
        );

    }

}

PointDateDropdown.propTypes = {
    min: PropTypes.object, // moment
    max: PropTypes.object, // moment
    date: PropTypes.shape({
        year: PropTypes.number,
        month: PropTypes.number,
        day: PropTypes.number
    }),
    onCancel: PropTypes.func.isRequired, // if dropdown is canceled
    onChange: PropTypes.func.isRequired, // if values are submitted
    showShortcuts: PropTypes.bool, // show entry shortcuts,
    show: PropTypes.bool.isRequired, // open/close dropdown
    tabIndex: PropTypes.number
};

// date input for a point date
class PointDateInput extends Component {

    constructor (props) {

        super(props);

        this.VALIDATION_STATES = {
            EMPTY: 'EMPTY',
            INVALID: 'INVALID',
            VALID: 'VALID'
        };

        this.getValidationState = (parsedInput) => {
            switch (parsedInput) {
                case 0:
                    return this.VALIDATION_STATES.EMPTY;
                case -1:
                    return this.VALIDATION_STATES.INVALID;
                default:
                    return this.VALIDATION_STATES.VALID;
            }
        };

        this.readProps = (props) => {
            const input = this.formattedInputFromProps(props);
            const parsedInput = this.parseInput(input, props);
            return {
                input: input,
                parsed: parsedInput, // 0 := no value, -1 := not valid, anything else is valid
                validationState: this.getValidationState(parsedInput),
                lastPropDate: props.date
            };
        };

        this.state = this.readProps(props);
        this.input = React.createRef();
    }

    componentWillReceiveProps (nextProps) {

        const {lastPropDate} = this.state;

        // only update props if they have changed
        if (lastPropDate !== nextProps.date) {
            this.setState(this.readProps(nextProps));
        }

    }

    formattedInputFromProps (props) {

        const {date, intl} = props;
        return FORMAT_UNCERTAIN_DATE(date, intl.locale);

    }

    parseInput (value, props) {

        const {min, max, intl} = props;
        return parseAndCheckDateConstraints(value, min, max, intl.locale);

    }

    handleChange (e) {

        const value = e.target.value;
        const parsedInput = this.parseInput(value, this.props);
        const validationState = this.getValidationState(parsedInput);
        this.setState({
            input: value,
            validationState: validationState
        });

    }

    // Check input only on blur.
    // If we checked input on every change we
    // would likely reformat the input which
    // would be totally confusing to any user.
    handleBlur (e, source) {

        const value = e.target.value;
        const parsedInput = this.parseInput(value, this.props);
        if (parsedInput !== -1 && parsedInput !== 0) {
            // Only trigger onChange callback on valid input.
            // We do not need to set the formatting here, as
            // triggering the callback will lead to an update
            // of our props which in turn will format the input.
            let date = CONVERT_MOMENT_TO_UNCERTAIN(parsedInput.moment, parsedInput.precision);
            // trigger the callback
            this.props.onChange(date, source || 'input-blur');
        } else if (parsedInput === -1) {
            // input could not be parsed, track to learn
        } else if (parsedInput === 0) {
            // empty input field
            this.props.onChange(null, source || 'input-blur');
        }

        this.setState({
            parsed: parsedInput,
            validationState: this.getValidationState(parsedInput)
        });

    }

    handleKey (e) {
        if (e.which === 13) {
            e.preventDefault();
            this.handleBlur(e, 'input-enter');
        }
    }

    handleDropDownToggle () {
        const {onDropdownToggle} = this.props;
        onDropdownToggle();
    }

    render () {

        const {onDropdownShow, placeholder, disabled, onFocus} = this.props;
        const {input, validationState} = this.state;

        const feedbackClasses = classNames({
            feedback: true,
            // show glyphicon only if something to show
            fa: validationState !== this.VALIDATION_STATES.EMPTY,
            // something is wrong
            'fa-exclamation-triangle': validationState === this.VALIDATION_STATES.INVALID,
            'text-warning': validationState === this.VALIDATION_STATES.INVALID,
            // your input is fine
            'fa-check': validationState === this.VALIDATION_STATES.VALID,
            'text-success': validationState === this.VALIDATION_STATES.VALID
        });

        return (
            <div className="input-group">
                <input
                    ref={this.input}
                    type="text"
                    className="form-control"
                    placeholder={placeholder}
                    onFocus={ onFocus }
                    onClick={onDropdownShow}
                    onKeyUp={this.handleKey.bind(this)}
                    onChange={this.handleChange.bind(this)}
                    onBlur={this.handleBlur.bind(this)}
                    value={input}
                    disabled={disabled}
                />
                <span className="input-group-btn">
                    <button className="btn btn-primary" type="button" onClick={this.handleDropDownToggle.bind(this)}>
                        <i className="fa fa-calendar"/>
                    </button>
                </span>
                <i className={feedbackClasses}></i>
            </div>
        );
    }
}

PointDateInput.propTypes = {
    min: PropTypes.object, // moment
    max: PropTypes.object, // moment
    date: PropTypes.shape({
        year: PropTypes.number,
        month: PropTypes.number,
        day: PropTypes.number
    }),
    onFocus: PropTypes.func, // if input is focused
    onChange: PropTypes.func.isRequired, // if values are submitted
    onDropdownShow: PropTypes.func.isRequired,
    onDropdownToggle: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool
};

// dropdown for a point date
class RangeDateDropdown extends Component {

    constructor (props) {
        super(props);

        this.stateFromProps = (props) => {
            let fromDate = CONVERT_UNCERTAIN_DATE_TO_INTERNAL(props.date && props.date.from),
                toDate = CONVERT_UNCERTAIN_DATE_TO_INTERNAL(props.date && props.date.to);

            let state = {
                fromDate: fromDate,
                toDate: toDate,
                ongoing: toDate.year <= 0
            };

            let isValid = this.isStateValid(state);

            return Object.assign({}, state, {
                canSave: isValid
            });
        };

        this.isStateValid = (state) => {
            return state.fromDate &&
                state.fromDate.day &&
                (
                    (state.toDate && state.toDate.day) ||
                    state.ongoing
                );
        };

        let localMoment = moment();
        localMoment.locale(mapLocaleForMoment(this.props.intl.locale));

        this.state = Object.assign(
            {},
            this.stateFromProps(props),
            {
                showDropdown: false,
                localeMoment: localMoment
            }
        );
    }

    componentWillReceiveProps (nextProps) {
        this.setState(this.stateFromProps(nextProps));
    }

    handleChange (type, value) {
        const { max, min } = this.props;
        const { fromDate, toDate } = this.state;

        const actualFrom = type === 'fromDate' ? { ...fromDate, ...value } : fromDate;
        const actualTo = type === 'toDate' ? { ...toDate, ...value } : toDate;
        const momentFrom = toMoment(actualFrom, min || moment(0)).startOf(datePrecision(actualFrom));
        const momentTo = toMoment(actualTo, max || moment()).endOf(datePrecision(actualTo));

        if (momentTo.isBefore(momentFrom)) {
            value = type === 'toDate' ? actualFrom : actualTo;
        }

        this.setState({
            [type]: Object.assign(this.state[type], value)
        }, () => {
            this.setState({
                canSave: this.isStateValid(this.state)
            });
        });
    }

    handleOngoingToggle () {

        let toDate = this.state.toDate;
        let shouldBeOngoing = !this.state.ongoing;

        if (shouldBeOngoing) {
            // unset value
            toDate = {
                year: VALUE_INTERNAL_NOT_SET,
                month: VALUE_INTERNAL_NOT_SET,
                day: VALUE_INTERNAL_NOT_SET
            };
        }

        this.setState({
            ongoing: shouldBeOngoing
        }, () => {
            this.handleChange('toDate', toDate, 'ongoingToggle');
        });
    }

    triggerChange () {
        let {fromDate, toDate} = this.state;

        let update = {
            from: CONVERT_INTERNAL_DATE_TO_UNCERTAIN(fromDate),
            to: CONVERT_INTERNAL_DATE_TO_UNCERTAIN(toDate)
        };
        this.props.onChange(update, 'dropdown');
    }

    triggerCancel () {
        this.props.onCancel();
    }

    renderControls () {
        let {canSave} = this.state;
        let okBtn = null;

        if (canSave) {
            okBtn = (
                <button type="button" className="btn btn-primary" onClick={this.triggerChange.bind(this)}>
                    <FormattedMessage { ...messages.btn_submit } />
                </button>
            );
        }

        return (
            <div className="controls">
                <div className="btn-group">
                    <button type="button" className="btn btn-default" onClick={this.triggerCancel.bind(this)}>
                        <FormattedMessage { ...messages.btn_cancel } />
                    </button>
                    { okBtn }
                </div>
            </div>
        );
    }

    renderFromDateSelector () {
        let {intl, max, min, showShortcuts} = this.props;
        let {fromDate, toDate} = this.state;
        let fromPlaceholder = intl.formatMessage(messages.from_placeholder);
        let fromMax = toMoment(toDate, max || moment()).endOf(datePrecision(toDate));

        return (
            <IntlPointDateSelector
                min={min}
                max={fromMax}
                showShortcuts={showShortcuts}
                placeholder={fromPlaceholder}
                date={fromDate}
                onChange={this.handleChange.bind(this, 'fromDate')}
            />
        );
    }

    render () {
        let {intl, show} = this.props;
        let {fromDate, toDate, ongoing} = this.state;

        let toClasses = classNames('range-part', {
            ongoing: ongoing
        });

        let fromClasses = classNames('range-part', {
            // this does not work properly yet:
            done: fromDate && (fromDate.month === VALUE_INTERNAL_DONT_KNOW || fromDate.day > 0 || fromDate.day === VALUE_INTERNAL_DONT_KNOW)
        });

        let localTodayString = intl.formatMessage(messages.today_text);

        return (
            <DateDropdown show={show} mobile={false} onCancel={this.triggerCancel.bind(this)}>
                <div className="selectors">
                    <div className={ fromClasses }>
                        <div className="title">
                            <span className="text">
                                <FormattedMessage
                                    id="part_daterangepicker3_from_head"
                                    defaultMessage="From"
                                    description="Header for the from section of the range dropdown"
                                />
                            </span>
                            <span className="date">{ FORMAT_INTERNAL_DATE(fromDate, intl.locale, '-') }</span>
                            <span className="controls"></span>
                        </div>
                        { this.renderFromDateSelector() }
                    </div>
                    <div className="boundary"></div>
                    <div className={ toClasses }>
                        <div className="title">
                            <span className="text">
                                <FormattedMessage
                                    id="part_daterangepicker3_to_head"
                                    defaultMessage="To"
                                    description="Header for the to section of the range dropdown"
                                />
                            </span>
                            <span className="date">{ FORMAT_INTERNAL_DATE(toDate,
                                intl.locale,
                                localTodayString) }</span>
                            <span className="controls">
                                { this.renderTitleControls() }
                            </span>
                        </div>
                        { this.renderToDateSelector() }
                    </div>
                </div>
                { this.renderControls() }
            </DateDropdown>
        );
    }

    renderTitleControls () {

        let {ongoing} = this.state;

        if (ongoing) {
            return null;
        }

        return (
            <button type="button" className="btn btn-default btn-xs" onClick={this.handleOngoingToggle.bind(this)}>
                <FormattedMessage
                    id="part_daterangepicker3_range_ongoing_label"
                    defaultMessage="Oops, still ongoing"
                    description="Label for the button to switch from date input to an open range in the range dropdown"
                />
            </button>
        );
    }

    renderToDateSelector () {

        let {intl, max, min, showShortcuts} = this.props;
        let {fromDate, toDate, ongoing} = this.state;
        let toPlaceholder = intl.formatMessage(messages.to_placeholder);

        if (ongoing) {
            return (
                <div className="ongoing-center">
                    <MarkdownMessage
                        tagName="div"
                        { ...messages.part_daterangepicker3_to_ongoing_text }
                    />
                    <button type="button" className="btn btn-default" onClick={ () => this.setState({ongoing: false}) }>
                        <FormattedMessage
                            id="part_daterangepicker3_to_ongoing_done_button_label"
                            defaultMessage="Enter end date"
                            description="Label for the button to switch from an open range to enter an end date in the range dropdown"
                        />
                    </button>
                </div>
            );
        }

        const toMin = toMoment(fromDate, min || moment(0)).startOf(datePrecision(fromDate));

        return (
            <IntlPointDateSelector
                min={toMin}
                max={max}
                placeholder={toPlaceholder}
                showShortcuts={showShortcuts}
                date={toDate}
                onChange={this.handleChange.bind(this, 'toDate')}/>
        );

    }
}

RangeDateDropdown.propTypes = {
    min: PropTypes.object, // moment
    max: PropTypes.object, // moment
    date: PropTypes.shape({
        from: PropTypes.shape({
            year: PropTypes.number,
            month: PropTypes.number,
            day: PropTypes.number
        }),
        to: PropTypes.shape({
            year: PropTypes.number,
            month: PropTypes.number,
            day: PropTypes.number
        })
    }),
    onCancel: PropTypes.func.isRequired, // if dropdown is canceled
    onChange: PropTypes.func.isRequired, // if values are submitted
    showShortcuts: PropTypes.bool, // show entry shortcuts,
    show: PropTypes.bool.isRequired // open/close dropdown
};

// date input for a point date
class RangeDateInput extends Component {

    constructor (props) {
        super(props);

        let localTodayString = props.intl.formatMessage(messages.today_text);

        this.readProps = (props) => {
            let input = this.formattedInputFromProps(props, localTodayString || this.state.localTodayString);
            return {
                input: input,
                valid: this.isValidInput(input, props, localTodayString)
            };
        };

        this.state = Object.assign({}, this.readProps(props), {
            localTodayString: localTodayString
        });

        this.input = React.createRef();
    }

    componentWillReceiveProps (nextProps) {
        this.setState(this.readProps(nextProps));
    }

    formattedInputFromProps (props, localTodayString) {
        let {date, intl} = props;
        let {from: fromDate, to: toDate} = date || {};

        if (fromDate || toDate) {
            return FORMAT_UNCERTAIN_DATE(fromDate, intl.locale) +
                ' - ' +
                FORMAT_UNCERTAIN_DATE(toDate, intl.locale, localTodayString || this.state.localTodayString);
        }
        return '';
    }

    isValidInput (input, props, localTodayString) {
        let {min, max, intl} = props || this.props;

        let components = input.split('-');

        let fromDateInputCheck = parseAndCheckDateConstraints(components[0], min, max, intl.locale),
            toDateInputCheck = parseAndCheckDateConstraints(components[1], min, max, intl.locale);

        let isOngoing = components.length === 1 || components[1].trim()
            .toLowerCase() === (localTodayString || this.state.localTodayString).toLowerCase();

        return fromDateInputCheck && (isOngoing || toDateInputCheck);
    }

    handleChange (e) {
        let input = e.target.value;
        this.setState({
            input: input
        });
    }

    handleBlur () {
        this.handleSubmit('input-blur');
    }

    handleKey (e) {
        if (e.which === 13) {
            e.preventDefault();
            this.handleSubmit('input-enter');
        }
    }

    handleSubmit (source) {

        let {min, max, intl} = this.props;

        let components = this.state.input.split('-');
        let fromDateInputCheck = parseAndCheckDateConstraints(components[0], min, max, intl),
            toDateInputCheck = parseAndCheckDateConstraints(components[1], min, max, intl);

        if (fromDateInputCheck) {

            let fromDate = CONVERT_MOMENT_TO_UNCERTAIN(fromDateInputCheck.moment, fromDateInputCheck.precision),
                toDate = CONVERT_MOMENT_TO_UNCERTAIN(toDateInputCheck.moment, toDateInputCheck.precision);

            this.props.onChange({
                from: fromDate,
                to: toDate
            }, source || 'input');

        }
    }

    render () {
        let {onDropdownShow, onDropdownToggle, placeholder} = this.props;
        let {input, valid} = this.state;

        let feedbackClasses = classNames({
            feedback: true,
            // hide glyphicon if no input present
            fa: valid !== 0,
            // something is wrong
            'fa-exclamation-triangle': valid === -1,
            'text-warning': valid === -1,
            // your input is fine
            'fa-check': valid && valid !== -1,
            'text-success': valid && valid !== -1
        });

        return (
            <div className="input-group">
                <input
                    ref={this.input}
                    type="text"
                    className="form-control"
                    placeholder={placeholder}
                    onClick={ () => {
                        onDropdownShow();
                        this.input.current.focus();
                    } }
                    onChange={ this.handleChange.bind(this) }
                    onKeyUp={ this.handleKey.bind(this) }
                    onBlur={this.handleBlur.bind(this)}
                    value={ input }
                />
                <span className="input-group-btn">
                    <button className="btn btn-primary" type="button" onClick={ onDropdownToggle }>
                        <i className="fa fa-calendar"/>
                    </button>
                </span>
                <i className={feedbackClasses}></i>
            </div>
        );
    }
}

RangeDateInput.propTypes = {
    placeholder: PropTypes.string,
    min: PropTypes.object, // moment
    max: PropTypes.object, // moment
    date: PropTypes.shape({
        from: PropTypes.shape({
            year: PropTypes.number,
            month: PropTypes.number,
            day: PropTypes.number
        }),
        to: PropTypes.shape({
            year: PropTypes.number,
            month: PropTypes.number,
            day: PropTypes.number
        })
    }),
    onChange: PropTypes.func.isRequired, // if values are submitted
    onDropdownShow: PropTypes.func.isRequired,
    onDropdownToggle: PropTypes.func.isRequired
};

// exports
let intlPointDateInput = injectIntl(PointDateInput);
export {intlPointDateInput as PointDateInput};

let intlPointDateDropdown = injectIntl(PointDateDropdown);
export {intlPointDateDropdown as PointDateDropdown};

let intlRangeDateDropdown = injectIntl(RangeDateDropdown);
export {intlRangeDateDropdown as RangeDateDropdown};

let intlRangeDateInput = injectIntl(RangeDateInput);
export {intlRangeDateInput as RangeDateInput};

export function mapLocaleForMoment(locale) {

    switch (locale) {
        case 'no':
            return 'nb';
        default:
            return locale;
    }

}

function catchWithDefault(lambda, defaultValue) {

    try {
        return lambda();
    } catch (e) {
        console.error('Unexepected error', e);
        return defaultValue;
    }

}

function datePrecision(date) {
    if (!date) {
        return undefined;
    }
    if (date.day > 0) {
        return 'date';
    }
    if (date.month > 0) {
        return 'month';
    }
    return 'year';
}

function toMoment(date, defaultDate) {
    if (date.year < 1) {
        return defaultDate || moment();
    }

    const d = Object.assign({}, date, {
        year: date.year,
        month: (date.month > 0) ? date.month : 0,
        day: (date.day > 0) ? date.day : 1
    });

    return moment(d);
}

