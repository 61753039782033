import _typeof from "@babel/runtime/helpers/esm/typeof";
import versionIndex from '../schema/index.json';
import { Schema } from './schema';
import { schemaTypesResolver } from './schema-types-resolver';
import { schemaFieldIdResolver } from './schema-field-id-resolver';
import { schemaTagResolver } from './schema-tag-resolver';
import { schemaView } from './view/schema-view';
import { generateSchemaId, parseSchemaId } from './schema-id';
import { tagsToArray } from './schema-utils';
import { Cache, CACHE_POLICY_LEAST_RECENTLY_USED } from './cache';
import { SCHEMA_ID, TYPE } from './data/utils';
var schemaFactoryCache = new Cache(CACHE_POLICY_LEAST_RECENTLY_USED, 5);
/**
 * Return a list of all available schema version
 *
 * @returns {string[]}
 */

export function getVersionNumbers() {
  return Object.keys(versionIndex);
}
/**
 * Returns the (semantic) version number of the latest version
 * @returns {string}
 */

export function getLatestVersionNumber() {
  return versionIndex.latest;
}
/**
 * Returns a {@link Schema} instance for the given version
 * and tag name(s)
 *
 * @param {string|string[]} tags
 * @param {string} [versionNumber=latest]
 * @returns {Promise<Schema>} An instance of Schema backed with the given schemaData version
 * @throws {Error} If the requested schema version cannot be found
 */

export function getInstance(tags) {
  var versionNumber = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'latest';

  if (versionNumber === 'latest' || arguments.length < 2) {
    return getInstance(tags, versionIndex.latest);
  }

  if (!versionIndex.hasOwnProperty(versionNumber)) {
    throw new Error('Requested schema version not found');
  }

  return import(
  /* webpackChunkName: "schema" */

  /* webpackInclude: /schema\.json$/ */

  /* webpackMode: "lazy" */
  "../schema/".concat(versionNumber, "/package/dist/schema.json")).then(function (schemaData) {
    return getInstanceFromSchemaData(tags, schemaData, versionNumber);
  });
}
/**
 * Returns a {@link Schema} instance which can work with the provided
 * eventData object.
 *
 * @param eventData
 * @return {Promise<Schema>}
 * @throws {Error} If the supplied eventData object is not valid
 */

export function getInstanceFromEventData(eventData) {
  if (!eventData || _typeof(eventData) !== 'object' || !eventData.hasOwnProperty(TYPE) && !eventData.hasOwnProperty(SCHEMA_ID)) {
    throw new Error('Not a valid eventData object');
  }

  var schemaIdFromEventData = eventData[SCHEMA_ID];

  if (schemaIdFromEventData) {
    return getInstanceFromId(schemaIdFromEventData);
  }

  var instance = getInstance();
  return instance.then(function (schemaInstance) {
    var schemaId = schemaInstance.getSchemaId();
    console.log("Event found without \"".concat(SCHEMA_ID, "\": Falling back to latest instance \"").concat(schemaId, "\".\nThis might result in an incompatible combination of \"").concat(TYPE, "\" and \"").concat(SCHEMA_ID, "\": \"").concat(eventData[TYPE], "@").concat(schemaId, "\".\n"));
    return schemaInstance;
  });
}
/**
 * Returns a {@link Schema} instance for the given schemaData.
 * This is internally used by {@link #getInstance} but also useful
 * for unit testing, where we use customized schemaData objects.
 *
 * @param {string|string[]} tags
 * @param {object} schemaData
 * @param {string} [versionNumber]
 * @returns {Schema}
 */

export function getInstanceFromSchemaData(tags, schemaData, versionNumber) {
  var resolvedSchema = resolveAndFilterSchema(tags, schemaData);
  var schemaId = generateSchemaId(tags, versionNumber);

  if (schemaId === 'vCUSTOM_(default)') {
    // no caching for full customized schema instances
    // As we cannot actually distinguish them, which
    // would break unit testing
    return new Schema(resolvedSchema, schemaId);
  }

  if (schemaFactoryCache.has(schemaId)) {
    return schemaFactoryCache.get(schemaId);
  }

  var schemaInstance = new Schema(resolvedSchema, schemaId);
  schemaFactoryCache.set(schemaId, schemaInstance);
  return schemaInstance;
}
/**
 * Get a schema instance by schemaId
 *
 * @param {string} schemaId - A schema id identifying a certain schema configuration
 * @returns {Promise<Schema>}
 * @throws {Error} If the supplied schemaId cannot be resolved / is not valid
 */

export function getInstanceFromId(schemaId) {
  var _parseSchemaId = parseSchemaId(schemaId),
      versionNumber = _parseSchemaId.versionNumber,
      tags = _parseSchemaId.tags;

  if (versionNumber === undefined) {
    throw new Error('Cannot create instance from custom version in schemaId');
  }

  return getInstance(tags, versionNumber);
}

function resolveAndFilterSchema(tags, schemaData) {
  var tagArray = tagsToArray(tags);
  var schemaWithResolvedTypes = schemaTypesResolver(schemaData);
  var schemaWithResolvedTags = schemaTagResolver(schemaWithResolvedTypes);
  var schemaWithResolvedFieldIds = schemaFieldIdResolver(schemaWithResolvedTags);
  return schemaView(schemaWithResolvedFieldIds, tagArray);
}