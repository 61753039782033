import React, { Component } from 'react';
import { FormsMixin } from '../forms-mixin';
import BtnGroup from '../one-of/btn-group';

class GleasonTertiary extends FormsMixin(Component) {
    render() {
        let field = this.getSubfield(this.props.id);
        if(!field) return null;

        let tertiaryType = field.type.fields.find(f => f.id === this.props.id + '/value');

        let val = this.getValue();
        let primaryValue = this.getValueOfField('gleason/two-values/primary');
        let secondaryValue = this.getValueOfField('gleason/two-values/secondary');

        let valueField = Object.assign({}, tertiaryType);
        // noinspection EqualityComparisonWithCoercionJS
        valueField.def = Object.assign({}, valueField.def, {
            values: valueField.def.values.filter(item => item.value && (item.value != secondaryValue && item.value != primaryValue))
        });

        valueField.def = this.transformDefValuesOfField(valueField.def, valueField.id);

        // if out internal value is equal to either gleason primary or secondary -> reset value
        // That prevents, that wrong data is being sent to the server
        if (val === primaryValue || val === secondaryValue){
            val = null;
            this.update(valueField.def, valueField.id, null);
        }
        return (
            <BtnGroup
                eventData={this.props.eventData}
                eventId={this.props.eventId}
                onChange={this.props.onChange}
                value={{value: val}}
                {...valueField}
            />
        );
    }

}

export default GleasonTertiary;
