import { useEffect, useReducer } from "react";
import { IntlProvider } from "react-intl";
import LoadingPlaceholder from "../components/loading-placeholder";

function reducer(state, action) {
    switch (action.type) {
        case 'load': return {...state, loading: true, error: ''};
        case 'load.success': return { ...state, loading: false, messages: action.messages };
        case 'load.error': return { ...state, loading: false, error: action.error };
        default: return state;
    }
}

export function I18nProvider({ locale = 'en', children }) {
    const [state, dispatch] = useReducer(reducer, { loading: true, messages: {}, error: '' });


    useEffect(() => {
        dispatch({ type: 'load' });
        import(`progether-messages/${locale}.json`)
            .then(result => {
                dispatch({ type: 'load.success', messages: result.default })
            })
            .catch(error => {
                dispatch({ type: 'load.error', error: error.message });
            })
    }, [locale]);


    if (state.error) {
        return <pre>{state.error}</pre>
    }

    return (
        <LoadingPlaceholder loading={state.loading} message="">
            <IntlProvider locale={locale} messages={state.messages}>
                {children}
            </IntlProvider>
        </LoadingPlaceholder>
    );
}