import { checkCondition } from "./validate/condition";
import { validate } from "./index";
import clone from "clone";
import { TYPE } from "./utils";
/**
 * Given an eventSchema and some corresponding eventData, this function removes all fields in the schema,
 * that do not pass the defined field conditions. (in the schema)
 *
 * @param eventSchema - the event schema that should be cleaned by using the given event data as a base
 * @param eventData - corresponding data to the given schema
 * @returns {object} cleaned-up schema, containing only fields that should be visible
 */

export function filterSchemaByConditions(eventSchema) {
  var eventData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  // empty object with no type given
  // transform it, so that the validation works
  if (!eventData[TYPE]) {
    eventData[TYPE] = eventSchema.id;
  } // scan through the given event-data and add completeness and validation information to the data


  var eventDataWithValidationAndCompletenessInformation = clone(eventData);
  validate(eventSchema, eventDataWithValidationAndCompletenessInformation);
  var filteredEventSchema = Object.assign({}, eventSchema, {
    fields: []
  }); // goes through every field of the schema, and removes the fields, that do not pass the condition tests.

  function handleField(schemaFragment, schemaFilteredFragment) {
    if (schemaFragment.fields) {
      schemaFilteredFragment.fields = [];
      schemaFragment.fields.forEach(function (field) {
        var fieldFragment = Object.assign({}, field, {
          fields: []
        });

        if (field.fields) {
          handleField(field, fieldFragment);
        }

        if (field.condition) {
          var conditionCheck = checkCondition(eventSchema, field.id, eventDataWithValidationAndCompletenessInformation);

          if (conditionCheck.result) {
            schemaFilteredFragment.fields.push(fieldFragment);
          }
        } else {
          schemaFilteredFragment.fields.push(fieldFragment);
        }

        if (!fieldFragment.fields.length) {
          delete fieldFragment.fields;
        }
      });
    }
  }

  handleField(eventSchema, filteredEventSchema);

  if (!filteredEventSchema.fields.length) {
    delete filteredEventSchema.fields;
  }

  return filteredEventSchema;
}