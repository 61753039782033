import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormsMixin} from '../forms-mixin';
import classnames from 'classnames';

const Button = ({active, label, onClick}) => {

    const classes = classnames('btn', 'btn-default', {
        active: active
    });

    return (
        <button type="button" className={classes} onClick={() => onClick()}>{label}</button>
    );

};

Button.propTypes = {
    active: PropTypes.bool,
    label: PropTypes.node,
    onClick: PropTypes.func.isRequired,
};

export class OneOfBtnGroup extends Component {

    static propTypes = {
        values: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string,
            labelFormatted: PropTypes.node
        })).isRequired,
        selectedValue: PropTypes.any,
        onSelection: PropTypes.func.isRequired
    };

    render() {

        const {values, selectedValue, onSelection} = this.props;


        return (
            <div>
                <div className="btn-group">
                    {
                        values.map(v => {
                            const isActive = v.value === selectedValue;
                            return (
                                <Button key={v.value}
                                    active={isActive}
                                    label={v.labelFormatted || v.label || v.value}
                                    onClick={() => onSelection(isActive ? undefined : v.value)}/>
                            );
                        })
                    }
                </div>
            </div>
        );

    }

}

export class ConnectedOneOfBtnGroup extends FormsMixin(Component) {

    render() {
        const {id, def} = this.props;
        return (
            <OneOfBtnGroup values={def.values} selectedValue={this.getValue()}
                onSelection={(value) => this.update(def, id, value)}/>
        );
    }

}

export default ConnectedOneOfBtnGroup;
