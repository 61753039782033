import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const CONFIRM_CONTAINER_ID = 'confirm-container';

let counter = 0;
const getId = () => CONFIRM_CONTAINER_ID + '-' + counter++;

export const attachToBody = (Component) => {

    return (props) => {
        const WrappedComponent = withConfirmation(Component);
        const id = getId();

        console.log('standard container create', id);
        const container = document.getElementById(id);
        const wrapper = document.body.appendChild(container || document.createElement('div'));
        wrapper.id = id;

        const listenForBackgroundClicks = (e) => {

            const container = document.getElementById(id);
            if (!clickWithinElement(e, container)) {
                dispose();
            }

        };

        const promise = new Promise((resolve, reject) => {

            try {

                console.log('standard container render', id);
                ReactDOM.render(
                    <WrappedComponent
                        reject={reject}
                        resolve={resolve}
                        dispose={dispose}
                        {...props}
                    />,
                    wrapper
                );

                // start listening to background clicks
                // Without the timeout we might immediately remove the popup on creation
                setTimeout(() => {
                    document.addEventListener('click', listenForBackgroundClicks);
                }, 10);

            } catch (e) {
                console.error(e);
                throw e;
            }

        });

        function dispose() {

            // stop listening to background clicks
            document.removeEventListener('click', listenForBackgroundClicks);

            setTimeout(() => {
                console.log('standard container dispose', id);
                ReactDOM.unmountComponentAtNode(wrapper);
                setTimeout(() => wrapper.remove(), 10);
            }, 10);

        }

        return promise.then(
            // resolved
            (result) => {
                dispose();
                return result;
            },
            // rejected
            (result) => {
                dispose();
                return Promise.reject(result);
            }
        );

    };

};


function clickWithinElement(event, element) {

    try {

        if (event.path) {
            return event.path.includes(element);
        }

        if (event.deepPath) {
            return event.deepPath.includes(element);
        }

        let el = element;
        do {
            if (el === event.target) {
                return true;
            }
            el = el.parentNode;
        } while (el);

    } catch (e) {
        console.error('Could not check element click path', e);
    }

    return false;

}


class PopupWithPosition extends React.Component {
    static propTypes = {
        placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
        positionLeft: PropTypes.number,
        positionTop: PropTypes.number,
        element: PropTypes.element.isRequired,
        width: PropTypes.number,
        height: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.oneOf(['auto'])
        ])
    };

    static defaultProps = {
        placement: 'top',
        width: 250,
        height: 150,
    };

    getPosition() {
        const { element, placement, positionLeft, positionTop, width, height } = this.props;

        const position = {
            top: positionTop,
            left: positionLeft,
        };

        if (element && (!positionTop && !positionLeft)) {
            const autoHeight = (height === 'auto') ? 150 : height;
            const bb = element.getBoundingClientRect();

            // this is apparently the way to escalate: https://github.com/nuxt/nuxt.js/issues/2512#issuecomment-358215583
            const scrollTop = Math.max(
                window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop
            );
            const scrollLeft = Math.max(
                window.pageXOffset, document.documentElement.scrollLeft, document.body.scrollLeft
            );

            switch (placement) {
                case 'top':
                    position.left = bb.left + bb.width / 2 - width / 2 + scrollLeft;
                    position.top = bb.top - 10 - autoHeight + scrollTop;
                    break;

                case 'bottom':
                    position.left = bb.left + bb.width / 2 - width / 2 + scrollLeft;
                    position.top = bb.top + 10 + bb.height + scrollTop;
                    break;

                case 'left':
                    position.left = bb.left - width - 10 + scrollLeft;
                    position.top = bb.top + bb.height / 2 + scrollTop - autoHeight / 2;
                    break;

                case 'right':
                    position.left = bb.left + bb.width + 10 + scrollLeft;
                    position.top = bb.top + bb.height / 2 + scrollTop - autoHeight / 2;
                    break;
            }

            // debug info to fix WEB-1046
            console.debug('tooltip-placement', {
                elementBoundingClientRect: bb,
                pageYOffset: window.pageYOffset,
                scrollTop,
                scrollLeft,
                position,
            });

        }

        return position;
    }
}

function withConfirmation(Component) {
    return class extends PopupWithPosition {
        state = {
            show: true,
        };

        dismiss = () => {
            this.setState(
                { show: false,},
                () => this.props.dispose()
            );
        };

        cancel = value => {
            this.setState(
                { show: false },
                () => this.props.reject(value)
            );
        };

        proceed = value => {
            this.setState(
                { show: false },
                () => this.props.resolve(value)
            );
        };

        render() {
            const { left, top } = this.getPosition();
            const proceed = this.props.noProceed
                ? null
                : this.proceed;

            return (
                <Component
                    proceed={proceed}
                    cancel={this.cancel}
                    dismiss={this.dismiss}
                    show={this.state.show}
                    { ...this.props}
                    positionLeft={left}
                    positionTop={top}
                />
            );
        }
    };
}


