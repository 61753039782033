import React, {Component} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import './edit-actions.less';
import {Conditional} from "../utils/conditional";
import SplitDropdownButton from "./split-dropdown-button";

const messages = defineMessages({
    cancelButtonLabel: {
        id: 'data_ui_components_confirm_btn_cancel_label',
        defaultMessage: 'Cancel',
    },
    confirmButtonLabel: {
        id: 'data_ui_components_confirm_btn_confirm_label',
        defaultMessage: 'Confirm',
    },
    submitButtonLabel: {
        id: 'event_form_buttons_save',
        defaultMessage: 'Save',
    },
    submitAnotherSameButtonLabel: {
        id: 'event_form_buttons_save_and_next',
        defaultMessage: 'Save & Next',
    },
});

export function EditActions(props) {

    return (
        <div className="edit-event-actions">

            <div>
                <Conditional cond={props.enableRemove}>
                    <ConfirmButton
                        buttonLabelMessage={{
                            id: 'event_form_buttons_remove',
                        }}
                        btnClasses="btn-link"
                        confirmButtonStyle="danger"
                        disabled={props.removeInProgress}
                        iconClasses={props.removeInProgress ? 'fa fa-spin fa-refresh' : 'fa fa-trash'}
                        onClick={props.onRemove}
                    />
                </Conditional>
            </div>

            <div>

                <Conditional cond={!props.removeInProgress && !props.submitInProgress}>
                    <FormattedMessage id="event_form_buttons_cancel">
                        {txt => (
                            <a className="btn btn-default"
                               onClick={props.onCancel}
                               disabled={!props.enableCancel}>
                                {txt}
                            </a>
                        )}
                    </FormattedMessage>
                </Conditional>

                <Conditional cond={!props.removeInProgress}>
                    <SplitDropdownButton
                        defaultActionLabelMessage={messages.submitButtonLabel}
                        defaultAction={props.onSubmit}
                        defaultActionLabelBefore={
                            props.submitInProgress
                                ? <i className="fa fa-spin fa-refresh"/>
                                : null
                        }
                        defaultActionStyle="primary"
                        disabled={!props.enableSubmit || props.submitInProgress}
                        actions={[
                            props.onSubmitAndNext
                                ? {
                                    actionLabelMessage: messages.submitAnotherSameButtonLabel,
                                    onAction: () => {
                                        props.onSubmitAndNext();
                                    },
                                }
                                : false,
                        ].filter(a => !!a)}
                        dropdownAlignment="right"
                    />
                </Conditional>

            </div>

        </div>
    );

}

EditActions.propTypes = {
    onRemove: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    onSubmitAndNext: PropTypes.func,
    enableRemove: PropTypes.bool,
    enableCancel: PropTypes.bool,
    enableSubmit: PropTypes.bool,
    removeInProgress: PropTypes.bool,
    submitInProgress: PropTypes.bool,
};

EditActions.defaultProps = {
    removeInProgress: false,
    submitInProgress: false,
};

const messageType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string,
});

class ConfirmButton extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            clicked: false,
        };
    }

    static propTypes = {
        buttonLabelMessage: messageType.isRequired,
        cancelButtonLabelMessage: messageType,
        confirmButtonLabelMessage: messageType,
        confirmButtonStyle: PropTypes.oneOf(['primary', 'success', 'info', 'warning', 'danger']),
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
        iconClasses: PropTypes.string,
        btnClasses: PropTypes.string,
    };

    static defaultProps = {
        disable: true,
        cancelButtonLabelMessage: messages.cancelButtonLabel,
        confirmButtonLabelMessage: messages.confirmButtonLabel,
        confirmButtonStyle: 'primary',
    };

    render() {

        const {
            buttonLabelMessage,
            cancelButtonLabelMessage,
            confirmButtonLabelMessage,
            confirmButtonStyle,
            btnClasses,
            onClick,
            disabled,
            iconClasses,
        } = this.props;

        const {
            clicked,
        } = this.state;

        if (!clicked) {
            return (
                <FormattedMessage {...buttonLabelMessage}>
                    {
                        txt => (
                            <a className={`btn ${btnClasses}`}
                               onClick={() => this.setState({clicked: true})}
                               disabled={disabled}>
                                <Conditional cond={iconClasses}>
                                    <i className={iconClasses}/>
                                </Conditional>
                                {txt}
                            </a>
                        )
                    }
                </FormattedMessage>
            );
        }

        return (
            <div className="btn-group" role="group">
                <FormattedMessage {...cancelButtonLabelMessage}>
                    {
                        txt => (
                            <a className="btn btn-default"
                               onClick={() => this.setState({clicked: false})}>
                                {txt}
                            </a>
                        )
                    }
                </FormattedMessage>
                <FormattedMessage {...confirmButtonLabelMessage}>
                    {
                        txt => (
                            <a className={`btn btn-${confirmButtonStyle}`}
                               onClick={() => this.setState({clicked: false}, onClick())}>
                                <Conditional cond={iconClasses}>
                                    <i className={iconClasses}/>
                                </Conditional>
                                {txt}
                            </a>

                        )
                    }
                </FormattedMessage>
            </div>
        );

    }

}
