import {injectIntl} from 'react-intl';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {ImageToVideoRenderer, LinkWithTargetRenderer, NoParagraphsRenderer} from './renderer';

/**
 * Simple wrapper around react-markdown to translate
 * the source before rendering it as markdown.
 */
class MarkdownMessage extends Component {

    static propTypes = {
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string,
        description: PropTypes.string,
        values: PropTypes.object,
        tagName: PropTypes.string,
        className: PropTypes.string,
        containerProps: PropTypes.object,
        // custom renderers
        renderers: PropTypes.object,
        // configure inbuild custom renderers
        forceNewTabsForLinks: PropTypes.bool,
        allowVideo: PropTypes.bool,
        noParagraphs: PropTypes.bool
    };

    static defaultProps = {
        tagName: 'div',
        forceNewTabsForLinks: true,
        allowVideo: false,
        noParagraphs: false
    };

    render() {

        const {
            intl,
            id,
            description,
            defaultMessage,
            values,
            renderers: renderer,
            className,
            tagName,
            containerProps,
            forceNewTabsForLinks,
            allowVideo,
            noParagraphs
        } = this.props;

        const sourceMessage = {
            id: id,
            defaultMessage: defaultMessage,
            description: description
        };

        const translatedSource = intl.formatMessage(sourceMessage, values || {});

        const buildinRenderers = {};

        if (forceNewTabsForLinks) {
            buildinRenderers.Link = LinkWithTargetRenderer;
        }

        if (allowVideo) {
            buildinRenderers.Image = ImageToVideoRenderer;
        }

        if (noParagraphs) {
            buildinRenderers.Paragraph = NoParagraphsRenderer;
        }

        // renderer supplied via props take precedence over build-in renderer
        const modifiedRenderers = Object.assign(buildinRenderers, renderer);

        return (
            <ReactMarkdown
                className={className}
                containerTagName={tagName}
                containerProps={containerProps}
                escapeHtml={true} // we cannot use html markup in messages any more
                source={translatedSource}
                renderers={modifiedRenderers}
            />
        );

    }

}

export default injectIntl(MarkdownMessage);
