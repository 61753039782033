import { validateFields } from './field';
import { IS_VALID, TYPE } from '../utils';
import { eventCompleteness } from './completeness';
/**
 * Validate the given eventData against the given eventSchema
 *
 * @param eventSchema
 * @param eventData
 * @returns {boolean} true if eventData is valid with respect to eventSchema
 */

export var validateEvent = function validateEvent(eventSchema, eventData) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    logTarget: console
  };
  var _options$logTarget = options.logTarget,
      logTarget = _options$logTarget === void 0 ? console : _options$logTarget; // make sure we only validate
  // if the data matches the id
  // of the event within the schema

  if (!eventSchema.id || eventData[TYPE] !== eventSchema.id) {
    logTarget.error('event type id mismatch between eventData and eventSchema');
    return false;
  } // process all fields
  // will update completeness and
  // set the isValid field


  validateFields(eventSchema, eventData, 0, options); // add completeness information

  eventCompleteness(eventSchema, eventData); // only if all fields are valid,
  // will the event as a whole be valid as well

  return eventData[IS_VALID];
};