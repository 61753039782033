import { defineMessages } from 'progether-messages';
var messages = defineMessages({
  other: {
    "id": "event_markdown_other_value",
    "defaultMessage": "Other: {customValue}"
  },
  none: {
    "id": "event_markdown_no_value",
    "defaultMessage": "-"
  }
});
/**
 * Extract the given value from the given map.
 * If the value indicates we are looking at an
 * "other" field here, handle this separately
 *
 * @param {any} value
 * @param {object} map
 * @returns {*} Our intermediate message object
 */

export function mapValueToMessageBundle(value, map) {
  if (/^_OTHER_/.test(String(value))) {
    return {
      message: messages.other,
      values: {
        customValue: String(value).substr(7) // remove the leading "_OTHER_"

      }
    };
  }

  if (!Object.prototype.hasOwnProperty.call(map, value)) {
    return {
      message: messages.none
    };
  }

  return {
    message: map[value]
  };
}
export function formatMessageBundle(messageBundle, intl) {
  var message = messageBundle.message,
      values = messageBundle.values;
  return intl.formatMessage(message, values);
}