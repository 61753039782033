import PropTypes from 'prop-types';
import React from 'react';
import {eventFormNavigationCategoryLabel, eventFormNavigationEventLabel} from '../../lib/intl-helper';
import {FormattedMessage, injectIntl} from 'react-intl';
import './event-menu.less';
import classnames from 'classnames';

const EventTypeCategoryItem = injectIntl((props) => {

    const {eventCategory, eventTypes, onClick, intl} = props;

    const sortedAndTranslatedEventTypes = sortAndTranslateEventTypes(eventCategory, eventTypes, intl);

    return (
        <li className={classnames('event-menu-category-item', 'type-' + eventCategory)}>
            <FormattedMessage id={eventFormNavigationCategoryLabel(eventCategory)}>
                {
                    (txt) => <div className="event-menu-category-item-title">{txt}</div>
                }
            </FormattedMessage>
            <EventTypeList eventTypes={sortedAndTranslatedEventTypes} onClick={onClick}/>
        </li>
    );

});

EventTypeCategoryItem.propTypes = {
    eventCategory: PropTypes.string.isRequired,
    eventTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    onClick: PropTypes.func.isRequired,
};

export function EventTypeCategoryList(props) {

    const {eventTypes, onClick} = props;

    const categories = new Map();

    eventTypes.forEach(type => {

        const categoryKey = type.split('/')[0];

        if (!categories.has(categoryKey)) {
            categories.set(categoryKey, []);
        }

        categories.get(categoryKey).push(type);

    });

    return (
        <ul className="event-menu-categories">
            {
                Array.from(categories)
                    .map(([eventCategory, eventTypesInCategory]) => (
                        <EventTypeCategoryItem key={eventCategory}
                                               eventCategory={eventCategory}
                                               eventTypes={eventTypesInCategory}
                                               onClick={onClick}/>
                    ))
            }
        </ul>
    );

}

EventTypeCategoryList.propTypes = {
    eventTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    onClick: PropTypes.func.isRequired,
};

function EventTypeList(props) {

    const {eventTypes, onClick} = props;

    return (
        <ul className="event-menu-type-list">
            {
                eventTypes.map(translated => (
                    <EventTypeItem key={translated.id}
                                   text={translated.text}
                                   eventTypeId={translated.id}
                                   onClick={onClick}/>
                ))
            }
        </ul>
    );

}

EventTypeList.propTypes = {
    eventTypes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    })).isRequired,
    onClick: PropTypes.func.isRequired,
};

function EventTypeItem(props) {

    const {text, onClick, eventTypeId} = props;

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(eventTypeId);
    };

    return (
        <li className="event-menu-type-item">
            <a href="" onClick={handleClick}>{text}</a>
        </li>
    );
}

EventTypeItem.propTypes = {
    eventTypeId: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

function sortAndTranslateEventTypes(typePrefix, eventTypes, intl) {

    const [pinnedEventTypes, unpinnedEventTypes] = splitEventTypes(
        eventTypes.map(translate)
    );

    return [
        ...pinnedEventTypes,
        ...unpinnedEventTypes.sort(localizedCompare)
    ];

    //
    // EOF
    //

    function splitEventTypes(eventTypes) {
        const pinnedTypeIds = getPinnedEventTypes();

        let top = 0;
        for (let i in pinnedTypeIds) {
            const from = eventTypes.findIndex(type => type.id === pinnedTypeIds[i]);
            if (from > -1) {
                moveElement(eventTypes, from, top++);
            }
        }

        return [
            eventTypes.slice(0, top),
            eventTypes.slice(top)
        ];
    }

    function moveElement(arr, from, to) {
        // nothing to be done
        if (from === to) {
            return arr;
        }

        // extend if needed
        if (to > arr.length) {
            arr[to] = undefined;
        }

        // move element
        arr.splice(to, 0, arr.splice(from, 1)[0]);

        return arr;
    }

    // Note for future self: this is the order of events
    // according to the Hauptstadturology flyer, which was our
    // spec at this time
    function getPinnedEventTypes() {
        const categories = {
            testing: [
                'testing/pathology/biopsy',
                'testing/imaging'
            ],
            therapy: [
                'therapy/local/radiation',
                'therapy/local/surgery',
                'therapy/systemic',
            ]
        };

        return categories[typePrefix] || [];
    }


    function translate(e) {
        const messageId = eventFormNavigationEventLabel(e);
        return {
            id: e,
            text: intl.formatMessage({id: messageId})
        };
    }

    function localizedCompare(a, b) {
        return a.text.localeCompare(b.text);
    }

}
