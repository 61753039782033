import css from './content-box.module.less';
import classes from 'classnames';

export function ContentBox({ children, style, className, id }) {

    return (
        <div className={classes(css.contentBox, className)} style={style} id={id}>
            {children}
        </div>
    )
}