import _typeof from "@babel/runtime/helpers/esm/typeof";
import { defineMessages } from 'progether-messages';
import { formatMessageBundle, mapValueToMessageBundle } from './message-utils';
import { MAP_EVENT_TYPE_ID_TO_MESSAGES, MAP_TESTING_MARKER_PSA_VALUE_MODIFIER_TO_MESSAGE } from './message-maps';
import { getEventCore, getEventType } from './event-utils';
import { Event, messageIdGenerators } from 'progether-data-schema'; // ---------------------------------------------
// Define all messages here,
// to get full build time tool support
// ---------------------------------------------

var messages = defineMessages({
  testing_marker_psa_eq: {
    "id": "event_detail_view_sampling_psa_eq",
    "defaultMessage": "Measured: {v, number}"
  },
  testing_marker_psa_neq: {
    "id": "event_detail_view_sampling_psa_neq",
    "defaultMessage": "Measured: {t} {v, number}"
  },
  testing_marker_testosterone_nmol: {
    "id": "event_detail_view_testing_marker_testosterone_value_nmol",
    "defaultMessage": "Testosterone: {value, number} nmol"
  },
  testing_marker_testosterone_ug: {
    "id": "event_detail_view_testing_marker_testosterone_value_ug",
    "defaultMessage": "Testosterone: {value, number} ug"
  },
  testing_pathology_biopsy_cancer_yes_risk: {
    "id": "event_markdown_testing_pathology_biopsy_gleason_riskgroup",
    "defaultMessage": "Biopsy indicates cancer. Gleason score: **{riskGroup}**"
  },
  testing_pathology_biopsy_cancer_yes_sum: {
    "id": "event_markdown_testing_pathology_biopsy_gleason_sum",
    "defaultMessage": "Biopsy indicates cancer. Gleason: **{sumScale}**"
  },
  testing_pathology_biopsy_cancer_yes_score: {
    "id": "event_markdown_testing_pathology_biopsy_gleason_score",
    "defaultMessage": "Biopsy indicates cancer. Gleason score: **{primary}** + **{secondary}** = **{score}** (Tertiary {tertiary})"
  },
  testing_pathology_biopsy_cancer_no: {
    "id": "event_detail_view_diagnosis_biopsy_without_cancer",
    "defaultMessage": "Biopsy without indication of prostate cancer"
  },
  incompleteEvent: {
    "id": "event_markdown_incomplete",
    "defaultMessage": "This event is incomplete"
  },
  eventConversionFailed: {
    "id": "event_markdown_failed",
    "defaultMessage": "Oops. Sorry we cannot show you info on this event."
  },
  therapy_conservative_active_surveillance_ongoing: {
    "id": "event_markdown_therapy_conservative_active_surveillance_ongoing",
    "defaultMessage": "Therapy ongoing"
  },
  therapy_conservative_active_surveillance_ended: {
    "id": "event_markdown_therapy_conservative_active_surveillance_ended",
    "defaultMessage": "Therapy ended"
  },
  therapy_conservative_watchful_waiting_ongoing: {
    "id": "event_markdown_therapy_conservative_watchful_waiting_ongoing",
    "defaultMessage": "Therapy ongoing"
  },
  therapy_conservative_watchful_waiting_ended: {
    "id": "event_markdown_therapy_conservative_watchful_waiting_ended",
    "defaultMessage": "Therapy ended"
  },
  therapy_local_radiation_dose: {
    "id": "event_detail_view_therapy_radiation_location_with_dosis",
    "defaultMessage": "Radiation with a dosage of **{dosis, number} Gray**."
  },
  therapy_local_surgery_gleason_score: {
    "id": "event_markdown_therapy_local_surgery_gleason_score",
    "defaultMessage": "Gleason Score: **{primary}** + **{secondary}** = **{score}**"
  },
  therapy_local_surgery_gleason_with_percentages: {
    "id": "event_markdown_therapy_local_surgery_gleason_with_percentages",
    "defaultMessage": "Primary: **{primary}** ({primaryPercentage,number,percent}); Secondary: **{secondary}** ({secondaryPercentage, number, percent}); Tertiary: **{tertiary}** ({tertiaryPercentage, number, percent})"
  },
  therapy_local_surgery_gleason_with_percentages_no_tertiary: {
    "id": "event_markdown_therapy_local_surgery_gleason_with_percentages_no_tertiary",
    "defaultMessage": "Primary: **{primary}** ({primaryPercentage,number,percent}); Secondary: **{secondary}** ({secondaryPercentage, number, percent}); Tertiary: **{tertiary}**"
  },
  therapy_local_surgery_gleason_with_percentages_primary_only: {
    "id": "event_markdown_therapy_local_surgery_gleason_with_percentages_primary_only",
    "defaultMessage": "Primary: **{primary}** ({primaryPercentage,number,percent}); Secondary: **{secondary}**; Tertiary: **{tertiary}**"
  },
  therapy_local_surgery_gleason_without_percentages: {
    "id": "event_markdown_therapy_local_surgery_gleason_without_percentages",
    "defaultMessage": "Primary: **{primary}**; Secondary: **{secondary}**; Tertiary: **{tertiary}**"
  },
  therapy_local_surgery_tumor_state: {
    "id": "event_markdown_therapy_local_surgery_tumorState",
    "defaultMessage": "Tumor state: **{tumorState}**"
  },
  therapy_systemic_ongoing: {
    "id": "event_markdown_therapy_systemic_ongoing",
    "defaultMessage": "Therapy is ongoing"
  },
  therapy_systemic_ended: {
    "id": "event_markdown_therapy_systemic_ended",
    "defaultMessage": "Therapy has ended"
  },
  testing_imaging_type: {
    "id": "event_markdown_testing_imaging_type",
    "defaultMessage": "Imaging technique used: **{kind}**"
  },
  comment: {
    "id": "event_markdown_comment",
    "defaultMessage": "**My comment**: *{comment}*"
  },
  fieldNotSet: {
    "id": "event_markdown_field_not_set_text",
    "defaultMessage": "Not set"
  }
});
/*
// noinspection EqualityComparisonWithCoercionJS
function gleasonSum(primary, secondary) {

    if (primary == 3 && secondary == 4) { // use == as we probably get strings as input
        return '7a';
    } else if (primary == 4 && secondary == 3) { // use == as we probably get strings as input
        return '7b';
    }

    return parseInt(primary, 10) + parseInt(secondary, 10);

}
*/

var MESSAGE_MAPPINGS = {
  'testing/marker/psa': function testingMarkerPsa(eventData, intl) {
    var modifier = eventData.get('value/modifier');
    var value = eventData.get('value/value');
    var markdownMessages = [];

    if (modifier === 'EQ') {
      markdownMessages.push({
        message: messages.testing_marker_psa_eq,
        values: {
          v: value
        }
      });
    } else if (modifier) {
      var modifierMessage = mapValueToMessageBundle(modifier, MAP_TESTING_MARKER_PSA_VALUE_MODIFIER_TO_MESSAGE);
      markdownMessages.push({
        message: messages.testing_marker_psa_neq,
        values: {
          t: formatMessageBundle(modifierMessage, intl),
          v: value
        }
      });
    }

    return markdownMessages;
  },
  'testing/marker/testosterone': function testingMarkerTestosterone(eventData, intl) {
    // eslint-disable-line no-unused-vars
    var unit = eventData.get('value/unit');
    var value = eventData.get('value/value');
    var markdownMessages = [];

    switch (unit) {
      case 'NMOL':
        markdownMessages.push({
          message: messages.testing_marker_testosterone_nmol,
          values: {
            value: value
          }
        });
        break;

      case 'UG':
        markdownMessages.push({
          message: messages.testing_marker_testosterone_ug,
          values: {
            value: value
          }
        });
    }

    return markdownMessages;
  },

  /*
  'testing/pathology/biopsy': (eventData, intl) => { // eslint-disable-line no-unused-vars
       const markdownMessages = [];
       const cancerFound = eventData.get('cancer-found');
      const noCancer = cancerFound && cancerFound !== 'YES';
       if (noCancer) {
           markdownMessages.push({
              message: messages.testing_pathology_biopsy_cancer_no
          });
           const highGradePin = eventData.get('high-grade-pin');
          if (highGradePin) {
              markdownMessages.push(mapValueToMessageBundle(highGradePin, MAP_TESTING_PATHOLOGY_BIOPSY_HGPIN_TO_MESSAGE));
          }
           const asap = eventData.get('atypical-small-acinar-proliferation');
          if (asap) {
              markdownMessages.push(mapValueToMessageBundle(asap, MAP_TESTING_PATHOLOGY_BIOPSY_ASAP_TO_MESSAGE));
          }
       } else { // cancer found
           const gleasonType = eventData.get('gleason/gleason-type');
           if (gleasonType === 'SINGLE_VALUE') {
               const singleValue = eventData.get('gleason/single-value');
              if (singleValue === 'ROMAN_NUMBER') {
                   const sumScale = eventData.get('gleason/sum-scale');
                  if (sumScale) {
                      markdownMessages.push({
                          message: messages.testing_pathology_biopsy_cancer_yes_sum,
                          values: {
                              sumScale: sumScale
                          }
                      });
                  }
               } else if (singleValue === 'ARABIC_NUMBER') {
                   const riskGroup = eventData.get('gleason/risk-group');
                  if (riskGroup) {
                       markdownMessages.push({
                          message: messages.testing_pathology_biopsy_cancer_yes_risk,
                          values: {
                              riskGroup: riskGroup
                          }
                      });
                   }
               }
           } else if (gleasonType === 'TWO_VALUES') {
               const primary = eventData.get('gleason/two-values/primary');
              const secondary = eventData.get('gleason/two-values/secondary');
              const tertiary = eventData.get('gleason/tertiary/value');
               markdownMessages.push({
                  message: messages.testing_pathology_biopsy_cancer_yes_score,
                  values: {
                      primary, secondary, tertiary: tertiary || '-', score: gleasonSum(primary, secondary)
                  }
              });
           }
       }
       return markdownMessages;
   },
   'testing/imaging': (eventData, intl) => {
       const type = eventData.get('type');
      const metastasisFound = eventData.get('metastasis-found');
       const markdownMessages = [];
       if (type) {
          markdownMessages.push({
              message: messages.testing_imaging_type,
              values: {
                  kind: formatMessageBundle(mapValueToMessageBundle(type, MAP_TESTING_IMAGING_TYPE_TO_MESSAGE), intl)
              }
          });
      }
       if (metastasisFound) {
          markdownMessages.push(mapValueToMessageBundle(metastasisFound, MAP_TESTING_IMAGING_METASTASIS_FOUND_TO_MESSAGE));
      }
       // @TODO: metastasis-state
       // @TODO: metastasis-location
       // @TODO: metastasis-location-bone
       // @TODO: metastasis-location-organ
       return markdownMessages;
   },
  */
  'therapy/conservative/active-surveillance': function therapyConservativeActiveSurveillance(eventData, intl) {
    // eslint-disable-line no-unused-vars
    var date = eventData.date();
    var isOngoing = !date.range.to;
    var message = isOngoing ? messages.therapy_conservative_active_surveillance_ongoing : messages.therapy_conservative_active_surveillance_ended;
    return {
      message: message,
      values: {}
    };
  },
  'therapy/conservative/watchful-waiting': function therapyConservativeWatchfulWaiting(eventData, intl) {
    // eslint-disable-line no-unused-vars
    var date = eventData.date();
    var isOngoing = !date.range.to;
    var message = isOngoing ? messages.therapy_conservative_watchful_waiting_ongoing : messages.therapy_conservative_watchful_waiting_ended;
    return {
      message: message,
      values: {}
    };
  }
  /*
      'therapy/local/other': (eventData, intl) => { // eslint-disable-line no-unused-vars
           const type = eventData.get('type');
           return mapValueToMessageBundle(type, MAP_THERAPY_LOCAL_OTHER_TYPE_TO_MESSAGE);
       },
       'therapy/local/radiation': (eventData, intl) => { // eslint-disable-line no-unused-vars
           const locations = eventData.get('location');
          const dose = eventData.get('dose');
           const markdownMessages = [];
           if (dose) {
              markdownMessages.push({
                  message: messages.therapy_local_radiation_dose,
                  values: {
                      dosis: dose
                  }
              });
          }
           if (locations && locations.length > 0) {
              locations.forEach(l => {
                  markdownMessages.push(mapValueToMessageBundle(l, MAP_THERAPY_LOCAL_RADIATION_LOCATION_TO_MESSAGE));
              });
          }
           return markdownMessages;
       },
       'therapy/local/surgery': (event, intl) => { // eslint-disable-line no-unused-vars
           const type = event.typeId();
          const tumorFound = event.get('tumor-found') === 'YES' || type === 'RADICAL_PROSTECTOMY';
           const markdownMessages = [];
           if (type) {
              markdownMessages.push(mapValueToMessageBundle(type, MAP_THERAPY_LOCAL_SURGERY_TYPE_TO_MESSAGES));
          }
           if (tumorFound) {
               const gleason = {
                  primary: event.get('gleason/two-values/primary'),
                  secondary: event.get('gleason/two-values/secondary'),
                  tertiary: event.get('gleason/tertiary/value'),
                  percentages: {
                      primary: event.get('gleason/percentages/primary'),
                      secondary: event.get('gleason/percentages/secondary'),
                      tertiary: event.get('gleason/percentages/tertiary'),
                  }
              };
               if (gleason.primary && gleason.secondary) {
                   const outputCompliantPercentage = (value) => {
                      if (value >= 0) {
                          return value / 100;
                      }
                      return undefined;
                  };
                   const getMessage = ({primary, secondary, tertiary}) => {
                      if (primary >= 0) {
                          if (secondary >= 0) {
                              if (tertiary >= 0) {
                                  return messages.therapy_local_surgery_gleason_with_percentages;
                              }
                              return messages.therapy_local_surgery_gleason_with_percentages_no_tertiary;
                          }
                          return messages.therapy_local_surgery_gleason_with_percentages_primary_only;
                      }
                      return messages.therapy_local_surgery_gleason_score;
                  };
                   markdownMessages.push({
                      message: getMessage(gleason.percentages),
                      values: {
                          primary: gleason.primary,
                          secondary: gleason.secondary,
                          tertiary: gleason.tertiary || '-',
                          score: gleasonSum(gleason.primary, gleason.secondary),
                          primaryPercentage: outputCompliantPercentage(gleason.percentages.primary),
                          secondaryPercentage: outputCompliantPercentage(gleason.percentages.secondary),
                          tertiaryPercentage: outputCompliantPercentage(gleason.percentages.tertiary)
                      }
                  });
               }
               const tumorState = event.get('tumor-state');
               if (tumorState) {
                   markdownMessages.push({
                      message: messages.therapy_local_surgery_tumor_state,
                      values: {
                          tumorState: formatMessageBundle(mapValueToMessageBundle(tumorState, MAP_THERAPY_LOCAL_SURGERY_TUMOR_STATE_TO_MESSAGES), intl)
                      }
                  });
               }
               // @TODO: Add text for remoteMetastasisState
              //const remoteMetastasisState =  eventData.get('remote-metastasis-state');
               // @TODO: Add text for remoteMetastasisLocation
              //const remoteMetastasisLocation =  eventData.get('remote-metastasis-location');
               // @TODO: Add text for lymphNodeState
              //const lymphNodeState =  eventData.get('lymph-node-state');
               // @TODO: Add text for depositionMargin
              //const depositionMargin =  eventData.get('deposition-margin');
               // @TODO: Add text for highGradePin
              //const highGradePin =  eventData.get('high-grade-pin');
               // @TODO: Add text for lymphVesselInvasion
              //const lymphVesselInvasion =  eventData.get('lymph-vessel-invasion');
               // @TODO: Add text for veinInvasion
              //const veinInvasion =  eventData.get('vein-invasion');
               // @TODO: Add text for perineuralInvasion
              //const perineuralInvasion =  eventData.get('perineural-invasion');
           }
           return markdownMessages;
       },
       'therapy/systemic': (eventData, intl) => { // eslint-disable-line no-unused-vars
           const date = eventData.date();
          const isOngoing = !date.range.to;
           const markdownMessages = [];
           const isOngoingMessage = isOngoing
              ? messages.therapy_systemic_ongoing
              : messages.therapy_systemic_ended;
           markdownMessages.push({
              message: isOngoingMessage,
              values: {}
          });
           return markdownMessages;
       },
  */

};
var STRING_MAPPINGS = {
  'therapy/systemic': function therapySystemic(eventData, intl, options) {
    var eventTypeId = eventData.typeId(); // special handling to resolve the drug name

    if (eventTypeId === 'therapy/systemic') {
      var drugId = eventData.get('drug/id');
      var core = getEventCore(eventData);

      var _eventTypeId = eventData.typeId();

      var eventSchema = eventData.eventSchema();

      if (drugId !== 'DONT_KNOW') {
        var drugInfo = eventData.eventData().find(function (d) {
          return d.progether_id === drugId;
        });

        if (!drugInfo) {
          console.error('unknown drug id', drugId, 'in event', eventData);
          return '__?__';
        }

        var active_ingredient = drugInfo.active_ingredient,
            user = drugInfo.group.user;
        var translations = eventData.eventTranslations();
        var activeIngredient = translations[intl.locale]['active-ingredient'][active_ingredient];
        var userGroup = translations[intl.locale]['user-group'][user];
        var message = {
          id: messageIdGenerators.eventFieldLabel(_eventTypeId, 'drug/id')
        };
        var label = intl.formatMessage(message);

        var remainingFields = _eventToMarkdownToString(core, eventSchema.fields.filter(function (f) {
          return !['drug'].includes(f.id);
        }), intl, _eventTypeId, options);

        return "_".concat(label, ":_ __").concat(activeIngredient, " (").concat(userGroup, ")__\n\n") + remainingFields;
      } else {
        return _eventToMarkdownToString(core, eventSchema.fields.filter(function (f) {
          return !['drug'].includes(f.id);
        }), intl, _eventTypeId, Object.assign({}, options, {
          valueMapping: valueMapping
        }));
      }
    }

    function valueMapping(eventTypeId, fieldId, fieldValue) {
      var FIELD_TO_TRANSLATION_MAPPING = {
        'group-user': 'user-group'
      };

      if (/^_OTHER_/.test(fieldValue)) {
        return '__' + fieldValue.substr(7) + '__';
      }

      if (['group-user', 'dosage-form', 'dosage-frequency', 'active-ingredient'].includes(fieldId)) {
        var _translations = eventData.eventTranslations();

        var key = FIELD_TO_TRANSLATION_MAPPING[fieldId] || fieldId;
        return '__' + _translations[intl.locale][key][fieldValue] + '__';
      } else {
        return false;
      }
    }
  }
};
/**
 * Convert a given eventData object to a markdown string.
 *
 * @param {object} event - The event to convert to markdown
 * @param {object} intl - The (progether-server-intl) object containing the language context
 * @param {boolean} [includeComment=true] - If the comment should be included
 * @param {boolean} [onlyMandatory=false] - If only mandatory fields should be returned
 * @returns {string} A string representation of the eventData object using markdown
 */

export function eventDataToString(event, intl) {
  var includeComment = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var onlyMandatory = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  if (!event) {
    console.error('eventDataToString requires valid event', event);
    return '-';
  }

  var eventTypeId = event.typeId();
  var messageMapping = getMessageMapping(eventTypeId);

  if (messageMapping) {
    return messageMappingToString(messageMapping);
  }

  var stringMapping = getStringMapping(eventTypeId);

  if (stringMapping) {
    return stringMapping(event, intl);
  }

  console.info("No mapping found for event type ".concat(eventTypeId, ", fallback to eventToMarkdown")); // provide the raw data (in English)

  return _eventToMarkdown(event, intl, includeComment, onlyMandatory);

  function messageMappingToString(messageMapping) {
    // message maps expect to always receive mandatory complete events
    if (event.completeness().mandatory.score < 1) {
      console.info('eventDataToString on mandatory incomplete event', event.completeness(), event.toJSON());
      return intl.formatMessage(messages.incompleteEvent);
    }

    try {
      var markdownMessages = messageMapping(event, intl);
      var output = '';

      if (Array.isArray(markdownMessages)) {
        if (markdownMessages.length > 1) {
          // return a markdown list
          output = markdownMessages.map(function (mapping) {
            return formatMessageBundle(mapping, intl);
          }).filter(function (formattedMessage) {
            return formattedMessage !== undefined;
          }).map(function (formattedMessage) {
            // one list item per message mapping
            return "* ".concat(formattedMessage);
          }).join('\n');
        } else if (markdownMessages.length === 1) {
          output = formatMessageBundle(markdownMessages[0], intl) || '';
        } else {
          output = '';
        }
      } else {
        output = formatMessageBundle(markdownMessages, intl) || '';
      }

      if (includeComment) {
        try {
          var comment = event.get('comment');

          if (comment) {
            output += "\n\n".concat(intl.formatMessage(messages.comment, {
              comment: comment
            }));
          }
        } catch (e) {// Not all eventTypes include a "comment" field. They can be ignored
        }
      }

      return output;
    } catch (e) {
      console.error('Cannot convert event to markdown', event.id(), event.typeId(), e.message, e);
      return intl.formatMessage(messages.eventConversionFailed);
    }
  }
}

function getMessageMapping(typeId) {
  if (Object.prototype.hasOwnProperty.call(MESSAGE_MAPPINGS, typeId)) {
    return MESSAGE_MAPPINGS[typeId];
  }
}

function getStringMapping(typeId) {
  if (Object.prototype.hasOwnProperty.call(STRING_MAPPINGS, typeId)) {
    return STRING_MAPPINGS[typeId];
  }
}

export function typeIdToString(typeId, intl) {
  try {
    var formTitle = messageIdGenerators.eventFormTitle(typeId);

    if (formTitle) {
      return intl.formatMessage({
        id: formTitle,
        defaultMessage: typeId
      });
    } else if (Object.prototype.hasOwnProperty.call(MAP_EVENT_TYPE_ID_TO_MESSAGES, typeId)) {
      return formatMessageBundle(mapValueToMessageBundle(typeId, MAP_EVENT_TYPE_ID_TO_MESSAGES), intl);
    }
  } catch (e) {
    console.error("Missing EVENT_TYPE_ID \"".concat(typeId, "\" in MAP_EVENT_TYPE_ID_TO_MESSAGES"));
  }

  return typeId;
}
/**
 * Extract the typeId from the provided event and return
 * a localized text for the type
 *
 * @param eventData
 * @param intl
 * @returns {string} with the localized text for the type
 */

export function eventTypeIdToString(eventData, intl) {
  var eventTypeId = getEventType(eventData);
  return typeIdToString(eventTypeId, intl);
}
/**
 * Automatically convert any event to markdown based on the
 * event schema. Will use the same message objects as the
 * input form and also use the same item ordering.
 *
 * @param event
 * @param intl
 * @param includeComment
 * @param onlyMandatory
 * @returns {string}
 */

function _eventToMarkdown(event, intl) {
  var includeComment = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var onlyMandatory = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  if (!event) {
    return undefined;
  }

  if (!(event instanceof Event)) {
    return undefined;
  }

  var eventTypeId = event.typeId();
  var eventSchema = event.eventSchema();
  var core = getEventCore(event);
  return _eventToMarkdownToString(core, eventSchema.fields, intl, eventTypeId, {
    includeComment: includeComment,
    onlyMandatory: onlyMandatory
  });
}

var defaultOptions = {
  includeComment: false,
  onlyMandatory: false
};
/**
 * @name ValueMapping
 * @function
 * @param {string} eventTypeId - The type of the event this field belongs to
 * @param {string} fieldId - The field id
 * @param {any} fieldValue - The value of the field
 * @returns {string|boolean} - If this function return false, _eventToMarkdownToString will behave as if this function
 *  was not provided in the first place. If the return is anything else, it will be used as the return value.
 */

/**
 * Recursively convert an eventCore object into a string.
 *
 * @param {object} obj
 * @param {object[]} fields - The list of schema fields belonging to this obj
 * @param {object} intl - An (react-)intl instance
 * @param {string} eventTypeId
 * @param {boolean} [options.includeComment=false]
 * @param {boolean} [options.onlyMandatory=false]
 * @param {ValueMapping} [options.valueMapping] -
 * @param prefix
 * @param fieldIdPrefix
 * @returns {string|*|string}
 * @private
 */

function _eventToMarkdownToString(obj, fields, intl, eventTypeId) {
  var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : defaultOptions;
  var prefix = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : '';
  var fieldIdPrefix = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : '';

  if (!obj) {
    return '';
  }

  if (_typeof(obj) !== 'object') {
    return '__' + formatFieldValue(fieldIdPrefix, ('' + obj).trim()) + '__';
  }

  if (Array.isArray(obj)) {
    return obj.map(function (i) {
      return "\n".concat(prefix, "- ").concat(_eventToMarkdownToString(i, fields, intl, eventTypeId, options, prefix + '  ', fieldIdPrefix));
    }).join('\n');
  }

  if (!fields) {
    return '';
  } // iterate over fields instead of Object.keys(obj) to
  // enforce the same order as defined in the schema.


  var fieldsToShow = fields.filter(function (f) {
    return !options.onlyMandatory || f.def.mandatory;
  }).map(function (f) {
    return f.id;
  }) // do not include fields without values
  .filter(function (id) {
    return Object.keys(obj).includes(id);
  }) // do not include the date in the output (only filtered on top level)
  .filter(function (k) {
    return k !== 'date';
  }) // optionally remove comments
  .filter(function (k) {
    return options.includeComment || k !== 'comment';
  });

  if (fieldsToShow.length === 0) {
    return '__' + intl.formatMessage(messages.fieldNotSet) + '__';
  }

  return (prefix.length > 0 ? '\n' : '') + fieldsToShow // properties to string
  .map(function (k) {
    var messageFieldId = k.replace(/\//g, '_');

    if (prefix.length > 0) {
      return "".concat(prefix, "- ").concat(formatFieldId(messageFieldId), " ").concat(getValue());
    } else {
      return "".concat(formatFieldId(messageFieldId), " ").concat(getValue());
    }

    function getValue() {
      if (typeof options.valueMapping === 'function') {
        var valueMapping = options.valueMapping(eventTypeId, k, obj[k]);

        if (valueMapping !== false) {
          return valueMapping;
        }
      } // default behaviour


      var subFields = (fields.find(function (f) {
        return f.id === k;
      }) || {}).fields;
      return _eventToMarkdownToString(obj[k], subFields, intl, eventTypeId, options, prefix + '  ', messageFieldId);
    }
  }).join(prefix.length > 0 ? '\n' : '\n\n');

  function formatFieldValue(fieldId) {
    var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

    if (/^_OTHER_/.test(value)) {
      return value.substr(7);
    }

    var message = {
      id: messageIdGenerators.eventFieldValueLabel(eventTypeId, fieldId, value.toLowerCase()),
      defaultMessage: value
    };
    return intl.formatMessage(message);
  }

  function formatFieldId(fieldId) {
    var message = {
      id: messageIdGenerators.eventFieldLabel(eventTypeId, fieldId),
      defaultMessage: '«' + fieldId + '»'
    };
    var translatedKey = intl.formatMessage(message).trim();
    return "_".concat(translatedKey).concat(optionalColon(), "_");

    function optionalColon() {
      // some keys end on questionsmarks, in those cases
      // we do not want to add a colon. As there might be
      // different chars used as questionsmarks in different
      // languages, we only print a colon if the text ends
      // on something other than an alphanumeric character
      return /[^a-z0-9]$/.test(translatedKey) ? '' : ':';
    }
  }
}