import { useContext } from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import './App.less';
import { AppHeader } from './components/app-header/app-header';
import LoadingPlaceholder from './components/loading-placeholder';
import { EventContext, EventProvider } from './contexts/event.context';
import { I18nProvider } from './contexts/i18n.context';
 

function AppContent() {
  const { loading } = useContext(EventContext);

  return (
    <div id="app-content">
      <LoadingPlaceholder loading={loading}>
        <Outlet/>
      </LoadingPlaceholder>
    </div>
  )
}

function App({ storage, fallbackLocale = 'en' }) {
  const { locale = fallbackLocale } = useParams();
  const location = useLocation();

  // unknown locale defined, go back to start
  if (!['de', 'en', 'no'].includes(locale)) {
    return <Navigate to="/" replace/>
  }
  
  // legacy hash based routing, redirect to new path
  if (location.hash && location.hash.startsWith('#add-event:')) {
    const match = /^#add-event:(.+)/.exec(location.hash);
    const target = match
      ? `/add/${match[1]}`
      : '/add';

    return <Navigate to={target} replace/>
  }

  
  return (
    <I18nProvider locale={locale}>
      <EventProvider storage={storage}>
          <AppHeader/>
          <AppContent/>
        </EventProvider>
    </I18nProvider>
  );
}

export default App;
