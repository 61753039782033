import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {HelpMixin} from './help-mixin';

class check extends HelpMixin(Component) {

    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        label: PropTypes.node.isRequired,
        checked: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired
    };

    render() {
        let {label, value, onChange, checked, name} = this.props;
        let labelText = label || value;

        return (
            <div className={'checkbox' + (checked ? ' checked' : '')}
                onChange={onChange.bind(this, value)}>

                <label>
                    <input
                        type="checkbox"
                        name={name}
                        value={value}
                        checked={checked}/>
                    {labelText}
                </label>
                {this.renderHelp()}
                {this.renderHelpText()}
            </div>
        );
    }
}

export default injectIntl(check);
