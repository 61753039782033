import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import {
    dateToString,
    eventDataToString,
    eventTypeIdToString,
    getEventType as getEventTypeFromData,
} from 'progether-event-utils';
import { IfNoEvents } from './shared/if-no-events';
import { confirmDeletePopup } from './shared/event-delete-popover';
import { Conditional } from '../utils/conditional';
import './event-table.less';

const messages = defineMessages({
    timeHeader: {
        id: 'part_event_table_header_date_label',
        defaultMessage: 'Date',
        description: 'Event table header for the time column',
    },
    updatedHeader: {
        id: 'part_event_table_header_updated_label',
        defaultMessage: 'Updated',
        description: 'Event table header for the updated column',
    },
    typeHeader: {
        id: 'part_event_table_header_type_label',
        defaultMessage: 'Type',
        description: 'Event table header for the time column',
    },
    descHeader: {
        id: 'part_event_table_header_summary_label',
        defaultMessage: 'Description',
        description: 'Event table header for the description column',
    },
    deleteEventButtonLabel: {
        id: 'event_table_delete_event_button_label',
        defaultMessage: 'Delete',
        description: 'Label for the delete event button',
    },
    editEventButtonLabel: {
        id: 'event_table_edit_event_button_label',
        defaultMessage: 'Edit',
        description: 'Label for the delete event button',
    },
    eventTiming: {
        id: 'event_table_timing_text',
        defaultMessage: 'Last updated: {date}',
        description: 'An optional label to indicate the last update (relative) date',
    },
});

export const EVENT_TABLE_SHOW_UPDATED_OPTIONS_KEYS = { none: 'none', relative: 'relative', absolute: 'absolute' };
const EVENT_TABLE_SHOW_UPDATED_OPTIONS = Object.values(EVENT_TABLE_SHOW_UPDATED_OPTIONS_KEYS);

class EventTable extends React.Component {

    static propTypes = {
        events: PropTypes.arrayOf(
            PropTypes.object,
        ),
        onEventSelection: PropTypes.func,
        onEventDeletion: PropTypes.func,
        onCreateEvent: PropTypes.func,

        sortBy: PropTypes.oneOf([ 'time', 'type' ]),
        sortDirection: PropTypes.oneOf([ 'asc', 'desc' ]),
        onSort: PropTypes.func,

        loading: PropTypes.bool,
        showComments: PropTypes.bool,
        showHeader: PropTypes.bool,
        showTypeHeader: PropTypes.bool,
        showUpdated: PropTypes.oneOf(EVENT_TABLE_SHOW_UPDATED_OPTIONS),
        existingEventsCount: PropTypes.number,
        highlightUpdatedAfter: PropTypes.oneOfType([ PropTypes.string, PropTypes.instanceOf(Date) ]),
    };

    static defaultProps = {
        events: [],

        sortBy: 'time',
        sortDirection: 'desc',
        onSort: () => null,

        loading: false,
        showComments: false,
        showHeader: true,
        showTypeHeader: true,
        showUpdated: EVENT_TABLE_SHOW_UPDATED_OPTIONS_KEYS.none,
    };

    render() {

        const {
            events, onEventSelection, onEventDeletion,
            loading,
            sortBy, sortDirection, onSort,
            showComments, showHeader, showTypeHeader, showUpdated,
            highlightUpdatedAfter,
        } = this.props;

        if (events.length === 0 || loading) {
            return (
                <IfNoEvents {...this.props} />
            );
        }

        return (
            <div className="event-table-responsive">
                <Conditional cond={showHeader}>
                    <ResponsiveHeaderRow sortBy={sortBy} sortDirection={sortDirection} onSort={onSort}
                                         showUpdated={showUpdated} showTypeHeader={showTypeHeader}/>
                </Conditional>
                <IfNoEvents {...this.props} />
                {events.map(e => (
                    <ResponsiveEventRow
                        key={e.id()}
                        event={e}
                        showComment={showComments}
                        showUpdated={showUpdated}
                        highlightUpdatedAfter={highlightUpdatedAfter}
                        onSelection={onEventSelection}
                        onDeletion={onEventDeletion}
                    />
                ))}
            </div>
        );
    }
}

export default EventTable;

function ResponsiveHeaderRow(props) {

    const {
        sortBy,
        sortDirection = 'asc',
        onSort,
        showUpdated = EVENT_TABLE_SHOW_UPDATED_OPTIONS_KEYS.none,
        showTypeHeader = true,
    } = props;

    return (
        <div className="event-table-header">
            <div className="event-table-meta-infos">
                <div className="event-table-meta-info">
                    <FormattedMessage {...messages.timeHeader}>
                        {txt => (
                            <span className={classesForField('time')}
                                  onClick={() => onSort('time', toggleSortDirection('time'))}>{txt}</span>
                        )}
                    </FormattedMessage>
                    {
                        showUpdated && showUpdated !== EVENT_TABLE_SHOW_UPDATED_OPTIONS_KEYS.none
                            ? (
                                <FormattedMessage {...messages.updatedHeader}>
                                    {txt => (
                                        <span className={classesForField('updated')}
                                              onClick={() => onSort('updated', toggleSortDirection('updated'))}>
                                            {txt}
                                        </span>
                                    )}
                                </FormattedMessage>
                            )
                            : null
                    }
                </div>

                <Conditional cond={showTypeHeader}>
                    <FormattedMessage {...messages.typeHeader}>
                        {txt => (
                            <div
                                className={classesForField('type')}
                                onClick={() => onSort('type', toggleSortDirection('type'))}
                            >
                                {txt}
                            </div>
                        )}
                    </FormattedMessage>
                </Conditional>
            </div>
            <div className="event-table-text">
                <FormattedMessage {...messages.descHeader}>
                    {txt => (
                        <div>{txt}</div>
                    )}
                </FormattedMessage>
            </div>
        </div>
    );

    function toggleSortDirection(fieldName) {
        if (fieldName === sortBy) {
            return (sortDirection === 'asc') ? 'desc' : 'asc';
        }
        return 'asc';
    }

    function classesForField(fieldName) {
        return classnames('sortable', 'event-table-meta-info', {
            'asc': sortBy === fieldName && sortDirection === 'asc',
            'desc': sortBy === fieldName && sortDirection === 'desc',
        });
    }
}

ResponsiveHeaderRow.propTypes = {
    onSort: PropTypes.func,
    sortBy: PropTypes.string,
    sortDirection: PropTypes.oneOf([ 'asc', 'desc' ]),
    showUpdated: PropTypes.oneOf(EVENT_TABLE_SHOW_UPDATED_OPTIONS),
    showTypeHeader: PropTypes.bool,
};

const ResponsiveEventRow = injectIntl(class extends React.Component {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        event: PropTypes.object.isRequired,
        showComment: PropTypes.bool,
        showUpdated: PropTypes.oneOf(EVENT_TABLE_SHOW_UPDATED_OPTIONS),
        onSelection: PropTypes.func,
        onDeletion: PropTypes.func,
        highlightUpdatedAfter: PropTypes.oneOfType([ PropTypes.string, PropTypes.instanceOf(Date) ]),
    };

    static defaultProps = {
        showComment: false,
        showUpdated: EVENT_TABLE_SHOW_UPDATED_OPTIONS_KEYS.none,
    };

    state = {
        toBeDeleted: false,
    };

    eventUpdateDate(event) {
        const { showUpdated, intl: { locale } } = this.props;

        if (!showUpdated || showUpdated === EVENT_TABLE_SHOW_UPDATED_OPTIONS_KEYS.none) {
            return null;
        }

        return (
            <FormattedMessage {...messages.eventTiming} values={{ date: getDate(event) }}>
                {txt => <span className="event-table-entry-updated">{txt}</span>}
            </FormattedMessage>
        );

        function getDate(event) {
            if (showUpdated === EVENT_TABLE_SHOW_UPDATED_OPTIONS_KEYS.absolute) {
                return event.updated().locale(locale).format('L');
            } else if (showUpdated === EVENT_TABLE_SHOW_UPDATED_OPTIONS_KEYS.relative) {
                return event.updated().fromNow();
            }
        }

    }

    render() {
        const { intl, event, onSelection, showComment, highlightUpdatedAfter } = this.props;
        const { toBeDeleted } = this.state;
        const typeHierarchy = this.getEventTypeCssHierarchy(getEventTypeFromData(event));

        const classes = classnames('event-table-entry', typeHierarchy, {
            'deletion-ongoing': toBeDeleted,
            'event-table-entry-selectable': !!onSelection,
            'event-table-entry-updated-after': event.updated().isAfter(highlightUpdatedAfter),
        });

        return (
            <div className={classes} onClick={this.handleSelection}>
                <div className="event-table-meta-infos">
                    <div className="event-table-meta-info">
                        {dateToString(event, intl)}
                        {this.eventUpdateDate(event)}
                    </div>
                    <ReactMarkdown
                        source={eventTypeIdToString(event, intl)}
                        escapeHtml
                        containerTagName="div"
                        className="event-table-meta-info"
                    />
                </div>
                <ReactMarkdown
                    source={eventDataToString(event, intl, showComment)}
                    escapeHtml
                    containerTagName="div"
                    className="event-table-text"
                />

                {this.renderOptions()}
            </div>
        );
    }

    renderOptions() {
        const { onDeletion, onSelection } = this.props;

        const options = [];
        if (onDeletion) {
            options.push(
                <a
                    key="delete"
                    className="event-table-meta-option event-table-meta-delete-option"
                    onClick={this.handleDeletion}
                >
                    <FormattedMessage {...messages.deleteEventButtonLabel}>
                        {txt => <span className="event-table-meta-option-label">{txt}</span>}
                    </FormattedMessage>
                    <i className="fa fa-times"/>
                </a>,
            );
        }

        if (onSelection) {
            options.push(
                <a key="edit" className="event-table-meta-option event-table-meta-edit-option">
                    <FormattedMessage {...messages.editEventButtonLabel}>
                        {txt => <span className="event-table-meta-option-label">{txt}</span>}
                    </FormattedMessage>
                    <i className="fa fa-pencil"/>
                </a>,
            );
        }

        if (!options.length) {
            return null;
        }

        return (
            <div className="event-table-meta-options">
                {options}
            </div>
        );
    }

    handleSelection = () => {
        const { onSelection, event } = this.props;
        const { toBeDeleted } = this.state;

        if (!toBeDeleted) {
            onSelection(event);
        }
    };

    handleDeletion = evt => {
        evt.preventDefault();
        evt.stopPropagation();

        const { event, intl, onDeletion } = this.props;

        confirmDeletePopup({
            id: `delete-event-${event.id()}`,
            confirmation: intl.formatMessage({ id: 'event_delete_confirm_dialog_text' }),
            title: intl.formatMessage({ id: 'event_delete_confirm_dialog_title' }),
            okLabel: intl.formatMessage({ id: 'event_delete_confirm_dialog_button_yes' }),
            cancelLabel: intl.formatMessage({ id: 'event_delete_confirm_dialog_button_no' }),
            placement: 'left',
            element: evt.target,
            height: 190,
        }).then(
            // confirmed
            () => this.setState({ toBeDeleted: true }, () => onDeletion(event)),
            // aborted
            () => null,
        );
    };

    getEventTypeCssHierarchy(typeId = '') {

        const asArray = typeId.split('/');
        const eventTypeClasses = [];

        for (let i = 1; i < asArray.length; i++) {
            eventTypeClasses.push('event-type-' + asArray.slice(0, i).join('-'));
        }

        return eventTypeClasses.join(' ');

    }


});
