import React from 'react';

import {FormattedMessage} from 'react-intl';
import {eventFieldValueHelp, eventFieldValueLabel} from '../../../lib/intl-helper';

/* eslint-disable react/prop-types */
export const FormsMixin = SuperClass => class _FormsMixin extends SuperClass {

    update(def, id, value) {
        this.props.onChange(id, value);
    }

    getDefaultValue() {
        if (typeof this.props.def.defaultValue === 'function') {
            return this.props.def.defaultValue();
        }
        return this.props.def.defaultValue;
    }

    getValueOfField(fieldId) {
        return this.props.eventData.get(fieldId);
    }

    getValue() {
        let value = this.props.eventData.get((this.props.field && this.props.field.id) || this.props.id);

        if (value === undefined || value === null) {
            return this.getDefaultValue();
        }

        return value;
    }

    getSubfield(id, field) {
        let fields = (field && field.type && field.type.fields) || this.props.fields || null;

        if (!fields) return undefined;

        const prefix = (field && field.type && field.type.id) || null;

        return fields.find(f => {
            const targetId = prefix ? prefix + '/' + id : id;
            const fieldId = (f.type && f.type.id) || (f.id) || undefined;

            return fieldId === targetId;
        });
    }

    transformDefValuesOfField(def, id) {
        // since the schema is frozen and we need to remap the values property,
        // we have to clone the definition here
        def = JSON.parse(JSON.stringify(def));

        // manipulate def values
        if (def.values && Array.isArray(def.values)) {
            let uniqueValues = [];
            def.values = def.values.map(value => {
                if (typeof value === 'string' || typeof value === 'boolean' || typeof value === 'number') {
                    const helpMsgId = eventFieldValueHelp(this.props.eventId, id, value);

                    if (uniqueValues.indexOf(value) !== -1) {
                        console.warn('value defined twice in def', def, value);
                    }

                    uniqueValues.push(value);

                    return {
                        label: '' + value,
                        value: value,
                        translate: true,
                        help: helpMsgId || null,
                        labelFormatted: (<FormattedMessage
                            id={eventFieldValueLabel(this.props.eventId, id, ('' + value).toLocaleLowerCase())}/>)
                    };
                }
                if (typeof value === 'object' && value.hasOwnProperty('value')) {
                    const helpMsgId = eventFieldValueHelp(this.props.eventId, id, value.value);

                    if (uniqueValues.indexOf(value.value) !== -1) {
                        console.warn('value defined twice in def', def, value.value);
                    }
                    uniqueValues.push(value.value);

                    let doTranslate = true;

                    if (value.hasOwnProperty('translate')) {
                        doTranslate = value.translate;
                    }

                    return {
                        label: value.label || '' + value.value,
                        value: value.value,
                        help: value.help || helpMsgId || null,
                        translate: doTranslate,
                        labelFormatted: doTranslate ? (<FormattedMessage
                            id={eventFieldValueLabel(this.props.eventId, id, ('' + value.value).toLocaleLowerCase())}/>) : null
                    };
                }
                console.warn('def value with wrong format, neither string nor object(label, value)', def, value);
            });
        }

        return def;
    }
};
