import { defineMessages } from 'progether-messages'; // ---------------------------------------------
// Define mappings from event field values to
// messages here. Allowing for easy extension
// ---------------------------------------------

export var MAP_TESTING_MARKER_PSA_VALUE_MODIFIER_TO_MESSAGE = defineMessages({
  GT: {
    "id": "event_detail_view_sampling_psa_type_GT",
    "defaultMessage": "greater than (>)"
  },
  LT: {
    "id": "event_detail_view_sampling_psa_type_LT",
    "defaultMessage": "less than (<)"
  }
});
export var MAP_TESTING_IMAGING_TYPE_TO_MESSAGE = defineMessages({
  SCINTIGRAPHY: {
    "id": "event_diagnosis_imaging_type_bone_scintigraphy",
    "defaultMessage": "Bone scan"
  },
  CT: {
    "id": "event_diagnosis_imaging_type_computed_tomography",
    "defaultMessage": "CT"
  },
  MRI: {
    "id": "event_diagnosis_imaging_type_magnetic_resonance_imaging",
    "defaultMessage": "MRI"
  },
  'PET-CT': {
    "id": "event_diagnosis_imaging_type_pet_ct",
    "defaultMessage": "PET-CT"
  },
  PET_MRI: {
    "id": "event_markdown_testing_imaging_type_pet_mri",
    "defaultMessage": "PET MRI"
  },
  PSMA_PET: {
    "id": "event_markdown_testing_imaging_type_psma_pet",
    "defaultMessage": "PSMA PET"
  },
  SONOGRAPHY: {
    "id": "event_markdown_testing_imaging_type_sonography",
    "defaultMessage": "Sonography"
  },
  X_RAY: {
    "id": "event_markdown_testing_imaging_type_xray",
    "defaultMessage": "X-Ray"
  },
  SPECT: {
    "id": "event_markdown_testing_imaging_type_spect",
    "defaultMessage": "SPECT"
  }
});
export var MAP_TESTING_IMAGING_METASTASIS_FOUND_TO_MESSAGE = defineMessages({
  YES: {
    "id": "event_detail_view_diagnosis_imaging_metastasis_state_found",
    "defaultMessage": "Metastasis have been found"
  },
  SUSPICION: {
    "id": "event_detail_view_diagnosis_imaging_metastasis_state_suspicion",
    "defaultMessage": "Suspicion of metastasis"
  },
  NO: {
    "id": "event_detail_view_diagnosis_imaging_metastasis_state_none",
    "defaultMessage": "No metastasis have been found"
  },
  DONT_KNOW: {
    "id": "event_detail_view_diagnosis_imaging_metastasis_state_unknown",
    "defaultMessage": "You said you are unsure whether metastasis have been found or not"
  }
});
export var MAP_THERAPY_LOCAL_OTHER_TYPE_TO_MESSAGE = defineMessages({
  HYPERTHERMIE: {
    "id": "event_therapy_ft_type_hyperthermie",
    "defaultMessage": "Heat therapy (hyperthermia)"
  },
  HIFU: {
    "id": "event_therapy_ft_type_hifu",
    "defaultMessage": "HIFU"
  },
  KRYO: {
    "id": "event_therapy_ft_type_kryo",
    "defaultMessage": "Cryotherapy"
  },
  TOOKAD: {
    "id": "event_therapy_ft_type_tookad",
    "defaultMessage": "TOOKAD"
  },
  NANO: {
    "id": "event_therapy_ft_type_nanoknife",
    "defaultMessage": "Nanoknife"
  }
});
export var MAP_THERAPY_LOCAL_RADIATION_LOCATION_TO_MESSAGE = defineMessages({
  PROSTATE: {
    "id": "event_therapy_radiation_location_prostate",
    "defaultMessage": "Radiation treatment of the prostate (External beam radiation, Proton Beam, Brachytherapy \"seeds\", etc.)"
  },
  SALVAGE: {
    "id": "event_therapy_radiation_location_prostate_removal",
    "defaultMessage": "Radiation of the prostate region after prostatectomy (removal of the prostate)"
  },
  LYMPH: {
    "id": "event_therapy_radiation_location_lymph_node",
    "defaultMessage": "Lymph nodes"
  },
  BONE: {
    "id": "event_therapy_radiation_location_bone",
    "defaultMessage": "Bone"
  }
});
export var MAP_THERAPY_LOCAL_SURGERY_TYPE_TO_MESSAGES = defineMessages({
  RADICAL_PROSTECTOMY: {
    "id": "event_therapy_op_name_therapy_radical",
    "defaultMessage": "Radical prostatectomy (complete removal of the prostate for prostate cancer)"
  },
  RESECTION: {
    "id": "event_therapy_op_name_therapy_resection",
    "defaultMessage": "Transurethral resection (surgery through the urethra, TURP) or other procedure for benign prostate enlargement (BPH)"
  },
  OPEN: {
    "id": "event_therapy_op_name_therapy_open",
    "defaultMessage": "Open surgery for benign prostatic enlargement (BPH)"
  },
  METASTASES_ORGAN: {
    "id": "event_therapy_op_name_therapy_metastases_organ",
    "defaultMessage": "Surgery of organ metastasis (for example, liver or lung)"
  },
  METASTASES_BONE: {
    "id": "event_therapy_op_name_therapy_metastases_bone",
    "defaultMessage": "Surgery of bone metastasis"
  },
  METASTASES_LYMPH: {
    "id": "event_therapy_op_name_therapy_metastases_lymph",
    "defaultMessage": "Surgery of lymph node metastasis (as an independent surgery)"
  },
  ORCHIDEKTOMIE: {
    "id": "event_therapy_op_name_therapy_orchidektomie",
    "defaultMessage": "Removal of the testicles (orchiectomy)"
  }
});
export var MAP_THERAPY_LOCAL_SURGERY_TUMOR_STATE_TO_MESSAGES = defineMessages({
  PT1A: {
    "id": "event_diagnosis_histology_tumor_stage_pt1a",
    "defaultMessage": "pT1a"
  },
  PT1B: {
    "id": "event_diagnosis_histology_tumor_stage_pt1b",
    "defaultMessage": "pT1b"
  },
  PT2A: {
    "id": "event_diagnosis_histology_tumor_stage_pt2a",
    "defaultMessage": "pT2a"
  },
  PT2B: {
    "id": "event_diagnosis_histology_tumor_stage_pt2b",
    "defaultMessage": "pT2b"
  },
  PT2C: {
    "id": "event_diagnosis_histology_tumor_stage_pt2c",
    "defaultMessage": "pT2c"
  },
  PT3A: {
    "id": "event_diagnosis_histology_tumor_stage_pt3a",
    "defaultMessage": "pT3a"
  },
  PT3B: {
    "id": "event_diagnosis_histology_tumor_stage_pt3b",
    "defaultMessage": "pT3b"
  },
  PT4: {
    "id": "event_diagnosis_histology_tumor_stage_pt4",
    "defaultMessage": "pT4"
  }
});
export var MAP_EVENT_TYPE_ID_TO_MESSAGES = defineMessages({
  'testing/marker/psa': {
    "id": "event_markdown_testing_marker_psa_typeid",
    "defaultMessage": "PSA"
  },
  'testing/marker/testosterone': {
    "id": "event_markdown_testing_marker_testosterone_typeid",
    "defaultMessage": "Testosterone"
  },
  'testing/pathology/biopsy': {
    "id": "event_markdown_testing_pathology_biopsy_typeid",
    "defaultMessage": "Biopsy"
  },
  'testing/imaging': {
    "id": "event_markdown_testing_imaging_typeid",
    "defaultMessage": "Imaging"
  },
  'therapy/conservative/active-surveillance': {
    "id": "event_markdown_therapy_conservative_activesurveillance_typeid",
    "defaultMessage": "Active Surveillance"
  },
  'therapy/conservative/watchful-waiting': {
    "id": "event_markdown_therapy_conservative_watchfulwaiting_typeid",
    "defaultMessage": "Watchful waiting"
  },
  'therapy/local/other': {
    "id": "event_markdown_therapy_local_other_typeid",
    "defaultMessage": "Other"
  },
  'therapy/local/radiation': {
    "id": "event_markdown_therapy_local_radiation_typeid",
    "defaultMessage": "Radiation"
  },
  'therapy/local/surgery': {
    "id": "event_markdown_therapy_local_surgery_typeid",
    "defaultMessage": "Surgery"
  },
  'therapy/systemic': {
    "id": "event_markdown_therapy_systemic_typeid",
    "defaultMessage": "Medication"
  }
});
export var MAP_TESTING_PATHOLOGY_BIOPSY_HGPIN_TO_MESSAGE = defineMessages({
  'YES': {
    "id": "event_markdown_testing_pathology_biopsy_hgpin_yes",
    "defaultMessage": "**High grade PIN** (prostatic intraepithelial neoplasia) diagnosed."
  } //'NO': undefined, // do not report the negative case
  //'DONT_KNOW': undefined // we also do not report the unknown case

});
export var MAP_TESTING_PATHOLOGY_BIOPSY_ASAP_TO_MESSAGE = defineMessages({
  'YES': {
    "id": "event_markdown_testing_pathology_biopsy_asap_yes",
    "defaultMessage": "**ASAP** (atypical small acinar proliferation) diagnosed."
  } //'NO': undefined, // do not report the negative case
  //'DONT_KNOW': undefined // we also do not report the unknown case

});