import PDFDocument from 'pdfkit';
import { eventFromJSON } from 'progether-data-schema';
import * as EventUtils from 'progether-event-utils';
import { defineMessages } from 'react-intl';
import SVGtoPDF from 'svg-to-pdfkit';
import moment from 'moment';
import * as d3 from '../d3-package';
import renderHeadline from './headline';
import { renderMultiPageFullWidthTable } from './table';
import * as FooterRenderer from './template/mydata/footer';
import * as HeaderRenderer from './template/mydata/header';
import { formatToPageSize, languageToPageFormat } from './utils/page-layout';
import psaChart from './utils/progether-charts/psa';
import timelineChart from './utils/progether-charts/timeline';
import './register-static-files';

export const messages = defineMessages({
    psaChartHeadline: {
        id: 'print_mydata_psachart_headline',
        defaultMessage: 'My PSA Values',
    },
    testingChartHeadline: {
        id: 'print_mydata_testingchart_headline',
        defaultMessage: 'Tests',
    },
    therapyChartHeadline: {
        id: 'print_mydata_therapychart_headline',
        defaultMessage: 'Therapies',
    },
    tableDate: {
        id: 'print_mydata_table_date_header',
        defaultMessage: 'Time',
    },
    tableType: {
        id: 'print_mydata_table_type_header',
        defaultMessage: 'Type',
    },
    tableDetails: {
        id: 'print_mydata_table_details_header',
        defaultMessage: 'Details',
    },
    documentAuthor: {
        id: 'print_mydata_document_author_property',
        defaultMessage: 'www.progether.com, your prostate cancer network.',
    },
});

export const defaultOptions = {
    size: 'LETTER',
    author: 'Progether',
    language: 'en',
};

export async function renderPdf(events, options) {

    options = Object.assign({}, defaultOptions, options);

    const { title, author, subject, keywords, intl, language, logo } = options;

    const pageFormat = languageToPageFormat(language);
    const pageSize = formatToPageSize(pageFormat);
    const [ width, height ] = pageSize;

    let renderOptions = {
        size: {
            width, height,
        },
        margins: { // by default, all are 72
            top: 50,
            bottom: 50 + FooterRenderer.marginChanges.bottom,
            left: 50,
            right: 50,
        },
        tableOptions: {
            header: {
                show: true,
                repeat: true,
                rowLineWidth: 2,
                fontSize: 12,
            },
            body: {
                rowLineWidth: 1,
                fontSize: 12,
            },
            cellPaddings: {
                top: 4,
                bottom: 0,
                left: 4,
                right: 4,
            },
        },
        intl,
        language,
        logo,
        debug: false,
    };

    const doc = new PDFDocument({
        size: [ width, height ],
        margins: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
        layout: 'portrait', // can be 'landscape'
        info: {
            // pdfkit 0.10.0 has a bug with undefined/null values
            // in the info attributes. Therefore we set a default
            // empty string here. This might be reversible once
            // https://github.com/foliojs/pdfkit/pull/998 has
            // been merged and published.
            Title: title || '',
            Author: author || '', // the name of the author
            Subject: subject || '', // the subject of the document
            Keywords: keywords || '', // keywords associated with the document
            //CreationDate: 'DD/MM/YYYY', // the date the document was created (added automatically by PDFKit)
            //ModDate: 'DD/MM/YYYY' // the date the document was last modified (not needed)
        },
        bufferPages: true, // allows us to move back to old pages to set the page x of y footer
    });

    // register only the needed fonts
    doc
        .registerFont('normal', 'fonts/Roboto-Medium.ttf')
        .registerFont('light', 'fonts/Roboto-Light.ttf')
        .registerFont('bold', 'fonts/Roboto-Bold.ttf')
        .registerFont('bold-italic', 'fonts/Roboto-BoldItalic.ttf')
        .registerFont('normal-italic', 'fonts/Roboto-MediumItalic.ttf')
        .registerFont('light-italic', 'fonts/Roboto-LightItalic.ttf')
        .font('normal');

    const  headerRenderOptions = await HeaderRenderer.firstPage(doc, renderOptions);

    // extract and transform events
    const datedEvents = EventUtils.getEventsWithDates(events);
    const sortedEvents = EventUtils.sortEventsByDate(datedEvents, false);
    const psaEvents = EventUtils.getEventsOfType(sortedEvents, 'testing/marker/psa');
    const therapyEvents = EventUtils.getEventsOfType(sortedEvents, 'therapy');
    const testEventsWithoutMarkers = EventUtils.getEventsOfType(sortedEvents, 'testing/imaging', 'testing/pathology');
    const timeDomain = EventUtils.getTimeDomain(datedEvents);
    // if there are no events, we draw an empty chart for the last 2 years
    const firstEventDate = (timeDomain[0] && moment(timeDomain[0])) || moment().subtract(2, 'years');

    // renderPdf svg
    const psaChartBox = renderPSALineChart(doc, psaEvents, firstEventDate, headerRenderOptions);
    const testTimeLineBox = renderEventTimeline(doc, testEventsWithoutMarkers, firstEventDate,
        messages.testingChartHeadline,
        '#8da0cb',
        {
            ...headerRenderOptions,
            margins: {
                ...headerRenderOptions.margins,
                top: psaChartBox.y + psaChartBox.height,
                bottom: renderOptions.margins.bottom,
            },
        });
    const therapyTimeLineBox = renderEventTimeline(doc, therapyEvents, firstEventDate,
        messages.therapyChartHeadline,
        '#fc8d62',
        {
            ...headerRenderOptions,
            margins: {
                ...headerRenderOptions.margins,
                top: testTimeLineBox.y + testTimeLineBox.height,
                bottom: renderOptions.margins.bottom,
            },
        });

    // renderPdf data table
    const eventsTable = [ ...sortedEvents ].map(getMapEventFunction(intl));

    const eventsTableColumns = [
        {
            name: 'category',
            label: '',
            width: 5,
            renderer: (doc, data, left, top, width, height, paddings, isHeader) => {
                if (isHeader) return;
                try {
                    const color = typeIdToColor(data);
                    doc.save()
                        .rect(left, top, width, height)
                        .lineWidth(0)
                        .fill(color)
                        .restore();
                } catch (e) {
                    console.error(e.message);
                }
            },
        },
        {
            name: 'date',
            label: intl.formatMessage(messages.tableDate),
            weight: 20,
        },
        {
            name: 'name',
            label: intl.formatMessage(messages.tableType),
            weight: 20,
        },
        {
            name: 'text',
            label: intl.formatMessage(messages.tableDetails),
            weight: 40,
            markdown: true,
        },
    ];
    const tableLeft = renderOptions.margins.left;
    const tableTop = therapyTimeLineBox.height + therapyTimeLineBox.y + 20;
    renderMultiPageFullWidthTable(doc, eventsTableColumns, eventsTable, tableLeft, tableTop, renderOptions);


    // render footer including page numbers
    // as we also include the total number of pages
    // this needs to be done at the very end

    const pages = doc.bufferedPageRange();
    for (let page = 0; page < pages.count; page++) {
        doc.switchToPage(page);
        const footerRenderOptions = Object.assign({}, renderOptions, {
            footer: {
                page: page + 1,
                pages: pages.count,
            },
        });
        FooterRenderer.normal(doc, footerRenderOptions);
    }


    // finalization
    return new Promise((resolve, reject) => {
        const buffers = [];
        doc.on('data', buffers.push.bind(buffers));
        doc.on('end', async () => {
            const pdfBuffer = Buffer.concat(buffers);
            resolve(pdfBuffer);
        });
        doc.on('error', reject);
        doc.end();
    });
}

export function getMapEventFunction(intl) {

    return (event) => {

        const tag = EventUtils.getEventType(event);
        // escape is needed for markdown, else we'll get an empty list
        const date = catchWithDefault(() => EventUtils.dateToString(event, intl), '\-'); // eslint-disable-line no-useless-escape
        // escape is needed for markdown, else we'll get an empty list
        const name = catchWithDefault(() => EventUtils.eventTypeIdToString(event, intl), '\-'); // eslint-disable-line no-useless-escape
        // escape is needed for markdown, else we'll get an empty list
        const text = catchWithDefault(() => EventUtils.eventDataToString(event, intl), '\-'); // eslint-disable-line no-useless-escape

        const firstCharacterUpperCased = (text) => text.substr(0, 1).toUpperCase() + text.substr(1);

        return {
            date: firstCharacterUpperCased(date),
            category: tag,
            name: name,
            text: text.trim(),
            id: event.id() || Math.floor(Math.random() * Math.pow(10, 9)),
        };

    };

}

export function renderEventTimeline(doc, timelineEvents, timeAxisStart, titleMessage, barColor, renderOptions) {
    const {
        size: {
            width,
        },
        margins: {
            top: templateTop,
            left: templateLeft,
            right: templateRight,
            bottom: templateBottom,
        },
        intl,
    } = renderOptions;

    const eventHeight = 20;
    const axisHeight = 2 * eventHeight;
    const headerHeight = 35;
    const minHeight = (headerHeight + eventHeight + axisHeight);
    const computeAvailableHeight = y => doc.page.height - y - templateBottom;

    let eventsRendered = 0;
    let lastBoundingRect = {
        width,
        height: 0,
        y: templateTop,
        x: templateLeft,
    };

    timelineEvents = Array.from(timelineEvents).sort((a, b) => a.date().from.toDate() - b.date().from.toDate());

    let curY = templateTop;
    while (eventsRendered < timelineEvents.length) {
        if (computeAvailableHeight(curY) <= minHeight) {
            doc.addPage();
            curY = 0;
        }
        const curHLeft = computeAvailableHeight(curY);

        const eventsToRender = Math.min(Math.floor((curHLeft - headerHeight - axisHeight) / eventHeight), timelineEvents.length - eventsRendered);
        const eventsSlice = timelineEvents.slice(eventsRendered, eventsRendered + eventsToRender);
        const svgWidth = width - templateLeft - templateRight;
        const svgHeight = eventsToRender * eventHeight + headerHeight;


        const d3Root = document.createElement('svg');
        d3Root.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
        d3Root.setAttribute('width', svgWidth);
        d3Root.setAttribute('height', svgHeight);

        const svg = d3.select(d3Root).append('g'); // create SVG w/ 'g' tag and width/height

        const headlineText = intl.formatMessage(titleMessage);
        const headlineOptions = Object.assign({}, renderOptions, {
            headlineOptions: {}, // no changes to defaults,
            size: { width: doc.page.width, height: curHLeft },
            margins: {
                ...renderOptions.margins,
                top: curY,
            },
        });
        const headlineBounds = renderHeadline(doc, headlineText, curY, headlineOptions);

        timelineChart(svg, svgWidth, svgHeight, eventsSlice, timeAxisStart, intl, {
            boxFill: barColor,
        });

        const svgString = d3Root.outerHTML;

        const svgTop = curY + headlineBounds.height;
        const svgLeft = templateLeft;

        doc.save()
            // light gray background
            .rect(svgLeft, svgTop, svgWidth, svgHeight)
            .fill('#f7f7f7')
            // psa color stripe to the left
            .rect(svgLeft, svgTop, 5, svgHeight)
            .fill(barColor)
            .restore();

        SVGtoPDF(doc, svgString, svgLeft, svgTop, {
            width: svgWidth,
            height: svgHeight,
            assumePt: true, // ensures fit on page
        });

        lastBoundingRect = {
            width: svgWidth,
            height: svgHeight + headlineBounds.height,
            y: curY,
            x: templateLeft,
        };
        eventsRendered += eventsToRender;
        curY += lastBoundingRect.height;
    }

    return lastBoundingRect;
}

export function renderPSALineChart(doc, psaEvents, timeAxisStart, renderOptions) {

    const {
        size: {
            width,
        },
        margins: {
            top: templateTop,
            left: templateLeft,
            right: templateRight,
        },
        intl,
    } = renderOptions;

    const svgWidth = width - templateLeft - templateRight;
    const svgHeight = svgWidth / (16 / 7);

    const d3Root = document.createElement('svg');
    d3Root.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
    d3Root.setAttribute('width', svgWidth);
    d3Root.setAttribute('height', svgHeight);
    const svg = d3.select(d3Root).append('g'); // create SVG w/ 'g' tag and width/height

    const headlineText = intl.formatMessage(messages.psaChartHeadline);
    const headlineOptions = Object.assign({}, renderOptions, {
        headlineOptions: {}, // no changes to defaults
    });
    const headlineBounds = renderHeadline(doc, headlineText, templateTop, headlineOptions);

    psaChart(svg, svgWidth, svgHeight, psaEvents, timeAxisStart, intl);

    const svgString = d3Root.outerHTML;

    const svgTop = templateTop + headlineBounds.height;
    const svgLeft = templateLeft;

    doc.save()
        // light gray background
        .rect(svgLeft, svgTop, svgWidth, svgHeight)
        .fill('#f7f7f7')
        // psa color stripe to the left
        .rect(svgLeft, svgTop, 5, svgHeight)
        .fill('#66c2a5')
        .restore();

    SVGtoPDF(doc, svgString, svgLeft, svgTop, {
        width: svgWidth,
        height: svgHeight,
        assumePt: true, // ensures fit on page
    });

    return {
        width: svgWidth,
        height: svgHeight + headlineBounds.height,
        x: templateLeft,
        y: templateTop,
    };

}

function catchWithDefault(func, defaultValue) {
    try {
        return func();
    } catch (e) {
        console.error('uncatched exception', e.message, e);
        return defaultValue;
    }
}

const MAP_TYPE_TO_COLOR = {
    'testing': '#8da0cb',
    'therapy': '#fc8d62',
    'testing-marker-psa': '#66c2a5',
    'default': '#eee',
};

export function typeIdToColor(typeId) {

    const matchingKey = Object.keys(MAP_TYPE_TO_COLOR).reduce((longestMatchingKey, currentKey) => {

        if (typeId.startsWith(currentKey) && currentKey.length > longestMatchingKey.length) {
            return currentKey;
        }

        return longestMatchingKey;

    }, '');

    return matchingKey ? MAP_TYPE_TO_COLOR[matchingKey] : '';

}