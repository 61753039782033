import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import './index.less';

export default class Tabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activePanelId: props.defaultPanelId || props.panels[0].panelId,
        };
    }

    render() {

        const {
            panels,
            stickyTabs,
        } = this.props;

        if (!Array.isArray(panels)) {
            console.error('Provided property panels must be an array', panels);
            return null;
        }

        if (panels.length === 0) {
            return null;
        }

        const {
            activePanelId,
        } = this.state;

        return (
            <div>

                <ul className={classNames('nav nav-tabs', {'tabs-sticky-headers': stickyTabs})} role="tablist" style={{borderBotton: 'none'}}>
                    {
                        panels.map(({ panelId, tabNode, tabLabelMessage }) => (
                            <li role="presentation" className={classNames({ active: activePanelId === panelId })}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        activePanelId: panelId,
                                    });
                                }}
                                key={panelId}>
                                <a href={`#${panelId}`} aria-controls={panelId} role="tab">
                                    { tabNode || <FormattedMessage {...tabLabelMessage} />}
                                </a>
                            </li>
                        ))
                    }
                </ul>

                <div className="tab-content">
                    {
                        panels.map(({ panelId, panelNode }) => (
                            <div key={panelId} role="tabpanel"
                                 className={classNames('tab-pane fade', {
                                     in: panelId === activePanelId,
                                     active: panelId === activePanelId,
                                 })}
                                 id={panelId}>
                                {panelNode}
                            </div>
                        ))
                    }

                </div>

            </div>
        );

    }

}

Tabs.propTypes = {
    stickyTabs: PropTypes.bool,
    defaultPanelId: PropTypes.string,
    panels: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                panelId: PropTypes.string.isRequired,
                tabNode: PropTypes.node.isRequired,
                panelNode: PropTypes.node.isRequired,
            }),
            PropTypes.shape({
                panelId: PropTypes.string.isRequired,
                tabLabelMessage: PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    defaultMessage: PropTypes.string,
                }).isRequired,
                panelNode: PropTypes.node.isRequired,
            }),
        ])
    ),
};
