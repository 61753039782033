import React from 'react';
import PropTypes from 'prop-types';

export function Conditional({cond, children}) {

    if (!cond) {
        // noinspection JSConstructorReturnsPrimitive
        return null;
    }

    // can be solved more elegantly using react v16
    // https://reactjs.org/docs/fragments.html
    return Array.isArray(children)
        ? <div>{children}</div>
        : children;

}

Conditional.propTypes = {
    cond: PropTypes.bool.isRequired,
};
