import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Field from './field';
import StructTypes from './prop-types';

class FieldSet extends Component {

    static propTypes = {
        eventData: PropTypes.object.isRequired,
        eventId: PropTypes.string.isRequired,
        fields: PropTypes.arrayOf(PropTypes.shape({
            config: PropTypes.object,
            type: StructTypes.field,
            value: StructTypes.fieldValue
        })),
        onChange: PropTypes.func.isRequired
    };

    static defaultProps = {
        fields: []
    };

    retrieveFields = () => (this.props.fields);

    onFieldValueChange = (id, value, ...args) => {
        this.props.onChange(id, value, ...args);
    };

    render() {
        if (!Array.isArray(this.props.fields)) {
            return (<div>No fields set !</div>);
        }

        return (
            <div className="field-set">
                {
                    this.props.fields.map(field => (
                        <Field
                            eventData={this.props.eventData}
                            eventId={this.props.eventId}
                            config={field.config}
                            key={field.type.id}
                            field={field.type}
                            value={field.value}
                            visible={true}
                            onChange={this.onFieldValueChange} //this.onFieldChangeCallback(field, this.retrieveFields, this.props.onChange.bind(this)).bind(this),
                            fields={this.props.fields}
                        />
                    ))
                }
            </div>
        );

    }
}

export default FieldSet;
