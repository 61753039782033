import React, {Component} from 'react';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import './index.less';
import {OneOfRadioGroup} from '../../one-of/radio';
import {FormsMixin} from '../../forms-mixin';
import {OneOfLabelGroup} from '../../one-of/label';
import classnames from 'classnames';
import MarkdownMessage from '../../../../markdown-message';
import {Message} from '../../../utils/custom-prop-types';
import PropTypes from 'prop-types';

const messages = defineMessages({
    dontknow: {
        id: 'part_data_ui_form_types_custom_drugid_option_3_label',
        defaultMessage: 'My medication is not in this list',
        description: 'Label to go to alternate drug entry mode'
    },
    undoSelection: {
        id: 'part_data_ui_form_types_custom_drugid_undoSelection_label',
        defaultMessage: 'Undo my selection',
        description: 'Label to go to alternate drug entry mode'
    },
    maybeido: {
        id: 'part_data_ui_form_types_custom_drugid_option_4_label',
        defaultMessage: 'Search again in medication list',
        description: 'Label to go back to the medication list'
    },
    filterGroup: {
        id: 'part_data_ui_form_types_custom_drugid_filter_group_label',
        defaultMessage: 'What kind of therapy is it?'
    },
    filterDosageForm: {
        id: 'part_data_ui_form_types_custom_drugid_filter_form_label',
        defaultMessage: 'In what form do I take it?'
    },
    filterDosageFrequency: {
        id: 'part_data_ui_form_types_custom_drugid_filter_frequency_label',
        defaultMessage: 'How often do I take it?'
    },
    filterNoOptions: {
        id: 'part_data_ui_form_types_custom_drugid_filter_no_options_text',
        defaultMessage: 'No options here'
    },
    filterClear: {
        id: 'part_data_ui_form_types_custom_drugid_filter_clear_one_text',
        defaultMessage: 'Undo this filter'
    },
    filterClearAll: {
        id: 'part_data_ui_form_types_custom_drugid_filter_clear_all_text',
        defaultMessage: 'Restart __MedicationFinder__'
    },
    doFilter: {
        id: 'part_data_ui_form_types_custom_drugid_option_1_label',
        defaultMessage: 'Use our _MedicationFinder_',
        description: 'Label above the filters for medication'
    },
    doSelect: {
        id: 'part_data_ui_form_types_custom_drugid_option_2_label',
        defaultMessage: 'Select your medication',
        description: 'Label above the medication list'
    },
    filterDontKnow: {
        id: 'part_data_ui_form_types_custom_drugid_filter_option_dontknow_label',
        defaultMessage: 'Don\'t know'
    }
    ,
    filterOther: {
        id: 'part_data_ui_form_types_custom_drugid_filter_option_other_label',
        defaultMessage: 'Other'
    },
    introText: {
        id: 'part_data_ui_form_types_custom_drugid_into_text',
        defaultMessage: 'Sometimes it\'s hard to remember what medication we have been taking months or years ago. In case you have no documentation at hand, you might want to use our __MedicationFinder__.'
    },
    chooseOne: {
        id: 'part_data_ui_form_types_custom_drugid_chooseone_text',
        defaultMessage: 'or'
    }
});

const ClearOne = (props) => {

    if (!props.show) {
        return null;
    }

    return (
        <div className="drug-id-filter-reset">
            <FormattedMessage {...messages.filterClear}>
                {
                    (txt) => <a onClick={props.onClick}>{txt}</a>
                }
            </FormattedMessage>
        </div>
    );

};

ClearOne.propTypes = {
    show: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

const DrugIdFilter = (props) => {

    const {values, selectedValue, onSelection, onToggle, stateOpen, labelMessage} = props;
    const classes = classnames('drug-id-filter', {
        active: stateOpen,
        selection: selectedValue !== undefined
    });

    const renderContent = () => {

        if (values.length > 0) {
            return (
                <div>
                    <OneOfLabelGroup values={values} selectedValue={selectedValue} onSelection={onSelection}/>
                    <ClearOne show={selectedValue !== undefined} onClick={() => onSelection(undefined)}/>
                </div>
            );
        } else {
            return (
                <FormattedMessage {...messages.filterNoOptions}>
                    {
                        (txt) => <div className="drug-id-filter-no-options">{txt}</div>
                    }
                </FormattedMessage>
            );
        }

    };

    const content = stateOpen ? renderContent() : null;

    return (
        <div className={classes}>
            <FormattedMessage {...labelMessage}>
                {
                    (txt) => <label onClick={onToggle}>{txt}</label>
                }
            </FormattedMessage>
            {content}
        </div>
    );

};

DrugIdFilter.propTypes = {
    values: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.any.isRequired,
        label: PropTypes.string,
        labelFormatted: PropTypes.node
    })).isRequired,
    selectedValue: PropTypes.any,
    onSelection: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    stateOpen: PropTypes.bool,
    labelMessage: Message,
};

const RestartMedicationFinder = (props) => {

    const {filterGroupUser, filterDosageForm, filterDosageFrequency, onClick} = props;

    if (filterGroupUser === undefined && filterDosageForm === undefined && filterDosageFrequency === undefined) {
        return null;
    }

    return (
        <div className="drug-id-filter-reset">
            <a onClick={onClick}>
                <MarkdownMessage {...messages.filterClearAll} noParagraphs={true}/>
            </a>
        </div>
    );

};

RestartMedicationFinder.propTypes = {
    filterGroupUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    filterDosageForm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    filterDosageFrequency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func.isRequired,
};

const PartsLabel = (props) => {

    const {message} = props;

    return (
        <MarkdownMessage {...message} tagName="div" className="drug-id-label"/>
    );

};

PartsLabel.propTypes = {
    message: Message
};

const byLabel = (a, b) => {
    return (a.label || '').localeCompare(b.label);
};

const byLabelExceptionsMovedToTheBottom = (exceptions) => {

    const exceptionSet = new Set(exceptions);

    return (a, b) => {

        if (exceptionSet.has(a.value)) {
            return 1;
        } else if (exceptionSet.has(b.value)) {
            return -1;
        }

        return byLabel(a, b);

    };

};


class _DrugId extends FormsMixin(Component) {

    constructor(props) {

        super(props);


        this._translatedData = {
            locale: undefined,
            data: []
        };

        this._getTranslatedData = (locale) => {

            try {

                if (this._translatedData.locale !== locale) {

                    const translations = this._translations[locale];

                    this._translatedData.locale = locale;
                    this._translatedData.data = this._data.map(d => {
                        return Object.assign({}, d, {
                            dosage: {
                                form: d.dosage.form.map(f => {
                                    return {
                                        label: translations['dosage-form'][f],
                                        value: f
                                    };
                                }),
                                frequency: d.dosage.frequency.map(f => {
                                    return {
                                        label: translations['dosage-frequency'][f],
                                        value: f
                                    };
                                })
                            },
                            group: {
                                medical: {
                                    label: translations['internal-group'][d.group.medical],
                                    value: d.group.medical
                                },
                                user: {
                                    label: translations['user-group'][d.group.user],
                                    value: d.group.user
                                }
                            },
                            active_ingredient: {
                                label: translations['active-ingredient'][d.active_ingredient],
                                value: d.active_ingredient
                            },
                            tags: [...d.tags]
                        });
                    });

                }

                return this._translatedData.data;

            } catch (e) {
                console.error('Could not translate data', e);
            }

            return [];

        };

        this._filterOrder = ['groupUser', 'dosageForm', 'dosageFrequency'];

        const value = this.getValueOfField('drug/id');

        this.state = {
            filterGroupUser: undefined,
            filterDosageForm: undefined,
            filterDosageFrequency: undefined,
            activeFilter: value === undefined ? this._filterOrder[0] : undefined,
            previousDrugCount: undefined
        };

    }

    getNextFilter() {
        const filters = this._filterOrder;
        const currentIndex = filters.indexOf(this.state.activeFilter);
        if (currentIndex + 1 < filters.length) {
            return filters[currentIndex + 1];
        }
        return undefined;
    }

    handleFilterToggle(filterName) {

        const nextActiveFilter = (filterName === this.state.activeFilter) ? undefined : filterName;
        this.setState({
            activeFilter: nextActiveFilter
        });

    }

    handleFilterSelection(filterName, value) {

        const capitalizeFirstLetter = (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };

        let activeFilter = this.state.activeFilter;
        if (value !== undefined) {
            // selection => change filter
            activeFilter = this.getNextFilter();
        }

        this.setState({
            [`filter${capitalizeFirstLetter(filterName)}`]: value,
            activeFilter
        });

    }

    handleFilterReset() {
        this.setState({
            filterGroupUser: undefined,
            filterDosageForm: undefined,
            filterDosageFrequency: undefined,
            activeFilter: this._filterOrder[0]
        });
    }

    handleDrugSelection(drugId) {
        this.setState({
            // if drug is unset, show first filter, else (if drug is set) hide all filters
            activeFilter: drugId === undefined ? this._filterOrder[0] : undefined
        });
        this.props.onChange('drug/id', drugId);
    }

    renderSelector() {

        const {intl} = this.props;

        const value = this.getValueOfField('drug/id');

        if (value === 'DONT_KNOW') {
            return null;
        }

        const {
            filterGroupUser,
            filterDosageForm,
            filterDosageFrequency,
            previousDrugCount,
            activeFilter
        } = this.state;

        const data = this._getTranslatedData(intl.locale);

        const skipThisFilter = (filterValue) => filterValue === undefined
            || filterValue === '__DONT_KNOW__';
        const applyValueFilter = (filterValue, fieldValue) => skipThisFilter(filterValue)
            || filterValue === fieldValue;
        const applyValuesFilter = (filterValue, fieldValues) => skipThisFilter(filterValue)
            || fieldValues.some(({value}) => applyValueFilter(filterValue, value));

        const filterSet = (filterValue) => filterValue !== undefined;

        const filteredData = data.filter(d => {
            return applyValueFilter(filterGroupUser, d.group.user.value)
                && applyValuesFilter(filterDosageForm, d.dosage.form)
                && applyValuesFilter(filterDosageFrequency, d.dosage.frequency)
                && (value === undefined || value === 'DONT_KNOW' || d.progether_id === value);
        });

        const allFiltersSet = filterSet(filterGroupUser)
            && filterSet(filterDosageForm)
            && filterSet(filterDosageFrequency);

        const drugs = filteredData.map(d => {
            return {
                value: d.progether_id,
                label: d.active_ingredient.label || d.active_ingredient.value || '-'
            };
        }); // do not sort the drug list, it comes sorted from the schema

        const dontKnow = [
            {value: '__DONT_KNOW__', label: intl.formatMessage(messages.filterDontKnow)}
        ];

        const groups = Array.from(filteredData.map(d => d.group.user).reduce((userGroupMap, userGroup) => {
            if (!userGroupMap.has(userGroup.value)) {
                userGroupMap.set(userGroup.value, userGroup);
            }
            return userGroupMap;
        }, new Map()).values()).sort(byLabelExceptionsMovedToTheBottom(['other'])).concat(dontKnow);

        const getValues = (accessor) => {
            return Array.from(filteredData.map(accessor).reduce((mapped, listOfThings) => {
                listOfThings.forEach(thing => {
                    if (!mapped.has(thing.value)) {
                        mapped.set(thing.value, thing);
                    }
                });
                return mapped;
            }, new Map()).values()).sort(byLabel).concat(dontKnow);
        };
        const dosageForms = getValues(d => d.dosage.form);
        const dosageFrequencies = getValues(d => d.dosage.frequency);

        const currentDrugCount = drugs.length;
        const drugCountHasChanged = currentDrugCount !== previousDrugCount;
        const listClasses = classnames('drug-id-drug-list', {
            'blink blink-primary': drugCountHasChanged,
            'active': allFiltersSet && value === undefined
        });

        if (drugCountHasChanged) {
            // make sure that the setState is conditional
            window.setTimeout(() => {
                this.setState({
                    previousDrugCount: currentDrugCount
                });
            }, 500);
        }

        return (
            <div>
                <MarkdownMessage {...messages.introText} className="drug-id-intro-text"/>
                <MarkdownMessage {...messages.chooseOne} className="drug-id-choose-one" noParagraphs={true}/>
                <div className="drug-id-selector">
                    <div className="drug-id-filter-list">
                        <PartsLabel message={messages.doFilter}/>
                        <DrugIdFilter stateOpen={activeFilter === 'groupUser'}
                            onToggle={() => this.handleFilterToggle('groupUser')}
                            labelMessage={messages.filterGroup}
                            values={groups}
                            selectedValue={filterGroupUser}
                            onSelection={(v) => this.handleFilterSelection('groupUser', v)}/>
                        <DrugIdFilter stateOpen={activeFilter === 'dosageForm'}
                            onToggle={() => this.handleFilterToggle('dosageForm')}
                            labelMessage={messages.filterDosageForm}
                            values={dosageForms}
                            selectedValue={filterDosageForm}
                            onSelection={(v) => this.handleFilterSelection('dosageForm', v)}/>
                        <DrugIdFilter stateOpen={activeFilter === 'dosageFrequency'}
                            onToggle={() => this.handleFilterToggle('dosageFrequency')}
                            labelMessage={messages.filterDosageFrequency}
                            values={dosageFrequencies}
                            selectedValue={filterDosageFrequency}
                            onSelection={(v) => this.handleFilterSelection('dosageFrequency', v)}/>
                        <RestartMedicationFinder {...this.state} onClick={() => this.handleFilterReset()}/>
                    </div>
                    <div className={listClasses} ref={(node) => this._refDrugList = node}>
                        <PartsLabel message={messages.doSelect}/>
                        <OneOfRadioGroup selectedValue={value} values={drugs}
                            onSelection={(v) => this.handleDrugSelection(v)}/>
                        {
                            this.renderUndoSelection()
                        }
                        {
                            this.renderAlternative()
                        }
                    </div>
                </div>
            </div>
        );

    }

    renderAlternative() {

        const value = this.getValueOfField('drug/id');

        const isSet = value !== undefined;
        const isDontKnow = value === 'DONT_KNOW';

        if (isSet && !isDontKnow) {
            // no message needed
            return null;
        }

        return (
            <div className="drug-id-alternative">
                <FormattedMessage {...messages.dontknow}>
                    {
                        (txt) => <a onClick={() => this.props.onChange('drug/id', 'DONT_KNOW')}>{txt}</a>
                    }
                </FormattedMessage>
            </div>
        );

    }

    renderUndoSelection() {

        const value = this.getValueOfField('drug/id');

        const isSet = value !== undefined;
        const isDontKnow = value === 'DONT_KNOW';

        if (!isSet || isDontKnow) {
            // no message needed
            return null;
        }

        return (
            <div className="drug-id-alternative">
                <FormattedMessage {...messages.undoSelection}>
                    {
                        (txt) => <a onClick={() => this.props.onChange('drug/id', undefined)}>{txt}</a>
                    }
                </FormattedMessage>
            </div>
        );

    }

    renderUndoDontKnow() {

        const value = this.getValueOfField('drug/id');
        const isDontKnow = value === 'DONT_KNOW';

        if (!isDontKnow) {
            // no message needed
            return null;
        }

        return (
            <div className="drug-id-alternative">
                <FormattedMessage {...messages.maybeido}>
                    {
                        (txt) => <a onClick={() => this.props.onChange('drug/id', undefined)}>{txt}</a>
                    }
                </FormattedMessage>
            </div>
        );

    }

    render() {

        const {eventData: event} = this.props;

        this._data = event.eventData();
        this._translations = event.eventTranslations();

        return (
            <div className="drug-id">
                {
                    this.renderSelector()
                }
                {
                    this.renderUndoDontKnow()
                }
            </div>
        );

    }

}

export const DrugId = injectIntl(_DrugId);
