/**
 * Created by Sebastian on 20.06.2016.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import {IntlProvider} from 'react-intl';

/**
 * A little helper to render anything at the documents body instead
 * of rendering it at it's designated place in the DOM.
 *
 * From: http://jamesknelson.com/rendering-react-components-to-the-document-body/
 */
class RenderInBody extends Component {

    static propTypes = {
        // language to display the message
        language: PropTypes.string.isRequired,
        // the translation messages for the given language
        messages: PropTypes.object.isRequired
    }

    componentDidMount () {
        // add wrapper to body
        this.container = document.createElement('div');
        document.body.appendChild(this.container);
        this._renderLayer();
    }

    componentDidUpdate () {
        // update component on body
        this._renderLayer();
    }

    componentWillUnmount () {
        // remove from body before unmount
        ReactDOM.unmountComponentAtNode(this.container);
        document.body.removeChild(this.container);
    }

    _renderLayer () {

        const {language, messages} = this.props;

        // render the actual component
        ReactDOM.render(
            <IntlProvider locale={language} messages={messages}>
                { this.props.children }
            </IntlProvider>,
            this.container
        );
    }

    render () {
        // Render a placeholder at the designated DOM location
        return null;
    }

}

export default RenderInBody;
