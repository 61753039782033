export const MultipleOfOtherMixin = SuperClass => class _MultipleOfOtherMixin extends SuperClass {
    /**
     * Make sure we always get an array for the current selection
     **/
    getSelection () {
        let selection = this.getValue();

        if (!Array.isArray(selection)) {
            if (selection !== null && selection !== undefined) {
                selection = [selection];
            } else {
                selection = [];
            }
        }

        return selection;
    }

    /**
     * Add an item if not present yet. Remove if already
     * present.
     **/
    toggleSelectionItem (item) {

        const selection = this.getSelection();
        let pos;

        if ((pos = selection.indexOf(item)) === -1) {
            selection.push(item);
        } else {
            selection.splice(pos, 1);
        }

        // update
        this.update(this.props.def, this.props.id, selection.length ? selection : undefined, true, 1);

    }

    /**
     * Is item present within selection?
     **/
    selectionHasItem (item) {
        return this.getSelectionWithoutOther().filter(function (s) {
            return s === item;
        }).length === 1;
    }

    /**
     * Get the selection without the OTHER element
     **/
    getSelectionWithoutOther() {
        return this.getSelection().filter(function (s) {
            return s.substring(0, 7) !== '_OTHER_';
        });
    }

    /**
     * Toggle state of other
     **/
    toggleOther() {
        let selection = this.getSelectionWithoutOther();

        if (!this.hasOther()) {
            selection.push('_OTHER_');
        }

        // update
        this.update(this.props.def, this.props.id, selection.length ? selection : undefined, true, 1);
    }

    /**
     * Has "other" been selected?
     **/
    hasOther() {
        return this.getSelection().filter(function (s) {
            return s.substring(0, 7) === '_OTHER_';
        }).length === 1;
    }

    /**
     *
     **/
    getOther() {
        let other = this.getSelection().filter(function (v) {
            return v.substring(0, 7) === '_OTHER_';
        });

        if (other.length === 1) {
            return other[0].substring(7);
        }

        return undefined;
    }

    setOther(value) {
        // get current selection without other
        let selection = this.getSelectionWithoutOther();

        // add new
        selection.push('_OTHER_' + value);

        // update
        this.update(this.props.def, this.props.id, selection, true, 1);

    }
};

export default MultipleOfOtherMixin;
