import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage} from 'react-intl';
import './loading-and-alternative.less';

const messages = defineMessages({
    loading: {
        id: 'loading_animation_alternative_text',
        defaultMessage: 'Showing an animated arrow to indicate loading data',
    }
});

export const LoadingAndAlternative = (props) => {

    // noinspection JSMismatchedCollectionQueryUpdate
    const {loading, condition, children} = props;

    if (loading) {

        if (Array.isArray(children) && children.length >= 3) {
            return children[2];
        }

        return (
            <div className="loading-placeholder loading">
                <FormattedMessage {...messages.loading}>
                    {(txt) => <i className="fa fa-4x fa-refresh spin" title={txt}/>}
                </FormattedMessage>
            </div>
        );

    }

    if (condition) {
        return Array.isArray(children) ? children[0] : children;
    }

    return Array.isArray(children) ? children[1] : null;

};

LoadingAndAlternative.propTypes = {
    loading: PropTypes.bool,
    condition: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};
