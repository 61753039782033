const leftPad = (str, len = 2, pad = '0') => {
    const _str = '' + str;
    if (_str.length < len) {
        return new Array(len - _str.length + 1).join(pad) + _str;
    }
    return _str;
};

const dateObjectToIsoString = (dateObject) => {

    if (!dateObject || !(dateObject.year > 0)) {
        return undefined;
    }

    let isoDateString = leftPad(dateObject.year, 4);

    if (dateObject.month > 0) {

        isoDateString += '-' + leftPad(dateObject.month);

        if (dateObject.day > 0) {
            isoDateString += '-' + leftPad(dateObject.day);
        }

    }

    return isoDateString;

};

export const DateMixin = SuperClass => class _DateMixin extends SuperClass {

    fromCustomIsoString(dateString) {
        if(!dateString) return undefined;

        const dateArray = dateString.split('-').map(v => parseInt(v));

        return {
            year: dateArray[0],
            month: dateArray[1],
            day: dateArray[2]
        };

    }

    // noinspection JSMethodCanBeStatic
    getDate(date) {

        if (!date) {
            return undefined;
        }

        if (date.from) {
            // range
            return {
                from: dateObjectToIsoString(date.from),
                to: dateObjectToIsoString(date.to),
            };
        } else {
            return dateObjectToIsoString(date);
        }

    }

};
