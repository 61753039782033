/**
 * @callback FilterElements
 * @param {string} id - the current id, a concatenation of all keys from the root to this node
 * @param {string} key - the key of the current node within the tree
 * @param {object|array} value - the value of the current node within the tree
 * @returns true to send the element to the handleElement callback, false to ignore it
 */

/**
 * @callback HandleElement
 * @param {string} id - the current id, a concatenation of all keys from the root to this node
 * @param {string} type - the type or name of the node
 * @param {object|array} element - the value of the current node within the tree
 * @returns * - return value is ignored
 */

/**
 * @typedef {object} Options
 * @property {FilterElements} [filter] -
 * @property {string} [idSeparator] - single character to separate the segments of a type/event id, defaults to '/'
 */
var defaultOptions = {
  filter: function filter(id, key, value) {
    return /^(event|types|data|translations)$/.test(key);
  },
  // eslint-disable-line no-unused-vars
  idSeparator: '/'
};
/**
 * Walk a schema tree.
 *
 * For each element in the schema tree, call the handleElement callback.
 *
 * If you need to filter the tree, pass a {@see FilterElements} callback
 * via the {@see Options} map. Only element for which the filter returns
 * true will be passed to the handleElement callback.
 *
 * @param {object} schema
 * @param {HandleElement} handleElement
 * @param {Options} [options]
 * @returns {number} - of calls to handleElement
 */

export var schemaTreeWalker = function schemaTreeWalker(schema, handleElement, options) {
  options = Object.assign({}, defaultOptions, options);
  var calls = 0;

  var internalTreeWalker = function internalTreeWalker(schema, handleElement, id) {
    var isElement = options.filter;
    Object.keys(schema).forEach(function (key) {
      var value = schema[key];

      if (isElement(id, key, value)) {
        calls++;
        handleElement(id, key, value);
      } else {
        var nextId = id ? "".concat(id).concat(options.idSeparator).concat(key) : key;
        internalTreeWalker(value, handleElement, nextId);
      }
    });
  };

  internalTreeWalker(schema, handleElement);
  return calls;
};