import PropTypes from 'prop-types';
import moment from 'moment';

const date = (props, propName) => {

    // allow null/undefined
    if (props[propName] === null || props[propName] === undefined)
        return null;

    // check is object
    if (typeof props[propName] !== 'object') {
        return new Error(propName + ' is not an object!');
    }

    let date = props[propName],
        requiredProps = ['day','month','year'];

    // check all required keys are available
    requiredProps.forEach(function (p) {
        if (!date.hasOwnProperty(p)) {
            return new Error('missing property "' + p + '" in ' + propName + '!');
        }
    });

    // check no additional keys are present
    if (Object.keys(date).length > requiredProps.length) {
        return new Error('too many properties in ' + propName + '!'); 
    }

    // check valid date
    if (!moment([date.year, date.month - 1, date.day]).isValid()) {
        return new Error('not a valid date in ' + propName + '!');  
    }

    return null;

};

export default {

    point: PropTypes.shape({
        date: date
    }),

    interval: PropTypes.shape({
        from: date,
        to: date
    }),

};
