import CommonmarkPDFKitRenderer from 'pdfkit-commonmark';
import { Parser } from 'commonmark';

const markdownRenderer = new CommonmarkPDFKitRenderer({
    fonts: {
        'default': 'light',
        'bold': 'normal',
        'italic': 'light-italic',
        'bold-italic': 'normal-italic',
        'heading-bold': 'bold',
        'heading-default': 'normal'
    },
    debug: false
});
const markdownParser = new Parser();

/**
 * Take an array of column objects { [width = 1] [,weight = 1] [,label] [,name] }
 * and create one column for each element in the input array.
 *
 * Column width is based on the provided width/weight properties, but scaled
 * to the available width.
 *
 * TODO: Take into account minWidth and maxWidth contraints
 *
 * @param columns
 * @param width
 * @returns {{count: number, hasLabels: boolean, columns}}
 */
const getColumnInfo = (columns, width, renderOptions) => { // eslint-disable-line no-unused-vars

    if (!Array.isArray(columns)) {
        throw {
            message: 'columns must be an array'
        };
    }

    let columnsWithWidthSet = columns.filter(c => !!c.width);
    let columnsWithoutWidthSet = columns.filter(c => !c.width);
    const predefinedColumnWidthTotal = columnsWithWidthSet.map(c => c.width).reduce((sum, width) => sum + width, 0);

    if (predefinedColumnWidthTotal > width) {
        console.error({
            tag: 'render-table-column-info',
            message: 'cannot satisfy column width requirements (too wide), will ignore predefined widths'
        });
        columnsWithWidthSet = [];
        columnsWithoutWidthSet = [...columns];
    }

    const freelyAssignableWidth = width - predefinedColumnWidthTotal;
    const minColumnWidth = 5;

    if (freelyAssignableWidth / columnsWithoutWidthSet.length < minColumnWidth) {
        console.error({
            tag: 'render-table-column-info',
            message: 'cannot satisfy column width requirements (not enough width left), will ignore predefined widths'
        });
        columnsWithWidthSet = [];
        columnsWithoutWidthSet = [...columns];
    }

    const tableColumnWeights = columns.map(c => columnsWithWidthSet.includes(c) ? 0 : c.weight || 1);
    const totalColumnWeight = tableColumnWeights.reduce((sum, weight) => sum + weight, 0);
    const hasLabels = columns.some(c => !!c.label);
    const hasNames = columns.every(c => !!c.name);
    const headerRow = columns.map(c => c.label || '');

    return {
        count: columns.length,
        hasLabels: hasLabels,
        hasNames: hasNames,
        columns: columns.map((c, ix) => {

            const hasFixedWidth = columnsWithWidthSet.includes(c);
            const width = (hasFixedWidth) ? c.width : Math.round((tableColumnWeights[ix] / totalColumnWeight) * freelyAssignableWidth);

            // create a copy and set the width attribute
            return Object.assign({}, c, {
                width: width
            });

        }),
        headerRow: headerRow,
    };

};

const getRowHeightObject = (doc, columnInfo, rowData, cellOptions) => {

    if (!columnInfo.hasNames) {
        throw {
            message: `cannot process rowData of type 'object' without column names`
        };
    }

    const rowDataAsArray = columnInfo.columns.map(c => rowData[c.name]);
    return getRowHeightArray(doc, columnInfo, rowDataAsArray, cellOptions);

};

const getCellInfo = (doc, width, text, cellOptions) => {

    const {
        paddings: {
            top,
            bottom
        },
        font: {
            size,
            name
        },
        rowLineWidth
    } = cellOptions;


    if (size) {
        doc.save().fontSize(size);
    }

    if (name) {
        doc.save().font(name);
    }

    const textHeight = doc.heightOfString(text, {width: width});

    if (name) {
        doc.restore();
    }

    if (size) {
        doc.restore();
    }

    return {
        text: text,
        width: width,
        height: Math.ceil(textHeight) + top + bottom + rowLineWidth
    };

};

const getRowHeightArray = (doc, columnInfo, rowData, cellOptions) => {

    const {
        paddings: {
            left,
            right,
            top,
            bottom
        }
    } = cellOptions;

    const cellHeights = columnInfo.columns.map((c, ix) => {

        const cellText = rowData[ix];
        const couldBeMarkdown = () => /[_*[\]()\-#]/.test(cellText);

        if (c.markdown === true && couldBeMarkdown()) {

            const textWidth = c.width - left - right;
            const cacheKey = cellText + textWidth;

            // we need to cache here for a significant speedup
            const parsed = markdownParser.parse(cellText);

            const height = markdownRenderer.heightOfMarkdown(doc, parsed, {
                width: c.width - left - right
            }) + top + bottom;

            return height;

        }
        if (c.renderer && typeof c.renderer === 'function') {

            // for a custom renderer, we
            // 2020-10-07: we what?!
            if (c.height) {
                if (typeof c.height === 'function') {
                    return c.height(doc, c.width - left - right, cellText, cellOptions);
                } else if (c.height >= 0) {
                    return c.height;
                }
                throw {
                    message: 'Invalid column height information'
                };
            }
            // default height of 0: adjust to other cells in the same row
            return 0;

        } else {

            const {height} = getCellInfo(doc, c.width - left - right, cellText, cellOptions);
            return height;

        }

    });

    return Math.ceil(Math.max(...cellHeights));

};

const getRowHeight = (doc, columnInfo, rowData, cellOptions) => {

    const rowDataIsArray = Array.isArray(rowData);
    if (rowDataIsArray) {
        // process as array
        return getRowHeightArray(doc, columnInfo, rowData, cellOptions);
    }

    const rowDataIsObject = !rowDataIsArray && typeof rowData === 'object';
    if (rowDataIsObject) {
        // process as object
        return getRowHeightObject(doc, columnInfo, rowData, cellOptions);
    }

    throw {
        message: `cannot process rowData of type '${typeof rowData}'`
    };

};

const renderRow = (doc, left, top, height, columnInfo, rowData, cellOptions) => {

    const {
        paddings: {
            top: paddingTop,
            bottom: paddingBottom,
            left: paddingLeft,
            right: paddingRight
        },
        font: {
            size,
            name
        },
        rowLineWidth,
        debug,
        isHeader = false,
        skipLine = false
    } = cellOptions;

    let leftOffset = left;

    // ensure rowData is in array form
    if (!Array.isArray(rowData)) {
        rowData = columnInfo.columns.map(c => rowData[c.name]);
    }

    // render text
    columnInfo.columns.forEach((c, ix) => {

        const supportsMarkdown = c.markdown === true;
        const width = c.width;
        const text = rowData[ix];

        if (debug) {
            // outer cell
            doc.save().rect(leftOffset, top, width, height + paddingTop + paddingBottom)
                .strokeColor('red')
                .strokeOpacity(.05)
                .stroke();

            // inner cell
            doc.rect(leftOffset + paddingLeft, top + paddingTop, width - paddingLeft - paddingRight, height)
                .fillColor('green')
                .fillOpacity(.05)
                .fill()
                .restore();
        }

        if (supportsMarkdown) {

            // Mark Twain: "Only render what there is to be rendered."
            if (text) {

                const parsed = markdownParser.parse(text);

                doc.x = leftOffset + paddingLeft;
                doc.y = top + paddingTop;

                // if the markdown is/contains a list, the current renderer will
                // reset the horizontal position to marginLeft + indent * listLevel
                // As we need to position the text within the table cell and not
                // relative to the page margin, we manipulate the page margin
                // here to be equal to our cell position
                const marginLeft = doc.page.margins.left;
                doc.page.margins.left = doc.x;

                markdownRenderer.render(doc, parsed, {width});

                // and reset it to its original value here
                doc.page.margins.left = marginLeft;

            }

        } else if (c.renderer && typeof c.renderer === 'function') { // call custom cell renderer

            c.renderer(doc, text, leftOffset, top, width, height + paddingTop + paddingBottom, cellOptions.paddings, isHeader);

        } else { // render content as text

            if (size) {
                doc.save().fontSize(size);
            }

            if (name) {
                doc.save().font(name);
            }

            doc.text(text, leftOffset + paddingLeft, top + paddingTop, {width});

            if (name) {
                doc.restore();
            }

            if (size) {
                doc.restore();
            }

        }

        leftOffset += c.width;

    });

    // draw line below row
    if (!skipLine) {
        const borderWidth = leftOffset;
        const borderLineWidth = rowLineWidth;
        const borderTop = height + top + borderLineWidth * .5;
        doc.moveTo(left, borderTop)
            .lineTo(borderWidth, borderTop)
            .save()
            .lineWidth(borderLineWidth)
            .stroke('#ccc')
            .restore();
    }

};

/**
 *
 * @param {PDFDocument} doc
 * @param {number} minRows
 * @param {object[]} columns
 * @param {object[]} rows
 * @param {number} [left]
 * @param {number} [top]
 * @param {number} [width]
 * @param {number} [height]
 * @param {object} renderOptions
 */
export const renderTable = (doc, minRows, columns, rows, left, top, width, height, renderOptions) => {

    // handle optional parameters
    if (typeof left === 'object') {
        renderOptions = left;
        left = renderOptions.margins.left;
        top = renderOptions.margins.top;
        width = renderOptions.size.width - renderOptions.margins.left - renderOptions.margins.right;
        height = renderOptions.size.height - top - renderOptions.margins.bottom;
    } else if (typeof top === 'object') {
        renderOptions = top;
        top = renderOptions.margins.top;
        width = renderOptions.size.width - renderOptions.margins.left - renderOptions.margins.right;
        height = renderOptions.size.height - top - renderOptions.margins.bottom;
    } else if (typeof width === 'object') {
        renderOptions = width;
        width = renderOptions.size.width - renderOptions.margins.left - renderOptions.margins.right;
        height = renderOptions.size.height - top - renderOptions.margins.bottom;
    } else if (typeof height === 'object') {
        renderOptions = height;
        height = renderOptions.size.height - top - renderOptions.margins.bottom;
    }

    // options
    const {
        tableOptions = {
            header: {
                show: true,
                repeat: true,
                rowLineWidth: 2,
                fontSize: 14
            },
            body: {
                rowLineWidth: 1,
                fontSize: 12
            },
            cellPaddings: {
                top: 4,
                bottom: 4,
                left: 4,
                right: 4
            }
        },
    } = renderOptions;


    // determine column sizes

    const columnInfo = getColumnInfo(columns, width, renderOptions);


    // determine all row heights

    const bodyCellOptions = {
        paddings: tableOptions.cellPaddings,
        font: {
            size: tableOptions.body.fontSize,
            name: 'light'
        },
        rowLineWidth: tableOptions.body.rowLineWidth,
        debug: tableOptions.debug || renderOptions.debug
    };
    const rowHeights = rows.map(r => getRowHeight(doc, columnInfo, r, bodyCellOptions));


    // determine header row height

    const headerCellOptions = {
        paddings: tableOptions.cellPaddings,
        font: {
            size: tableOptions.header.fontSize,
            name: 'normal'
        },
        rowLineWidth: tableOptions.header.rowLineWidth,
        debug: tableOptions.debug || renderOptions.debug,
        isHeader: true
    };
    let headerRowHeight = 0;
    if (tableOptions.header.show) {
        headerRowHeight = getRowHeight(doc, columnInfo, columnInfo.headerRow, headerCellOptions);
    }


    // determine how many rows can fit on the current page

    const availableHeight = height;
    let alreadyUsedHeight = headerRowHeight;
    const rowsThatWillFit = rows.filter((row, ix) => (alreadyUsedHeight += rowHeights[ix]) <= availableHeight);
    const fittedRowsCount = rowsThatWillFit.length;
    const remainingRowsCount = rows.length - fittedRowsCount;
    const minRowsOnPage = minRows;
    const minRowsOnNextPage = minRows;
    if (remainingRowsCount < minRowsOnNextPage && remainingRowsCount > 0) {
        const rowCountThatNeedToMoveToNextPage = minRowsOnNextPage - remainingRowsCount;
        const indexOfLastFittedRow = fittedRowsCount - 1;
        const indexOfLastFittedRowAfterAdjustment = Math.max(fittedRowsCount - 1 - rowCountThatNeedToMoveToNextPage, 0);
        for (let i = indexOfLastFittedRow; i > indexOfLastFittedRowAfterAdjustment; i--) {
            // remove the last n elements
            rowsThatWillFit.splice(i, 1);
        }
        // if there are not enough rows left on the current page,
        // remove them all, as to move the whole table to the next page
        if (rowsThatWillFit.length < minRowsOnPage) {
            // remove all elements
            rowsThatWillFit.splice(0, rowsThatWillFit.length);
        }
    }


    // add header row (if requested)

    let currentTop = top;
    const hasRows = rowsThatWillFit.length > 0;
    if (tableOptions.header.show && hasRows) {
        renderRow(doc, left, currentTop, headerRowHeight, columnInfo, columnInfo.headerRow, headerCellOptions);
        currentTop += headerRowHeight;
    }


    // add the fitting rows to the page

    rowsThatWillFit.forEach((r, ix) => {
        const cellOptions = Object.assign({}, bodyCellOptions, {skipLine: ix === rowsThatWillFit.length - 1});
        renderRow(doc, left, currentTop, rowHeights[ix], columnInfo, r, cellOptions);
        currentTop += rowHeights[ix];
    });


    // return the remaining rows

    const remainingRows = rows.slice(rowsThatWillFit.length);
    if (remainingRows.length + rowsThatWillFit.length !== rows.length) {
        throw {
            message: `We lost some data rows!`
        };
    }
    return remainingRows;

};

export const renderMultiPageFullWidthTable = (doc, columns, rows, left, top, renderOptions) => {

    const {
        size: {width, height},
        margins: {
            top: pageMarginTop,
            bottom: pageMarginBottom,
            left: pageMarginLeft,
            right: pageMarginRight
        }
    } = renderOptions;

    const tableWidth = width - pageMarginLeft - pageMarginRight;

    let remainingRows = rows;
    let currentTop = top;

    // when starting on fresh page, don't require more than one row
    let minRows = Math.abs(top - pageMarginTop) < Number.EPSILON ? 1 : 3;

    while (remainingRows.length > 0) {
        const tableHeight = height - pageMarginTop - currentTop - pageMarginBottom;
        const rowsBefore = remainingRows.length;

        remainingRows = renderTable(doc, minRows, columns, remainingRows, left, currentTop, tableWidth, tableHeight, renderOptions);
        currentTop = pageMarginTop;

        if (minRows === 1 && rowsBefore === remainingRows.length) {
            throw Error('could not render any rows');
        }

        minRows = 1;
        if (remainingRows.length > 0) {
            doc.addPage();
        }
    }

};

export default renderTable;