// source: https://github.com/devongovett/pdfkit/blob/master/lib/page.coffee#L72-L122
export const PAGE_SIZES = {
    A4: [595.28, 841.89],
    A5: [419.53, 595.28],
    LETTER: [612.00, 792.00]
};

export function mmToPoint(cm) {
    return cm * 2.83465;
}

export function languageToPageFormat(lang) {
    switch (lang) {
        case 'de': return 'A4';
        case 'en': return 'LETTER';
        default:
            console.log({
                tag: 'print-service-page-format-guess',
                message: 'unknown language "' + lang + '", using A4'
            });
            return 'LETTER';
    }
}

export function formatToPageSize(format) {
    const size = PAGE_SIZES[format];
    if (!size) {
        throw new Error('unknown page format "' + format + '"');
    }

    return size;
}
