/**
 * A react class for a simple tab component
 *
 * @author sebastian@progether.com
 * @param {type} require
 * @returns {PSA}
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import classNames from 'classnames';
import './index.less';

const messages = defineMessages({
    EQ: {
        id: 'wizard_section_psa_type_equal',
        defaultMessage: 'equal to (=)'
    },
    LT: {
        id: 'wizard_section_psa_type_less',
        defaultMessage: 'less than (<)'
    },
    GT: {
        id: 'wizard_section_psa_type_greater',
        defaultMessage: 'greater than (>)'
    },
    placeholder: {
        id: ' component_input_psa_value_placeholder',
        defaultMessage: 'ex: 0.2'
    }
});

const PSA_TYPE_OPTION_VALUES = ['EQ', 'LT', 'GT'];
const NUMBER_WITH_COMMA_DECIMAL = /^((\d+(,\d+)?)|((([1-9]\d{0,2})(\.\d{3})*)?(,\d+)?))$/;
const NUMBER_WITH_DOT_DECIMAL = /^((\d+(\.\d+)?)|((([1-9]\d{0,2})(,\d{3})*)?(\.\d+)?))$/;

class PSAInput extends Component {

    constructor(props) {

        super(props);

        // we need to take the floating point value and convert it into
        // a localized string for presentation
        const formatOptions = {
            useGrouping: false,
            maximumFractionDigits: 5
        };

        const mapLocale = (locale) => {
            switch (locale) {
                case 'no':
                    // NumberFormat('no').format(.2) return 0.2
                    // NumberFormat('nb').format(.2) return 0,2
                    return 'nb';
                default:
                    return locale;
            }
        };

        const numberFormat = new Intl.NumberFormat(mapLocale(props.intl.locale), formatOptions);

        this.convertEntryToString = (entry) => {
            return numberFormat.format(entry);
        };

        this.state = {
            showDropdown: false,
            transientEntry: (props.psa && (props.psa.entry >= 0) && this.convertEntryToString(props.psa.entry)) || ''
        };

        this.handleEntryChange = this.handleEntryChange.bind(this);

    }

    static propTypes = {
        // the current value
        psa: PropTypes.shape({
            entry: PropTypes.number,
            type: PropTypes.oneOf(PSA_TYPE_OPTION_VALUES)
        }),
        // returns the updated psa value, same shape as the psa prop
        onChange: PropTypes.func.isRequired,
        // will disable the input
        disabled: PropTypes.bool
    };

    componentWillReceiveProps(nextProps) {

        const {psa: {entry: current} = {}} = this.props;
        const {psa: {entry: next} = {}} = nextProps;

        if (current !== next) {
            this.setState({
                transientEntry: ((next >= 0) && this.convertEntryToString(next)) || ''
            });
        }

    }

    handleTypeChange(e, newType) {

        e.preventDefault();

        const {onChange, psa, disabled} = this.props;

        // will not be changeable if disabled
        if (disabled) return;

        onChange(Object.assign({}, psa, {
            type: newType
        }));

    }

    handleEntryChange(e) {

        const {intl, psa} = this.props;
        const inputValue = e.target.value;
        let value = inputValue;
        let type = (psa && psa.type) || 'EQ';

        // if type modifier found
        if (/^\s*(<|>|=)/.test(inputValue)) {
            const groups = /^\s*(<|>|=)(.*)/.exec(inputValue);
            value = groups[2];
            switch (groups[1]) {
                case '<' :
                    type = 'LT';
                    break;
                case '>' :
                    type = 'GT';
                    break;
                default:
                    type = 'EQ';
            }
        }

        let isValid = false;
        let entry = undefined;
        switch (intl.locale) {
            case 'de':
            case 'no':
                isValid = NUMBER_WITH_COMMA_DECIMAL.test(value);
                if (isValid) {
                    entry = parseFloat(value.replace(/\./g, '').replace(/,/, '.'));
                }
                break;
            default:
                isValid = NUMBER_WITH_DOT_DECIMAL.test(value);
                if (isValid) {
                    entry = parseFloat(value.replace(/,/, ''));
                }
        }

        if (isValid) {
            const {onChange} = this.props;
            this.setState({
                transientEntry: value
            }, () => {
                onChange({
                    entry: entry,
                    type: type
                });
            });
        } else {
            this.setState({
                transientEntry: value
            });
        }

    }

    render() {

        const {psa, intl, disabled = false} = this.props;
        const {showDropdown, transientEntry} = this.state;

        const type = (psa && PSA_TYPE_OPTION_VALUES.includes(psa.type)) ? psa.type : 'EQ';

        const currentType = type;
        const inputPlaceholder = intl.formatMessage(messages.placeholder);
        const typeOptions = PSA_TYPE_OPTION_VALUES.map(ov => ({value: ov, label: intl.formatMessage(messages[ov])}));
        const typeDropdownOptions = typeOptions.map(to => {
            return (
                <li key={to.value}>
                    <a href="#" onClick={(e) => this.handleTypeChange(e, to.value)}>{to.label}</a>
                </li>
            );
        });

        const componentClasses = classNames('input-group-btn', {
            open: showDropdown,
            disabled: disabled
        });

        return (
            <div>
                <div className="input-group">
                    <div className={componentClasses}>
                        <button type="button"
                            className="btn btn-default dropdown-toggle"
                            onClick={() => !disabled && this.setState({showDropdown: !showDropdown})}
                            onBlur={() => window.setTimeout(() => this.setState({showDropdown: false}), 150)}
                            aria-haspopup="true" aria-expanded={showDropdown}>
                            <FormattedMessage {...messages[currentType]}/>
                            <span className="caret"></span>
                        </button>
                        <ul className="dropdown-menu">
                            {typeDropdownOptions}
                        </ul>
                    </div>
                    <input type="text" className="form-control"
                        aria-label=""
                        value={transientEntry}
                        placeholder={inputPlaceholder}
                        onChange={this.handleEntryChange}
                        disabled={disabled}/>
                </div>
            </div>
        );

    }

}

export default injectIntl(PSAInput);
