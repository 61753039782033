import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { schemaTreeWalker } from './schema-tree-walker';

var clone = function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
};
/**
 * Takes a schema and resolves custom type defined within
 * the same schema. Will return a copy of the original
 * schema, with all custom types resolved and the type
 * definitions removed from the tree.
 *
 * @param {object} schema
 * @param {string} [idSeparator] defaults to '/'
 * @returns {object} the modified schema
 */


export var schemaTypesResolver = function schemaTypesResolver(schema) {
  var idSeparator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
  var types = new Map(); // map typeId -> type

  var events = new Map(); // map eventId -> event

  var data = new Map(); // map eventId -> data prop

  var translations = new Map(); // map eventId -> translations prop
  // walk through the schema tree to find and register all events and types

  schemaTreeWalker(schema, function (elementId, elementType, element) {
    switch (elementType) {
      case 'event':
        events.set(elementId, clone(element));
        break;

      case 'types':
        Object.keys(element).forEach(function (type) {
          var typeId = elementId ? "".concat(elementId).concat(idSeparator, "types").concat(idSeparator).concat(type) : "types".concat(idSeparator).concat(type);
          types.set(typeId, clone(element[type]));
        });
        break;

      case 'data':
        data.set(elementId, clone(element));
        break;

      case 'translations':
        translations.set(elementId, clone(element));
        break;

      default:
        throw new Error('unknown schema element type', elementId, elementType);
    }
  }, {
    idSeparator: idSeparator
  });
  var resolvedSchema = {};

  var resolveCustomTypes = function resolveCustomTypes(field) {
    var defType = field.def.type;

    if (types.has(defType)) {
      field.fields = types.get(defType).fields;
    }
  }; // custom types can contain other custom types


  types.forEach(function (type) {
    type.fields.forEach(resolveCustomTypes);
  });
  events.forEach(function (event, key) {
    // flatten the fields structure
    if (event.fields) {
      event.fields.forEach(function (f) {
        try {
          resolveCustomTypes(f);
        } catch (e) {
          console.error({
            tag: 'schema-types-resolver-resolve-field-custom-types-failed',
            message: e.message,
            context: {
              eventId: event.id,
              fieldId: f.id
            }
          });
          throw e;
        }
      });
    } // place the event into the resolvedSchema tree


    var node = resolvedSchema;
    var eventIdPath = [].concat(_toConsumableArray(event.id.split(idSeparator)), ['event']);

    for (var i = 0; i < eventIdPath.length; i++) {
      var nodeName = eventIdPath[i];

      if (!node.hasOwnProperty(nodeName)) {
        var isLeafNode = i === eventIdPath.length - 1;

        if (isLeafNode) {
          // add the event element (always present)
          node.event = event; // add optional data element

          if (data.has(event.id)) {
            node.data = data.get(event.id);
          } // add optional translations element


          if (translations.has(event.id)) {
            node.translations = translations.get(event.id);
          }
        } else {
          node[nodeName] = {};
        }
      }

      node = node[nodeName];
    }
  }); // return the original schema

  return resolvedSchema;
};