import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/bootstrap.js';
import './index.less';

import App from './App';
import {LocalEventStorage} from './lib/event-storage/local-event-storage';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './registerServiceWorker';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { DataPage } from './pages/data.page.jsx';
import { EventTypePage } from './pages/event-type.page.jsx';
import { EventCreatePage } from './pages/event-create.page.jsx';
import { EventEditPage } from './pages/event-edit.page.jsx';
import { RemoteEventStorage } from './lib/event-storage/remote-event-storage.js';


const publicUrl = process.env.PUBLIC_URL || undefined;
const browserLocation = getBasenameAndLocale(publicUrl);

const storage = process.env.REACT_APP_USE_REMOTE === 'true' 
  ? new RemoteEventStorage('progether-mydata-events') 
  : new LocalEventStorage('progether-mydata-events');

const root = ReactDOM.createRoot(document.getElementById('content'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename={browserLocation.basename}>
      <Routes>
        {!browserLocation.basename && <Route index element={<LanguageRedirect/>}/> }
        <Route path={!browserLocation.basename ? "/:locale" : '/'} element={<App storage={storage} fallbackLocale={browserLocation.locale}/> }>
          <Route index element={<DataPage/>}/>
          <Route path="add" element={<EventTypePage/>}/>
          <Route path="add/*" element={<EventCreatePage/>}/>
          <Route path="edit/:eventId" element={<EventEditPage/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (!browserLocation.basename) {
  serviceWorkerRegistration.register();
}

function LanguageRedirect() {
  const userLanguage = navigator.language || navigator.userLanguage || 'en';
  
  let locale = userLanguage.split('-').shift();
  if (!['de', 'en', 'no'].includes(locale)) {
    locale = 'en';
  }

  return (
    <Navigate to={`/${locale}`} replace/>
  )
}

/**
 * Tries to extract locale and base directory of application PUBLIC_URL.
 * Normally we could just use PUBLIC_URL, but since progether has dynamic path prefixes (/data -> locale: en; /de/data -> locale: de),
 * we need to orientate ourselves first when we have PUBLIC_URL defined.
 * 
 * @param {string} publicUrl - the base directory location when application is not hosted on root
 * @returns {{ basename?: string, locale?: string }}
 */
function getBasenameAndLocale(publicUrl) {
  // when served from root directory, we do not need to proceed
  if (!publicUrl) {
    return {};
  }
  
  const {pathname} = window.location;
  // check if pathname acually contains our public url
  const indexOf = pathname.indexOf(publicUrl);
  if (indexOf < 0) {
    return {};
  }

  // determine actual PUBLIC_URL including optional locale
  const basename = pathname.substring(0, indexOf + publicUrl.length);
  const match = basename.match(/^\/(de|no)?/);
  
  return {
    locale: match ? match[1] : 'en',
    basename,
  };

}