import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { EventStorage } from "../lib/event-storage/event-storage";


export const EventContext = createContext(null);


export function EventProvider({ storage, children }) {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    storage.load().then(() => setLoading(false)).catch(err => {
        setError(err.message);
        setLoading(false);
    });
  }, []);

  return (
    <EventContext.Provider value={{ storage, loading, error }}>
      {children}
    </EventContext.Provider>
  );
}

export function useEvents() {

    const context = useContext(EventContext);
    if (!context) {
        throw new Error('no event context found');
    }

    return {
      events: context.storage.listEvents(),
      loading: context.loading,
      error: context.error
    };
}