import clone from 'clone';
import { assertSchemaHasField, assertDataObjectIsValid, assertMatchingEvent, ID_SEPARATOR, VALUE } from './utils';
import { filterSchemaByConditions } from './conditional-schema';
export var set = function set(eventSchema, fieldId, update) {
  var eventData = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

  var _ref = options || {},
      _ref$enforceCondition = _ref.enforceConditions,
      enforceConditions = _ref$enforceCondition === void 0 ? true : _ref$enforceCondition; // create a clone, to always return a new object


  eventData = clone(eventData);
  assertDataObjectIsValid(eventData); // filter eventSchema by conditions

  var filteredEventSchema = enforceConditions ? filterSchemaByConditions(eventSchema, eventData) : eventSchema;
  assertSchemaHasField(filteredEventSchema, fieldId);
  assertMatchingEvent(filteredEventSchema, eventData, true);
  /**
   * Build the data tree based from scratch if needed
   *
   * @param {object} object - The current node
   * @param {string[]} fieldIdPath - An array with the fieldId split up
   * @returns {*} - the newly build data tree
   */

  var recursiveSet = function recursiveSet(update, object, fieldIdPath) {
    if (!object.hasOwnProperty(VALUE)) {
      object[VALUE] = {};
    }

    if (fieldIdPath.length > 0) {
      var objectValue = object[VALUE];

      var _fieldId = fieldIdPath.shift();

      if (!fieldIdPath.length && update === undefined) {
        delete objectValue[_fieldId];
      } else {
        if (!objectValue.hasOwnProperty(_fieldId)) {
          objectValue[_fieldId] = {};
        }

        recursiveSet(update, objectValue[_fieldId], fieldIdPath);
      }
    } else {
      object[VALUE] = update;
    }

    return object;
  };

  var fieldIdPath = fieldId.split(ID_SEPARATOR);
  return recursiveSet(update, eventData, fieldIdPath);
};