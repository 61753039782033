import React, {Component} from 'react';
import {FormsMixin} from '../forms-mixin';
import Input from '../text/input';
import {eventFieldLabel} from '../../../../lib/intl-helper';
import {FormattedMessage} from 'react-intl';
import './gleason-primary-secondary.less';

class GleasonPrimarySecondary extends FormsMixin(Component) {

    render() {
        const field = this.getSubfield(this.props.id);

        if (!field) return null;

        const primaryField = field.type.fields.find(f => f.id === this.props.id + '/primary');
        const secondaryField = field.type.fields.find(f => f.id === this.props.id + '/secondary');

        const primaryValue = this.getValueOfField(primaryField.id);
        const secondaryValue = this.getValueOfField(secondaryField.id);
        const sum = primaryValue + secondaryValue;

        return (

            <div>

                <div className="data-form-custom-gleason">

                    <div className="data-form-custom-gleason-input">

                        <div className="data-form-custom-gleason-primary">
                            <label>
                                <FormattedMessage
                                    id={eventFieldLabel(this.props.eventId, primaryField.id)}/>
                            </label>
                            <Input def={primaryField.def}
                                id={primaryField.id}
                                visible={true}
                                eventData={this.props.eventData}
                                eventId={this.props.eventId}
                                onChange={this.props.onChange}/>
                        </div>
                        <div className="data-form-custom-gleason-add">
                            +
                        </div>
                        <div className="data-form-custom-gleason-secondary">
                            <label><FormattedMessage
                                id={eventFieldLabel(this.props.eventId, secondaryField.id)}/></label>
                            <Input def={secondaryField.def}
                                id={secondaryField.id}
                                visible={true}
                                eventData={this.props.eventData}
                                eventId={this.props.eventId}
                                onChange={this.props.onChange}/>
                        </div>

                    </div>

                    <div className="data-form-custom-gleason-sum">
                        = {sum > 0 ? sum : '?'}
                    </div>

                </div>

            </div>
        );

    }

}

export default GleasonPrimarySecondary;
