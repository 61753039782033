import { assertDataObjectIsValid, assertSchemaHasField, COMPLETENESS, ID_SEPARATOR, IS_VALID, TYPE, VALUE } from './utils';
import clone from 'clone';
/**
 * @param fieldId {string} The field id to check. Leave empty to return the event completeness
 * @param eventData {object} The event data object to check
 * @returns {*} An completeness object or undefined if no field with the given fieldId exists
 */

export function getCompleteness(fieldId) {
  var eventData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var defaultCompleteness = {
    mandatory: {
      score: 1,
      total: 0,
      set: 0
    },
    optional: {
      score: 1,
      total: 0,
      set: 0
    }
  };
  /**
   * Traverse the data tree from root to leaf.
   *
   * @param {object} object - The current node
   * @param {string[]} fieldIdPath - An array with the fieldId split up
   * @returns {*} - The objectValue or undefined if the value has not been set prior
   */

  var recursiveGetCompleteness = function recursiveGetCompleteness(object, fieldIdPath) {
    if (fieldIdPath.length === 0) {
      if (object.hasOwnProperty(COMPLETENESS)) {
        return clone(object[COMPLETENESS]);
      } else {
        return defaultCompleteness;
      }
    }

    if (!object.hasOwnProperty(VALUE)) {
      // invalid structure, cannot infer any information
      return undefined;
    }

    var objectValue = object[VALUE];

    if (fieldIdPath.length > 0) {
      var _fieldId = fieldIdPath.shift();

      if (!objectValue.hasOwnProperty(_fieldId)) {
        return defaultCompleteness;
      }

      return recursiveGetCompleteness(objectValue[_fieldId], fieldIdPath);
    }

    if (!object.hasOwnProperty(COMPLETENESS)) {
      return defaultCompleteness;
    }

    return clone(object[COMPLETENESS]);
  };

  var fieldIdPath = splitFieldPath(fieldId);
  return recursiveGetCompleteness(eventData, fieldIdPath);
}
/**
 * Return the isValid status from an event or any of its fields
 *
 * @param fieldId {string} The fieldId to check. Leave empty to retrieve the event status
 * @param eventData {object} The event data object
 * @returns {*|boolean} Returns a boolean for the status of the field, or undefined if it does not exist
 */

export function getIsValid(fieldId) {
  var eventData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var recursiveGetIsValid = function recursiveGetIsValid(object, fieldIdPath) {
    if (fieldIdPath.length === 0) {
      if (object.hasOwnProperty(IS_VALID)) {
        return object[IS_VALID];
      } else {
        return false;
      }
    }

    if (!object.hasOwnProperty(VALUE)) {
      return false;
    }

    var objectValue = object[VALUE];

    if (fieldIdPath.length > 0) {
      var _fieldId2 = fieldIdPath.shift();

      if (!objectValue.hasOwnProperty(_fieldId2)) {
        return undefined;
      }

      return recursiveGetIsValid(objectValue[_fieldId2], fieldIdPath);
    }

    return objectValue;
  };

  var fieldIdPath = splitFieldPath(fieldId);
  return recursiveGetIsValid(eventData, fieldIdPath);
}
export function getWithoutSchemaValidation(fieldId) {
  var eventData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  /**
   * Traverse the data tree from root to leaf.
   *
   * @param {object} object - The current node
   * @param {string[]} fieldIdPath - An array with the fieldId split up
   * @returns {*} - The objectValue or undefined if the value has not been set prior
   */
  var recursiveGet = function recursiveGet(object, fieldIdPath) {
    if (!object.hasOwnProperty(VALUE)) {
      return undefined;
    }

    var objectValue = object[VALUE];

    if (fieldIdPath.length > 0) {
      var _fieldId3 = fieldIdPath.shift();

      if (!objectValue.hasOwnProperty(_fieldId3)) {
        return undefined;
      }

      return recursiveGet(objectValue[_fieldId3], fieldIdPath);
    }

    return objectValue;
  };

  var fieldIdPath = fieldId.split(ID_SEPARATOR);
  return recursiveGet(eventData, fieldIdPath);
}
export function get(eventSchema, fieldId) {
  var eventData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  assertDataObjectIsValid(eventData);
  assertSchemaHasField(eventSchema, fieldId);

  if (eventData.hasOwnProperty(TYPE) && eventData[TYPE] !== eventSchema.id) {
    throw new Error('eventSchemaId mismatch');
  }

  return getWithoutSchemaValidation(fieldId, eventData);
}

function splitFieldPath(fieldId) {
  // allows us to also return the whole event
  // completeness by omitting the fieldId or
  // setting it to the fieldId separator
  if (fieldId === ID_SEPARATOR || !fieldId) {
    return [];
  }

  return fieldId.split(ID_SEPARATOR);
}