import {defineMessages} from 'react-intl';

const messages = defineMessages({
    placeForYourNotes: {
        id: 'print_template_yournotes_text',
        defaultMessage: 'A place for your notes:',
    },
    pleaseCheckByDoctor: {
        id: 'print_template_please_check_by_doctor_text',
        defaultMessage: 'Please ask your doctor to review your data',
    },
});

const marginChanges = {};

const applyMarginChange = (originalMargins, marginChanges) => {

    const newMargins = Object.assign({}, originalMargins);

    Object.keys(marginChanges).forEach(k => {
        newMargins[k] += marginChanges[k];
    });

    return newMargins;

};

const noHeader = (doc, renderOptions) => renderOptions;

export const marginChangesFirstPage = {
    top: 100
};

export const marginChangesLastPage = marginChanges;

export const marginChangesOddPage = marginChanges;

export const marginChangesEvenPage = marginChanges;

export const firstPage = async (doc, renderOptions) => {

    const {
        size: {width},
        margins: {top, left, right},
        language,
        intl,
        logo,
    } = renderOptions;


    // header logo
    const logoPaddings = {
        top: 10,
        bottom: 10,
        left: 0,
        right: 10
    };

    const logoLeft = left + logoPaddings.left;
    const logoTop = top + logoPaddings.top;

    let logoHeight;
    let logoWidth;
    let logoSrc;

    if (!logoSrc) {
        logoHeight = marginChangesFirstPage.top - logoPaddings.top - logoPaddings.bottom;
        logoWidth = (logoHeight / 546) * 1000;
        logoSrc = `images/progether-logo-${language}-1000.png`;
    }

    doc.image(logoSrc, logoLeft, logoTop, {
        height: logoHeight
    });


    // header text

    const boxPadding = 10;
    const boxWidth = width - left - right - (logoWidth + logoPaddings.left + logoPaddings.right) - boxPadding;
    const boxHeight = logoHeight + logoPaddings.top + logoPaddings.bottom;
    const boxLeft = logoLeft + (logoWidth + logoPaddings.left + logoPaddings.right) + boxPadding;
    const boxTop = top;

    doc.rect(boxLeft, boxTop, boxWidth, boxHeight)
        .save()
        .fillColor('#eee')
        .fill()
        .restore();

    const textPaddings = {top: 4, bottom: 4, left: 2, right: 2};
    const textLeft = boxLeft + textPaddings.left;
    const textTop = boxTop + textPaddings.top;
    const textWidth = boxWidth - textPaddings.left - textPaddings.right;

    doc.save()
        .fillColor('#999')
        .font('light')
        .text(intl.formatMessage(messages.placeForYourNotes), textLeft, textTop, {width: textWidth})
        .restore();

    return Object.assign({}, renderOptions, {
        margins: applyMarginChange(renderOptions.margins, marginChangesFirstPage)
    });

};

export const firstPageReview = async (doc, renderOptions) => {

    const {
        size: {width},
        margins: {top, left, right},
        language,
        intl,
        logo,
    } = renderOptions;


    // header logo
    const logoPaddings = {
        top: 10,
        bottom: 10,
        left: 0,
        right: 10
    };

    const logoLeft = left + logoPaddings.left;
    const logoTop = top + logoPaddings.top;

    let logoHeight;
    let logoWidth;
    let logoSrc;

    if (!logoSrc) {
        logoHeight = marginChangesFirstPage.top - logoPaddings.top - logoPaddings.bottom;
        logoWidth = (logoHeight / 546) * 1000;
        logoSrc = `images/progether-logo-${language}-1000.png`;
    }

    doc.image(logoSrc, logoLeft, logoTop, {
        height: logoHeight
    });


    // header text

    const boxPadding = 10;
    const boxWidth = width - left - right - (logoWidth + logoPaddings.left + logoPaddings.right) - boxPadding;
    const boxHeight = logoHeight + logoPaddings.top + logoPaddings.bottom;
    const boxLeft = logoLeft + (logoWidth + logoPaddings.left + logoPaddings.right) + boxPadding;
    const boxTop = top;

    doc.rect(boxLeft, boxTop, boxWidth, boxHeight)
        .save()
        .fillColor('#eee')
        .fill()
        .restore();

    const text = intl.formatMessage(messages.pleaseCheckByDoctor);
    const textPaddings = {top: 4, bottom: 4, left: 2, right: 2};
    const textLeft = boxLeft + textPaddings.left;
    const textTop = boxTop + textPaddings.top;
    const textWidth = boxWidth - textPaddings.left - textPaddings.right;
    const textOptions = {width: textWidth, align: 'center'};
    const calculatedHeight = doc.heightOfString(text, textOptions);

    doc.save()
        .fillColor('#000')
        .font('light')
        .text(text, textLeft, textTop + (boxHeight - calculatedHeight) / 2, textOptions)
        .restore();

    return Object.assign({}, renderOptions, {
        margins: applyMarginChange(renderOptions.margins, marginChangesFirstPage)
    });

};

export const renderLastPage = noHeader;

export const renderOddPage = noHeader;

export const renderEvenPage = noHeader;
