import Select from './select';
import BtnGroup from './btn-group';
import BtnDropdown from './btn-dropdown';
import Radio from './radio';

export default {
    default: Select,
    radio: Radio,
    button: BtnGroup,
    buttonDropdown: BtnDropdown,
    select: Select
};
