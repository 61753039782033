import React, {Component} from 'react';
import {FormsMixin} from '../forms-mixin';
import Input from '../text/input';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import './gleason-percentage.less';

class GleasonPercentage extends FormsMixin(Component) {

    renderGleasonBar = (data, i) => {

        const type = data.label;
        const referenceFieldValue = data.refValue || null;

        if (!referenceFieldValue) {
            return null;
        }

        const field = this.getSubfield(this.props.id);
        if (!field) {
            return null;
        }

        const value = this.props.eventData.get(this.props.id + '/' + type) || 0;

        if (value === 0) {

            return (
                <div className="data-form-custom-gleason-percentage-bar-segment"/>
            );

        }

        const style = {
            width: value + '%',
        };

        const classes = classNames('data-form-custom-gleason-percentage-bar-segment', {
            'data-form-custom-gleason-percentage-input-primary': i === 0,
            'data-form-custom-gleason-percentage-input-secondary': i === 1,
            'data-form-custom-gleason-percentage-input-tertiary': i === 2,

        });

        const titleTextValues = {value: referenceFieldValue, percentage: value / 100};
        return (
            <FormattedMessage id="data_form_custom_gleason_percentage_bar_segment_title"
                              defaultMessage="Gleason {value}: {percentage, number, percent}"
                              values={titleTextValues}>
                {txt => <div className={classes} style={style} title={txt}>{txt}</div>}
            </FormattedMessage>
        );

    };

    renderGleasonInput = (data, i) => {

        const type = data.label;
        const referenceFieldValue = data.refValue || null;

        if (!referenceFieldValue) {
            return null;
        }

        const field = this.getSubfield(this.props.id);
        if (!field) {
            return null;
        }

        const percentageField = field.type.fields.find(f => f.id === this.props.id + '/' + type);

        const classes = classNames('data-form-custom-gleason-sum-input', {
            'data-form-custom-gleason-sum-input-primary': i === 0,
            'data-form-custom-gleason-sum-input-secondary': i === 1,
            'data-form-custom-gleason-sum-input-tertiary': i === 2,
        });

        return (

            <div className={classes}>

                <FormattedMessage id="data_form_custom_gleason_percentage_postfix_text"
                                  defaultMessage="% of Gleason {value}"
                                  values={{value: referenceFieldValue}}>

                    {txt => (
                        <Input def={percentageField.def}
                               eventData={this.props.eventData}
                               eventId={this.props.eventId}
                               id={this.props.id + '/' + type}
                               visible={true}
                               postfixText={txt}
                               onChange={this.props.onChange}/>
                    )
                    }

                </FormattedMessage>

            </div>

        );

    };

    render() {

        const referencedValues = [['primary', 'two-values'], ['secondary', 'two-values'], 'tertiary/value'].reduce((acc, key) => {

            const params = Array.isArray(key) ? key.slice(0) : [key];
            params.reverse();

            let k = params[params.length - 1];

            if (k.indexOf('/') !== -1) {
                k = k.split('/')[0];
            }

            acc[k] = this.getValueOfField(['gleason'].concat(params).join('/'));

            return acc;

        }, {});

        const data = ['primary', 'secondary', 'tertiary']
            .filter(item => {
                return item !== 'tertiary' || (referencedValues['tertiary'] !== 'NOT_AVAILABLE' && referencedValues['tertiary'] !== 'DONT_KNOW');
            })
            .map((key) => {

                const valueOfField = this.getValueOfField([this.props.id, key].join('/'));

                const val = parseInt(valueOfField, 10) || 0;

                return {
                    label: key,
                    value: val,
                    refValue: referencedValues[key] || null,
                };

            });

        return (

            <div className="data-form-custom-gleason-percentage">

                <div className="data-form-custom-gleason-percentage-inputs">
                    {data.map(this.renderGleasonInput)}
                </div>

                <div className="data-form-custom-gleason-percentage-bar">
                    {data.map(this.renderGleasonBar)}
                </div>

            </div>

        );

    }

}

export default GleasonPercentage;
