import React, {Component} from 'react';
import {FormsMixin} from '../forms-mixin';
import PartRadio from '../../parts/radio';
import PropTypes from 'prop-types';

export class OneOfRadioGroup extends Component {

    static propTypes = {
        values: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string,
            labelFormatted: PropTypes.node
        })).isRequired,
        selectedValue: PropTypes.any,
        onSelection: PropTypes.func.isRequired
    };

    render() {

        const {values, selectedValue, onSelection} = this.props;

        return (
            <div>
                {
                    values.map(v => {
                        const isActive = v.value === selectedValue;
                        return (
                            <PartRadio key={v.value}
                                name={v.value}
                                checked={isActive}
                                label={v.labelFormatted || v.label || v.value}
                                onChange={() => onSelection(isActive ? undefined : v.value)}/>
                        );
                    })
                }
            </div>
        );

    }

}


class ConnectedOneOfRadio extends FormsMixin(Component) {
    render() {
        let def = this.props.def,
            id = this.props.id;

        let cb = function (value) {
            this.update(def, id, value, true, 1);
        };

        return (
            <div>
                {
                    def.values.map(function (v) {
                        let label = v.labelFormatted ? v.labelFormatted : v.label;

                        let prop = Object.assign({}, v, {
                            key: this.props.id + '-' + v.value,
                            name: this.props.id,
                            checked: this.getValue() === v.value,
                            onChange: cb.bind(this),
                            label: label
                        });

                        return <PartRadio {...prop}/>;

                    }.bind(this))
                }
            </div>
        );
    }

}

export default ConnectedOneOfRadio;
