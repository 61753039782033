import React from 'react';
import PropTypes from 'prop-types';
import EventTable, { EVENT_TABLE_SHOW_UPDATED_OPTIONS_KEYS } from './event-table';
import { sortEventsBy, sortEventsByDate } from 'progether-event-utils';

class SortableEventTable extends React.Component {

    static propTypes = {
        events: PropTypes.arrayOf(
            PropTypes.object,
        ),
        onEventSelection: PropTypes.func,
        onEventDeletion: PropTypes.func,
        onCreateEvent: PropTypes.func,
        sortBy: PropTypes.oneOf([ 'time', 'type' ]),
        sortDirection: PropTypes.oneOf([ 'asc', 'desc' ]),
        onSort: PropTypes.func,
        loading: PropTypes.bool,
        showComments: PropTypes.bool,
        showHeader: PropTypes.bool,
        showTypeHeader: PropTypes.bool,
        showUpdated: PropTypes.oneOf(Object.values(EVENT_TABLE_SHOW_UPDATED_OPTIONS_KEYS)),
        existingEventsCount: PropTypes.number,
        onResetFilters: PropTypes.func,
        initialSortBy: PropTypes.oneOf([ 'time', 'type' ]),
        initialSortDirection: PropTypes.oneOf([ 'desc', 'asc' ]),
        highlightUpdatedAfter: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    };

    static defaultProps = {
        initialSortBy: 'time',
        initialSortDirection: 'desc',
        showUpdated: EVENT_TABLE_SHOW_UPDATED_OPTIONS_KEYS.none,
    };

    state = {
        sortBy: this.props.initialSortBy,
        sortDirection: this.props.initialSortDirection,
    };

    render() {

        const { events } = this.props;
        const { sortBy, sortDirection } = this.state;

        const sortAscending = sortDirection === 'asc';
        const sortedEvents = sortEvents(events, sortBy, sortAscending);

        return (
            <EventTable
                {...this.props}
                events={sortedEvents}
                sortBy={sortBy}
                sortDirection={sortDirection}
                onSort={this.handleSort}
            />
        );

    }

    handleSort = (sortBy, sortDirection) => this.setState({ sortBy, sortDirection })

}

function sortEvents(events, sortBy, sortAscending = true) {

    if (sortBy === 'time') {
        return sortEventsByDate(events, sortAscending);
    }

    if (sortBy === 'updated') {
        return sortEventsBy(
            events,
            (e) => e.updated(),
            (a, b) => a && a.isBefore(b) ? -1 : 1,
            sortAscending,
        );
    }

    return sortEventsBy(
        events,
        e => e.typeId() || '',
        (a, b) => a.localeCompare(b),
        sortAscending,
    );

}

export default SortableEventTable;
