import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormsMixin} from '../forms-mixin';
import classnames from 'classnames';
import {injectIntl} from 'react-intl';
import {eventFieldValueLabel} from '../../../../lib/intl-helper';

class OneOfBtnDropdown extends FormsMixin(Component) {

    constructor(props) {

        super(props);

        this.state = {
            open: false
        };

    }

    static propTypes = {
        outerClassName: PropTypes.oneOf(['btn-group', 'input-group-btn'])
    };

    static defaultProps = {
        outerClassName: 'btn-group'
    };

    componentDidMount() {

        document.querySelector('body')
            .addEventListener('click', this.handleBodyClick);

    }

    componentWillUnmount() {

        document.querySelector('body')
            .removeEventListener('click', this.handleBodyClick);

    }

    handleBodyClick = (event) => {

        if (!this.state.open) {
            return;
        }

        const isOutsideClick = !event.path.includes(this.btnGroupElement);

        if (isOutsideClick) {
            this.handleToggle();
        }

    };

    handleItemClick = (value, event) => {

        event.preventDefault();

        this.setState({
            open: false,
        });

        const v = value.hasOwnProperty('value') ? value.value : value;
        this.update(this.props.def, this.props.id, v);

    };

    handleToggle = () => {

        this.setState({
            open: !this.state.open
        });

    };

    renderItem = (value) => {

        let v = value.value || value;
        let label = value.labelFormatted ? value.labelFormatted : value.label || v;

        return (
            <li
                key={value.value || value}
                onClick={this.handleItemClick.bind(this, value)}
                active={v === this.getValue()}
            >
                <a href="#">{label}</a>
            </li>
        );
    };

    render() {

        const title = this.props.intl.formatMessage({
            id: eventFieldValueLabel(this.props.eventId, this.props.id, ('' + this.getValue()).toLocaleLowerCase())
        });

        const classes = classnames('btn-group', {
            open: this.state.open,
        });

        return (
            <div className={this.props.outerClassName} ref={(ref) => this.btnGroupElement = ref}>
                <div className={classes}>
                    <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown"
                        onClick={this.handleToggle}
                        title={title}>
                        {title} <span className="caret"></span>
                    </button>
                    <ul className="dropdown-menu" role="menu">
                        {this.props.def.values.map(this.renderItem)}
                    </ul>
                </div>
            </div>
        );

    }

}

export default injectIntl(OneOfBtnDropdown);
