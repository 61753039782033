import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import './index.less';


function LoadingPlaceholder(props) {

    const { className, children = null, loading, message = null, ...otherProps } = props;

    if (!loading) {
        return Array.isArray(children) ? <div>{children}</div> : children;
    }

    let messageComponent;
    if (React.isValidElement(message)) {

        messageComponent = message;

    } else if (message && message.hasOwnProperty('id')) {

        messageComponent = <FormattedMessage {...message} />;

    } else {

        messageComponent = <span>{message}</span>;

    }

    const classes = classnames(
        'loading-placeholder',
        className,
    );

    return (
        <div {...otherProps} className={classes}>
            <div className="loading-placeholder-overlay">
                <i className="fa fa-4x fa-refresh spin"/>
                {messageComponent}
            </div>
        </div>
    );
}

LoadingPlaceholder.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    loading: PropTypes.bool.isRequired,
    message: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            defaultMessage: PropTypes.string
        }),
    ]),
    style: PropTypes.object,
};

export default LoadingPlaceholder;
