import { VALUE } from './utils';
export function cleanup(eventSchema, eventData) {
  // get a copy of the input data
  var resultData = JSON.parse(JSON.stringify(eventData)); // call the recursive cleanup

  fieldsCleanup(eventSchema, resultData[VALUE]); // return the cleaned copy of the data

  return resultData;
}

function fieldsCleanup(fieldsContainer, containerData) {
  if (fieldsContainer.fields) {
    Object.keys(containerData).forEach(function (k) {
      if (!k.startsWith("_")) {
        var currentField = getFieldFromFieldsContainer(fieldsContainer, k);

        if (!currentField) {
          delete containerData[k];
        } else {
          fieldsCleanup(currentField, containerData[k][VALUE]);
        }
      }
    });
  }
}

function getFieldFromFieldsContainer(fieldsContainer, field) {
  return fieldsContainer.fields.find(function (f) {
    var fid = f.id;

    if (fid.indexOf('/') !== -1) {
      fid = fid.substring(fid.lastIndexOf('/') + 1, fid.length);
    }

    return fid === field;
  });
}