import React, {Component} from 'react';
import {DateMixin} from './date-mixin';
import {FormsMixin} from '../forms-mixin';
import DateTypes from './types';
import Datepicker2 from '../../../datepicker/datepicker-2';

class DatePoint extends DateMixin(FormsMixin(Component)) {

    static propTypes = {
        value: DateTypes.point
    };

    getValidDate() {

        const valueField = this.getSubfield(this.props.id);
        if (!valueField) {
            return null;
        }

        try {
            return this.fromCustomIsoString(
                this.getValueOfField(valueField.type.fields[0].id)
            );
        } catch (ex) {
            return null;
        }

    }

    render() {

        const date = this.getValidDate() || undefined;

        return (
            <div>
                <Datepicker2 showShortcuts={true} date={date} onChange={onDateChange.bind(this)}/>
            </div>
        );

        function onDateChange(date) {
            const newDate = this.getDate(date);
            this.update(this.props.def, this.props.id + '/point', newDate);
        }

    }

}

export default DatePoint;
