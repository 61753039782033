import React, {Component} from 'react';
import {FormsMixin} from '../forms-mixin';
import PartRadio from '../../parts/radio';

import {FormattedMessage} from 'react-intl';
import {eventFieldValueLabel} from '../../../../lib/intl-helper';

class OneOfOther extends FormsMixin(Component) {
    isOther() {
        return (this.getValue() && this.getValue().substring(0, 7) === '_OTHER_');
    }

    handleOtherChange = (e) => {
        let newValue = '_OTHER_' + e.target.value;

        // update
        this.update(this.props.def, this.props.id, newValue, true, 1);
    };

    renderOther() {
        if (!this.isOther()) {
            return null;
        }

        let val = this.getValue().substring(7);

        return (
            <input
                type="text"
                className="form-control"
                onChange={this.handleOtherChange}
                value={val}/>
        );

    }

    handleRadioOnClick = (e) => {
        let newValue = this.getValue();

        if (this.isOther()) {
            // will be removed
            newValue = null;
        } else {
            newValue = '_OTHER_';
        }

        // See: https://facebook.github.io/react/docs/forms.html
        window.setTimeout(function () {
            this.update(this.props.def, this.props.id, newValue, true, 1);
        }.bind(this), 1);

        e.preventDefault();
    };

    handleRadioOnChange = (value) => {
        this.update(this.props.def, this.props.id, value, true, 1);
    };

    render() {
        return (
            <div>
                {
                    this.props.def.values.map(v => {
                        let label = v.labelFormatted ? v.labelFormatted : v.label;

                        let prop = Object.assign({}, v, {
                            name: this.props.id,
                            checked: this.getValue() === v.value,
                            onChange: this.handleRadioOnChange.bind(this, v.value),
                            label
                        });

                        return <PartRadio key={this.props.id + '-' + v.value} {...prop}/>;

                    })
                }
                <div className={'radio' + (this.isOther() ? ' checked' : '')} onClick={this.handleRadioOnClick}>
                    <label>
                        <input
                            type="radio"
                            name={this.props.id}
                            checked={this.isOther()}/>
                        <FormattedMessage id={eventFieldValueLabel(this.props.eventId, this.props.id, 'other')}/>
                    </label>
                </div>
                {this.renderOther()}
            </div>
        );

    }
}

export default OneOfOther;
