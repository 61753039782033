import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './label.less';

const Label = ({active, text, onClick}) => {

    const classes = classnames('label', 'label-default', {
        active: active
    });

    return (
        <span className={classes} onClick={() => onClick()}>{text}</span>
    );

};

Label.propTypes = {
    active: PropTypes.bool,
    text: PropTypes.node,
    onClick: PropTypes.func.isRequired,
};

export class OneOfLabelGroup extends Component {

    static propTypes = {
        values: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string,
            labelFormatted: PropTypes.node
        })).isRequired,
        selectedValue: PropTypes.any,
        onSelection: PropTypes.func.isRequired
    };

    render() {

        const {values, selectedValue, onSelection} = this.props;

        return (
            <div>
                <div className="label-group">
                    {
                        values.map(v => {
                            const isActive = v.value === selectedValue;
                            return (
                                <Label key={v.value}
                                    active={isActive}
                                    text={v.labelFormatted || v.label || v.value}
                                    onClick={() => onSelection(isActive ? undefined : v.value)}/>
                            );
                        })
                    }
                </div>
            </div>
        );

    }

}
