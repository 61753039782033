import * as d3 from '../../../d3-package';
import * as EventUtils from 'progether-event-utils';
import moment from 'moment';

export default (svg, width, height, chartEvents, timeAxisStartValue, intl, options = {}) => {

    const {
        boxFill = 'red',
        boxInnerTextColor = '#fff',
        boxOuterTextColor = '#333',
    } = options;

    const margins = {
        top: 10,
        bottom: 25,
        left: 10,
        right: 20
    };

    const innerDimensions = {
        width: width - margins.left - margins.right,
        height: height - margins.top - margins.bottom
    };

    const colors = {
        valueAxisLabels: '#555',
        valueAxisTickText: '#777',
        valueAxisTickLine: '#777', // or '#777'?
        timeAxisLabels: '#555',
        timeAxisTickText: '#777',
        timeAxisTickLine: '#777',
        valueLineStroke: '#ccc',
        valuePointStroke: '#808080',
        valuePointFill: '#66c2a5'
    };

    const timeScale = d3.scaleTime().rangeRound([0, innerDimensions.width]);
    const timeStartPosition = d => timeScale(d.from);
    const timeEndPosition = d => timeScale(d.to);
    const eventWidth = d => Math.max(timeEndPosition(d) - timeStartPosition(d), 2);

    const events = chartEvents.map(e => {
        return {
            from: e.date().from.toDate(),
            to: e.date().to ? e.date().to.toDate() : new Date(),
            interval: e.date().interval,
            text: EventUtils.eventTypeIdToString(e, intl),
            highlight: eventToSemanticOutline(e),
        };

    }).sort((a, b) => a.from - b.from);

    const myTimeFormatter = (date) => moment(date).locale(intl.locale).format('MMM YY');

    // set viewbox
    svg
        .attr('viewBox', `0 0 ${width} ${height}`)
        .attr('preserveAspectRatio', 'xMinYMin meet');

    const g = svg.append('g')
        .attr('transform', `translate(${margins.left}, ${margins.top})`)
        .style('font', 'light');

    const timeDomainEnd = moment();
    // to prevent overlapping of the psa dots/line with the y axis, add some
    // 5% padding to the lower end of the time axis
    const timeDomainLengthInDays = timeDomainEnd.diff(timeAxisStartValue, 'days');
    const extendedTimeDomainStart = moment(timeAxisStartValue).subtract(timeDomainLengthInDays * .10, 'days');
    const extendedTimeDomainEnd = timeDomainEnd; //.add(timeDomainLengthInDays * .10, 'days');
    const timeDomain = [extendedTimeDomainStart.toDate(), extendedTimeDomainEnd.toDate()];

    timeScale.domain(timeDomain);

    const timeAxis = d3.axisBottom(timeScale)
        .tickFormat(myTimeFormatter);

    const styledTimeAxis = (g) => {
        g.call(timeAxis);
        g.selectAll('.domain')
            .attr('stroke', colors.timeAxisTickLine);
        // change alignment of first tick text to ensure smooth left border
        g.selectAll('.tick:first-of-type text');
        g.selectAll('.tick line')
            .attr('stroke', colors.timeAxisTickLine);
        g.selectAll('.tick text')
            .attr('text-anchor', 'start')
            .style('font', 'light')
            .attr('fill', colors.timeAxisTickText);
    };

    g.append('g')
        .attr('class', 'axis axis--x')
        .attr('transform', 'translate(0,' + innerDimensions.height + ')')
        .call(styledTimeAxis);

    const eventsContainer = g.append('g')
        .attr('class', 'events');

    const eventPadding = 2;
    const eventHeight = innerDimensions.height / events.length - eventPadding * 2;
    const textStart = (d) => {
        if (eventWidth(d) > .3 * innerDimensions.width) {
            return timeStartPosition(d);
        }
        if (timeStartPosition(d) > .5 * innerDimensions.width) {
            return timeStartPosition(d);
        }
        return timeEndPosition(d);
    };
    const textAnchor = (d) => {
        if (eventWidth(d) > .3 * innerDimensions.width) {
            return 'start';
        }
        if (timeStartPosition(d) > .5 * innerDimensions.width) {
            return 'end';
        }
        return 'start';
    };
    const textFill = (d) => {
        if (eventWidth(d) > .3 * innerDimensions.width) {
            return boxInnerTextColor;
        }
        return boxOuterTextColor;
    };
    function renderEvent (selection) {
        if (selection.empty()) {
            return;
        }
        const d = selection.datum();
        if (d.interval) {
            selection
                .append('rect')
                .attr('x', timeStartPosition)
                .attr('y', eventPadding)
                .attr('width', eventWidth)
                .attr('height', eventHeight)
                .attr('fill', boxFill)
                .attr('stroke', d => d.highlight);

        } else {
            const height = eventHeight * 0.75;
            const halfHeight = height / 2;
            const x = timeStartPosition(d) - halfHeight;
            const y = halfHeight - eventPadding;
            const container = selection.append('g')
                .attr('transform', `translate(${x},${y})`);
            container.append('path')
                .datum(d)
                .attr('d', `M 0 0 H ${height} L ${halfHeight} ${height} z`)
                .attr('fill', boxFill)
                .attr('stroke', d => d.highlight);
        }
    }

    // prettier-ignore
    const svgEvents = eventsContainer.selectAll('.event')
        .data(events)
        .enter().append('g')
        .attr('class', 'event')
        .attr('transform', (d, i) => `translate(0,${i * (eventHeight + 2 * eventPadding)})`)
        .each(function () { d3.select(this).call(renderEvent); });

    svgEvents.append('text')
        .attr('x', textStart)
        .attr('y', eventPadding)
        .attr('dx', d => textAnchor(d) === 'start' ? 5 : -5)
        .attr('dy', eventHeight * .7)
        .attr('fill', textFill)
        .attr('font-size', eventHeight * .6)
        .attr('text-anchor', textAnchor)
        .text(d => d.text);

    function eventToSemanticOutline(event) {
        const typeId = event.typeId();

        if (typeId === 'testing/pathology/biopsy') {
            if (event.get('cancer-found') === 'YES') {
                return '#d76b73';
            }
        }

        return undefined;
    }
};
