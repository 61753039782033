import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {EventMenu} from '../components/event-editor/event-menu'
import { EventSchemaProvider } from '../contexts/schema.context';


export function EventTypePage() {
    const params = useParams();
    const navigate = useNavigate();

    const handleSelect = useCallback(eventTypeId => {
        navigate(eventTypeId)
    }, [navigate]);

    const handleBack = useCallback(() => {
        navigate('..');
    }, [navigate]);

    console.log(params)
    return (
        <EventSchemaProvider>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <EventMenu onSelect={handleSelect} onBack={handleBack} />
                    </div>
                </div>
            </div>
        </EventSchemaProvider>
    )
}

EventTypePage.propTypes = {
    eventTypeId: PropTypes.string,
}