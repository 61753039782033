import React, {Component} from 'react';
import PropTypes from 'prop-types';

import FieldSet from './fieldset';
import StructTypes from './prop-types';
import OneOf from './types/one-of/main';
import OneOfOther from './types/one-of-other/main';
import MultipleOf from './types/multiple-of/main';
import MultipleOfOther from './types/multiple-of-other/main';
import DatePoint from './types/date/point';
import DateRange from './types/date/range';
import Text from './types/text/main';
import Custom from './types/custom/main';
import classnames from 'classnames';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import MarkdownMessage from '../markdown-message';
import {eventFieldHelpText, eventFieldLabel} from '../../lib/intl-helper';
import {FormsMixin} from './types/forms-mixin';

const messages = defineMessages({
    incompleteLabel: {
        id: 'part_data_ui_form_field_incomplete_label',
        defaultMessage: 'Incomplete',
        description: 'A label shown on incomplete fields'
    }
});

const IncompleteLabel = (props) => {

    if (props.state !== true) {
        return null;
    }

    // only shown if state is true
    return (
        <FormattedMessage {...messages.incompleteLabel} >
            {
                (txt) => <div className="field-incomplete-label">{txt}</div>
            }
        </FormattedMessage>
    );

};

IncompleteLabel.propTypes = {
    state: PropTypes.bool,
};

class Field extends FormsMixin(Component) {

    static propTypes = {
        eventData: PropTypes.object.isRequired,
        eventId: PropTypes.string.isRequired,
        field: StructTypes.field,
        value: StructTypes.fieldValue,
        visible: PropTypes.bool,
        // callback
        onChange: PropTypes.func.isRequired
    };

    state = {
        help: false
    };

    renderLabel() {
        let isOptional = !this.props.field.def.mandatory;

        let classes = classnames({
            'with-help': !!this.props.field.help,
            'expanded': !!this.state.help
        });

        return (
            <label className={classes}>
                {isOptional ? <span className="form-field-mandatory-state"><FormattedMessage
                    id="event_form_field_optional_label"/></span> : null}
                <FormattedMessage id={eventFieldLabel(this.props.eventId, this.props.field.id)}/>
                {this.renderHelpIcon()}
            </label>
        );
    }


    toggleHelp() {
        this.setState((oldState) => {
            return {
                help: !oldState.help
            };
        });
    }

    getHelpText() {
        const msgId = this.props.field.help || eventFieldHelpText(this.props.eventId, this.props.field.id);

        return this.props.intl.messages.hasOwnProperty(msgId) ? msgId : null;
    }

    renderHelpIcon() {
        if (!this.getHelpText()) {
            return null;
        }

        return (
            <span className={'help-icon' + (this.state.help ? ' expanded' : '')} onClick={this.toggleHelp.bind(this)}/>
        );
    }

    renderHelpText() {
        if (!this.state.help) {
            return null;
        }
        return (
            <div className="help-text"><MarkdownMessage id={this.getHelpText()} allowVideo={true}
                forceNewTabsForLinks={true}/></div>
        );
    }

    renderFieldSet(field) {
        if (!field.fields) return null;

        return (
            <FieldSet
                eventData={this.props.eventData}
                fields={
                    field.fields.map(function (fieldDefinition) {
                        return {
                            config: Object.assign({}, this.props.config, field.def.config, fieldDefinition.config),
                            type: fieldDefinition,
                            value: this.props.eventData.get(fieldDefinition.id) || null
                        };
                    }.bind(this))
                }
                onChange={
                    (fid, value) => {
                        this.update(fid, value);
                    }
                }

                eventId={this.props.eventId}
            />
        );
    }

    renderField(field) {
        const id = field.id;
        let def = field.def;
        let Component = null;

        // manipulate def values
        def = this.transformDefValuesOfField(def, id);

        // extract the best matching representation out of a main file
        // 1. will use the defaultRepresentation set in the struct.json file
        // 2. will try if there is a default set in the main file
        // 3. will use the first representation set
        // 4. throws an error if no representation is available
        let getRepresentation = (type, defaultRepresentation) => {
            if (type.hasOwnProperty(defaultRepresentation)) {
                return type[defaultRepresentation];
            }
            if (type.hasOwnProperty('default')) {
                return type.default;
            }
            if (Object.keys(type).length > 0) {
                return type[Object.keys(type)[0]];
            }
            throw new Error('type has no representation');
        };

        switch (def.type) {
            case 'oneOf':
                Component = getRepresentation(OneOf, def.defaultRepresentation);
                break;
            case 'oneOfOther':
                Component = getRepresentation(OneOfOther, def.defaultRepresentation);
                break;
            case 'types/date-point':
                Component = DatePoint;
                break;
            case 'types/date-range':
                Component = DateRange;
                break;
            case 'multipleOf':
                Component = getRepresentation(MultipleOf, def.defaultRepresentation);
                break;
            case 'multipleOfOther':
                Component = getRepresentation(MultipleOfOther, def.defaultRepresentation);
                break;
            case 'text':
                Component = getRepresentation(Text, def.defaultRepresentation);
                break;
            default:
                if (Custom.hasOwnProperty(def.type)) {
                    // there is a specific custom rendering module for this type
                    Component = Custom[def.type];
                } else if (field.fields) {
                    return this.renderFieldSet(field);
                } else {
                    // no information found on type
                    console.warn('unknown type', def.type);
                    Component = false;
                }
        }

        if (Component) {

            const props = {
                eventData: this.props.eventData,
                config: Object.assign({}, this.props.config, def.config),
                def: def,
                id: id,
                value: this.props.value,
                onChange: this.update.bind(this),
                fields: this.props.fields,
                eventId: this.props.eventId
            };

            return <Component {...props}/>;

        }

        return <div>Field not defined yet</div>;
    }

    update(id, updatedValue) {
        this.props.onChange(id, updatedValue);
    }

    render() {

        // visibility is controlled externally
        if (!this.props.visible) return null;

        let fieldRendered = this.renderField(this.props.field);

        let isFieldset = fieldRendered.type.name === 'FieldSet';

        let completeness = this.props.eventData.completeness(this.props.field.id);

        let isComplete = (this.props.field.def.mandatory && completeness && completeness.mandatory.score) ||
            (!this.props.field.def.mandatory);

        const isIncomplete = !isComplete && !isFieldset;

        const classes = classnames({
            'has-error': !this.props.eventData.isValid(this.props.field.id),
            'optional': !this.props.field.def.mandatory,
            'incomplete': isIncomplete,
            'complete': isComplete && !isFieldset
        });

        return (
            <div key={this.props.field.id} className={classes}>
                <IncompleteLabel state={isIncomplete}/>
                {(isFieldset) ? null : this.renderLabel()}
                {this.renderHelpText()}
                {fieldRendered}
            </div>
        );
    }
}

export default injectIntl(Field);
