import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage} from 'react-intl';
import './if-no-events.less';
import LoadingPlaceholder from '../../loading-placeholder';

const messages = defineMessages({
    reset: {
        id: 'event_list_reset_filters',
        defaultMessage: 'No events in current filter set. Reset filter to see {numEvents} more {numEvents, plural, one {event} other {events}}.',
        description: 'Label for the delete event button'
    },
    empty: {
        id: 'event_list_empty',
        defaultMessage: 'No data yet. Add some now.',
        description: 'Label for the "there are now events, change this now" link'
    },
    emptyNoCreate: {
        id: 'event_list_empty_no_create',
        defaultMessage: 'No data.',
        description: 'Label for the "there are no events" case, without any method to add new events'
    },
    loading: {
        id: 'event_list_loading',
        defaultMessage: 'Loading your data',
        description: 'Label for the "we are currently loading your data" info'
    }
});

export const IfNoEvents = (props) => {

    // noinspection JSMismatchedCollectionQueryUpdate
    const {
        loading,
        events = [],
        existingEventsCount,
        onResetFilters,
        onCreateEvent
    } = props;

    if (loading) {

        return (
            <LoadingPlaceholder loading={true} message={messages.loading} />
        );

    }

    if (events.length === 0) {

        if (existingEventsCount > 0) {

            return (
                <div className="event-representation-empty">
                    <FormattedMessage {...messages.reset} values={{numEvents: existingEventsCount}}>
                        {
                            (txt) => <a onClick={() => onResetFilters()}>{txt}</a>
                        }
                    </FormattedMessage>
                </div>
            );

        } else {
            const message = onCreateEvent
                ? messages.empty
                : messages.emptyNoCreate;

            const renderFn = onCreateEvent
                ? txt => <a onClick={() => onCreateEvent()}>{txt}</a>
                : txt => <span>{txt}</span>;

            return (
                <div className="event-representation-empty">
                    <FormattedMessage {...message} >
                        {renderFn}
                    </FormattedMessage>
                </div>
            );

        }
    }

    return null;
};

IfNoEvents.propTypes = {
    loading: PropTypes.bool,
    events: PropTypes.arrayOf(PropTypes.object),
    existingEventsCount: PropTypes.number,
    onResetFilters: PropTypes.func.isRequired,
    onCreateEvent: PropTypes.func,

};

IfNoEvents.defaultProps = {
    loading: false,
    events: [],
    onResetFilters: () => null,
};
