import clone from 'clone';
import { schemaTreeWalker } from './schema-tree-walker';
var FIELD_ID_SEPARATOR = '/';
/**
 * Resolve field ids: Nested fields get prefixed with their parent
 * field id(s)
 *
 * @param {object} field - A field, basically an object
 *  with an id and an optional field property
 * @param {string} [parentId] - the (concatenated) parent id(s)
 * @returns {object} the field
 */

function resolveField(field) {
  var parentId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

  if (parentId) {
    field.id = [parentId, field.id].join(FIELD_ID_SEPARATOR);
  }

  if (field.fields) {
    field.fields.forEach(function (f) {
      return resolveField(f, field.id);
    });
  }

  return field;
}

function resolveEvent(event) {
  if (event.fields) {
    event.fields.forEach(function (f) {
      return resolveField(f);
    });
  }
}

export var schemaFieldIdResolver = function schemaFieldIdResolver(schema) {
  schemaTreeWalker(schema, function (id, type, event) {
    resolveEvent(event);
  });
  return schema;
};