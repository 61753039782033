import moment from 'moment';
var adjustPartialDatePrecision = {
  4: 'year',
  7: 'month',
  10: 'day'
};
var adjustPartialDateParsingFormat = {
  4: 'YYYY',
  7: 'YYYY-MM',
  10: 'YYYY-MM-DD'
};
export function adjustPartialDate(partialDate, toStart) {
  if (!partialDate) {
    return undefined;
  }

  var partialDateLength = partialDate.length; // check if we have a mapping for the current input string length
  // if we do not, this hints at an unsupported input format

  if (!Object.keys(adjustPartialDatePrecision).includes('' + partialDateLength)) {
    throw new Error('Unsupported date format');
  }

  var momentFromPartialDate = moment(partialDate, adjustPartialDateParsingFormat[partialDateLength]);

  if (toStart) {
    return momentFromPartialDate.startOf(adjustPartialDatePrecision[partialDateLength]);
  } else {
    return momentFromPartialDate // to get the end of the desired period with clock set to 23:59:59
    .endOf(adjustPartialDatePrecision[partialDateLength]) // adjust clock to 00:00:00 (same as the from date)
    // to prevent potential timezone issues with start end end
    // no longer being within the same day in some circumstances
    .startOf('day');
  }
}
export function tagsToArray(tags) {
  // ensure tags is an array
  if (!Array.isArray(tags)) {
    tags = (tags || '').split(',');
  } // remove any empty tags


  tags = tags.filter(function (t) {
    return typeof t === 'string';
  }).map(function (t) {
    return t.trim();
  }).filter(function (t) {
    return t.length > 0;
  }); // if empty, add default tag

  if (tags.length === 0) {
    tags.push('default');
  }

  return tags;
}