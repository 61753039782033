import * as d3 from '../../../d3-package';
import * as EventUtils from 'progether-event-utils';
import moment from 'moment';

export default (svg, width, height, psaEvents, timeAxisStartValue, intl) => {

    const margins = {
        top: 10,
        bottom: 25,
        left: 10,
        right: 20
    };

    const innerDimensions = {
        width: width - margins.left - margins.right,
        height: height - margins.top - margins.bottom
    };

    const colors = {
        valueAxisLabels: '#555',
        valueAxisTickText: '#777',
        valueAxisTickLine: '#777', // or '#777'?
        timeAxisLabels: '#555',
        timeAxisTickText: '#777',
        timeAxisTickLine: '#777',
        valueLineStroke: '#ccc',
        valuePointStroke: '#808080',
        valuePointFill: '#66c2a5'
    };

    const timeScale = d3.scaleTime().rangeRound([0, innerDimensions.width]);
    const valueScale = d3.scaleLinear().rangeRound([innerDimensions.height, 0]);

    const valueAccessor = d => d.value;
    const timeAccessor = d => d.time;

    const valuePosition = d => valueScale(valueAccessor(d));
    const timePosition = d => timeScale(timeAccessor(d));

    const events = psaEvents.map(e => {

        const modifier = e.get('value/modifier');

        return {
            value: modifier === 'LT' ? 0 : e.get('value/value'),
            time: e.date().from.toDate(),
            text: EventUtils.eventDataToString(e, intl, false, true),
            fuzzy: modifier !== 'EQ'
        };

    }).sort((a, b) => a.time - b.time);

    // define the line
    const valueLine = d3.line()
        .x(timePosition)
        .y(valuePosition);

    const myTimeFormatter = (date) => moment(date).locale(intl.locale).format('MMM YY');

    // set viewbox
    svg
        .attr('viewBox', `0 0 ${width} ${height}`)
        .attr('preserveAspectRatio', 'xMinYMin meet');

    const g = svg.append('g')
        .attr('transform', `translate(${margins.left}, ${margins.top})`)
        .style('font', 'light');

    const timeDomainEnd = moment();
    // to prevent overlapping of the psa dots/line with the y axis, add some
    // 5% padding to the lower end of the time axis
    const timeDomainLengthInDays = timeDomainEnd.diff(timeAxisStartValue, 'days');
    const extendedTimeDomainStart = moment(timeAxisStartValue).subtract(timeDomainLengthInDays * .10, 'days');
    const extendedTimeDomainEnd = timeDomainEnd; //.add(timeDomainLengthInDays * .10, 'days');
    const timeDomain = [extendedTimeDomainStart.toDate(), extendedTimeDomainEnd.toDate()];

    const defaultDomainValues = [0, .2, 1, 10, 100, 1000, 10000];
    // ensure maxValue has a default
    const maxValue = d3.max(events, valueAccessor) || .2;
    // TODO combine defaultDomainValues and sortedValueList to focus on "real values"
    //const sortedValueList = events.map(valueAccessor).sort();
    const valueDomain = defaultDomainValues.filter(t => t < maxValue).concat(maxValue);
    const valueRange = valueDomain.map((v, i) => (valueDomain.length - i) * innerDimensions.height / valueDomain.length);

    timeScale.domain(timeDomain);
    valueScale.domain(valueDomain).range(valueRange);

    const timeAxis = d3.axisBottom(timeScale)
        .tickFormat(myTimeFormatter);
    const valueAxis = d3.axisRight(valueScale)
        .tickSize(width - margins.left - margins.right)
        .tickValues(valueDomain)
        .tickFormat((v) => intl.formatNumber(v, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        }));

    const styledTimeAxis = (g) => {
        g.call(timeAxis);
        g.selectAll('.domain')
            .attr('stroke', colors.timeAxisTickLine);
        // change alignment of first tick text to ensure smooth left border
        g.selectAll('.tick:first-of-type text');
        g.selectAll('.tick line')
            .attr('stroke', colors.timeAxisTickLine);
        g.selectAll('.tick text')
            .attr('text-anchor', 'start')
            .style('font', 'light')
            .attr('fill', colors.timeAxisTickText);
    };

    g.append('g')
        .attr('class', 'axis axis--x')
        .attr('transform', 'translate(0,' + innerDimensions.height + ')')
        .call(styledTimeAxis);

    const fullWidthValueAxis = (g) => {
        g.call(valueAxis);
        // remove vertical line (.domain)
        g.select('.domain').remove();
        // remove first tick line (overlays time axis)
        g.selectAll('.tick:first-of-type line').remove();
        // change the styling of the tick marks aka lines
        g.selectAll('.tick:not(:first-of-type) line')
            .style('stroke-opacity', .5)
            .attr('stroke', colors.valueAxisTickLine)
            .attr('stroke-dasharray', '2, 2');
        g.selectAll('.tick text')
            .attr('text-anchor', 'left')
            .attr('x', 0)
            .attr('dy', -4)
            .style('font', 'light')
            .attr('fill', colors.valueAxisTickText);
    };

    g.append('g')
        .attr('class', 'axis axis--y')
        .call(fullWidthValueAxis);

    g.append('path')
        .data([events])
        .attr('class', 'line psa')
        .attr('d', valueLine)
        .style('fill', 'none')
        .style('stroke', colors.valueLineStroke)
        .style('stroke-dasharray', '10,6')
        .style('stroke-width', 4);

    g.append('g')
        .attr('class', 'points')
        .selectAll('circle')
        .data(events)
        .enter().append('circle')
        .attr('class', 'point psa')
        .style('stroke', colors.valuePointStroke)
        .style('stroke-width', 2)
        // dash if not an exact value
        .style('stroke-dasharray', (d) => (d.fuzzy) ? '2' : null)
        .style('fill', colors.valuePointFill)
        .attr('cx', timePosition)
        .attr('cy', valuePosition)
        .attr('r', 6);

    const labelPositioning = (d, i, nodes) => {

        const getSlopeDegree = (d0, d1) => {

            const y0 = valueScale(valueAccessor(d0));
            const y1 = valueScale(valueAccessor(d1));
            const x0 = timeScale(timeAccessor(d0));
            const x1 = timeScale(timeAccessor(d1));

            // https://gist.github.com/conorbuck/2606166
            const angleRad = Math.atan2((y1 - y0), (x1 - x0));
            return angleRad * 180 / Math.PI;

        };

        const getTimeDistance = (d0, d1) => {
            const x0 = timeScale(timeAccessor(d0));
            const x1 = timeScale(timeAccessor(d1));
            return x1 - x0;
        };

        const slopeIn = (i === 0) ? 0 : getSlopeDegree(d, events[i - 1]);
        const slopeOut = (i === events.length - 1) ? 0 : getSlopeDegree(d, events[i + 1]);

        const timeDistancePrev = (i === 0) ? Number.POSITIVE_INFINITY : getTimeDistance(events[i-1], d);
        const timeDistanceNext = (i === events.length - 1) ? Number.POSITIVE_INFINITY : getTimeDistance(d, events[i+1]);

        const spaces = {
            top: 20,
            bottom: 20,
            left: 20,
            right: 20
        };

        const tests = {
            q0: (slopeIn, slopeOut, x, y) => slopeIn >= -90 && y > margins.top + spaces.top,
            q1: (slopeIn, slopeOut, x, y) => slopeOut >= 0 && y > margins.top + spaces.top,
            q2: (slopeIn, slopeOut, x, y) => slopeOut >= 90 && innerDimensions.height - y > spaces.bottom,
            q3: (slopeIn, slopeOut, x, y) => slopeIn <= 90 && innerDimensions.height - y > spaces.bottom,
            top: (slopeIn, slopeOut, x, y) => slopeIn >= 0 && slopeOut >= 0 && y > margins.top + spaces.top, // eslint-disable-line no-unused-vars
            left: (slopeIn, slopeOut, x, y) => x > 40 && timeDistancePrev > spaces.left, // eslint-disable-line no-unused-vars
            middle: (slopeIn, slopeOut, x, y) => x > 20 && innerDimensions.width - x > spaces.right && timeDistancePrev > spaces.left / 2 && timeDistanceNext > spaces.right / 2, // eslint-disable-line no-unused-vars
            right: (slopeIn, slopeOut, x, y) => innerDimensions.width - x > spaces.right && timeDistanceNext > spaces.right, // eslint-disable-line no-unused-vars
            bottom: (slopeIn, slopeOut, x, y) => slopeIn <= 0 && slopeOut <= 0 && innerDimensions.height - y > spaces.bottom  // eslint-disable-line no-unused-vars
        };
        const placementOptions = [
            {
                name: 'top-middle',
                requires: {
                    top: true,
                    middle: true
                },
                dy: -10,
                dx: 0,
                textAnchor: 'middle'
            },
            {
                name: 'top-right',
                requires: {
                    q1: true,
                    right: true
                },
                dy: -10,
                dx: 0,
                textAnchor: 'start'
            },
            {
                name: 'top-left',
                requires: {
                    q0: true,
                    left: true
                },
                dy: -10,
                dx: 0,
                textAnchor: 'end'
            }, {
                name: 'bottom-middle',
                requires: {
                    bottom: true,
                    middle: true
                },
                dy: 20,
                dx: 0,
                textAnchor: 'middle'
            },
            {
                name: 'bottom-right',
                requires: {
                    q2: true,
                    right: true
                },
                dy: 20,
                dx: 0,
                textAnchor: 'start'
            },
            {
                name: 'bottom-left',
                requires: {
                    q3: true,
                    left: true
                },
                dy: 20,
                dx: 0,
                textAnchor: 'end'
            }
        ];

        const x = timeScale(timeAccessor(d));
        const y = valueScale(valueAccessor(d));

        const testResults = Object.entries(tests).reduce((result, [name, test]) => {
            return Object.assign(result, {[name]: test(slopeIn, slopeOut, x, y)});
        }, {});

        // filter those placements, that are possible according to the current test results
        const availablePlacements = placementOptions.filter(p => Object.entries(p.requires).every(([name, result]) => testResults[name] === result));

        //console.log('_placements', availablePlacements, timeAccessor(d), valueAccessor(d));

        // take the first placement
        // TODO: Check if we need to do a second round of establishing which placement to use
        const placement = availablePlacements.shift();

        if (placement) {
            // this is not set to the required value within an arrow function
            // https://medium.com/@yonester/on-d3-and-arrow-functions-b6559c1cebb8
            d3.select(nodes[i])
                .attr('text-anchor', placement.textAnchor)
                .attr('dx', placement.dx)
                .attr('dy', placement.dy);
        }

    };

    g.append('g')
        .attr('class', 'point-labels')
        .selectAll('text')
        .data(events)
        .enter().append('text')
        .attr('x', timePosition)
        .attr('y', valuePosition)
        .style('font-size', 12)
        .style('fill', colors.valueAxisTickText)
        .text(d => d.text)
        .each(labelPositioning);

};
