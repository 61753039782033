import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Completeness} from '../utils/custom-prop-types';
import {eventFieldLabel} from '../../../lib/intl-helper';
import {FormattedMessage} from 'react-intl';
import './segmented-progress-bar.less';

export default function SegmentedProgressBar(props) {

    const textualValues = {
        current: props.segments.findIndex(s => s.active) + 1,
        total: props.segments.length
    };

    const incompleteSegments = props.segments.filter(s => s.incomplete);

    return (
        <div>
            <div className="segmented-progress-bar hidden-xs">
                {props.segments.map((segment) => (
                    <FormattedMessage key={segment.id} id={eventFieldLabel(props.eventId, segment.id)}>
                        {
                            txt => (
                                <div key={segment.id} className={classNames('segment-progress-bar-segment', {
                                    'segment-progress-bar-segment-active': segment.active,
                                    'segment-progress-bar-segment-complete': segmentIsMandatoryComplete(segment),
                                    'segment-progress-bar-segment-incomplete': segment.incomplete,
                                })} onClick={() => props.onSegmentClick(segment.index)} title={txt}/>
                            )
                        }
                    </FormattedMessage>
                ))}
            </div>
            <div className="segmented-textual-progress-bar visible-xs">
                <FormattedMessage id="data_form_segmented_progress_text"
                    defaultMessage="Question {current} of {total}"
                    values={textualValues}/>
                <InvalidSegmentsList segments={incompleteSegments}
                    eventId={props.eventId}
                    onChange={(index) => props.onSegmentClick(index)}/>
            </div>
        </div>
    );

}

const SegmentProp = PropTypes.shape({
    index: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    completeness: Completeness.isRequired,
    active: PropTypes.bool.isRequired,
    incomplete: PropTypes.bool.isRequired,
});

SegmentedProgressBar.propTypes = {
    segments: PropTypes.arrayOf(SegmentProp),
    onSegmentClick: PropTypes.func.isRequired,
    eventId: PropTypes.string.isRequired,
};

function InvalidSegmentsList(props) {

    if (!props.segments || props.segments.length < 1) {
        return null;
    }

    return (
        <div className="segmented-progress-bar-invalid-segments-list">
            <FormattedMessage id="data_form_segmented_progress_invalid_segments_list"
                defaultMessage="We need additional information at:"/>
            <SegmentList {...props}/>
        </div>
    );

}

InvalidSegmentsList.propTypes = {
    segments: PropTypes.arrayOf(SegmentProp),
    eventId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

function SegmentSelect(props) {

    const currentSegment = props.segments.find(s => s.active);

    return (
        <select className="form-control visible-xs segmented-progress-bar-select"
            onChange={(e) => props.onChange(parseInt(e.currentTarget.value, 10))}
            value={currentSegment && currentSegment.index}>
            {props.segments.map((segment) => (
                <FormattedMessage key={segment.id} id={eventFieldLabel(props.eventId, segment.id)}>
                    {
                        txt => (
                            <option key={segment.id} value={segment.index}>
                                {segment.index + 1}: {txt}
                            </option>
                        )
                    }
                </FormattedMessage>
            ))}
        </select>
    );

}

SegmentSelect.propTypes = {
    segments: PropTypes.arrayOf(SegmentProp),
    eventId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

function SegmentList(props) {

    return (
        <ul className="segmented-progress-bar-list">
            {props.segments.map((segment) => (
                <FormattedMessage key={segment.id} id={eventFieldLabel(props.eventId, segment.id)}>
                    {
                        txt => (
                            <li>
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    props.onChange(segment.index);
                                }} href="#">
                                    {segment.index + 1}: {txt}
                                </a>
                            </li>
                        )
                    }
                </FormattedMessage>
            ))}
        </ul>
    );

}

SegmentList.propTypes = {
    segments: PropTypes.arrayOf(SegmentProp),
    eventId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

function segmentIsMandatoryComplete(segment) {

    const hasMandatory = segment.completeness.mandatory.total > 0;

    if (hasMandatory) {
        return segment.completeness.mandatory.score === 1;
    } else {
        return segment.completeness.optional.score === 1;
    }

}
