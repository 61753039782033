import { Event } from "progether-data-schema/cjs/event";
import { useCallback, useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EventEditorContext, EventEditorProvider } from "../contexts/event-editor.context";
import { EventContext } from "../contexts/event.context";
import { EventEditor } from '../components/event-editor/event-editor';
import LoadingPlaceholder from '../components/loading-placeholder';
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
    confirmCancel: {
        id: 'event_form_modal_close_confirmation_text',
        defaultMessage: 'You have unsaved data, cancel anyway?',
    },
});


function EventEditorWrapper() {
    const intl = useIntl();
    const navigate = useNavigate();

    const eventEditor = useContext(EventEditorContext);
    const [showHelp, setShowHelp] = useState(false);

    const handleCancel = useCallback(() => {
        if (eventEditor.dirty) {
            const allow = window.confirm(intl.formatMessage(messages.confirmCancel));
            if (!allow) {
                return;
            }
        }

        navigate(-1);
    }, [eventEditor.dirty, navigate, intl]);

    return (
        <LoadingPlaceholder loading={eventEditor.loading}>
            <EventEditor
                event={eventEditor.event}
                showHelp={showHelp}
                onShowHelp={setShowHelp}

                onChange={eventEditor.onUpdate}
                onCancel={handleCancel}
                onSubmit={eventEditor.onSave}
                onSubmitAndNext={eventEditor.onSaveAndNext}
                saveInProgress={eventEditor.saving}

            />
        </LoadingPlaceholder>
    );
}

export function EventCreatePage() {
    const location = useLocation();
    const params = useParams();
    const eventTypeId = params['*'];
    
    const navigate = useNavigate();

    const handleNext = useCallback(() => {
        navigate(location.pathname.replace(eventTypeId, ''));
    }, [navigate, eventTypeId, location]);

    const handleDone = useCallback(() => {
        navigate(location.pathname.replace('/add/' + eventTypeId, '') || '/')
    }, [navigate, eventTypeId]);
    const { storage } = useContext(EventContext);
    
    return (
        <EventEditorProvider storage={storage} eventTypeId={eventTypeId} onDone={handleDone} onContinue={handleNext}>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <EventEditorWrapper />
                    </div>
                </div>
            </div>
        </EventEditorProvider>
    )
}