// replacement for (v || defValue) as we 
// have some v === 0 which are valid and
// should not be replaced with the default

import moment from 'moment';
import dateParsingFormats from './date-parsing-formats.json';
import {mapLocaleForMoment} from './_parts';

export function ifNull (value, defValue) {
    if (value === null || value === undefined) {
        return defValue;
    }
    return value;
}

// return the smaller (read: earlier) of two 
// moment objects
export function minMoment (momentA, momentB) {
    if (momentA.isBefore(momentB)) {
        return momentA;
    }
    return momentB;
}

const getDateParsingFormatsForLocale = (locale) => {
    if (!dateParsingFormats.hasOwnProperty(locale)) {
        if (!dateParsingFormats.hasOwnProperty('en')) {
            throw new {
                message: 'dateParsingFormats needs to have formats for English (en) defined'
            };
        }
        locale = 'en'; // using English as default, expecting it to always be present
    }
    return dateParsingFormats[locale];
};

/**
 * Check if the given moment lies within the given bounds using
 * the required precision.
 * @param moment {moment} - A moment.js object representing the value under test
 * @param min {moment} - A moment.js object representing the smallest allowed date
 * @param max {moment} - A moment.js object representing the larged allowed date
 * @param precision {string} - The desired precision for this test, one of ("day", "month", "year")
 */
export const checkDateConstraints = (moment, min, max, precision) => {
    return (moment.isSameOrAfter(min.clone().startOf(precision))
    && moment.isSameOrBefore(max.clone().endOf(precision)));
};

/**
 * Try to convert a textual representation of a date into a valid date object.
 * Also considering an upper and lower bound.
 * This method uses locale specific parsing rules.
 *
 * @param input {string} - the textual representation of a date
 * @param min {moment} - A moment.js object representing the smallest allowed date
 * @param max {moment} - A moment.js object representing the larged allowed date
 * @param locale {string} - The locale used for parsing
 * @returns {*}
 * @constructor
 */
export const parseAndCheckDateConstraints = (input, min, max, locale = 'de') => {

    const parsingResult = parseTextualDateInput(input, locale);

    const inputCouldBeParsed = typeof parsingResult === 'object' && parsingResult.hasOwnProperty('moment');

    if (inputCouldBeParsed) {
        const isWithinDateBounds = checkDateConstraints(parsingResult.moment, min, max, parsingResult.precision);
        if (isWithinDateBounds) {
            return parsingResult;
        } else {
            return -1; // not within bounds
        }
    }

    return parsingResult;

};

/**
 * Try to convert a textual representation of a date into a valid date object.
 * This method uses locale specific parsing rules.
 *
 * @param input {string} - the textual representation of a date
 * @param locale {string} - The locale used for parsing
 * @returns {*}
 * @constructor
 */
export const parseTextualDateInput = (input, locale = 'de') => {

    // no input at all
    if (!input) {
        return 0;
    }

    try {
        // trim input
        input = input.trim();
    } catch (ex) {
        // catch exception if input has no property trim()
        console.warn('invalid input supplied to parseTextualDateInput', input);
        return 0; // no input provided
    }

    // no input provided
    if (input.length === 0) {
        return 0;
    }

    // parsing formats
    const formats = getDateParsingFormatsForLocale(locale);

    // parse using all defined formats
    // will use the first matching, strict parsing is on
    let localMoment = moment(input, formats.map(f => f.format), mapLocaleForMoment(locale), true);
    // if one of the formats could be found we should have a valid date
    if (localMoment.isValid()) {
        // get some information about the used format
        let usedFormat = localMoment._f;
        // get the precision the used format provides us
        let precision = formats.find(f => f.format === usedFormat).precision;
        // return the moment and formatting info instead of a simple boolean
        return {moment: localMoment, format: usedFormat, precision: precision};
    }

    return -1; // could not parse input

};

export const getScrollParent = (element, includeHidden) => {
    let style = getComputedStyle(element);
    let excludeStaticParent = style.position === 'absolute';
    let overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

    if (style.position === 'fixed') return document.body;
    for (let parent = element; (parent = parent.parentElement);) {
        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === 'static') {
            continue;
        }
        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) return parent;
    }

    return document.body;
};
