import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';


export default class Popover extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        placement: PropTypes.string,
        positionLeft: PropTypes.number,
        positionTop: PropTypes.number,
        arrowOffsetLeft: PropTypes.number,
        arrowOffsetTop: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        element: PropTypes.object,
        children: PropTypes.node,
        style: PropTypes.object,
    };

    static defaultProps = {
        placement: 'top',
        width: 250,
        height: 150,
    };

    render() {
        const {
            title,
            children,
            placement,
            positionLeft,
            positionTop,
            arrowOffsetTop,
            arrowOffsetLeft,
            width,
            height,
            style,
            ...otherProps
        } = this.props;

        const classes = classnames('popover', placement);

        const styles = {
            display: 'block',
            top: positionTop,
            left: positionLeft,
            width: width + 'px',
            height: height + 'px',
            ...style,
        };

        const arrowStyles = {
            top: arrowOffsetTop,
            left: arrowOffsetLeft,
        };

        return (
            <div
                role="tooltip"
                className={classes}
                style={styles}
                { ...otherProps }
            >
                <div className="arrow" style={arrowStyles} />

                { title && <h3 className="popover-title">{title}</h3> }

                <div className="popover-content">
                    { children }
                </div>
            </div>

        );
    }
}
