const defaultHeadlineOptions = {
    paddings: {
        top: 15,
        bottom: 5,
        left: 0,
        right: 5
    },
    background: {
        color: '#ccc',
        opacity: 0
    },
    font: {
        color: '#000',
        size: 12,
        name: 'normal'
    }
};

export default (doc, text, top, renderOptions) => {

    const headlineOptions = Object.assign({}, defaultHeadlineOptions, renderOptions.headlineOptions);

    doc.save().font(headlineOptions.font.name).fontSize(headlineOptions.font.size);

    const innerPageWidth = renderOptions.size.width - renderOptions.margins.left - renderOptions.margins.right;

    const headlinePaddings = {
        top: headlineOptions.paddings.top,
        bottom: headlineOptions.paddings.bottom,
        left: headlineOptions.paddings.left,
        right: headlineOptions.paddings.right
    };

    const headlineBoxDimensions = {
        left: renderOptions.margins.left,
        top: top,
        width: innerPageWidth,
        height: doc.heightOfString(text, {width: innerPageWidth}) + headlinePaddings.top + headlinePaddings.bottom
    };

    if (headlineOptions.background.opacity > 0) {
        doc.save()
            .rect(headlineBoxDimensions.left, headlineBoxDimensions.top, headlineBoxDimensions.width, headlineBoxDimensions.height)
            .fill(headlineOptions.background.color, headlineOptions.background.opacity)
            .restore();
    }

    const headlineTextDimensions = {
        left: headlineBoxDimensions.left + headlinePaddings.left,
        top: headlineBoxDimensions.top + headlinePaddings.top,
        width: headlineBoxDimensions.width
    };

    doc.fill(headlineOptions.font.color)
        .text(text, headlineTextDimensions.left, headlineTextDimensions.top, {width: headlineTextDimensions.width})
        .restore();

    return {
        width: headlineBoxDimensions.width,
        height: headlineBoxDimensions.height
    };

};