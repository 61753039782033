import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SegmentedProgressBar from './parts/segmented-progress-bar';
import FieldSet from './fieldset';
import {EVENT_KEY_WORDS, Event } from 'progether-data-schema';
import {FormattedMessage} from 'react-intl';
import FieldPagination from './parts/field-pagination';

import './form.less'; // require stylesheet

const {TYPE} = EVENT_KEY_WORDS;


class Builder extends Component {

    constructor(props) {

        super(props);

        this.state = {
            fieldIndex: 0,
        };

    }


    static propTypes = {
        // The event to render the form for, may be an
        // empty new event
        event: PropTypes.instanceOf(Event),
        // An event schema to be used instead of the schema
        // associated with the event object
        eventSchemaOverride: PropTypes.object,
        // Do not show optional fields
        disableOptionalFields: PropTypes.bool,
        // Called if the user requests a form reset
        // If not provided, onChange will be called
        // with an empty event of the same type
        onReset: PropTypes.func,
        // Called if the user changes any event value
        // Will return an updated event
        onChange: PropTypes.func.isRequired,
        // Called if the user submits the form
        onSubmit: PropTypes.func.isRequired,
        // If true, show for each field if the
        // information is entered completely
        showCompleteness: PropTypes.bool,
        // If true, show one field at a time instead
        // of all (visible) fields at once
        pagedFields: PropTypes.bool,
    };

    onReset = (e) => {

        e.preventDefault();

        if (typeof this.props.onReset === 'function') {
            this.props.onReset();
        }

        this.props.onChange(
            {
                [TYPE]: this.props.event.typeId(),
            },
            false,
            {
                mandatory: 0, optional: 0
            }
        );

    };

    onSubmit = (e) => {

        e.preventDefault();

        if (typeof this.props.onSubmit === 'function') {
            this.props.onSubmit(this.props.event);
        }

    };

    render() {

        const {fieldIndex} = this.state;
        const {event, pagedFields, onChange, showCompleteness} = this.props;

        // no event provided
        if (!event) {

            return (
                <div className="data-form-error data-form-error-missing-event">
                    <FormattedMessage id="data_form_no_event_loaded_text" defaultMessage="No event loaded!"/>
                </div>
            );

        }

        // invalid event provided
        if (!(event instanceof Event)) {

            return (
                <div className="data-form-error data-form-error-invalid-event">
                    <FormattedMessage id="data_form_invalid_event_text" defaultMessage="Oops, cannot read event!"/>
                </div>
            );

        }

        const eventSchema = this.checkEventSchemaOverride();

        const handleChange = (id, changedValue) => {

            let newEvent = event;

            /*
            * Allow concurrently set of multiple values in event when "id" is an object of type {fieldID: fieldValue, ...}
            * If "id" is an string, only the field with the given "id" will be set to "changedValue" in the event data.
            */
            const changes = (typeof id !== 'string') ? id : {[id]: changedValue};

            /*
             * If we override our schema, which should still be sane, we need to override condition checks.
             * This is necessary because some previously optional fields might have been promoted to be mandatory.
             */
            const enforceConditions = !this.props.eventSchemaOverride;

            Object.keys(changes).forEach(k => {
                newEvent = newEvent.set(k, changes[k], { enforceConditions });
            });

            onChange(newEvent);

        };

        const formClasses = classNames({
            'edit': showCompleteness,
            'data-form-paged': pagedFields,
            'data-form-single': !pagedFields,
        });

        const fields = (eventSchema.fields || []);
        const visibleFields = pagedFields ? [fields[fieldIndex]] : fields;
        const segmentFields = fields.map((field, index) => ({
            index: index,
            id: field.id,
            completeness: event.completeness(field.id),
            active: index === fieldIndex,
            incomplete: (showCompleteness && isIncompleteField(field, event)) || !event.isValid(field.id),
        }));

        return (

            <form name={eventSchema.id.replace(/[^a-z0-9]+/ig, '-')} className={formClasses} onReset={this.onReset}
                onSubmit={this.onSubmit}>

                <Optional show={pagedFields}>
                    <SegmentedProgressBar segments={segmentFields}
                        eventId={event.typeId()}
                        onSegmentClick={fieldIndex => this.setState({fieldIndex})}
                    />
                </Optional>

                <FieldSet
                    eventData={event}
                    fields={
                        visibleFields.map(fieldDefinition => {
                            return {
                                config: fieldDefinition.def.config || {},
                                type: fieldDefinition,
                                value: event.get(fieldDefinition.id)
                            };
                        })
                    }
                    onChange={handleChange}
                    eventId={eventSchema.id}
                />

                <Optional show={pagedFields}>
                    <FieldPagination
                        fieldCount={eventSchema.fields.length}
                        fieldIndex={fieldIndex}
                        segments={segmentFields}
                        onChange={fieldIndex => this.setState({fieldIndex})}
                        eventId={event.typeId()}
                    />
                </Optional>

            </form>

        );

    }

    checkEventSchemaOverride() {

        const { event, eventSchemaOverride } = this.props;

        const eventSchema = event.eventSchema();

        if (!eventSchemaOverride) {
            return eventSchema;
        }

        if (!checkFields(eventSchema, eventSchemaOverride)) {
            console.warn('Schema found in eventSchemaOverride does not match event.eventSchema()!');
        }

        return eventSchemaOverride;

        // check if fields contained in eventSchemaOverride do match original fields (ignoring order)
        function checkFields(original, override) {

            if (!override) {
                console.warn('Override for field ID does exist:', original.id);
                return false;
            }

            const { fields = [], id, def } = original;
            const { fields: overrideFields = [], id: overrideId, def: overrideDef } = override;

            if (id !== overrideId) {
                console.warn('IDs do not match:', { override: overrideId, original: id });
                return false;
            }

            if (fields.length !== overrideFields.length) {
                console.warn('Number of fields do not match:', { id, override: overrideFields.length, original: fields.length });
                return false;
            }

            if (!!def !== !!overrideDef) {
                console.warn('Definition does not match:', {id, override: overrideDef, original: def });
            }

            if (def && def.type !== overrideDef.type) {
                console.warn('Types do not match:', { id, override: overrideDef.type, original: def.type });
            }

            if (def && def.mandatory && !overrideDef.mandatory) {
                console.warn('Mandatory fields cannot to be set to optional', { id, override: overrideDef.mandatory, original: def.mandatory });
            }
            return fields.every(field =>
                checkFields(field, overrideFields.find(overrideField => overrideField.id === field.id))
            );

        }
    }
}

export default Builder;

function Optional(props) {

    if (!props.show) {
        return null;
    }

    return (
        props.children
    );

}

Optional.propTypes = {
    show: PropTypes.bool.isRequired,
};

function isIncompleteField(fieldDef, event) {

    const completeness = event.completeness(fieldDef.id);

    return (completeness.mandatory.total > 0 && completeness.mandatory.score < 1);

}
