import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import {eventFormHelpText} from '../../lib/intl-helper';
import MarkdownMessage from '../markdown-message';
import './edit-help.less';

export function Help({event, onClose, closeMessage}) {

    return (
        <div className={classNames('edit-event-box-help')}>

            <div className="edit-event-box-help-content">

                <div>

                    <MarkdownMessage
                        id={eventFormHelpText(event.typeId())}
                        forceNewTabsForLinks={true}
                        allowVideo={true}
                        className="edit-event-box-help-content-text"
                    />

                </div>

                <div className="edit-event-box-help-actions">
                    <FormattedMessage {...closeMessage}>
                        {txt => (
                            <a className="btn btn-link" onClick={onClose}>
                                <i className="fa fa-close" title={txt}/>
                                {txt}
                            </a>
                        )}
                    </FormattedMessage>
                </div>

            </div>

        </div>
    );

}

Help.propTypes = {
    event: PropTypes.object,
    onClose: PropTypes.func,
    closeMessage: PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string,
    }).isRequired
};
