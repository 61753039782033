import React from 'react';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {Completeness, Message} from '../utils/custom-prop-types';
import './field-pagination.less';
import {eventFieldLabel} from '../../../lib/intl-helper';

const messages = defineMessages({

    paginationPrevious: {
        id: 'data_form_builder_pagination_button_previous_label',
        defaultMessage: 'Previous',
    },
    paginationNext: {
        id: 'data_form_builder_pagination_button_next_label',
        defaultMessage: 'Next: {id}',
    },

});

const FieldPaginationButton = injectIntl(_FieldPaginationButton);

export default function FieldPagination(props) {

    return (
        <div className="data-form-field-pagination">
            <FieldPaginationButton
                targetIndex={props.fieldIndex + 1}
                fieldCount={props.fieldCount}
                message={messages.paginationNext}
                segment={props.segments[props.fieldIndex + 1]}
                style="primary"
                onClick={() => props.onChange(props.fieldIndex + 1)}
                eventId={props.eventId}
            />
            <FieldPaginationButton
                targetIndex={props.fieldIndex - 1}
                fieldCount={props.fieldCount}
                message={messages.paginationPrevious}
                segment={props.segments[props.fieldIndex - 1]}
                style="link"
                onClick={() => props.onChange(props.fieldIndex - 1)}
                eventId={props.eventId}
            />
        </div>
    );

}

const SegmentProp = PropTypes.shape({
    id: PropTypes.string.isRequired,
    completeness: Completeness.isRequired,
    active: PropTypes.bool.isRequired,
    incomplete: PropTypes.bool.isRequired,
});

FieldPagination.propTypes = {
    fieldCount: PropTypes.number.isRequired,
    fieldIndex: PropTypes.number.isRequired,
    segments: PropTypes.arrayOf(SegmentProp),
    onChange: PropTypes.func.isRequired,
    eventId: PropTypes.string,
};

/**
 * Requires intl, so must be wrapped in `injectIntl` before use
 *
 * @param props
 * @returns {null|*}
 * @private
 */
function _FieldPaginationButton(props) {

    if (props.targetIndex < 0 || props.targetIndex >= props.fieldCount) {
        return null;
    }

    const fieldLabelId = eventFieldLabel(props.eventId, props.segment && props.segment.id);
    const localizedFieldLabel = props.intl.formatMessage({id: fieldLabelId, defaultMessage: ''});

    return (
        <FormattedMessage {...props.message} values={{id: localizedFieldLabel}}>
            {
                txt => (
                    <a className={`data-form-field-pagination-btn btn btn-${props.style}`}
                        onClick={handleClick}
                        title={localizedFieldLabel}>
                        {txt}
                    </a>
                )
            }
        </FormattedMessage>
    );

    function handleClick(e) {

        e.preventDefault();
        props.onClick();

    }

}

_FieldPaginationButton.propTypes = {
    fieldCount: PropTypes.number.isRequired,
    targetIndex: PropTypes.number.isRequired,
    message: Message.isRequired,
    style: PropTypes.oneOf(['default', 'primary', 'link']),
    onClick: PropTypes.func.isRequired,
    segment: SegmentProp,
    eventId: PropTypes.string,
};
