import PropTypes from 'prop-types';

export const Message = PropTypes.shape({

    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired,

});

export const Completeness = PropTypes.shape({
    mandatory: PropTypes.shape({
        set: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        score: PropTypes.number.isRequired,
    }).isRequired,
    optional: PropTypes.shape({
        set: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        score: PropTypes.number.isRequired,
    }).isRequired,
});
