import { Event } from "progether-data-schema";

export class EventStorage {

    load() { throw new Error('not implemented'); }

    listEvents() { throw new Error('not implemented'); }

    getEvent(eventId) { throw new Error('not implemented'); }

    saveEvent(event)  { throw new Error('not implemented'); }

    deleteEvent(eventId)  { throw new Error('not implemented'); }
    
    createEvent(eventTypeId) {
        return Event.create(eventTypeId);
    }
}