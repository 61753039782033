import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormsMixin} from '../forms-mixin';
import {injectIntl} from 'react-intl';
import {eventFieldValueLabel, eventFieldPlaceholder} from '../../../../lib/intl-helper';
import classNames from 'classnames';
import './select.less';

class OneOfOtherSelect extends FormsMixin(Component) {

    constructor(props) {

        super(props);

        this.state = {
            showDropdown: false,
            keyboardSelectedDropdownItem: 0
        };

        this.myRefs = {
            keyboardSelectedDropdown: null,
            dropdownList: null,
            dropdownButton: null
        };

    }

    componentDidUpdate() {

        // scroll within the dropdown if needed
        if (this.myRefs.keyboardSelectedDropdown !== null) {

            const item = this.myRefs.keyboardSelectedDropdown;
            const list = this.myRefs.dropdownList;

            const scrollTop = list.scrollTop;
            const offsetTop = item.offsetTop;
            const listHeight = list.clientHeight;
            const itemHeight = item.clientHeight;

            const belowView = offsetTop + itemHeight > listHeight;
            const aboveView = offsetTop < scrollTop;

            if (belowView || aboveView) {
                list.scrollTop = offsetTop;
            }

        }

        if (!this.state.showDropdown && this.myRefs.dropdownButton === document.activeElement) {
            console.log('dropdown button has focus');
            this.myRefs.dropdownButton.blur();
        }

    }

    isOther() {
        return (this.getValue() && this.getValue().substring(0, 7) === '_OTHER_');
    }

    handleDropdownSelection = (value) => {
        this.update(this.props.def, this.props.id, value, true, 1);
        this.setState({
            showDropdown: false
        });
    };

    handleInputChange(e) {
        const otherValue = `_OTHER_${e.target.value}`;
        // update
        this.update(this.props.def, this.props.id, otherValue, true, 1);
    }

    handleBlur() {
        // make sure the dropdown stays visible long enough to click something
        window.setTimeout(() => {
            this.setState({
                showDropdown: false
            });
        }, 250);
    }

    handleInputKeyDown(e) {

        const key = e.keyCode || e.which;

        const {keyboardSelectedDropdownItem} = this.state;
        const valueCount = this.getFilteredOptionsCount();

        switch (key) {
            case 38: // up arrow
                e.preventDefault();
                this.setState({
                    keyboardSelectedDropdownItem: ((keyboardSelectedDropdownItem + valueCount) - 1) % valueCount
                });
                break;
            case 40: // down array
                e.preventDefault();
                this.setState({
                    keyboardSelectedDropdownItem: ((keyboardSelectedDropdownItem) + 1) % valueCount
                });
                break;
            case 13: { // enter
                e.preventDefault();
                const selected = this.getFilteredOptions()[keyboardSelectedDropdownItem];
                this.handleDropdownSelection(selected.value);
            }
        }

    }

    handleDropdownHide() {
        return this.setState({showDropdown: false});
    }

    handleDropdownShow() {
        return this.setState({showDropdown: true});
    }

    getFilteredOptions() {

        const {id, def: {values}} = this.props;

        const options = values.map((v) => {
            const label = v.labelFormatted || v.label;
            return Object.assign({}, v, {
                key: id + '-' + v.value,
                value: v.value,
                label
            });
        });

        const byInput = () => {
            const otherInputValue = this.isOther() ? this.getValue().substring(7) : '';
            if (otherInputValue) {
                const regexp = new RegExp(`.*${otherInputValue}.*`, 'i');
                return (o) => regexp.test(o.label);
            }
            return () => true;
        };

        return options.filter(byInput());

    }

    getFilteredOptionsCount() {
        return this.getFilteredOptions().length;
    }

    render() {

        const {id, intl} = this.props;
        const {showDropdown, keyboardSelectedDropdownItem} = this.state;

        const getInputValue = () => {

            const currentValue = this.getValue();

            if (!currentValue) {
                return '';
            }

            if (this.isOther()) {
                return currentValue.substring(7);
            }

            const generatedMessageId = eventFieldValueLabel(
                this.props.eventId,
                this.props.id,
                ('' + currentValue).toLocaleLowerCase()
            );

            return intl.formatMessage({id: generatedMessageId});

        };

        const placeholderMessage = {
            id: eventFieldPlaceholder(
                this.props.eventId,
                this.props.id,
            ),
            // force empty placeholder if key is not defined for the current locale
            // an empty string would be ignored by react-intl, so we need to have
            // at least one space character here
            defaultMessage: ' ',
        };

        const Dropdown = ({visible}) => {

            // reset the keyboard selection reference
            this.keyboardSelectedDropdownItemRef = null;

            if (!visible) return null;

            const filteredOptions = this.getFilteredOptions();

            const selectedFilteredOptions = filteredOptions.map((o, ix) => {
                o.selected = ix === keyboardSelectedDropdownItem;
                return o;
            });

            const listClasses = classNames('progether-select-dropdown', {
                'active': visible
            });

            return (
                <ul className={listClasses} ref={(node) => this.myRefs.dropdownList = node}>
                    {selectedFilteredOptions.map(o => {

                        const itemClasses = classNames({
                            active: o.selected
                        });

                        const label = o.labelFormatted ? o.labelFormatted : o.label;

                        return (
                            <li className={itemClasses}
                                ref={(node) => o.selected ? this.myRefs.keyboardSelectedDropdown = node : ''}
                                key={o.key}
                                onClick={() => this.handleDropdownSelection(o.value)}>
                                {label}
                            </li>
                        );

                    })}
                </ul>
            );

        };

        Dropdown.propTypes = {
            visible: PropTypes.bool,
        };

        return (
            <div className="progether-select">
                <div className="input-group">
                    <input
                        className="form-control"
                        type="text"
                        name={id}
                        value={getInputValue()}
                        placeholder={intl.formatMessage(placeholderMessage)}
                        onFocus={() => this.handleDropdownShow()}
                        onBlur={(e) => this.handleBlur(e)}
                        onKeyDown={(e) => this.handleInputKeyDown(e)}
                        onChange={(e) => this.handleInputChange(e)}/>
                    <span className="input-group-btn">
                        <button
                            className="btn btn-default"
                            type="button"
                            ref={(node) => this.myRefs.dropdownButton = node}
                            onFocus={() => this.handleDropdownShow()}
                            onBlur={() => this.handleBlur()}
                            onKeyDown={(e) => this.handleInputKeyDown(e)}>
                            <i className="fa fa-chevron-down"/>
                        </button>
                    </span>
                    <Dropdown visible={showDropdown}/>
                </div>
            </div>
        );

    }

}

export default injectIntl(OneOfOtherSelect);
