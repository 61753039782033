import classNames from "classnames";
import styles from './menu-bar.module.less';

export function MenuBar({ children, className, style }) {

    return (
        <div className={classNames(styles['menu-bar'], className)} style={style}>
            {children}
        </div>
    )
}