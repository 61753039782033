import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormsMixin} from '../forms-mixin';
import StructTypes from '../../prop-types';
import Input from '../text/input';
import OneOfBtnDropdown from '../one-of/btn-dropdown';
import './input-with-right-button-dropdown.less';

export default function (schemaValueFieldId = 'value', schemaUnitFieldId = 'unit') {

    class InputWithRightButtonDropdown extends FormsMixin(Component) {

        static propTypes = {
            value: StructTypes.fieldValue,
            eventData: PropTypes.object,
            eventId: PropTypes.string
        };

        render() {

            const field = this.getSubfield(this.props.id);
            if(!field) return null;

            const valueField = field.type.fields.find(f => f.id === this.props.id + '/' + schemaValueFieldId);
            const unitField = field.type.fields.find(f => f.id === this.props.id + '/' + schemaUnitFieldId);
            const unitDef = this.transformDefValuesOfField(unitField.def, unitField.id);

            const id = this.props.id;
            const onChange = this.props.onChange;
            // Using a custom onChange function will ensure we always
            // set the default unit value. If we do not set it here,
            // data might be subtly broken, but invisible to the user.
            const withDefaultUnitIfNeeded = (fieldId, value) => {
                if (fieldId === valueField.id) {
                    const unitValue = this.getValue()[schemaUnitFieldId];
                    const hasUnitValue = !!(unitValue && unitValue._value);
                    if (!hasUnitValue) {
                        const defaultUnitValue = getDefaultListValue(unitDef);
                        onChange(id, {
                            [schemaValueFieldId]: {
                                _value: value,
                            },
                            [schemaUnitFieldId]: {
                                _value: defaultUnitValue,
                            },
                        });
                        return;
                    }
                }
                onChange(fieldId, value);
            };

            return (
                <div>
                    <div className="input-group input-with-right-button-dropdown">
                        <Input
                            def={valueField.def}
                            id={valueField.id}
                            eventData={this.props.eventData}
                            eventId={this.props.eventId}
                            onChange={withDefaultUnitIfNeeded}/>
                        <OneOfBtnDropdown
                            def={unitDef}
                            id={unitField.id}
                            eventData={this.props.eventData}
                            outerClassName="input-group-btn input-group-btn-dropdown"
                            onChange={withDefaultUnitIfNeeded}
                            eventId={this.props.eventId}
                        />
                    </div>
                </div>
            );

        }

    }

    return InputWithRightButtonDropdown;

    function getDefaultListValue(def) {
        if (!Array.isArray(def.values)) {
            console.warn('trying to get default list value from non list definition');
            return undefined;
        }
        const first = def.values[0];
        return typeof first === 'object' ? first.value : first;
    }

}
