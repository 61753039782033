import {defineMessages, FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import './edit-title.less';
import {Conditional} from '../utils/conditional';

export const messages = defineMessages({
    backLabel: {
        id: "form_event_prev",
        defaultMessage: "Back"
    },
});

export function EditTitle(props) {

    return (
        <div className="edit-event-title">

            <FormattedMessage {...props.backText}>
                {txt =>
                    <a href="#"
                       className="btn btn-default btn-outline"
                       onClick={(e) => {
                           e.preventDefault();
                           props.onBack();
                       }}>
                        <i className="fa fa-chevron-left"/> {txt}
                    </a>
                }
            </FormattedMessage>

            <div> { /* we always need three elements for the layout to work */}
                <Conditional cond={!!props.titleText}>
                    <FormattedMessage {...props.titleText} tagName="h2" />
                </Conditional>
            </div>

            <div> { /* we always need three elements for the layout to work */}
                <Conditional cond={typeof props.onHelp === 'function'}>

                    <FormattedMessage {...props.helpText}>
                        {
                            txt => (
                                <a href="#"
                                   className="btn btn-link edit-event-title-help-btn"
                                   onClick={(e) => {
                                       e.preventDefault();
                                       props.onHelp();
                                   }}>
                                    {txt}
                                </a>
                            )
                        }
                    </FormattedMessage>
                </Conditional>
            </div>

        </div>
    );

}

const messageType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string,
});

EditTitle.propTypes = {
    backText: messageType,
    titleText: messageType,
    helpText: messageType,
    onHelp: PropTypes.func,
    onBack: PropTypes.func,
};

EditTitle.defaultProps = {
    backText: messages.backLabel,
};
