import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage} from "react-intl";
import classNames from 'classnames';
import './split-dropdown-button.less';

const messages = defineMessages({
    defaultLabelMessage: {
        id: 'component_split_dropdown_button_default_label',
        defaultMessage: 'Action',
    },
    defaultDropdownTitleMessage: {
        id: 'component_split_dropdown_default_dropdown_title',
        defaultMessage: 'Toggle Dropdown',
    },
});

const messagePropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string,
});

const actionPropTypes = {
    actionLabelMessage: messagePropType.isRequired,
    onAction: PropTypes.func.isRequired,
};

/**
 * A bootstrap v3 split button dropdown
 * https://getbootstrap.com/docs/3.3/components/#btn-dropdowns
 */
export default class SplitDropdownButton extends Component {

    constructor(props) {
        super(props);
        this.state = {open: false};
    }

    static propTypes = {
        defaultActionLabelMessage: messagePropType,
        defaultAction: PropTypes.func.isRequired,
        defaultActionStyle: PropTypes.oneOf([
            'default',
            'primary',
            'success',
            'info',
            'warning',
            'danger',
        ]),
        defaultActionLabelBefore: PropTypes.node,
        defaultActionLabelAfter: PropTypes.node,
        dropdownTitleMessage: messagePropType,
        actions: PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.shape(actionPropTypes),
            PropTypes.shape({}),
        ])),
        disabled: PropTypes.bool,
        dropdownAlignment: PropTypes.oneOf([
            'left',
            'right',
        ]),
    };

    static defaultProps = {
        defaultActionLabelMessage: messages.defaultLabelMessage,
        dropdownTitleMessage: messages.defaultDropdownTitleMessage,
        defaultActionStyle: 'default',
        actions: [],
        dropdownAlignment: 'left',
    };

    render() {

        const {
            defaultActionLabelMessage,
            defaultActionStyle,
            defaultAction,
            defaultActionLabelBefore,
            defaultActionLabelAfter,
            dropdownTitleMessage,
            disabled,
            actions,
            dropdownAlignment,
        } = this.props;

        const {
            open
        } = this.state;

        if (!actions || actions.length === 0) {
            return <DefaultActionButton/>;
        }

        return (

            <div className={classNames('btn-group split-dropdown-button', {open})}>
                <DefaultActionButton/>
                <FormattedMessage {...dropdownTitleMessage}>
                    {
                        txt => (
                            <button type="button"
                                    disabled={disabled}
                                    className={`btn btn-${defaultActionStyle} dropdown-toggle`}
                                    data-toggle="dropdown"
                                    title={txt}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({open: !open});
                                    }}
                                    aria-haspopup="true" aria-expanded={open}>
                                <span className="caret"/>
                                <span className="sr-only">{txt}</span>
                            </button>
                        )
                    }
                </FormattedMessage>
                <ul className={classNames('dropdown-menu', { 'dropdown-menu-right': dropdownAlignment === 'right'})}>
                    {
                        actions.map(a => a.onAction
                            ? <DropdownAction {...a} onAction={() => {
                                this.setState({open: false});
                                a.onAction();
                            }}/>
                            : <Separator {...a}/>
                        )
                    }
                </ul>
            </div>

        );

        function DefaultActionButton() {
            return (
                <FormattedMessage {...defaultActionLabelMessage}>
                    {txt => <button type="button"
                                    disabled={disabled}
                                    className={`btn btn-${defaultActionStyle}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        defaultAction();
                                    }}>
                        {defaultActionLabelBefore}
                        {txt}
                        {defaultActionLabelAfter}
                    </button>}
                </FormattedMessage>
            );
        }

    }

}

function DropdownAction(props) {

    return (
        <FormattedMessage {...props.actionLabelMessage}>
            {
                txt => (
                    <li><a href="#" onClick={(e) => {
                        e.preventDefault();
                        props.onAction();
                    }}>{txt}</a></li>
                )
            }

        </FormattedMessage>
    );

}

DropdownAction.propTypes = actionPropTypes;

function Separator() {

    return (
        <li role="separator" className="divider"/>
    );

}
